import { InputAdornment, TextField } from '@mui/material';
import { Search } from '@mui/icons-material';
import './search-input.scss';

export const SearchIconPosition = Object.freeze({
  START: 'start',
  END: 'end',
  NONE: 'none',
});

function SearchIconAdornment({ position }) {
  return (
    <InputAdornment position={position}>
      <Search />
    </InputAdornment>
  );
}

export default function SearchInput({ placeholder, value, setValue, searchIconPosition = SearchIconPosition.START }) {
  return (
    <div className="search-input" data-testid="search-input">
      <TextField
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        InputProps={{
          startAdornment: searchIconPosition === SearchIconPosition.START && <SearchIconAdornment position="start" />,
          endAdornment: searchIconPosition === SearchIconPosition.END && <SearchIconAdornment position="end" />,
        }}
      />
    </div>
  );
}
