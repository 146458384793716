/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subEntityState: {},
};

export const entityFlowSlice = createSlice({
  name: 'entityFlow',
  initialState,
  reducers: {
    setSubEntities: (state, { payload }) => {
      state.subEntityState[payload.id] = payload.subEntitiesObj;
    },
    resetSubEntitiesState: (state) => {
      state.subEntityState = {};
    },
  },
});

export const { setSubEntities, resetSubEntitiesState } = entityFlowSlice.actions;

// this is for configureStore
export default entityFlowSlice.reducer;
