import classNames from 'classnames';
import './colored-box.scss';

export default function ColoredBox({
  isFilled = false,
  hasBorder = true,
  text,
  icon,
  color,
  hasPadding = true,
  style = {},
  className,
}) {
  return (
    <div
      className={classNames(
        'bordered-box',
        className,
        color,
        { filled: isFilled },
        { 'no-border': !hasBorder },
        { 'no-padding': !hasPadding },
      )}
      style={style}
    >
      {icon && <span className="icon">{icon}</span>}
      <span className="text"> {text}</span>
    </div>
  );
}
