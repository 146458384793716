import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

function ToBeStyledTooltip({ className, ...props }) {
  return <Tooltip {...props} classes={{ tooltip: className }} />;
}

export const WhiteTooltip = styled(ToBeStyledTooltip)(() => ({
  backgroundColor: '#FFF',
  color: 'black',
  border: '1px solid #E8E8E8',
  boxShadow: '0px 5px 15px rgba(16, 24, 40, 0.1)',
  borderRadius: '10px',
  maxWidth: '220px',
}));

export const WideWhiteTooltip = styled(WhiteTooltip)(() => ({
  maxWidth: '350px',
  width: '350px',
}));
