import { useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import Tags from '../../../components/tags/Tags';
import Table from '../../../components/table/Table';
import NoData from '../../../components/no-data/NoData';
import { StyledSwitch } from '../../../components/StyledSwitch';
import ConnectionShareType from '../../connection/ConnectionShareType';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import InternalLink from '../../../components/internal-link/InternalLink';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import ConnectorName from '../../../components/connector-name/ConnectorName';

import flowIcon from './icons/flow_icon.svg';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';

export default function DataSources({ connections, isLoading, linksBreadcrumbs }) {
  const [useDirectDataSources, setUseDirectDataSources] = useState(false);
  const appConnections = useDirectDataSources ? connections.filter(({ usedDirectly }) => usedDirectly) : connections;
  const { t } = useTranslation(['common', 'single-application']);

  const columns = useMemo(
    () => [
      {
        Header: t('general.name'),
        accessor: 'name',
        width: '30%',
        Cell: ({ value, cell }) => (
          <InternalLink text={value} url={`/data/${cell.row.original.id}?from=${linksBreadcrumbs}`} />
        ),
      },
      {
        Header: t('general.type'),
        accessor: 'connector.name',
        width: '15%',
        Cell: ({ value, cell }) => <ConnectorName name={value} connectorName={cell.row.original.connector.name} />,
      },
      {
        Header: t('general.dataSources'),
        accessor: 'data',
        Cell: ({ value }) => value.length || '-',
        width: '13%',
      },
      {
        Header: t('general.share'),
        accessor: 'shareType',
        Cell: ({ value }) => <ConnectionShareType type={value} />,
        width: '13%',
      },
      {
        Header: t('general.usedBy'),
        accessor: 'usedDirectly',
        Cell: ({ value, cell }) => {
          if (value) {
            return t('general.direct');
          }
          const apps = cell.row.original.connectionAccess.flatMap((access) => access.appInstance.application);
          const tooltipId = `indirect-flows-${cell.row.original.id}`;

          return (
            <div className="connection-indirect-flows">
              Indirect
              {apps.length && (
                <div className="flows">
                  <img src={flowIcon} alt={t('applications.flows')} />
                  <div data-tooltip-id={tooltipId}>{t('applications.numberFlows', { count: apps.length })}</div>
                  <Tooltip id={tooltipId} clickable>
                    {apps.map((app) => (
                      <InternalLink key={app.name} text={app.name} url={`/applications/${app.id}`} />
                    ))}
                  </Tooltip>
                </div>
              )}
            </div>
          );
        },
        width: '13%',
      },
      {
        Header: t('tags.tags'),
        accessor: 'connectionTags',
        Cell: ({ value, cell }) =>
          value?.length ? (
            <Tags tags={value.map((tag) => ({ name: tag.tagsName }))} tooltipAnchor={`tags-${cell.row.original.id}`} />
          ) : (
            <div className="gray">{t('tags.untagged')}</div>
          ),
        width: '13%',
      },
    ],
    [linksBreadcrumbs],
  );

  const sortBy = [{ id: 'name', desc: false }];

  return (
    <CollapseCard
      title={t('general.dataSources')}
      initialOpen={false}
      isLoading={isLoading}
      totalElementsCount={appConnections?.length}
    >
      <div className="show-closed-toggle">
        <span>{t('general.onlyDirect')}</span>
        <StyledSwitch checked={useDirectDataSources} onChange={() => setUseDirectDataSources(!useDirectDataSources)} />
      </div>
      {appConnections?.length ? (
        <Table
          columns={columns}
          data={appConnections}
          options={{ showFilter: false, showPagination: false, showSort: false }}
          type="connections"
          sortBy={sortBy}
          initialFilters={[]}
          customFilters={[]}
          isRowDisabled={() => false}
          getElementId={(connection) => connection.id}
          isLoading={isLoading}
          classNames="card-view-table"
          emptyState={<TableEmptyContent img={emptyApplicationsImg} mainText={t('connections.noConnections')} />}
        />
      ) : (
        <NoData text={t('noDataSourcesFound', { ns: 'single-application' })} />
      )}
    </CollapseCard>
  );
}
