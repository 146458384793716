import { useTranslation } from 'react-i18next';
import Button from '../buttons/button/Button';

export default function FilterValue({ title, children, onClose, onApply }) {
  const { t } = useTranslation(['common']);

  return (
    <div className="apply-filter-box">
      <div className="filter-title">{title}</div>
      <div className="content">{children}</div>
      <div className="buttons">
        <Button isFilled={false} onClick={onClose} text={t('general.cancel')} />
        <Button onClick={onApply} text={t('tables.filters.applyFilter')} />
      </div>
    </div>
  );
}
