import { getConnectorIcon } from '../../pages/data-source/connector-icons/connectorsIconsService';
import './connector-name.scss';
import EllipsisText from '../ellipsis-text/EllipsisText';

export default function ConnectorName({ name, connectorName, shouldEllipsis = false }) {
  const img = getConnectorIcon(connectorName);

  return (
    <div className="connector-name">
      <img src={img} alt={name} />
      {shouldEllipsis ? <EllipsisText text={name} /> : <span>{name}</span>}
    </div>
  );
}
