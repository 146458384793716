import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import { mapDate } from '../../../services/MapperUtils';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';

export default function UserActivityTable({ isLoading, activities = [] }) {
  const { t } = useTranslation(['common', 'single-user']);
  const tableName = t('activityTable.tableName', { ns: 'single-user' });

  const columns = useMemo(
    () => [
      {
        Header: t('applications.applicationName', { ns: 'common' }),
        accessor: 'applicationName',
        width: '30%',
        Cell: ({ value, cell }) => (
          <OrganizationAwareLink to={`/applications/${cell.row.original.applicationId}`}>{value}</OrganizationAwareLink>
        ),
        sortType: (rowA, rowB) => {
          const a = rowA.original.applicationName.toLowerCase();
          const b = rowB.original.applicationName.toLowerCase();
          return a.localeCompare(b);
        },
      },
      {
        Header: t('general.date', { ns: 'common' }),
        accessor: 'latestActivity',
        width: '33%',
        Cell: ({ value }) => mapDate(value),
      },
      {
        Header: t('general.owner', { ns: 'common' }),
        accessor: 'owner',
        width: '15%',
        Cell: ({ value }) => value || '-',
        sortType: (rowA, rowB) => {
          if (!rowA.original.owner) {
            return 1;
          }
          const a = rowA.original.owner;
          const b = rowB.original.owner;
          return a.localeCompare(b);
        },
      },
    ],
    [],
  );

  const sortBy = [{ id: 'name', desc: false }];

  return (
    <div className="card-view-table-container">
      <div className="collapse-card-container">
        <CollapseCard title={tableName} isLoading={isLoading} totalElementsCount={activities.length} initialOpen>
          <Table
            columns={columns}
            data={activities}
            totalCount={activities.length}
            options={{ showFilter: false, showPagination: true, showSort: true, usePaginationSearchParam: false }}
            type="users"
            shouldScrollOnPagination={false}
            sortBy={sortBy}
            initialFilters={[]}
            customFilters={[]}
            isRowDisabled={() => false}
            getElementId={(activity) => activity.applicationId}
            isLoading={isLoading}
            classNames="card-view-table"
            backendPagination={false}
            pageSize={5}
            emptyState={
              <TableEmptyContent
                img={emptyApplicationsImg}
                mainText={t('activityTable.noData', { ns: 'single-user' })}
              />
            }
          />
        </CollapseCard>
      </div>
    </div>
  );
}
