import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import rulesIcon from './rules_icon.svg';
import HomePageCounter from '../HomePageCounter';
import { getRules, isRulesLoading } from '../../../redux/selector/policySelector';
import Loader from '../../../components/loader/Loader';

export default function RulesCounter() {
  const rules = useSelector(getRules);
  const loading = useSelector(isRulesLoading);
  const { t } = useTranslation(['homePage']);

  return loading ? (
    <Loader />
  ) : (
    <HomePageCounter count={rules?.length} text={t('systemWidget.totalRules')} src={rulesIcon} />
  );
}
