import { getTenantId, getTenants } from '../services/AuthService';

export const Roles = Object.freeze({
  superAdmin: 'super-admin',
  admin: 'admin',
  reader: 'reader',
});

const SUPER_ADMIN_ORG = '*';

function hasRole(roles, role) {
  const tenants = getTenants();
  const tenantId = getTenantId();
  const currentOrgId = tenants?.find(({ name }) => name === tenantId)?.id;
  return roles?.[currentOrgId]?.some(({ name }) => name === role);
}

export function isSuperAdmin(roles) {
  return roles?.[SUPER_ADMIN_ORG]?.[0]?.name === Roles.superAdmin;
}

export function isAdmin(roles, organizationId) {
  return hasRole(roles, Roles.admin, organizationId);
}
