export const AppType = Object.freeze({
  CANVAS_APP: 'Canvas App',
  AUTOMATION: 'Automation',
  CLOUD_FLOW: 'Cloud Flow',
  DESKTOP_FLOW: 'Desktop Flow',
  MODULE_DRIVEN_APP: 'Module Driven App',
  PORTAL: 'Portal',
  BI: 'BI',
  EMBEDDED_SHAREPOINT: 'Embedded SharePoint',
  COPILOT_BOT: 'Copilot Bot',
});
