import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

export function StatusBullet({ value, active, loading, accountName }) {
  const { t } = useTranslation(['common']);
  return (
    <div className="status-bullet-container">
      {accountName && <div className="account-name">{`${t('general.platform')}: ${accountName}`}</div>}
      <div className="status-bullet">
        {!loading && <div className={classnames('bullet', { inactive: !active, active })} />}
        {value}
      </div>
    </div>
  );
}
