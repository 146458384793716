import { useState } from 'react';
import { compact } from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoopIcon from '@mui/icons-material/Loop';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import CloseableModal from './modal/ClosableModal';
import Input from './input/Input';
import { resetCreateProcess, scheduleReport } from '../redux/slicers/notificationsSlicer';
import { getReportError, getReportSuccessful } from '../redux/selector/notificationSelector';
import ButtonWithIcon from './buttons/button-with-icon/ButtonWithIcon';
import Button from './buttons/button/Button';
import Toast from './toast/Toast';

export default function ScheduleReport({ getFiltersSelector, type }) {
  const filters = useSelector(getFiltersSelector);
  const dispatch = useDispatch();
  const { user } = useAuth0();

  const [modalOpen, setModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [scheduleInterval, setScheduleInterval] = useState('daily');
  const [name, setName] = useState('');
  const [message, setMessage] = useState([]);
  const [toastOpen, setToastOpen] = useState(false);

  const reportError = useSelector(getReportError);
  const reportSuccessful = useSelector(getReportSuccessful);

  const { t } = useTranslation(['common']);

  function closeModal() {
    dispatch(resetCreateProcess());
    setModalOpen(false);
    setEmail('');
    setScheduleInterval('daily');
    setName('');
    setMessage('');
  }

  async function submit() {
    const errors = [];
    if (email.trim() === '') {
      errors.push(t('validationErrors.emailRequired'));
    } else if (!email.includes('@')) {
      errors.push(t('validationErrors.emailInvalid'));
    }
    if (name.trim() === '') {
      errors.push(t('validationErrors.nameRequired', { name: 'Report' }));
    }
    if (errors.length > 0) {
      setMessage(errors);
      return;
    }
    dispatch(resetCreateProcess());
    setMessage([]);

    await dispatch(
      scheduleReport({
        name,
        interval: scheduleInterval,
        email,
        reportType: type,
        filters,
        auditUserName: user.name,
        auditUserEmail: user.email,
      }),
    );

    setModalOpen(false);
    setToastOpen(true);
  }

  return (
    <div>
      <CloseableModal open={modalOpen} closeModal={() => setModalOpen(false)}>
        <div className="title">{t('reports.scheduleReport')}</div>
        <div className="body">
          <Input
            id="name"
            value={name}
            setValue={setName}
            placeholder={t('reports.setReportName')}
            type="text"
            required
          />
          <Input
            id="email"
            value={email}
            setValue={setEmail}
            placeholder={t('reports.setEmail')}
            type="email"
            required
          />
          <RadioGroup
            row
            id="interval"
            defaultValue={scheduleInterval}
            label="interval"
            onChange={(e) => setScheduleInterval(e.target.value)}
          >
            <FormControlLabel value="daily" control={<Radio />} label={t('reports.interval.daily')} />
            <FormControlLabel value="weekly" control={<Radio />} label={t('reports.interval.weekly')} />
            <FormControlLabel value="monthly" control={<Radio />} label={t('reports.interval.monthly')} />
          </RadioGroup>

          <div className="error">
            {compact(message || []).map((err) => (
              <li>{err}</li>
            ))}
          </div>

          <div className="buttons-row">
            <Button isFilled={false} text={t('general.close')} onClick={closeModal} />
            <Button isFilled text={t('reports.schedule')} onClick={submit} />
          </div>
        </div>
      </CloseableModal>
      <ButtonWithIcon icon={<LoopIcon />} text={t('reports.scheduleReport')} onClick={() => setModalOpen(true)} />
      <Toast
        closeTimeout={3000}
        isSuccess={reportSuccessful}
        open={toastOpen}
        setOpen={setToastOpen}
        title={t(reportSuccessful ? 'reports.scheduleResult.success' : 'reports.scheduleResult.failed')}
        message={reportError}
      />
    </div>
  );
}
