export const insightSeverity = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  CRITICAL: 'critical',
};

export const insightSeverityToBackendValue = {
  INFO: '-1',
  LOW: '0',
  MEDIUM: '1',
  HIGH: '2',
  CRITICAL: '3',
};
