import { combineReducers } from 'redux';
import authSlicer from './slicers/authSlicer';
import applicationSlicerReducer from './slicers/applicationSlicer';
import insightsSliceReducer from './slicers/insightSlicer';
import environmentsSlicer from './slicers/environmentsSlicer';
import policySlicer from './slicers/policySlicer';
import homepageSlicer from './slicers/homepageSlicer';
import notificationChannelsSliceReducer from './slicers/notificationsSlicer';
import tasksSlicer from './slicers/tasksSlicer';
import dataSources from './slicers/dataSourceSlicer';
import tags from './slicers/dataTagSlicer';
import historySlicer from './slicers/historySlicer';
import connectionSlicer from './slicers/connectionsSlicer';
import csvSliceReducer from './slicers/csvSlicer';
import usersSlicer from './slicers/usersSlicer';
import accountSlicer from './slicers/accountSlicer';
import biSlicer from './slicers/biSlicer';
import entityFlowSlicer from './slicers/entityFlowSlicer';
import sandboxFlowSlicer from './slicers/sandboxFlowSlicer';

const appReducer = combineReducers({
  auth: authSlicer,
  accounts: accountSlicer,
  entityFlow: entityFlowSlicer,
  sandboxFlow: sandboxFlowSlicer,
  homepage: homepageSlicer,
  application: applicationSlicerReducer,
  insights: insightsSliceReducer,
  dataSources,
  environments: environmentsSlicer,
  policy: policySlicer,
  notificationChannels: notificationChannelsSliceReducer,
  tasks: tasksSlicer,
  history: historySlicer,
  connection: connectionSlicer,
  csv: csvSliceReducer,
  users: usersSlicer,
  bi: biSlicer,
  tags,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
