import './chart-legend.scss';

export function getHtmlLegendPlugin({ backgroundColorProp = 'strokeStyle' }) {
  return {
    id: 'htmlLegend',
    afterUpdate(chart, args, options) {
      const container = document.getElementById(options.legendContainerId);

      // Remove old legend items
      while (container.firstChild) {
        container.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item, index) => {
        const legendItem = document.createElement('div');
        legendItem.className = 'legend-item';

        legendItem.onclick = () => {
          chart.setDatasetVisibility(index, item.hidden);
          chart.update();
        };

        const circle = document.createElement('div');
        circle.style.backgroundColor = item[backgroundColorProp];
        circle.className = 'legend-item-circle';

        const text = document.createElement('div');
        text.style.textDecoration = item.hidden ? 'line-through' : '';
        text.className = 'legend-item-text';
        const textNodeValue = document.createTextNode(item.text);
        text.appendChild(textNodeValue);

        legendItem.appendChild(circle);
        legendItem.appendChild(text);

        container.appendChild(legendItem);
      });
    },
  };
}
