import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/card/Card';
import {
  lightBlueColor,
  lightGreenColor,
  lightOrangeColor,
  lightPurpleColor,
  lightRedColor,
} from '../../../consts/colors';
import DoughnutChart from '../../../components/chart/doughnut/DoughnutChart';
import { getHomePagePlatformName, isHomePagePlatformNameLoading } from '../../../redux/selector/homepageSelector';
import { enrichLinkWithFilters } from '../utils';

export default function ApplicationsByPlatform({ inProduction }) {
  const applicationByPlatform = useSelector(getHomePagePlatformName);
  const loading = useSelector(isHomePagePlatformNameLoading);
  const { t } = useTranslation(['homePage']);

  const data = {
    labels: applicationByPlatform.map((platform) => platform.key),
    datasets: [
      {
        data: applicationByPlatform.map((platform) => Number(platform.value)),
        backgroundColor: [lightGreenColor, lightBlueColor, lightPurpleColor, lightOrangeColor, lightRedColor],
        borderWidth: 3,
      },
    ],
  };

  const chartSize = window.innerWidth > 1600 ? 160 : 120;

  const enrichedNavPath = useMemo(
    () => `${enrichLinkWithFilters(null, 'createdAt', inProduction, '')}&platform:is`,
    [inProduction],
  );

  return (
    <Card
      title={t('applicationsWidget.applicationsByPlatform')}
      loading={loading}
      dataTestId="applications-by-platform-card"
    >
      <div className="chart-wrapper">
        <DoughnutChart
          id="by-platform"
          width={chartSize}
          height={chartSize}
          data={data}
          loading={loading}
          tooltipDark={false}
          legendValues={false}
          navigatePath="/applications"
          navigateQueryParam={enrichedNavPath}
        />
      </div>
    </Card>
  );
}
