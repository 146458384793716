import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import './checkbox.scss';

function CustomCheckbox({ checked, indeterminate, onChange, name, value, label, isDisabled = false }) {
  return (
    <FormControlLabel
      label={label}
      control={
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
          name={name}
          disabled={isDisabled}
          value={value}
          className="checkbox"
        />
      }
    />
  );
}

export default CustomCheckbox;
