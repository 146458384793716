export function enrichLinkWithFilters(startDate, timeColumnName, inProduction, link) {
  let enrichedLink = link;
  if (startDate) {
    enrichedLink = `${enrichedLink}&${timeColumnName}:after=${startDate}`;
  }
  if (inProduction) {
    enrichedLink = `${enrichedLink}&inProduction:is=True`;
  }
  return enrichedLink;
}
