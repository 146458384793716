import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HomePageTable } from './HomePageTable';
import ColoredBox from '../../../components/box/ColoredBox';
import PersonName from '../../../components/person-name/PersonName';
import BooleanValue from '../../../components/boolean-value/BooleanValue';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import {
  getHomePageUnattendedApplications,
  isHomePageUnattendedApplicationsLoading,
} from '../../../redux/selector/homepageSelector';

const MIN_INSIGHTS = 5;

export default function UnattendedApplicationsTable({ inProduction }) {
  const unattendedApplications = useSelector(getHomePageUnattendedApplications);
  const loading = useSelector(isHomePageUnattendedApplicationsLoading);
  const { t } = useTranslation(['common', 'homePage']);

  const columns = useMemo(
    () => [
      {
        Header: t('applications.applicationName'),
        backendKey: 'name',
        width: '30%',
        accessor: (cell) => cell.name,
        Cell: ({ row, value }) => (
          <OrganizationAwareLink to={`/applications/${row.original.id}?from=Overview`}>{value}</OrganizationAwareLink>
        ),
      },
      {
        Header: t('general.owner'),
        backendKey: 'owner',
        width: '15%',
        accessor: (cell) => cell.owner,
        Cell: ({ value }) => <PersonName name={value} />,
        sortType: (rowA, rowB) => {
          const a = (rowA.original.owner ?? '').toLowerCase();
          const b = (rowB.original.owner ?? '').toLowerCase();
          return a.localeCompare(b);
        },
      },
      {
        Header: t('general.inProduction'),
        backendKey: 'inProduction',
        width: '10%',
        accessor: (cell) => cell.inProduction,
        Cell: ({ value }) => <BooleanValue isTrue={value} />,
        sortType: (rowA, rowB) => {
          const a = rowA.original.inProduction;
          const b = rowB.original.inProduction;
          return b - a; // reverse order so true is first
        },
      },
      {
        Header: t('insights.insights'),
        backendKey: 'insights',
        width: '10%',
        accessor: (cell) => cell.insights,
        Cell: ({ value }) => (
          <ColoredBox isFilled text={value} hasBorder={false} color="gray" style={{ fontSize: '12px' }} />
        ),
      },
    ],
    [],
  );

  return (
    <HomePageTable
      type="home.unattendedApplications"
      tooltipText={t('homePageTables.unattendedApplicationsTooltip', { minInsights: MIN_INSIGHTS, ns: 'homePage' })}
      title={t('homePageTables.unattendedApplications', { ns: 'homePage' })}
      columns={columns}
      data={unattendedApplications}
      isLoading={loading}
      link={`/applications?sort=insights,desc&insights:greaterThan=${MIN_INSIGHTS}${
        inProduction ? '&inProduction:is=true' : ''
      }`}
      emptyState={
        <TableEmptyContent
          img={emptyApplicationsImg}
          mainText={t('homePageTables.noUnattendedApplications', { ns: 'homePage' })}
          secondaryText=""
        />
      }
    />
  );
}
