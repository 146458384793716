import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteNotificationChannelError,
  deleteNotificationChannelLoading,
  getNotificationChannelById,
} from '../../../redux/selector/notificationSelector';
import ActionModal from '../../../components/modal/ActionModal';
import { deleteNotificationChannel } from '../../../redux/slicers/notificationsSlicer';

export default function DeleteNotificationChannelModal({ channelId, modalOpen, closeModal }) {
  const { t } = useTranslation(['settings']);
  const { user } = useAuth0();
  const dispatch = useDispatch();

  const loading = useSelector(deleteNotificationChannelLoading);
  const error = useSelector(deleteNotificationChannelError);
  const channel = useSelector(getNotificationChannelById(channelId));

  const toastTitle = t(
    !error ? 'notifications.channelsTable.deleteSuccess' : 'notifications.channelsTable.deleteFailed',
    {
      name: channel?.name,
    },
  );

  return (
    <ActionModal
      id="delete-notification-channel-modal"
      title={t('notifications.channelsTable.delete')}
      onApprove={async () =>
        dispatch(
          deleteNotificationChannel({
            channelId,
            body: {
              auditUserName: user.name,
              auditUserEmail: user.email,
            },
          }),
        )
      }
      loading={loading}
      open={modalOpen}
      closeModal={closeModal}
      toastSuccessful={!error}
      toastTitle={toastTitle}
    >
      {t('notifications.channelsTable.deleteConfirmation', {
        name: channel?.name,
      })}
    </ActionModal>
  );
}
