import { useSelector } from 'react-redux';
import { groupBy } from 'lodash';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import AppInstance from './AppInstance';
import AppInstanceStatus from './AppInstanceStatus';
import { getEnvironments } from '../../../redux/selector/environmentsSelector';
import SandboxFlowControls from './sandbox-flow-controls/SandboxFlowControls';
import { getEnvironmentName } from '../../../services/EnvironmentUtils';
import { POWER_PLATFORM } from '../../../consts/platforms';
import ExternalResourceLink from './ExternalResourceLink';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import { getAccountByPlatformName } from '../../../redux/selector/accountsSelector';

export default function AppInstances({
  platform,
  isFlow,
  isCopilot,
  accountId,
  appId,
  allAppInstances = [],
  isLoading = false,
}) {
  const environments = useSelector(getEnvironments);
  const powerAppsAccount = useSelector(getAccountByPlatformName(POWER_PLATFORM));
  const { t } = useTranslation(['single-application']);

  const byEnvironment = groupBy(allAppInstances, 'environmentId');

  return Object.entries(byEnvironment)
    .map(([envId, env]) => [getEnvironmentName(environments, envId), env, envId])
    .sort(([envNameA], [envNameB]) => envNameA.localeCompare(envNameB))
    .map(([environmentName, appInstances, environmentId]) => {
      const title = environmentName;
      const runningProcessesCount = appInstances.filter((a) => a.deployed).length;
      let environmentStatus;
      let environmentStatusColor;
      switch (runningProcessesCount) {
        case 0:
          environmentStatus = t('draftOnly');
          environmentStatusColor = 'gray';
          break;
        default:
          environmentStatus = t('numberRunningProcesses', { count: runningProcessesCount });
          environmentStatusColor = 'green';
      }
      if (appInstances.some((a) => a.status === 'SUSPENDED')) {
        environmentStatus = t(isFlow ? 'disabled' : 'quarantined');
        environmentStatusColor = 'orange';
      }

      const platformEnvId = (environments || []).find((env) =>
        [env.name, `${env.name} (Prod)`].includes(environmentName),
      )?.data?.environmentId;
      const link = appInstances[0].extraData?.platformLink;
      const isPowerApps = platform === POWER_PLATFORM;
      const showLink = !!platformEnvId && isPowerApps && link;
      const showSandboxFlowControls = isFlow && isPowerApps && powerAppsAccount?.data?.sandboxEnvId;
      const titleComponent = showLink ? (
        <div className="title">
          {title}
          <ExternalResourceLink text={title} href={link} />
          {showSandboxFlowControls && (
            <>
              <Divider orientation="vertical" flexItem />
              <SandboxFlowControls
                environmentId={environmentId}
                platformAppId={appInstances[0].platformAppId}
                accountId={accountId}
                flowId={appId}
              />
            </>
          )}
        </div>
      ) : (
        title
      );

      return (
        <div className="app-instances" key={environmentId}>
          <CollapseCard
            title={titleComponent}
            initialOpen={false}
            isLoading={isLoading}
            headerComponents={[
              <div className="environment-status" key={environmentId}>
                <AppInstanceStatus
                  text={environmentStatus}
                  backgroundColor={environmentStatusColor === 'orange' ? 'orange' : 'white'}
                  circleColor={environmentStatusColor}
                  textColor="black"
                />
              </div>,
            ]}
          >
            {appInstances
              .sort((a, b) => b.version.localeCompare(a.version))
              .map((instance) => (
                <AppInstance key={instance.id} appInstance={instance} platform={platform} isCopilot={isCopilot} />
              ))}
          </CollapseCard>
        </div>
      );
    })
    .flat();
}
