/* eslint-disable no-param-reassign */
import axios from 'axios';
import * as qs from 'qs';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { partition } from 'lodash';
import { doDelete, doGet, doPatch, doPost } from '../../services/HttpService';
import { EnvironmentType } from '../../pages/settings/platforms/power-platform/EnvironmentType';
import { clearMsalKeys, getTokenData } from '../../services/AuthService';
import { AzurePermissions } from '../../auth/azure-auth-config';
import { appendEnvironment } from './environmentsSlicer';
import { POWER_PLATFORM } from '../../consts/platforms';

export const fetchAccounts = createAsyncThunk('account/fetchAccounts', async () => {
  const res = await doGet('accounts');

  return res.data;
});

export const fetchPowerPlatformEnvironments = createAsyncThunk(
  'account/fetchPowerPlatformEnvironments',
  async ({ accountId, adminAccessToken }) => {
    const res = await doPost(`account/${accountId}/platform-environments`, {
      adminAccessToken,
    });

    return res.data;
  },
);

export const createPowerPlatformIntegration = createAsyncThunk(
  'account/createPowerPlatformIntegration',
  async ({
    platformTenantId,
    authenticationMethod,
    clientId,
    clientSecret,
    accessToken,
    email,
    auditUserEmail,
    auditUserName,
    name,
  }) => {
    let res = null;

    try {
      res = await doPost('account', {
        auditUserName,
        auditUserEmail,
        platform: POWER_PLATFORM,
        authenticationData: {
          clientId,
          clientSecret,
        },
        data: {
          platformTenantId,
          clientId,
        },
        adminAccessToken: accessToken,
        email,
        name,
        authenticationMethod,
      });
    } catch (e) {
      throw new Error(e.message);
    }

    // create Power BI environment
    await doPost(`account/${res.data.account.id}/environment`, {
      name: 'Power BI',
      isProduction: true,
      type: EnvironmentType.PowerBI,
      auditUserName,
      auditUserEmail,
      data: {},
    });

    return res.data;
  },
);

async function getAccessTokenForEnv(platformTenantId, refreshToken, instanceUrl) {
  try {
    const res = await axios.post(
      `https://login.microsoftonline.com/${platformTenantId}/oauth2/v2.0/token`,
      qs.stringify({
        grant_type: 'refresh_token',
        scope: `${instanceUrl}/.default`,
        refresh_token: refreshToken,
      }),
    );
    return res.data.access_token;
  } catch (e) {
    throw e?.message ? { message: e.message, status: e.status } : e;
  }
}

function getTokenDataAndValidate(clientId, type, scope) {
  const { secret: token, expiresOn } = getTokenData(clientId, type, scope) || {};
  const isTokenExpired = new Date(Number(expiresOn)).getTime() < Date.now() / 1000;

  if (!token || isTokenExpired) {
    clearMsalKeys(clientId);
    throw new Error('Power Platform authentication is incomplete. Please refresh the page');
  }
  return token;
}

export const deleteAccount = createAsyncThunk('account/deleteAccount', async ({ accountId, body }) => {
  const res = await doDelete(`account/${accountId}`, body);

  return res.data;
});

export const renameAccount = createAsyncThunk('account/renameAccount', async ({ accountId, body }) => {
  const res = await doPatch(`account/${accountId}/rename`, body);

  return res.data;
});

export const editAccountCredentials = createAsyncThunk(
  'account/editAccountCredentials',
  async ({ accountId, body }) => {
    const res = await doPatch(`account/${accountId}/credentials`, body);

    return res.data;
  },
);

export const createPowerPlatformEnvironments = createAsyncThunk(
  'account/createPowerPlatformEnvironment',
  async ({ accountId, environments, clientId, platformTenantId, auditUserName, auditUserEmail }, { dispatch }) => {
    const refreshToken = getTokenDataAndValidate(clientId, 'refreshToken');
    const powerPlatformToken = getTokenDataAndValidate(
      clientId,
      'accessToken',
      AzurePermissions.POWER_PLATFORM_USERS_APPLY,
    );

    const promises = environments.map(async ({ name, isProduction, platformEnvId, instanceUrl, instanceApiUrl }) => {
      const accessToken = await getAccessTokenForEnv(platformTenantId, refreshToken, instanceUrl);
      try {
        return await doPost(`account/${accountId}/environment`, {
          name,
          isProduction,
          auditUserName,
          auditUserEmail,
          type: EnvironmentType.Default,
          data: {
            environmentId: platformEnvId,
            instanceApiUrl,
          },
          instanceUrl,
          instanceApiUrl,
          clientId,
          platformTenantId,
          accessToken,
          powerPlatformToken,
        });
      } finally {
        // eslint-disable-next-line no-use-before-define
        dispatch(incrementCompletedEnvironmentsCounter({ environment: instanceUrl }));
      }
    });

    const envResults = await Promise.allSettled(promises);
    const [saved, failures] = partition(envResults, ({ status }) => status === 'fulfilled');

    if (!saved.length) {
      throw new Error('Failed to integrate environments');
    }

    const newEnvs = saved.map(({ value }) => value?.data);
    dispatch(appendEnvironment(newEnvs));

    return {
      succeedEnvs: saved.map(({ value }) => value?.data),
      failuresEnvs: failures.map((res) => {
        let envName = null;
        if (typeof res.reason?.message === 'string') {
          const url = res.reason?.message.match(/https:\/\/.*\.com/)?.[0];
          const environment = environments.find((env) => env.instanceApiUrl === url);
          envName = environment?.name;
        }

        return {
          reason: res.reason,
          name: envName,
        };
      }),
    };
  },
);

const initialState = {
  error: false,
  loading: true,
  content: [],
  azureConfigClientId: null,
  /*
  accountOperations structure like:
  [accountId]: {
    accountRename: {
      error: false,
      loading: false,
      errorMessage: null,
    },
    accountEditCredentials: {
      error: false,
      loading: false,
      errorMessage: null,
    }
  }
   */
  accountOperations: {},
  powerPlatform: {
    integrationCreate: {
      error: false,
      loading: false,
      errorMessage: null,
    },
    environments: {
      content: {
        allEnvs: [],
        succeedEnvs: [],
        failuresEnvs: [],
        completed: [],
      },
      error: false,
      loading: false,
      errorMessage: null,
      success: false,
    },
  },
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAccounts.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchAccounts.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.content = payload;
    });
    builder.addCase(fetchAccounts.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(deleteAccount.pending, (state, { meta }) => {
      const { accountId } = meta.arg;
      if (!state.accountOperations[accountId]) {
        state.accountOperations[accountId] = {};
      }
      state.accountOperations[accountId].deleteAccount = {
        loading: true,
        error: false,
        errorMessage: null,
      };
    });
    builder.addCase(deleteAccount.rejected, (state, { meta, error }) => {
      const { accountId } = meta.arg;
      state.accountOperations[accountId].deleteAccount = {
        loading: false,
        error: true,
        errorMessage: error.message,
      };
    });
    builder.addCase(deleteAccount.fulfilled, (state, { meta, payload }) => {
      const { accountId } = meta.arg;
      state.accountOperations[accountId].deleteAccount = {
        loading: false,
        error: false,
        errorMessage: null,
      };

      state.content = state.content.filter((account) => account.id !== payload.id);
    });
    builder.addCase(renameAccount.pending, (state, { meta }) => {
      const { accountId } = meta.arg;
      if (!state.accountOperations[accountId]) {
        state.accountOperations[accountId] = {};
      }
      state.accountOperations[accountId].accountRename = {
        loading: true,
        error: false,
        errorMessage: null,
      };
    });
    builder.addCase(renameAccount.rejected, (state, { meta, error }) => {
      const { accountId } = meta.arg;
      state.accountOperations[accountId].accountRename = {
        loading: false,
        error: true,
        errorMessage: error.message,
      };
    });
    builder.addCase(renameAccount.fulfilled, (state, { meta, payload }) => {
      const { accountId } = meta.arg;
      state.accountOperations[accountId].accountRename = {
        loading: false,
        error: false,
        errorMessage: null,
      };

      state.content = state.content.map((account) => {
        if (account.id === payload.id) {
          account.name = payload.name;
          return account;
        }
        return account;
      });
    });
    builder.addCase(editAccountCredentials.pending, (state, { meta }) => {
      const { accountId } = meta.arg;
      if (!state.accountOperations[accountId]) {
        state.accountOperations[accountId] = {};
      }
      state.accountOperations[accountId].accountEditCredentials = {
        loading: true,
        error: false,
        errorMessage: null,
      };
    });
    builder.addCase(editAccountCredentials.rejected, (state, { meta, error }) => {
      const { accountId } = meta.arg;
      state.accountOperations[accountId].accountEditCredentials = {
        loading: false,
        error: true,
        errorMessage: error.message,
      };
    });
    builder.addCase(editAccountCredentials.fulfilled, (state, { meta }) => {
      const { accountId } = meta.arg;
      state.accountOperations[accountId].accountEditCredentials = {
        loading: false,
        error: false,
        errorMessage: null,
      };
    });
    builder.addCase(createPowerPlatformIntegration.pending, (state) => {
      state.powerPlatform.integrationCreate.loading = true;
      state.powerPlatform.integrationCreate.error = false;
    });
    builder.addCase(createPowerPlatformIntegration.fulfilled, (state, { payload }) => {
      state.powerPlatform.integrationCreate.loading = false;
      state.powerPlatform.integrationCreate.error = false;
      state.powerPlatform.environments.content.succeedEnvs = payload.additionalData.environments;
      state.content.push(payload.account);
    });
    builder.addCase(createPowerPlatformIntegration.rejected, (state, { error }) => {
      state.powerPlatform.integrationCreate.loading = false;
      state.powerPlatform.integrationCreate.error = true;
      state.powerPlatform.integrationCreate.errorMessage = error.message;
    });
    builder.addCase(fetchPowerPlatformEnvironments.pending, (state) => {
      state.powerPlatform.environments.loading = true;
      state.powerPlatform.environments.error = false;
    });
    builder.addCase(fetchPowerPlatformEnvironments.fulfilled, (state, { payload }) => {
      state.powerPlatform.environments.loading = false;
      state.powerPlatform.environments.error = false;
      state.powerPlatform.environments.content.allEnvs = payload;
    });
    builder.addCase(fetchPowerPlatformEnvironments.rejected, (state) => {
      state.powerPlatform.environments.loading = false;
      state.powerPlatform.environments.error = true;
    });
    builder.addCase(createPowerPlatformEnvironments.pending, (state) => {
      state.powerPlatform.environments.content.completed = [];
      state.powerPlatform.environments.loading = true;
      state.powerPlatform.environments.error = false;
      state.powerPlatform.environments.success = false;
    });
    builder.addCase(createPowerPlatformEnvironments.fulfilled, (state, { payload }) => {
      state.powerPlatform.environments.loading = false;
      state.powerPlatform.environments.error = false;
      state.powerPlatform.environments.success = true;
      state.powerPlatform.environments.content = {
        ...payload,
        allEnvs: state.powerPlatform.environments.content.allEnvs,
      };
    });
    builder.addCase(createPowerPlatformEnvironments.rejected, (state, { error }) => {
      state.powerPlatform.environments.loading = false;
      state.powerPlatform.environments.error = true;
      state.powerPlatform.environments.errorMessage = error.message;
      state.powerPlatform.environments.success = false;
    });
  },
  reducers: {
    setAzureConfigClientId: (state, { payload }) => {
      state.azureConfigClientId = payload;
    },
    resetPowerPlatformAccountState: (state) => {
      state.powerPlatform.integrationCreate = initialState.powerPlatform.integrationCreate;
    },
    resetPowerPlatformEnvsError: (state) => {
      state.powerPlatform.environments.error = false;
      state.powerPlatform.environments.errorMessage = null;
    },
    resetPowerPlatformEnvsSuccess: (state) => {
      state.powerPlatform.environments.success = false;
    },
    changeIsProduction(state, action) {
      state.powerPlatform.environments.content.allEnvs = state.powerPlatform.environments.content.allEnvs.map((env) => {
        if (action.payload.ids.includes(env.platformEnvId)) {
          return { ...env, isProduction: action.payload.isProduction };
        }
        return env;
      });
    },
    incrementCompletedEnvironmentsCounter: (state, { payload }) => {
      state.powerPlatform.environments.content.completed.push(payload.environment);
    },
  },
});

export const {
  setAzureConfigClientId,
  resetPowerPlatformAccountState,
  resetPowerPlatformEnvsError,
  changeIsProduction,
  incrementCompletedEnvironmentsCounter,
  resetPowerPlatformEnvsSuccess,
} = accountSlice.actions;

// this is for configureStore
export default accountSlice.reducer;
