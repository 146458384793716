export const getBiResources = (state) => state.bi.resources.content;
export const getBiResourcesLoading = (state) => state.bi.resources.loading;
export const getBiResourcesTotal = (state) => state.bi.resources.total;
export const getBiResourcesFilters = (state) => state.bi.resources.filters;
export const getBiFilterOptions = (state) => state.bi.resources.filtersOptions;

export const getPublicReports = (state) => state.bi.publicReports.public || 0;
export const getPublicReportsTotal = (state) => state.bi.publicReports.total || 0;
export const getPublicReportsLoading = (state) => state.bi.publicReports.loading;

export const getReportsTrend = (state) => state.bi.creationTrend.content || [];
export const getReportsTrendLoading = (state) => state.bi.creationTrend.loading;

export const getActiveDevelopers = (state) => state.bi.activeDevelopers.content || [];
export const getActiveDevelopersLoading = (state) => state.bi.activeDevelopers.loading;
export const getEnrichmentLoading = (state) => state.bi.enrichment.loading;
export const getEnrichmentError = (state) => state.bi.enrichment.error;
export const getEnrichmentSuccessStatus = (state) => state.bi.enrichment.success;

export const getEmbedCodeEnrichmentLoading = (state) => state.bi.embedCodeEnrichment.loading;
export const getEmbedCodeEnrichmentError = (state) => state.bi.embedCodeEnrichment.error;
export const getEmbedCodeEnrichmentSuccessStatus = (state) => state.bi.embedCodeEnrichment.success;

export const getPublicReportsEnrichmentStatus = (state) => state.bi.reportEnrichmentStatus.publicReports;
export const getSharedReportsEnrichmentStatus = (state) => state.bi.reportEnrichmentStatus.sharedReports;
export const getSharedReportsEnrichmentLoading = (state) => state.bi.reportEnrichmentStatus.loading;
