/* eslint-disable no-param-reassign */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGet } from '../../services/HttpService';

export const fetchEnvironments = createAsyncThunk('environments/fetchEnvironments', async () => {
  const res = await doGet(`environments/`);

  return {
    data: res.data,
  };
});

const initialState = {
  loading: false,
  error: false,
  environments: null,
  success: false,
};

export const environmentsSlice = createSlice({
  name: 'data',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchEnvironments.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.success = false;
    });
    builder.addCase(fetchEnvironments.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.environments = payload.data;
      state.success = true;
    });
    builder.addCase(fetchEnvironments.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    });
  },
  reducers: {
    appendEnvironment: (state, action) => {
      state.environments.push(...action.payload);
    },
  },
});

// this is for configureStore
export default environmentsSlice.reducer;

export const { appendEnvironment } = environmentsSlice.actions;
