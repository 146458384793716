import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Select } from '@mui/material';
import { camelCase } from 'lodash';
import FilterValue from './FilterValue';
import { enforceTranslations } from './filterUtils';

export default function SelectFilter({ onClose, addFilter, column, options, operator }) {
  const [value, setValue] = useState(null);
  const { t } = useTranslation(['common']);
  const title = t(`tables.filters.operators.${camelCase(operator)}`);

  return (
    <FilterValue title={title} onClose={onClose} onApply={() => addFilter(operator, column, value)}>
      <Select value={value} onChange={(e) => setValue(e.target.value)} label={column}>
        {(options || []).map((option) => (
          <MenuItem key={option} value={option}>
            {enforceTranslations(t, option.toString())}
          </MenuItem>
        ))}
      </Select>
    </FilterValue>
  );
}
