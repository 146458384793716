import { getTenantId } from './AuthService';

const HTTP_ENDPOINTS_ALLOWED_TENANTS = [
  'bb7ae1c9-8640-4990-a72a-8d8ac5e404bc', // ADP
  '21ece7c4-25f7-4532-89ab-4bafe0d59568', // McCumber
  'c288f6f7-ac56-4739-9974-c6a04d769e51', // Nokod
];

// TODO allow all tenants ND-2118
export function isHttpEndpointsAllowed() {
  return HTTP_ENDPOINTS_ALLOWED_TENANTS.includes(getTenantId());
}
