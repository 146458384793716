import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fromPairs } from 'lodash';
import { validate } from 'uuid';
import { editAccountCredentials } from '../../../../../redux/slicers/accountSlicer';
import {
  getAccountEditCredentialsError,
  getAccountEditCredentialsLoading,
} from '../../../../../redux/selector/accountsSelector';
import ActionModal from '../../../../../components/modal/ActionModal';
import { AccountCredentialsFields } from './AccountCredentialsConsts';
import Input from '../../../../../components/input/Input';

export default function EditAccountCredentialsModal({ account, modalOpen, closeModal }) {
  const { t } = useTranslation(['settings']);
  const { user } = useAuth0();
  const dispatch = useDispatch();

  const loading = useSelector(getAccountEditCredentialsLoading(account.id));
  const error = useSelector(getAccountEditCredentialsError(account.id));

  const fields = AccountCredentialsFields[account.platformName]?.fields || [];

  const [fieldValues, setFieldValues] = useState({
    clientId: account.data?.clientId,
  });
  const [fieldErrors, setFieldErrors] = useState({});

  const toastTitle = t(`platforms.accounts.editCredentials.${error ? 'editFailed' : 'editSuccess'}`);

  function resetValues() {
    setFieldValues({
      clientId: account.data?.clientId,
    });
    setFieldErrors({});
  }

  function validateFields() {
    let hasErrors = false;

    const newFieldErrors = {};
    fields.forEach((field) => {
      if (field.required && !fieldValues[field.key]?.trim()) {
        newFieldErrors[field.key] = t('platforms.accounts.editCredentials.errors.requiredField');
        hasErrors = true;
      } else if (field.isUUID && !validate(fieldValues[field.key])) {
        newFieldErrors[field.key] = t('platforms.accounts.editCredentials.errors.invalidUUID');
        hasErrors = true;
      }
    });
    setFieldErrors(newFieldErrors);

    return hasErrors;
  }

  async function onApprove() {
    const hasErrors = validateFields();
    const channelFieldValues = fromPairs(fields.map((field) => [field.key, fieldValues[field.key]]));

    if (!hasErrors) {
      await dispatch(
        editAccountCredentials({
          accountId: account.id,
          body: {
            authenticationData: channelFieldValues,
            auditUserName: user.name,
            auditUserEmail: user.email,
          },
        }),
      );
    }

    return !hasErrors;
  }

  useEffect(() => {
    resetValues();
  }, [modalOpen]);

  return (
    <ActionModal
      id="toggle-notification-policy-modal"
      title={t('platforms.accounts.editCredentials.title')}
      onApprove={onApprove}
      loading={loading}
      open={modalOpen}
      closeModal={closeModal}
      toastSuccessful={!error}
      toastTitle={toastTitle}
    >
      <div>
        {fields.map((field) => (
          <div className="input-field-container">
            <div>{field.name}</div>
            <Input
              id={field.key}
              value={fieldValues[field.key]}
              setValue={(val) => setFieldValues({ ...fieldValues, [field.key]: val })}
              placeholder={t(`platforms.accounts.editCredentials.input.${field.key}`)}
              type={field.type}
              required={field.required}
              error={fieldErrors[field.key]}
            />
          </div>
        ))}
      </div>
    </ActionModal>
  );
}
