export const ENTITIES = Object.freeze({
  APPLICATIONS: 'applications',
  FLOWS: 'flows',
  USERS: 'users',
  CONNECTIONS: 'connections',
  GROUPS: 'groups',
});

export const USER_TYPES = Object.freeze({
  USER: 'User',
  GROUP: 'Group',
});
