// sortByField must be from type Date
export function getValues(labels, insights, sortByField = 'creationTime') {
  const gap = labels.length > 1 ? new Date(labels[1]).getTime() - new Date(labels[0]).getTime() : 0;
  return labels.map((date, i) => {
    const numOfInsights = insights.filter((insight) => {
      const creationTime = new Date(insight[sortByField]).getTime();
      const lowerBoundary = new Date(date).getTime();
      const upperBoundary = new Date(date).getTime() + gap;
      return creationTime >= lowerBoundary && creationTime < upperBoundary;
    }).length;
    return numOfInsights;
  });
}
