import { sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import CollectedDataWidget from './CollectedDataWidget';
import { lightRedColor } from '../../../consts/colors';
import rulesIcon from './rules_icon.svg';
import processedDataImg from './process_data_icon.svg';

export default function DataInProcessing({ labels, scanData = [], loading, datesLabel }) {
  const { t } = useTranslation(['homePage']);

  const values = scanData.map((scans) => sumBy(scans, (scan) => Number(scan.bytesScanned)));

  return (
    <CollectedDataWidget
      text={t('systemWidget.dataProcessed')}
      color={lightRedColor}
      counter={values.at(-1)}
      src={rulesIcon}
      values={values}
      img={processedDataImg}
      labels={labels}
      loading={loading}
      datesLabel={datesLabel}
    />
  );
}
