import { useSelector } from 'react-redux';
import * as moment from 'moment';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/card/Card';
import StackedBar from '../../../components/chart/stacked-bar/StackedBar';
import { getValues } from '../homePageChartsService';
import { infoColor, lightGreenColor } from '../../../consts/colors';
import { getHomePageAppChanges, isHomePageAppChangesLoading } from '../../../redux/selector/homepageSelector';

export default function ApplicationChanges({ labels = [] }) {
  const appChanges = useSelector(getHomePageAppChanges);
  const loading = useSelector(isHomePageAppChangesLoading);
  const { t } = useTranslation(['homePage']);

  const { applicationChanges = [], appInstanceChanges = [] } = appChanges;
  let data = { labels: [], datasets: [] };

  if (!loading && applicationChanges.length) {
    const newAppValues = getValues(labels, applicationChanges, 'createdAt');
    const appChangesValues = getValues(labels, appInstanceChanges, 'changedAt');

    data = {
      labels: labels.map((label) => moment(label).format('DD/MM')),
      datasets: [
        {
          label: t('applicationsWidget.newApplications'),
          data: newAppValues,
          backgroundColor: infoColor,
          barPercentage: 0.5,
        },
        {
          label: t('applicationsWidget.applicationChanges'),
          data: appChangesValues,
          backgroundColor: lightGreenColor,
          barPercentage: 0.5,
        },
      ],
    };
  }

  return (
    <Card title={t('applicationsWidget.applicationChanges')} loading={loading} dataTestId="application-changes-card">
      <StackedBar data={data} />
    </Card>
  );
}
