import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import PersonName from '../../../components/person-name/PersonName';
import ColoredBox from '../../../components/box/ColoredBox';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import Table from '../../../components/table/Table';

export default function UsedByTable({ apps, isLoading, isFlow, linksBreadcrumbs }) {
  const { t } = useTranslation(['single-application', 'common']);

  const columns = useMemo(
    () => [
      {
        Header: t('general.name', { ns: 'common' }),
        width: '40%',
        accessor: (cell) => cell.name,
        Cell: ({ row, cell }) => (
          <OrganizationAwareLink to={`/applications/${row.original.id}?from=${linksBreadcrumbs}`}>
            {cell.value}
          </OrganizationAwareLink>
        ),
      },
      {
        Header: t('general.owner', { ns: 'common' }),
        width: '40%',
        accessor: (cell) => cell.owner,
        Cell: ({ value }) => {
          const name = value?.name || value?.email?.split('@')[0];
          return <PersonName name={name} />;
        },
      },
      {
        Header: t('usedByApp'),
        width: '20%',
        accessor: (cell) => cell.usedDirectly,
        Cell: ({ value }) => (
          <ColoredBox
            isFilled
            text={t(value ? 'general.directly' : 'general.indirectly', { ns: 'common' })}
            hasBorder={false}
            className="direct-box"
            color={value ? 'red' : 'green'}
          />
        ),
      },
    ],
    [],
  );

  const sortBy = [
    {
      id: 'Name',
      desc: false,
    },
  ];

  return (
    <Table
      columns={columns}
      data={apps}
      options={{ showFilter: false, showPagination: false, showSort: false }}
      type="usedBy"
      sortBy={sortBy}
      initialFilters={[]}
      customFilters={[]}
      isRowDisabled={() => false}
      getElementId={(app) => app.id}
      isLoading={isLoading}
      classNames="card-view-table"
      emptyState={<TableEmptyContent img={emptyApplicationsImg} mainText={t(isFlow ? 'flowNotUsed' : 'appNotUsed')} />}
    />
  );
}
