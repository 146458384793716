/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: {
    application: false,
    insights: false,
    aggregations: false,
    connections: false,
    connectors: false,
  },
};

export const csvSlice = createSlice({
  name: 'csv',
  initialState,
  reducers: {
    setCsvLoading: (state, { payload }) => {
      const { tableName, isLoading } = payload;
      state.loading[tableName] = isLoading;
    },
  },
});

// this is for configureStore
export const { setCsvLoading } = csvSlice.actions;

export default csvSlice.reducer;
