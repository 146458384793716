/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doPost } from '../../services/HttpService';

export const sendFetchDataTask = createAsyncThunk('tasks/sendFetchDataTask', async () => {
  const res = await doPost('tasks', {});

  return res.data;
});

const initialState = {
  loading: false,
  successful: false,
  error: false,
};

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(sendFetchDataTask.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.successful = false;
    });
    builder.addCase(sendFetchDataTask.fulfilled, (state) => {
      state.loading = false;
      state.error = false;
      state.successful = true;
    });
    builder.addCase(sendFetchDataTask.rejected, (state) => {
      state.loading = false;
      state.error = true;
      state.successful = false;
    });
  },
});

// this is for configureStore
export default tasksSlice.reducer;
