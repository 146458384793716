import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import ButtonWithIcon from '../../../components/buttons/button-with-icon/ButtonWithIcon';
import { StyledSwitch } from '../../../components/StyledSwitch';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import { getApplicationDependencies } from '../../../redux/selector/applicationSelector';
import { isSuperAdmin } from '../../../redux/selector/authSelector';
import { fetchApplicationDependencies } from '../../../redux/slicers/applicationSlicer';
import { PLURAL_FORM } from '../../../consts/translations';

export default function DependenciesTable({ appId }) {
  const [directDependencies, setDirectDependencies] = useState(false);
  const { t } = useTranslation(['common', 'single-application']);

  const dispatch = useDispatch();
  const dependencies = useSelector(getApplicationDependencies(appId)) || [];
  const isSuperAdminRole = useSelector(isSuperAdmin);
  const dependenciesLoading = !dependencies;

  const dependenciesToShow = dependencies.filter(
    (dependency) => !directDependencies || dependency.relation === 'Direct',
  );

  useEffect(() => {
    if (!dependencies?.length) {
      dispatch(fetchApplicationDependencies(appId));
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: t('general.name'),
        accessor: 'name',
        width: '35%',
        Cell: ({ cell }) => {
          const [name] = cell.value.split(':');
          return (
            <OrganizationAwareLink to={`/applications?Dependency%3AIs=${name}`}>{cell.value}</OrganizationAwareLink>
          );
        },
      },
      {
        Header: t('general.type'),
        accessor: 'type',
      },
      {
        Header: t('general.relation'),
        accessor: 'relation',
      },
      {
        Header: t('general.repository'),
        accessor: 'repository',
      },
      {
        Header: t('general.version'),
        accessor: 'version',
      },
      {
        Header: t('LatestExistingVersion', { ns: 'single-application' }),
        accessor: 'latestVersion',
      },
      {
        Header: t('insights.vulnerability', { count: PLURAL_FORM }),
        accessor: (cell) => cell.vulnerabilities.length,
      },
    ],
    [],
  );

  const sortBy = [{ id: 'name', desc: false }];

  return (
    <div className="collapse-card-container card-view-table-container">
      <CollapseCard
        title={t('applications.dependency', { count: PLURAL_FORM })}
        initialOpen={false}
        isLoading={dependenciesLoading}
        totalElementsCount={dependenciesToShow.length}
      >
        <div
          className={classnames({
            'show-closed-toggle': true,
            'with-export': isSuperAdminRole,
          })}
        >
          {isSuperAdminRole && (
            <div className="export-sbom-button">
              <ButtonWithIcon
                icon={<FileDownloadOutlinedIcon />}
                onClick={() => {}}
                text={t('exportSBOM', { ns: 'single-application' })}
              />
            </div>
          )}
          <div className="show-direct-toggle">
            <span>{t('general.onlyDirect')}</span>
            <StyledSwitch checked={directDependencies} onChange={() => setDirectDependencies(!directDependencies)} />
          </div>
        </div>
        <Table
          columns={columns}
          data={dependenciesToShow}
          options={{ showFilter: false, showPagination: false, showSort: false }}
          type="dependencies"
          sortBy={sortBy}
          initialFilters={[]}
          customFilters={[]}
          isRowDisabled={() => false}
          getElementId={(dependency) => `${dependency.name}-${dependency.version}`}
          isLoading={false}
          classNames="card-view-table"
          emptyState={
            <TableEmptyContent
              img={emptyApplicationsImg}
              mainText={t('noDependenciesUsed', { ns: 'single-application' })}
            />
          }
        />
      </CollapseCard>
    </div>
  );
}
