import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getLogo } from '../../../services/LogoService';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';

export default function InsightGenericData({ platformName, entityName, scopeSubType, resourceId }) {
  const { t } = useTranslation(['common']);

  const logo = getLogo(platformName);
  const appName = entityName || t('general.platformEnvironment', { platformName });

  let resource = appName;
  if (scopeSubType === 'connection' && resourceId) {
    resource = <OrganizationAwareLink to={`/data/${resourceId}?tab=Connections`}>{entityName}</OrganizationAwareLink>;
  } else if (scopeSubType === 'connector') {
    resource = (
      <OrganizationAwareLink to={`/data/?tab=Connectors&name:is=${entityName}`}>{entityName}</OrganizationAwareLink>
    );
  }

  return (
    <div className="row">
      <KeyValueElement title={t('insights.scopeName', { scope: startCase(scopeSubType) })} value={resource} />
      <KeyValueElement title={t('general.platform')} value={platformName} img={logo} />
    </div>
  );
}
