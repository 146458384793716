import classnames from 'classnames';
import './loader.scss';

export default function Loader({ classNames }) {
  return (
    <div className="loader-container">
      <span className={classnames('loader', classNames)} />
    </div>
  );
}
