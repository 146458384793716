import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useTranslation } from 'react-i18next';

import './external-link.scss';

export default function ExternalLink({ href, text, textFirst = true }) {
  const { t } = useTranslation(['common']);
  const textToDisplay = text || t('general.link');

  return (
    <div className="external-link">
      {textFirst && <span>{textToDisplay}</span>}
      <a href={href} target="_blank" rel="noreferrer" onClick={(e) => e.stopPropagation()}>
        <OpenInNewOutlinedIcon className="icon" />
        {!textFirst && <span>{textToDisplay}</span>}
      </a>
    </div>
  );
}
