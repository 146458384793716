import EditIcon from '@mui/icons-material/Edit';

import './editable-text.scss';
import { useState } from 'react';
import { TextField } from '@mui/material';
import Loader from '../loader/Loader';
import Toast from '../toast/Toast';

export default function EditableText({ text, onSubmit, isLoading, toastSuccessful, toastTitle }) {
  const [isEditing, setIsEditing] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [value, setValue] = useState(text);

  function handleBlur() {
    setValue(text);
    setIsEditing(false);
  }

  async function handleKeyDown(event) {
    if (event.key === 'Enter') {
      if (!value || !value.trim()) {
        return;
      }
      await onSubmit(value);
      setIsEditing(false);
      setToastOpen(true);
    } else if (event.key === 'Escape') {
      setValue(text);
      setIsEditing(false);
    }
  }

  return (
    <div className="editable-text">
      <Toast open={toastOpen} setOpen={setToastOpen} isSuccess={toastSuccessful} title={toastTitle} />
      {isEditing ? (
        <div className="editable-text-field">
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            autoFocus
          />
        </div>
      ) : (
        <div className="editable-text-display">
          {value}
          <EditIcon className="edit-icon" onClick={() => setIsEditing(true)} variant="filled" />
        </div>
      )}
      {isLoading && <Loader />}
    </div>
  );
}
