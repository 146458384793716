import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import './boolean-value.scss';

export default function BooleanValue({ isTrue }) {
  const { t } = useTranslation('common');
  const className = classnames('boolean-value', {
    isTrue,
  });
  return <div className={className}>{t(isTrue ? 'general.true' : 'general.false')}</div>;
}
