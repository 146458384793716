import powerplatformLogo from './logos/powerplatform.svg';
import outsystemsLogo from './logos/outsystems.png';
import uipathLogo from './logos/uipath.png';
import mendixLogo from './logos/mendix.png';
import pegaLogo from './logos/pega.png';
import serviceNowLogo from './logos/servicenow.png';
import salesforceLogo from './logos/salesforce.svg';

import slackIcon from '../pages/data-source/connector-icons/slack.svg';
import emailIcon from '../pages/data-source/connector-icons/email.svg';
import teamsIcon from '../pages/data-source/connector-icons/teams.svg';
import { NotificationChannelType } from '../pages/settings/notifications/NotificationConsts';

export function getLogo(platform) {
  switch (platform.toLowerCase()) {
    case 'power apps': // keep this for backward compatibility
    case 'power platform':
      return powerplatformLogo;
    case 'out systems':
      return outsystemsLogo;
    case 'uipath':
      return uipathLogo;
    case 'mendix':
      return mendixLogo;
    case 'pega':
      return pegaLogo;
    case 'servicenow':
      return serviceNowLogo;
    case 'salesforce':
      return salesforceLogo;
    default:
      return '';
  }
}

export function getNotificationChannelIcon(channelType) {
  switch (channelType) {
    case NotificationChannelType.STATIC_EMAIL:
    case NotificationChannelType.DYNAMIC_EMAIL:
      return emailIcon;
    case NotificationChannelType.SLACK_CHANNEL:
    case NotificationChannelType.SLACK_PRIVATE:
      return slackIcon;
    case NotificationChannelType.TEAMS_CHANNEL:
      return teamsIcon;
    default:
      return null;
  }
}
