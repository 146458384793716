import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactFlow, { Controls, MiniMap, ReactFlowProvider, useEdgesState, useNodesState } from 'reactflow';
import { getEntitiesState } from '../../redux/selector/entityFlowSelector';
import useAutoLayout from './useAutoLayout';
import EntityNode from './EntityNode';
import OwnerEdge from './OwnerEdge';
import { hideShowChildrenNodes } from './utils/flowUtils';

import 'reactflow/dist/style.css';

const nodeTypes = {
  entityNode: EntityNode,
};

const edgeTypes = {
  ownerEdge: OwnerEdge,
};

function ReactFlowAutoLayout({ initialData }) {
  const subEntitiesStates = useSelector(getEntitiesState);
  const { nodes: initialNodes, edges: initialEdges } = initialData;

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes || []);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges || []);

  useAutoLayout();
  useEffect(() => {
    setNodes(initialData.nodes);
    setEdges(initialData.edges);
  }, []);

  useEffect(() => {
    if (Object.keys(subEntitiesStates).length) {
      setEdges((eds) =>
        eds.map((edge) => {
          const { source, data } = edge;
          const shouldHide = !subEntitiesStates[source]?.[data.targetType];
          return { ...edge, hidden: shouldHide };
        }),
      );
    }
  }, [JSON.stringify(edges), subEntitiesStates]);

  useEffect(() => {
    nodes.forEach((node) => {
      const nodeHasChildren = subEntitiesStates[node.id];
      if (nodeHasChildren) {
        const newNodes = hideShowChildrenNodes(nodes, edges, node, subEntitiesStates);
        setNodes((nds) => nds.map((n) => newNodes.find((newNode) => newNode.id === n.id) || n));
      }
    });
  }, [JSON.stringify(nodes), subEntitiesStates]);

  return (
    <ReactFlow
      fitView
      nodes={nodes}
      edges={edges}
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      zoomOnDoubleClick={false}
      proOptions={{ hideAttribution: true }}
      minZoom={0.1}
      maxZoom={0.8}
    >
      <MiniMap />
      <Controls />
    </ReactFlow>
  );
}

function ReactFlowWrapper({ initialData }) {
  return (
    <ReactFlowProvider>
      <ReactFlowAutoLayout initialData={initialData} />
    </ReactFlowProvider>
  );
}

export default ReactFlowWrapper;
