import './insight-scope.scss';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import applicationIcon from './application_scope_icon.svg';
import connectorIcon from './connector_scope_icon.svg';
import environmentIcon from './environment_scope_icon.svg';
import localIcon from './local_scope_icon.svg';
import machineIcon from './machine_scope_icon.svg';
import userIcon from './user_scope_icon.svg';

function getIcon(text) {
  switch (text) {
    case 'Local':
      return <img src={localIcon} alt="Local" />;
    case 'Robot':
      return <img src={machineIcon} alt="Machine" />;
    case 'User':
      return <img src={userIcon} alt="User" />;
    case 'Application':
      return <img src={applicationIcon} alt="Application" />;
    case 'Connector':
    case 'Data':
      return <img src={connectorIcon} alt="Connector" />;
    case 'Environment':
    case 'Feed':
    default:
      return <img src={environmentIcon} alt="Environment" />;
  }
}

function mapScopeSubType(scopeSubType) {
  switch (scopeSubType) {
    case 'app_instance':
      return 'application';
    default:
      return scopeSubType;
  }
}

export default function InsightScope({ insightScopeSubType }) {
  const { t } = useTranslation('common');
  const scopeSubType = mapScopeSubType(insightScopeSubType);
  const icon = getIcon(scopeSubType);

  return (
    <div className="insight-scope">
      {icon}
      <div>{t(`scopes.${camelCase(scopeSubType)}`, { ns: 'common' })}</div>
    </div>
  );
}
