import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // can't use window.scrollTo(0, 0) because the body-wrapper is the scrollable element
    const bodyWrapper = document.querySelector('.body-wrapper');
    if (bodyWrapper) {
      bodyWrapper.scrollTop = 0;
    }
  }, [pathname]);

  return null;
}
