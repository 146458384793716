const LOCATION_PARAMS = ['tab', 'from'];

export function updateQueryParam(searchParams, paramName, paramValue) {
  if (paramValue) {
    searchParams.set(paramName, paramValue);
  } else {
    searchParams.delete(paramName);
  }
  return searchParams;
}

export function copyLocationParams(currentSearchParams, newSearchParams) {
  LOCATION_PARAMS.forEach((param) => {
    const value = currentSearchParams.get(param);
    updateQueryParam(newSearchParams, param, value);
  });

  return newSearchParams;
}

export function updateSortByParams(searchParams, sortBy) {
  if (sortBy.length) {
    const params = sortBy.map(({ id, desc }) => `${id},${desc ? 'desc' : 'asc'}`).join(',');
    return updateQueryParam(searchParams, 'sort', params);
  }
  return updateQueryParam(searchParams, 'sort');
}
