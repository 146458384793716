import { isEmpty } from 'lodash';

const TENANT_ID = 'tenantId';
const TENANTS = 'tenants';
const ROLES = 'roles';

// access token
export const getAuthToken = () => window.localStorage.getItem('access_token');
export const setAuthToken = (token) => window.localStorage.setItem('access_token', token);
export const clearToken = () => window.localStorage.removeItem('access_token');
export const getGuestToken = () => new URLSearchParams(window.location.search).get('token');

// current tenantId
export const getTenantId = () => window.localStorage.getItem(TENANT_ID);

// TODO fix this

export const isFinaro = getTenantId() === 'b9abda26-d2c7-4c3c-bdbe-752954f57793';

export const setTenantId = (tenantId) => window.localStorage.setItem(TENANT_ID, tenantId);
export const clearTenantId = () => window.localStorage.removeItem(TENANT_ID);

// all tenants
export const setTenants = (tenants) => window.localStorage.setItem(TENANTS, JSON.stringify(tenants));
export const getTenants = () => JSON.parse(window.localStorage.getItem(TENANTS));
export const clearTenants = () => window.localStorage.removeItem(TENANTS);

// roles
export const setRoles = (roles) => window.localStorage.setItem(ROLES, JSON.stringify(roles));
export const getRoles = () => JSON.parse(window.localStorage.getItem(ROLES));
export const clearRoles = () => window.localStorage.removeItem(ROLES);

export const hasRoles = (roles) => !isEmpty(roles) && Object.values(roles).flat().length;

export const isInitialized = () => !!(getTenants()?.length && hasRoles(getRoles())) || !!getGuestToken();

export const getTokenData = (clientId, type, scope) => {
  const keys = JSON.parse(localStorage.getItem(`msal.token.keys.${clientId}`));

  if (!keys) {
    return null;
  }

  const tokenKey = keys[type].find((key) => key.includes(clientId) && (!scope || key.includes(scope.toLowerCase())));

  return JSON.parse(localStorage.getItem(tokenKey));
};

export const clearMsalKeys = (clientId) => {
  const keys = localStorage.getItem(`msal.token.keys.${clientId}`);
  if (!keys) {
    return;
  }
  Object.values(JSON.parse(keys)).forEach((val) => {
    if (typeof val === 'string') {
      localStorage.removeItem(val);
      return;
    }
    if (!Array.isArray(val)) {
      return;
    }
    val.forEach((key) => {
      localStorage.removeItem(key);
    });
  });

  localStorage.removeItem(`msal.token.keys.${clientId}`);
};
