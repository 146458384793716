import { Bar } from 'react-chartjs-2';
import { getHtmlLegendPlugin } from '../chartLegends';

import './stacked-bar.scss';
import { mapChartTick } from '../../../services/MapperUtils';

export default function StackedBar({ data }) {
  const htmlLegend = getHtmlLegendPlugin({ backgroundColorProp: 'fillStyle' });
  const legendContainerId = 'legend-container';

  return (
    <div className="stacked-bar">
      <div id={legendContainerId} className="legend-container" />
      <Bar
        plugins={[htmlLegend]}
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            htmlLegend: {
              legendContainerId,
            },
          },
          scales: {
            y: {
              stacked: true,
            },
            x: {
              stacked: true,
              callback: (value, index) => mapChartTick(data.labels[index]),
              grid: {
                display: false,
              },
            },
          },
        }}
      />
    </div>
  );
}
