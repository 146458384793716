import './severity-picker.scss';
import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const severities = [
  {
    name: 'Info',
    value: '-1',
  },
  {
    name: 'Low',
    value: '0',
  },
  {
    name: 'Medium',
    value: '1',
  },
  {
    name: 'High',
    value: '2',
  },
  {
    name: 'Critical',
    value: '3',
  },
];

export default function SeverityPicker({ initialSeverity, onChange }) {
  const [selectedSeverity, setSelectedSeverity] = useState(initialSeverity);
  const { t } = useTranslation(['common']);

  return (
    <div className="severity-picker">
      {severities.map((severity) => (
        <div
          className={classNames('severity-option', severity.name.toLowerCase(), {
            checked: severity.name === selectedSeverity,
          })}
          onClick={() => {
            setSelectedSeverity(severity.name);
            onChange(severity.value);
          }}
        >
          {t(`insights.${severity.name.toLowerCase()}`)}
        </div>
      ))}
    </div>
  );
}
