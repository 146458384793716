import fileDownload from 'js-file-download';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { setCsvLoading } from '../../../redux/slicers/csvSlicer';
import { isFetchCsvLoading } from '../../../redux/selector/csvSelector';
import { doGet } from '../../../services/HttpService';
import { mapFiltersToParams } from '../../../services/MapperUtils';

import './export-button.scss';

export default function ExportButton({
  exportPath,
  getFiltersSelector,
  mapFilterFunction,
  mapFilterValue,
  extraCsvFilters = [],
  filename = 'summary',
}) {
  const { t } = useTranslation('common');
  const filters = useSelector(getFiltersSelector);
  const tableName = exportPath.split('/')[0];
  const isLoading = useSelector((state) => isFetchCsvLoading(state, tableName));
  const dispatch = useDispatch();

  async function downloadCSV() {
    const params = mapFiltersToParams([...filters, ...extraCsvFilters], {
      mapValueFunction: mapFilterValue,
      mapFilterFunction,
    });
    const path = params ? `${exportPath}?${params.toString()}` : exportPath;
    try {
      dispatch(setCsvLoading({ isLoading: true, tableName }));
      const response = await doGet(path, { stream: true });
      dispatch(setCsvLoading({ isLoading: false, tableName }));
      fileDownload(response.data, `${filename}.csv`);
    } catch (e) {
      dispatch(setCsvLoading({ isLoading: false, tableName }));
      console.error(e);
    }
  }

  return (
    <LoadingButton onClick={downloadCSV} startIcon={<FileDownloadOutlinedIcon />} loading={isLoading}>
      {t('tables.export')}
    </LoadingButton>
  );
}
