import { useTranslation } from 'react-i18next';
import NoData from '../../../components/no-data/NoData';
import ApplicationInsight from './ApplicationInsight';

export default function ApplicationInsights({ insights = [], linksBreadcrumbs }) {
  const { t } = useTranslation(['single-application']);

  if (!insights.length) {
    return <NoData text={t('noApplicationInsightsFound')} />;
  }

  return insights.map((insight) => (
    <ApplicationInsight key={insight.id} insight={insight} linksBreadcrumbs={linksBreadcrumbs} />
  ));
}
