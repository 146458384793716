import { useTranslation } from 'react-i18next';
import Table from '../../../components/table/Table';
import InformationIcon from '../../../components/information-icon/InformationIcon';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';

const MUM_OF_ROWS = 5;

export function HomePageTable({
  title,
  data,
  columns,
  isLoading,
  link,
  type,
  emptyState,
  tooltipText,
  numOfRows = MUM_OF_ROWS,
}) {
  const slicedData = data.slice(0, numOfRows);

  return (
    <div className="table-section">
      <HomePageTableHeader title={title} tooltipText={tooltipText} />
      <Table
        type={type}
        columns={columns}
        data={slicedData}
        isLoading={isLoading}
        options={{ showFilter: false, showPagination: false, showSort: false }}
        customFooter={link && slicedData.length === numOfRows ? <HomePageTableFooter link={link} /> : <div />}
        emptyState={emptyState}
        pageSize={numOfRows}
      />
    </div>
  );
}

function HomePageTableHeader({ title, tooltipText }) {
  return (
    <div className="home-page-table-header">
      <div className="title">{title}</div>
      <InformationIcon tooltipText={tooltipText} />
    </div>
  );
}

function HomePageTableFooter({ link }) {
  const { t } = useTranslation(['common']);

  return (
    <div className="home-page-table-footer">
      <OrganizationAwareLink to={link}>{t('general.showMore')}</OrganizationAwareLink>
    </div>
  );
}
