import { useEffect, useState, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Toast from '../../../../components/toast/Toast';
import Loader from '../../../../components/loader/Loader';
import InformationIcon from '../../../../components/information-icon/InformationIcon';
import { sendCopyFlow, fetchSandBoxFlowLink } from '../../../../redux/slicers/sandboxFlowSlicer';
import {
  getFlowError,
  getSandBoxFlowLink,
  getShowToast,
  isCopyFlowLoading,
} from '../../../../redux/selector/sandboxFlowSelector';

import './sandbox-flow-controls.scss';

const translationPrefix = 'applicationData.environment';

export default function SandboxFlowControls({ platformAppId, environmentId, accountId, flowId }) {
  const [link, setLink] = useState(null);
  const [toastOpen, setToastOpen] = useState(false);
  const [shouldOpenLink, setShouldOpenLink] = useState(false);
  const sandboxInterval = useRef(null);
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { t } = useTranslation(['single-application']);
  const sandboxFlowLink = useSelector(getSandBoxFlowLink(platformAppId));
  const isLoading = useSelector(isCopyFlowLoading(platformAppId));
  const error = useSelector(getFlowError(platformAppId));
  const showToast = useSelector(getShowToast);

  function fetchLink() {
    dispatch(fetchSandBoxFlowLink({ flowId, platformAppId }));
  }

  useEffect(() => {
    setLink(sandboxFlowLink);
    if (shouldOpenLink) {
      setShouldOpenLink(false);
      window.open(sandboxFlowLink, '_blank');
    }
  }, [sandboxFlowLink]);

  useEffect(() => {
    setToastOpen(showToast);
  }, [showToast]);

  useEffect(() => {
    if (error || link) {
      clearInterval(sandboxInterval.current);
    }
  }, [link, error]);

  useEffect(() => {
    if (!link && !error && !sandboxInterval.current) {
      fetchLink(); // Execute immediately
      sandboxInterval.current = setInterval(fetchLink, 5000);
    }
    return () => clearInterval(sandboxInterval.current);
  }, []);

  function onClick(e) {
    e.stopPropagation();
    if (!link) {
      dispatch(sendCopyFlow({ flowId, accountId, environmentId, platformAppId, email: user.email }));
      setShouldOpenLink(true);
    }
  }

  function renderControl() {
    return (
      <>
        {isLoading ? <Loader classNames="control-loader" /> : <VisibilityOutlinedIcon className="control-icon" />}
        {t(`${translationPrefix}.viewInSandbox`)}
      </>
    );
  }

  return (
    <div className="sandbox-flow-controls">
      <div className="controls">
        {link ? (
          <a href={link} target="_blank" rel="noreferrer" className="control">
            {renderControl()}
          </a>
        ) : (
          <div className="control" onClick={onClick}>
            {renderControl()}
          </div>
        )}
      </div>
      <div className="sandbox-info">
        <InformationIcon tooltipText={t(`${translationPrefix}.sandboxFlowTooltip`)} />
      </div>
      <Toast
        isSuccess={!!link}
        open={toastOpen}
        setOpen={setToastOpen}
        title={t(`${translationPrefix}.fetchLink.title`)}
        message={t(link ? `${translationPrefix}.fetchLink.success` : `${translationPrefix}.fetchLink.failed`)}
      />
    </div>
  );
}
