import { forwardRef } from 'react';

function ModalWrapper(props, ref) {
  const { children, closeModal } = props;

  return (
    <div ref={ref} className="modal-wrapper" onClick={closeModal} tabIndex={-1}>
      {children}
    </div>
  );
}

export default forwardRef(ModalWrapper);
