import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import CloseableModal from './ClosableModal';
import Loader from '../loader/Loader';
import Button from '../buttons/button/Button';
import Toast from '../toast/Toast';

export default function ActionModal({
  open,
  closeModal,
  id,
  title,
  onApprove,
  loading,
  children,
  toastSuccessful,
  toastTitle,
  toastMessage,
  approveDisabled = false,
}) {
  const [toastOpen, setToastOpen] = useState(false);
  const { t } = useTranslation(['common']);
  return (
    <>
      <CloseableModal open={open} closeModal={closeModal} id={id}>
        <div className="title">{title}</div>
        {loading ? (
          <Loader />
        ) : (
          <>
            {children && <div className="text-container">{children}</div>}
            <div className="buttons-row">
              <Button isFilled={false} text={t('general.cancel')} onClick={closeModal} />
              <Button
                disabled={approveDisabled}
                isFilled
                text={t('general.approve')}
                onClick={async () => {
                  const shouldClose = await onApprove();
                  if (shouldClose) {
                    setToastOpen(true);
                    closeModal();
                  }
                }}
              />
            </div>
          </>
        )}
      </CloseableModal>
      <Toast
        open={toastOpen}
        setOpen={setToastOpen}
        isSuccess={toastSuccessful}
        title={toastTitle}
        message={toastMessage}
      />
    </>
  );
}
