import { setTenantId } from './AuthService';

export function getTenantNameFromUrl() {
  const splitPath = window.location.pathname.split('/');
  if (splitPath.length < 2) return '';

  return decodeURI(window.location.pathname.split('/')[1]);
}

export function changeTenant(dispatch, tenant, path = '') {
  setTenantId(tenant.name);
  dispatch({
    type: 'USER_LOGOUT',
  });

  const orgNamePrefix = `${tenant.display_name}/portal`;
  if (decodeURI(path).includes(orgNamePrefix)) {
    window.location.href = path;
  } else {
    window.location.href = `/${orgNamePrefix}${path}`;
  }
}
