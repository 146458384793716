import { useEffect } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';

export default function useChangeTab(selectedTab, setSelectedTab, defaultTab) {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  const changeTab = (tab) => {
    setSelectedTab(tab);
    setSearchParams({ tab });
  };

  useEffect(() => {
    const searchParamTab = searchParams.get('tab');
    if (!location.search.includes('tab')) {
      changeTab(defaultTab);
    } else if (searchParamTab && searchParamTab !== selectedTab) {
      setSelectedTab(searchParamTab);
    }
  }, [location.search, selectedTab, searchParams]);

  return changeTab;
}
