import { useDispatch, useSelector } from 'react-redux';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ActionModal from '../../../components/modal/ActionModal';
import { markInsightAsFalsePositive } from '../../../redux/slicers/insightSlicer';
import { isMarkFalsePositiveLoading, isMarkFalsePositiveSuccess } from '../../../redux/selector/insightsSelector';

export default function MarkAsFalsePositiveButton({ insightId }) {
  const { t } = useTranslation(['single-insight']);
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const loading = useSelector(isMarkFalsePositiveLoading);
  const success = useSelector(isMarkFalsePositiveSuccess);

  const [modalOpen, setModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div className="mark-as-false-positive">
      <ActionModal
        open={modalOpen}
        closeModal={() => {
          setModalOpen(false);
        }}
        id="mark-as-false-positive"
        title={t('falsePositive.title')}
        onApprove={() => dispatch(markInsightAsFalsePositive({ insightId, email: user.email, name: user.name }))}
        loading={loading}
        toastSuccessful={success}
        toastTitle={t(`falsePositive.toastTitle${success ? 'Success' : 'Error'}`)}
      >
        <div>{t('falsePositive.description')}</div>
      </ActionModal>
      <MoreVertIcon
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setMenuOpen(true);
        }}
      />
      <Menu
        id="new-notification-channel-menu"
        open={menuOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setMenuOpen(false);
          setAnchorEl(null);
        }}
      >
        <MenuItem
          onClick={() => {
            setModalOpen(true);
            setMenuOpen(false);
            setAnchorEl(null);
          }}
        >
          {t('falsePositive.buttonText')}
        </MenuItem>
      </Menu>
    </div>
  );
}
