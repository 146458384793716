import { useTranslation } from 'react-i18next';
import { mapDate, mapSeverityFromCode } from '../../../services/MapperUtils';
import { mapStatus } from '../../../services/InsightsMapper';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import ColoredCircledBoolean from '../../../components/circled-text/ColoredCircledBoolean';
import CircledText from '../../../components/circled-text/CircledText';
import SeverityBox from '../../../components/box/SeverityBox';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import { insightType } from '../../../consts/insight-type';

function getInsightType(type) {
  switch (type.toLowerCase()) {
    case insightType.GOVERNANCE:
      return 'governanceNotification';
    case insightType.MALICIOUS:
      return 'maliciousActivity';
    case insightType.COMPLIANCE:
      return 'complianceIssue';
    case insightType.VULNERABILITY:
      return 'vulnerabilityDetected';
    default:
      return 'insight';
  }
}

export default function ApplicationInsight({ insight, linksBreadcrumbs }) {
  const createTime = insight.insightHistory.slice().sort((a, b) => a.changeTime - b.changeTime)[0].changeTime;
  const inProduction = insight.inProduction || false;
  const { t } = useTranslation(['common', 'single-application']);

  return (
    <div className="application-insight">
      <ApplicationInsightItem>
        <OrganizationAwareLink to={`/insights/${insight.id}?from=${linksBreadcrumbs}`}>
          <div className="insight-title">
            <div className="title-prefix">{t(getInsightType(insight.rule.type), { ns: 'single-application' })}</div>
            <div className="title-rule-name">{insight.rule.name}</div>
          </div>
          <div className="insight-rule-description">{insight.description}</div>
        </OrganizationAwareLink>
      </ApplicationInsightItem>
      <ApplicationInsightItem>
        <KeyValueElement title={t('general.date')} value={mapDate(createTime)} />
        <KeyValueElement title={t('general.status')} value={<CircledText text={mapStatus(insight.status)} />} />
      </ApplicationInsightItem>
      <ApplicationInsightItem>
        <KeyValueElement title={t('general.inProduction')} value={<ColoredCircledBoolean isTrue={inProduction} />} />
      </ApplicationInsightItem>
      <ApplicationInsightItem>
        <KeyValueElement
          title={t('insights.severity')}
          value={<SeverityBox severity={mapSeverityFromCode(insight.severity)} />}
        />
      </ApplicationInsightItem>
    </div>
  );
}

function ApplicationInsightItem({ children, style = {} }) {
  return (
    <div className="application-insight-item" style={style}>
      {children}
    </div>
  );
}
