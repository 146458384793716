import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

function SubEntity({ name, isOpen, onSubEntityClick }) {
  const { t } = useTranslation(['entity-map']);
  return (
    <div key={name} className={classnames('sub-entity', { open: isOpen })} onClick={() => onSubEntityClick(name)}>
      <div>{t(`entities.${name.toLowerCase()}`)}</div>
      <div className="sign">{isOpen ? '-' : '+'}</div>
    </div>
  );
}

export default SubEntity;
