import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import FilterValue from './FilterValue';

export default function NumericFilter({ onClose, addFilter, column, operator }) {
  const [value, setValue] = useState(null);
  const { t } = useTranslation(['common']);
  const title = t(`tables.filters.operators.${camelCase(operator)}`);

  return (
    <FilterValue title={title} onClose={onClose} onApply={() => addFilter(operator, column, value)}>
      <input type="number" onInput={(e) => setValue(e.target.value)} />
    </FilterValue>
  );
}
