import Markdown from 'react-markdown';
import { isEmail } from 'class-validator';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import Button from '../../buttons/button/Button';
import './orphan-application-remediation.scss';
import ButtonWithIcon from '../../buttons/button-with-icon/ButtonWithIcon';
import { LinkRenderer } from '../RemediationUtils';

export default function OrphanApplicationRemediation({ onClose, handleRemediate, entity, remediationActions }) {
  const [settingNewOwnerEmail, setSettingNewOwnerEmail] = useState(false);
  const [newOwnerEmail, setNewOwnerEmail] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(null);
  const { user } = useAuth0();
  const [email, setEmail] = useState('');

  const { t } = useTranslation(['remediation', 'common']);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);
    if (isEmail(newEmail)) {
      setIsValidEmail(true);
      setNewOwnerEmail(newEmail);
    } else if (newEmail === '') {
      setIsValidEmail(null);
    } else {
      setIsValidEmail(false);
    }
  };

  const takeOwnership = () => {
    handleRemediate({ extraData: { newOwnerEmail: user.email } });
  };

  function getDefaultOptions() {
    return (
      <>
        <div className="text-container">
          <div>
            <div className="text">
              {(remediationActions || '')
                .split('\n')
                .filter((l) => l)
                .map((line) => (
                  <Markdown key={line} components={{ a: LinkRenderer }}>
                    {line}
                  </Markdown>
                ))}
            </div>
          </div>
        </div>
        <div className="spaced-buttons">
          <Button text={t('general.cancel', { ns: 'common' })} onClick={onClose} isFilled={false} />
          <div className="right-buttons">
            <Button
              text={t('orphanApplication.setNewOwner')}
              onClick={() => setSettingNewOwnerEmail(true)}
              isFilled={false}
            />
            <Button text={t('orphanApplication.takeOwnership')} onClick={() => takeOwnership()} isFilled />
          </div>
        </div>
      </>
    );
  }

  function getSettingNewOwnerEmail() {
    return (
      <>
        <div className="text-container">
          <div className="text margin">{t('orphanApplication.message', { entity })}</div>
          <div>{t('orphanApplication.note')}</div>
        </div>
        <div className="buttons-row">
          <ButtonWithIcon icon={<ArrowBackIcon />} onClick={() => setSettingNewOwnerEmail(false)} />
          <TextField
            id="outlined-basic"
            placeholder="Enter user email"
            value={email}
            onChange={handleEmailChange}
            error={isValidEmail === false}
          />
          <Button
            text={t('orphanApplication.setOwner')}
            className="set-owner-button"
            class="set-owner-button"
            onClick={() => handleRemediate({ extraData: { newOwnerEmail } })}
            isFilled
            disabled={!isValidEmail}
          />
        </div>
      </>
    );
  }

  return !settingNewOwnerEmail ? getDefaultOptions() : getSettingNewOwnerEmail();
}
