import vulnIcon from './vulnerablity.svg';
import complianceIcon from './compliance.svg';
import governanceIcon from './governance.svg';
import maliciousIcon from './malicious.svg';

export function getIcon(text) {
  let icon = vulnIcon;
  switch (text.toLowerCase()) {
    case 'compliance':
      icon = complianceIcon;
      break;
    case 'governance':
      icon = governanceIcon;
      break;
    case 'malicious':
      icon = maliciousIcon;
      break;
    case 'vulnerability':
      icon = vulnIcon;
      break;
    default:
      icon = vulnIcon;
      break;
  }

  return <img src={icon} alt={text} />;
}
