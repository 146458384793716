import React from 'react';
import { camelCase, capitalize } from 'lodash';
import { mapSeverityFromCode } from '../../../services/MapperUtils';
import ColoredBox from '../../../components/box/ColoredBox';
import SeverityBox from '../../../components/box/SeverityBox';
import { Operator } from './OperatorEnum';

function getValue({ column, value }) {
  switch (column) {
    case 'severity':
      return <SeverityBox severity={mapSeverityFromCode(value)} />;
    default:
      return camelCase(value.toLowerCase());
  }
}

export default function NotificationPolicyFilters({ filters }) {
  if (!filters.length) return <div>All Insights</div>;

  const renderFilter = (filter) => (
    <div key={`${filter.column}_${filter.type}`} className="notification-policy-filter">
      <span>{capitalize(filter.column.toLowerCase())}</span>
      <ColoredBox color="gray" text={Operator[filter.type]} isFilled hasBorder={false} />
      <span className="notification-policy-filter-value">{getValue(filter)}</span>
    </div>
  );

  return (
    <div className="notification-policy-filters">
      {filters.map((filter, index) => (
        <React.Fragment key={`${filter.column}_${filter.type}`}>
          {index > 0 && <ColoredBox color="gray" text={Operator.AND} isFilled hasBorder={false} />}
          {renderFilter(filter, index)}
        </React.Fragment>
      ))}
    </div>
  );
}
