import { useSelector } from 'react-redux';
import * as moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/card/Card';
import { getValues } from '../homePageChartsService';
import LineChart from '../../../components/chart/line-chart/LineChart';
import { infoColor } from '../../../consts/colors';
import { getHomePageAppChanges, isHomePageAppChangesLoading } from '../../../redux/selector/homepageSelector';

export default function NewApplicationChart({ labels = [] }) {
  const appChanges = useSelector(getHomePageAppChanges);
  const loading = useSelector(isHomePageAppChangesLoading);
  const { t } = useTranslation(['homePage']);

  let data = { labels: [], datasets: [] };
  if (!loading && appChanges) {
    const { applicationChanges = [] } = appChanges;
    const newAppValues = getValues(labels, applicationChanges, 'createdAt');

    data = {
      labels: labels.map((label) => moment(label).format('DD/MM')),
      datasets: [
        {
          label: t('applicationsWidget.newApplications'),
          data: newAppValues,
          borderColor: infoColor,
          width: '2px',
          tension: 0.2,
        },
      ],
    };
  }

  return (
    <Card title={t('applicationsWidget.newApplications')} loading={loading} dataTestId="new-applications-card">
      <div className="chart-wrapper new-application-chart">
        <LineChart data={data} labels={labels} id="new-applications" showLegend={false} />
      </div>
    </Card>
  );
}
