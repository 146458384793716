import moment from 'moment';

export const isValidDate = (dateString) => {
  const formats = [
    'MM/DD/YYYY',
    'DD/MM/YYYY',
    'YYYY/MM/DD',
    'DD/MM/YY',
    'YYYY-MM-DD',
    'MM-DD-YYYY',
    'DD-MM-YYYY',
    'DD-MM-YY',
    'YYYY.MM.DD',
    'MM.DD.YYYY',
    'DD.MM.YYYY',
    'DD.MM.YY',
    // Add more formats as needed
  ];
  return moment(dateString, formats, true).isValid();
};
