import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IS } from '../../filter/filterConsts';
import './quick-filter.scss';

export default function QuickFilter({ cell, getFiltersSelector, handleFiltersChange, onClose }) {
  const filters = useSelector(getFiltersSelector);
  const { t } = useTranslation(['common']);

  function handleFilterClick() {
    const newFilters = filters.filter((filter) => !(filter.column === cell.column.backendKey && filter.type === IS));
    newFilters.push({
      column: cell.column.backendKey,
      type: IS,
      value: cell.value,
    });

    handleFiltersChange(newFilters);
    onClose();
  }

  function getValue() {
    let value;
    if (typeof cell?.value === 'boolean') {
      value = cell?.value ? t('general.true') : t('general.false');
    } else {
      value = cell?.value;
    }

    return `"${value}"`;
  }

  return (
    <div className="quick-filter" onClick={handleFilterClick} data-testid="quick-filter">
      <FilterAltOutlinedIcon width={6} />
      <div>{t('tables.filters.filter')}</div>
      <div>{getValue()}</div>
    </div>
  );
}
