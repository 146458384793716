import { Drawer } from '@mui/material';

import './blurred-drawer.scss';

export default function BlurredDrawer({ open, setOpen, children, onClose = () => {} }) {
  return (
    <Drawer
      open={open}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      anchor="right"
      className="blurred-drawer"
    >
      {children}
    </Drawer>
  );
}
