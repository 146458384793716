import { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { mapDate } from '../../../../../services/MapperUtils';
import TableEmptyContent from '../../../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../../../inventory/empty_applications.svg';
import Table from '../../../../../components/table/Table';
import { StyledSwitch } from '../../../../../components/StyledSwitch';
import SearchInput from '../../../../../components/search-input/SearchInput';
import Button from '../../../../../components/buttons/button/Button';
// eslint-disable-next-line max-len
import PowerPlatformIntegrationEnvironmentConfirmationModal from './PowerPlatformIntegrationEnvironmentConfirmationModal';
import {
  changeIsProduction,
  fetchPowerPlatformEnvironments,
  resetPowerPlatformEnvsError,
  setAzureConfigClientId,
} from '../../../../../redux/slicers/accountSlicer';
import {
  getAccountById,
  getPowerPlatformIntegrationEnvironments,
  isPowerPlatformEnvironmentsLoading,
} from '../../../../../redux/selector/accountsSelector';
import PowerPlatformIntegrationGetEnvironmentsModal from './PowerPlatformIntegrationGetEnvironmentsModal';
import { getDefaultEnvironmentsByAccountId } from '../../../../../redux/selector/environmentsSelector';
import { getTokenData } from '../../../../../services/AuthService';
import AzureAuthConfig, { AzurePermissions, isTokenExpired } from '../../../../../auth/azure-auth-config';

import './edit-environments-page.scss';
import Breadcrumbs from '../../../../../components/breadcrumbs/Breadcrumbs';

export default function PowerPlatformIntegrationEnvironmentsPage() {
  const { accountId } = useParams();
  const platformEnvironments = useSelector(getPowerPlatformIntegrationEnvironments);
  const relevantAccount = useSelector(getAccountById(accountId));
  const environments = useSelector(getDefaultEnvironmentsByAccountId(accountId));
  const isLoading = useSelector(isPowerPlatformEnvironmentsLoading);
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [envsToShow, setEnvsToShow] = useState(platformEnvironments.allEnvs);
  const [envsToIntegrate, setEnvsToIntegrate] = useState([]);
  const [productionHeader, setProductionHeader] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [fetchEnvironmentsModalOpen, setFetchEnvironmentsModalOpen] = useState(false);

  const existingPlatformEnvironmentsIds = (environments || []).map((env) => env.data.environmentId);

  const { t } = useTranslation(['common', 'onboarding']);

  useEffect(() => {
    if (relevantAccount) {
      const { clientId, platformTenantId } = relevantAccount.data;
      AzureAuthConfig.setConfig(clientId, platformTenantId);
      dispatch(setAzureConfigClientId(clientId));
    }
  }, [relevantAccount]);

  useEffect(() => {
    const newEnvs = platformEnvironments.allEnvs.filter((env) =>
      Object.values(env).some((value) => value.toString().toLowerCase().includes(searchQuery.toLowerCase())),
    );

    setEnvsToShow(newEnvs);

    const hasAtLeastOneNotProduction = newEnvs.some((env) => !env.isProduction);
    if (hasAtLeastOneNotProduction) {
      setProductionHeader(false);
    }
  }, [searchQuery, platformEnvironments.allEnvs]);

  useEffect(() => {
    if (relevantAccount && !platformEnvironments.allEnvs.length) {
      const accessTokenData = getTokenData(
        relevantAccount.data.clientId,
        'accessToken',
        AzurePermissions.POWER_APPS_SERVICE,
      );

      const tokenExpired = isTokenExpired(accessTokenData);
      if (tokenExpired) {
        setFetchEnvironmentsModalOpen(true);
        return;
      }
      dispatch(
        fetchPowerPlatformEnvironments({
          accountId,
          adminAccessToken: accessTokenData.secret,
          email: user.email,
        }),
      );
    } else {
      setFetchEnvironmentsModalOpen(false);
    }
  }, [platformEnvironments.allEnvs, relevantAccount]);

  function isRowDisabled(row) {
    return !row.instanceApiUrl || existingPlatformEnvironmentsIds.includes(row.platformEnvId);
  }

  const columns = useMemo(
    () => [
      {
        Header: t('general.name'),
        accessor: 'name',
        width: '50%',
      },
      {
        Header: t('general.type'),
        accessor: 'type',
        width: '20%',
      },
      {
        Header: t('general.creator'),
        accessor: 'createdBy',
        width: '20%',
      },
      {
        Header: t('general.creationDate'),
        accessor: 'createdTime',
        width: '25%',
        Cell: ({ value }) => mapDate(value) || t('general.na'),
      },
      {
        Header: t('general.status'),
        accessor: 'status',
        width: '20%',
        Cell: ({ value }) => <div className={classnames('env-status', { valid: value === 'Enabled' })}>{value}</div>,
      },
      {
        Header: (
          <div className="toggle-header">
            <StyledSwitch
              checked={productionHeader}
              onChange={() => {
                dispatch(
                  changeIsProduction({
                    ids: envsToShow.map((env) => env.platformEnvId),
                    isProduction: !productionHeader,
                  }),
                );
                setProductionHeader(!productionHeader);
              }}
            />
            {t('general.inProduction')}
          </div>
        ),
        accessor: 'isProduction',
        width: '20%',
        Cell: ({ value, cell }) => (
          <StyledSwitch
            checked={value}
            disabled={isRowDisabled(cell.row.original)}
            onChange={async () => {
              dispatch(
                changeIsProduction({
                  ids: [cell.row.original.platformEnvId],
                  isProduction: !value,
                }),
              );
            }}
          />
        ),
      },
    ],
    [platformEnvironments, productionHeader],
  );

  const sortBy = [{ id: 'name', desc: false }];

  function getSelectedIds(envs = []) {
    return envs.map((env) => env.platformEnvId).filter((id) => !existingPlatformEnvironmentsIds.includes(id));
  }

  function handleSubmit() {
    setConfirmationModalOpen(true);
    dispatch(resetPowerPlatformEnvsError());
  }

  return (
    <>
      <Breadcrumbs current="Connect Environments" />
      <div className="integration-environments">
        <div className="integration-environments-body">
          <PowerPlatformIntegrationGetEnvironmentsModal
            open={fetchEnvironmentsModalOpen}
            setOpen={setFetchEnvironmentsModalOpen}
          />
          <PowerPlatformIntegrationEnvironmentConfirmationModal
            envsToIntegrate={envsToIntegrate}
            open={confirmationModalOpen}
            setOpen={setConfirmationModalOpen}
          />
          <div className="text">{t('environmentIntegration.selectEnvironments', { ns: 'onboarding' })}</div>
          <SearchInput placeholder={t('tables.search')} value={searchQuery} setValue={setSearchQuery} />

          <Table
            contextualActions={[
              (selectedEnvs) => {
                const selectedIds = getSelectedIds(selectedEnvs);
                const currentSelectedIds = getSelectedIds(envsToIntegrate);

                if (!isEqual(selectedIds, currentSelectedIds)) {
                  const newEnvsToIntegrate = selectedEnvs.filter((env) => selectedIds.includes(env.platformEnvId));
                  setEnvsToIntegrate(newEnvsToIntegrate);
                }
              },
            ]}
            columns={columns}
            data={envsToShow}
            totalCount={envsToShow.length}
            options={{ showFilter: false, showPagination: true, showSort: false, usePaginationSearchParam: false }}
            type="users"
            sortBy={sortBy}
            initialFilters={[]}
            customFilters={[]}
            isRowDisabled={isRowDisabled}
            getElementId={(env) => env.platformEnvId}
            isLoading={isLoading}
            classNames="environment-table"
            backendPagination={false}
            pageSize={20}
            initialSelectedRows={existingPlatformEnvironmentsIds}
            showSelectAll
            emptyState={
              <TableEmptyContent
                img={emptyApplicationsImg}
                mainText={t('environmentIntegration.noEnvironments', { ns: 'onboarding' })}
              />
            }
          />

          <div className="footer">
            <Button text={t('general.submit')} onClick={handleSubmit} disabled={!envsToIntegrate.length} />
          </div>
        </div>
      </div>
    </>
  );
}
