import { useMemo, useState } from 'react';
import { Modal } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { buildGraph } from './utils/flowUtils';
import ReactFlowWrapper from './ReactFlowWrapper';

import './flow.scss';
import 'reactflow/dist/style.css';

export default function EntitiesFlow({ data, isFinishLoading }) {
  const [modalOpen, setModalOpen] = useState(false);
  const initialData = useMemo(() => buildGraph([], [], data), [isFinishLoading, modalOpen]);

  const onClick = () => setModalOpen(true);
  const handleClose = (e) => {
    e.stopPropagation();
    setModalOpen(false);
  };

  const flow = (
    <div className="flow" onClick={onClick}>
      <ReactFlowWrapper initialData={initialData} />
    </div>
  );

  return modalOpen ? (
    <Modal sx={{ top: '5%', left: '2%', width: '96%', height: '90%' }} open={modalOpen} onClose={handleClose}>
      {flow}
    </Modal>
  ) : (
    <div onClick={onClick} className="flow-container">
      <div className="overlay">
        <OpenInFullIcon className="expand-icon" />
      </div>
      {flow}
    </div>
  );
}
