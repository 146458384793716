import { Skeleton } from '@mui/material';
import Card from '../card/Card';

import './segment.scss';

export default function Segment({ title, children, isLoading, background = true }) {
  return (
    <div className="segment">
      <div className="segment-title">{title}</div>
      {isLoading ? (
        <Skeleton animation="wave" variant="rectangular" width="95%" />
      ) : (
        <Card background={background}>{children}</Card>
      )}
    </div>
  );
}

export function SegmentCards({ title, isLoading, cards }) {
  return (
    <div className="segment">
      <div className="segment-title">{title}</div>
      {isLoading ? (
        <Skeleton animation="wave" variant="rectangular" width="95%" />
      ) : (
        <div className="cards">
          {cards.map(({ background, children, key }) => (
            <Card key={key} background={background}>
              {children}
            </Card>
          ))}
        </div>
      )}
    </div>
  );
}
