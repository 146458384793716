import { useTranslation } from 'react-i18next';
import { StyledSwitch } from '../../../components/StyledSwitch';
import DropdownBox from '../../../components/dropdown-box/DropdownBox';
import InputNumber from '../../../components/input-number/InputNumber';
import Button from '../../../components/buttons/button/Button';
import { ReminderInterval } from './NotificationConsts';

export default function NotificationPolicyReminder({
  reminderActive,
  setReminderActive,
  reminderInterval,
  setReminderInterval,
  maxReminders,
  setMaxReminders,
  closeDrawer,
  onApprove,
}) {
  const { t } = useTranslation(['settings']);
  return (
    <div className="notification-policy-drawer-reminder">
      <div className="notification-policy-drawer-reminder-toggle">
        <StyledSwitch
          checked={reminderActive}
          onChange={() => setReminderActive(!reminderActive)}
          data-testid="policy-reminder"
        />
        <span>{t('notifications.policiesTable.columns.reminder')}</span>
        <span className="light-text">({t('notifications.policiesTable.drawer.optional')})</span>
      </div>
      <div className="notification-policy-drawer-reminder-properties">
        <DropdownBox
          disabled={!reminderActive}
          width={240}
          text=""
          value={t(`notifications.policiesTable.drawer.reminder.${reminderInterval}`)}
          selectedId={reminderInterval}
          menuItems={Object.values(ReminderInterval).map((option) => ({
            id: option,
            key: option,
            onClick: () => setReminderInterval(option),
            text: t(`notifications.policiesTable.drawer.reminder.${option}`),
          }))}
        />
        <span>{t(`notifications.policiesTable.drawer.reminder.maxReminders`)}</span>
        <InputNumber
          value={maxReminders}
          setValue={setMaxReminders}
          disabled={!reminderActive}
          minValue={1}
          maxValue={1000}
        />
      </div>
      <div className="buttons-row">
        <Button isFilled={false} text={t('general.cancel', { ns: 'common' })} onClick={closeDrawer} />
        <Button isFilled text={t('general.approve', { ns: 'common' })} onClick={onApprove} />
      </div>
    </div>
  );
}
