import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import DropdownBox from '../../../components/dropdown-box/DropdownBox';
import ClosableModal from '../../../components/modal/ClosableModal';
import { EnrichWholeOrg, UploadEmbedCodes } from './EnrichComponents';
import { StatusBullet } from '../../../components/status-bullet/StatusBullet';
import EnrichModal from './EnrichModal';
import {
  fetchEnrichmentStatus,
  resetEmbedCodeEnrichmentStatus,
  resetEnrichmentStatus,
} from '../../../redux/slicers/biSlicer';
import {
  getPublicReportsEnrichmentStatus,
  getSharedReportsEnrichmentLoading,
  getSharedReportsEnrichmentStatus,
} from '../../../redux/selector/biSelector';
import { EmbedCodesModal } from './EmbedCodesModal';
import { getPowerPlatformAccounts } from '../../../redux/selector/accountsSelector';
import AzureAuthConfig from '../../../auth/azure-auth-config';
import { setAzureConfigClientId } from '../../../redux/slicers/accountSlicer';

const EnrichModalOptions = Object.freeze({
  EMBED_CODES: 'embedCodes',
  SHARE_WITH_ENTIRE_ORG: 'shareWithEntireOrg',
});

function isEnrichedSynced(reportStatus) {
  return reportStatus.enriched === reportStatus.total;
}

export default function EnrichDropdown() {
  const [enrichModalOpen, setEnrichModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [enrichRequired, setEnrichRequired] = useState(false);
  const { t } = useTranslation(['bi']);
  const dispatch = useDispatch();

  const powerPlatformAccounts = useSelector(getPowerPlatformAccounts);
  const sharedReportsStatus = useSelector(getSharedReportsEnrichmentStatus);
  const publicReportsStatus = useSelector(getPublicReportsEnrichmentStatus);
  const loading = useSelector(getSharedReportsEnrichmentLoading);

  useEffect(() => {
    dispatch(fetchEnrichmentStatus());
  }, []);

  const showAccountName = powerPlatformAccounts.length > 1;
  const menuItems = useMemo(
    () =>
      powerPlatformAccounts.flatMap((account) => [
        {
          id: 'Upload',
          text: (
            <UploadEmbedCodes
              reportStatus={publicReportsStatus}
              loading={loading}
              account={account}
              showAccountName={showAccountName}
            />
          ),
          onClick: () => {
            setSelectedAccountId(account.id);
            dispatch(resetEmbedCodeEnrichmentStatus());
            setModalContent(EnrichModalOptions.EMBED_CODES);
            setEnrichModalOpen(true);
          },
        },
        {
          id: 'Enrich',
          text: (
            <EnrichWholeOrg
              reportStatus={sharedReportsStatus}
              loading={loading}
              account={account}
              showAccountName={showAccountName}
            />
          ),
          onClick: () => {
            const { clientId, platformTenantId } = account.data;
            AzureAuthConfig.setConfig(clientId, platformTenantId);
            dispatch(setAzureConfigClientId(clientId));

            setSelectedAccountId(account.id);
            dispatch(resetEnrichmentStatus());
            setModalContent(EnrichModalOptions.SHARE_WITH_ENTIRE_ORG);
            setEnrichModalOpen(true);
          },
        },
      ]),
    [powerPlatformAccounts, publicReportsStatus],
  );

  useEffect(() => {
    const enrichRequiredNew = powerPlatformAccounts
      .filter((account) => !!sharedReportsStatus[account.id])
      .some(
        (account) =>
          !isEnrichedSynced(sharedReportsStatus[account.id]) || !isEnrichedSynced(publicReportsStatus[account.id]),
      );

    setEnrichRequired(enrichRequiredNew);
  }, [powerPlatformAccounts]);

  return (
    <div>
      <ClosableModal id="enrich-modal" open={enrichModalOpen} closeModal={() => setEnrichModalOpen(false)}>
        {modalContent === EnrichModalOptions.EMBED_CODES && (
          <EmbedCodesModal closeModal={() => setEnrichModalOpen(false)} accountId={selectedAccountId} />
        )}
        {modalContent === EnrichModalOptions.SHARE_WITH_ENTIRE_ORG && (
          <EnrichModal closeModal={() => setEnrichModalOpen(false)} accountId={selectedAccountId} />
        )}
      </ClosableModal>
      <DropdownBox
        id="bi-enrichment"
        width={200}
        text="Columns"
        value={<StatusBullet value={t('enrichDropdown')} active={!enrichRequired} loading={loading} />}
        menuItems={menuItems}
        shouldCheckIcon={false}
      />
    </div>
  );
}
