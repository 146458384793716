import { getConnectorIcon } from '../../pages/data-source/connector-icons/connectorsIconsService';
import automationIcon from './icons/application_type_automation_icon.svg';
import cloudFlowIcon from './icons/application_type_cloud_flow_icon.svg';
import desktopFlowIcon from './icons/application_type_desktop_flow_icon.svg';
import canvasAppIcon from './icons/application_type_canvas_app_icon.svg';
import moduleDrivenAppIcon from './icons/application_type_module_driven_app_icon.svg';
import pageIcon from './icons/application_type_page_icon.svg';
import biIcon from './icons/application_type_bi_icon.svg';
import copilotBotIcon from './icons/application_type_copilot_bot_icon.svg';
import { AppType } from '../../consts/application-type';

export default function ApplicationType({ value }) {
  let icon;
  switch (value) {
    case AppType.CANVAS_APP:
      icon = canvasAppIcon;
      break;
    case AppType.AUTOMATION:
      icon = automationIcon;
      break;
    case AppType.CLOUD_FLOW:
      icon = cloudFlowIcon;
      break;
    case AppType.DESKTOP_FLOW:
      icon = desktopFlowIcon;
      break;
    case AppType.MODULE_DRIVEN_APP:
      icon = moduleDrivenAppIcon;
      break;
    case AppType.PORTAL:
      icon = pageIcon;
      break;
    case AppType.BI:
      icon = biIcon;
      break;
    case AppType.EMBEDDED_SHAREPOINT:
      icon = getConnectorIcon('sharepoint');
      break;
    case AppType.COPILOT_BOT:
      icon = copilotBotIcon;
      break;
    default:
      icon = canvasAppIcon;
  }

  return (
    <div className="icon-text">
      <img src={icon} alt="Application icon" />
      <span>{value}</span>
    </div>
  );
}
