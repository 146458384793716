import { TextField, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Button from '../../../components/buttons/button/Button';

export default function Attributes({ attributes, setAttributes }) {
  function handleAddAttribute() {
    setAttributes([...attributes, { key: '', value: '' }]);
  }

  function handleRemoveAttribute(index) {
    const newAttributes = attributes.filter((_, i) => i !== index);
    setAttributes(newAttributes);
  }

  function handleChange(index, field, value) {
    const updatedAttributes = attributes.map((attr, i) => (i === index ? { ...attr, [field]: value } : attr));
    setAttributes(updatedAttributes);
  }

  return (
    <div className="attributes">
      {attributes.map((attribute, index) => (
        <div className="attributes-row">
          <TextField
            label="Key"
            value={attribute.key}
            onChange={(e) => handleChange(index, 'key', e.target.value)}
            variant="outlined"
          />
          <TextField
            label="Value"
            value={attribute.value}
            onChange={(e) => handleChange(index, 'value', e.target.value)}
            variant="outlined"
          />

          <IconButton onClick={() => handleRemoveAttribute(index)}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </div>
      ))}
      <Button startIcon={<Add />} onClick={handleAddAttribute} text="Add Attribute" />
    </div>
  );
}
