/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGet, doPatch } from '../../services/HttpService';

export const fetchRules = createAsyncThunk('policy/fetchRules', async () => {
  const [rules, openInsights] = await Promise.all([
    doGet('rules'),
    doGet('insights/summary/grouped/name?active%5Bequals%5D=true'),
  ]);

  return rules.data.map((rule) => ({
    ...rule,
    openInsights: openInsights.data.find((openInsight) => openInsight.key === rule.name)?.value || 0,
  }));
});

export const updateRule = createAsyncThunk('policy/updateRule', async ([ruleId, ruleBody]) => {
  const res = await doPatch(`rules/${ruleId}`, ruleBody);

  return {
    data: res.data,
  };
});

const initialState = {
  rules: {
    loading: false,
    error: false,
    content: [],
  },
  update: {
    loading: false,
    error: false,
    successful: false,
  },
};

export const policySlice = createSlice({
  name: 'policy',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchRules.pending, (state) => {
      state.rules.loading = true;
      state.rules.error = false;
    });
    builder.addCase(fetchRules.fulfilled, (state, { payload }) => {
      state.rules.loading = false;
      state.rules.error = false;
      state.rules.content = payload;
    });
    builder.addCase(fetchRules.rejected, (state) => {
      state.rules.loading = false;
      state.rules.error = true;
    });
    builder.addCase(updateRule.pending, (state) => {
      state.update.loading = true;
      state.update.error = false;
      state.update.successful = false;
    });
    builder.addCase(updateRule.rejected, (state) => {
      state.update.loading = false;
      state.update.error = true;
      state.update.successful = false;
    });
    builder.addCase(updateRule.fulfilled, (state, { payload }) => {
      state.update.loading = false;
      state.update.error = false;
      state.update.successful = true;
      const currentRuleIndex = state.rules.content.findIndex((rule) => rule.id === payload.data.id);
      if (currentRuleIndex === -1) {
        state.rules.content = [...state.rules.content, payload.data];
        return;
      }
      state.rules.content[currentRuleIndex] = payload.data;
    });
  },
});

// this is for configureStore
export default policySlice.reducer;
