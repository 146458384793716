import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import { mapDate } from '../../../services/MapperUtils';
import InternalLink from '../../../components/internal-link/InternalLink';

export default function EmbeddedFlowsTable({ isLoading, flows = [], linksBreadcrumbs }) {
  const { t } = useTranslation(['common', 'single-connection']);
  const columns = useMemo(
    () => [
      {
        Header: t('general.name', { ns: 'common' }),
        accessor: 'name',
        width: '30%',
        Cell: ({ value, cell }) => (
          <InternalLink text={value} url={`/applications/${cell.row.original.id}?from=${linksBreadcrumbs}`} />
        ),
      },
      {
        Header: t('users.users', { ns: 'common' }),
        accessor: 'users',
        width: '10%',
        Cell: ({ value }) => (value.length ? JSON.stringify(value) : t('general.na', { ns: 'common' })),
      },
      {
        Header: t('embeddedFlows.appsUsed', { ns: 'single-connection' }),
        accessor: 'appsUsingFlow',
        width: '10%',
      },
      {
        Header: t('embeddedFlows.openInsights', { ns: 'single-connection' }),
        accessor: 'insights.insightsCount',
        width: '10%',
      },
      {
        Header: t('general.creationTime', { ns: 'common' }),
        accessor: 'createdAt',
        Cell: ({ value }) => mapDate(value),
      },
      {
        Header: t('general.lastChange', { ns: 'common' }),
        accessor: 'changedAt',
        Cell: ({ value }) => mapDate(value),
      },
    ],
    [],
  );

  const sortBy = [{ id: 'name', desc: false }];

  return (
    <div className="card-view-table-container">
      <CollapseCard
        title={t('embeddedFlows.title', { ns: 'single-connection' })}
        isLoading={isLoading}
        totalElementsCount={flows.length}
        initialOpen
      >
        <Table
          columns={columns}
          data={flows}
          options={{ showFilter: false, showPagination: true, showSort: true, usePaginationSearchParam: false }}
          type="users"
          sortBy={sortBy}
          initialFilters={[]}
          customFilters={[]}
          isRowDisabled={() => false}
          getElementId={(user) => user.id}
          isLoading={isLoading}
          backendPagination={false}
          classNames="card-view-table"
          pageSize={5}
          totalCount={flows.length}
          emptyState={
            <TableEmptyContent
              img={emptyApplicationsImg}
              mainText={t('embeddedFlows.noData', { ns: 'single-connection' })}
            />
          }
        />
      </CollapseCard>
    </div>
  );
}
