import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import dayjs from 'dayjs';
import FilterValue from './FilterValue';
import 'dayjs/locale/en-gb';
import { isUsLocale } from '../../services/MapperUtils';

export default function DateFilter({ onClose, addFilter, column, operator }) {
  const [value, setValue] = useState(null);
  const { t } = useTranslation(['common']);
  const title = t(`tables.filters.operators.${camelCase(operator)}`);

  return (
    <FilterValue title={title} onClose={onClose} onApply={() => addFilter(operator, column, value)}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={navigator.language}>
        <DatePicker
          onChange={(newValue) => {
            const parsed = dayjs(newValue).format(isUsLocale() ? 'MM/DD/YYYY' : 'DD/MM/YYYY');
            setValue(parsed);
          }}
        />
      </LocalizationProvider>
    </FilterValue>
  );
}
