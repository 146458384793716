// This is the page that the Auth0 provider will redirect to after the user has successfully logged in
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { SystemErrorType } from '../error/errorConsts';

const BAD_INVITATION = 'invitation not found or already used';
const NO_ORGANIZATION = 'client requires organization membership, but user does not belong to any organization';

export function CallbackPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // these are the params auth0 returns after a bad login
  const errorParam = searchParams.get('error');
  const errorDescriptionParam = searchParams.get('error_description');

  useEffect(() => {
    if (!errorParam) {
      return;
    }

    switch (errorDescriptionParam) {
      case BAD_INVITATION:
        navigate(`/error?type=${SystemErrorType.INVALID_INVITATION}`);
        break;
      case NO_ORGANIZATION:
        navigate(`/error?type=${SystemErrorType.NO_ORGANIZATION}`);
        break;
      default:
        // handle other errors
        break;
    }
  }, [errorParam]);

  return <div />;
}
