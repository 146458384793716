import { useState } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import vetIcon from './connector-icons/vet_icon.svg';
import ConnectorVettingModal from './ConnectorVettingModal';
import { isAdmin, isSuperAdmin } from '../../redux/selector/authSelector';

const ConnectorStatusEnum = {
  DEPRECATED: 'Deprecated',
  VETTED: 'Vetted',
  NOT_VETTED: 'Not Vetted',
};

function getFormattedStatus(deprecated, vetted) {
  if (deprecated) {
    return ConnectorStatusEnum.DEPRECATED;
  }

  if (vetted) {
    return ConnectorStatusEnum.VETTED;
  }

  return ConnectorStatusEnum.NOT_VETTED;
}

export default function ConnectorStatus({ statusData }) {
  const [open, setOpen] = useState(false);
  const isSuperAdminRole = useSelector(isSuperAdmin);
  const isAdminRole = useSelector(isAdmin);
  const { t } = useTranslation(['connections']);

  const isAllowedToVet = isSuperAdminRole || isAdminRole;
  const { id: connectorId, name, deprecated, vetted, dateOfVetting, vettedByName } = statusData;

  const formattedStatus = getFormattedStatus(deprecated, vetted);

  const vettingDetails = t('connectorStatus.dateVettedBy', {
    date: dateOfVetting ? format(new Date(dateOfVetting), 'MMM dd, yyyy') : '',
    vettedByName,
  });

  return (
    <>
      <ConnectorVettingModal open={open} setOpen={setOpen} connectorId={connectorId} connectorName={name} />

      <div className="connector-status-container">
        <div
          className={classNames('connector-status', {
            vetted: formattedStatus === ConnectorStatusEnum.VETTED,
            deprecated: formattedStatus === ConnectorStatusEnum.DEPRECATED,
          })}
        >
          {t(`connectorStatus.${camelCase(formattedStatus)}`)}
          {formattedStatus === ConnectorStatusEnum.NOT_VETTED && isAllowedToVet && (
            <div className="icon-container" role="button" tabIndex={0} onClick={() => setOpen(true)}>
              <img src={vetIcon} alt="vetted" />
            </div>
          )}
        </div>
        {(vettedByName || dateOfVetting) && <div className="connector-vetting-details">{vettingDetails}</div>}
      </div>
    </>
  );
}
