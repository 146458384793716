import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import Tooltip from '@mui/material/Tooltip';

export default function PremiumIcon({ value }) {
  return (
    <Tooltip title={value} arrow>
      <DiamondOutlinedIcon fontSize="small" className="premium-icon-diamond gray" />
    </Tooltip>
  );
}
