import { getTenantNameFromUrl } from '../../services/OrgNameService';

export function navigateToSearch(navigate, navigatePath, navigateQueryParam, clickedLabel) {
  const currentPath = window.location.pathname;
  const currentSearch = window.location.search.slice(1); // remove the '?' from the search string

  const newValues = navigateQueryParam.split('&').map((param) => param.split('='));

  // don't replace current search params, only add new ones
  const searchParams = new URLSearchParams(currentSearch);
  navigateQueryParam.split('&').forEach((param) => {
    const [key] = param.split('=');
    if (!searchParams.has(key)) {
      const newValue = newValues.find(([k]) => k === key);
      // value can be either the value of an existing param or the clicked label
      searchParams.set(key, newValue[1] || clickedLabel);
    }
  });

  if (searchParams.has('noFilters')) {
    searchParams.delete('noFilters');
  }
  const nextSearch = searchParams.toString();

  const organizationName = getTenantNameFromUrl();
  const gotoPath = `/${decodeURI(organizationName)}/portal${navigatePath}`;
  navigate(`${gotoPath}?${nextSearch}`);

  // if current path equals to navigatePath, reload the page and request params are differnet
  if (decodeURI(currentPath) === gotoPath && currentSearch !== nextSearch) {
    window.location.reload();
  }
}
