import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import BasicTabs from '../../components/basic-tabs/BasicTabs';
import CreateInsightPage from './create-insight/CreateInsightPage';
import FalsePositivePage from './false-positives/FalsePositivePage';

import './back-office-page.scss';

export default function BackOfficePage() {
  const { t } = useTranslation(['back-office']);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(searchParams.get('tab') || 'createInsight');

  function changeTab(tab) {
    setSelectedTab(tab);
    setSearchParams({ tab });
  }

  const tabs = [
    {
      id: 'createInsight',
      label: t('createInsight.title'),
      onClick: () => changeTab('createInsight'),
    },
    {
      id: 'falsePositive',
      label: t('falsePositive.title'),
      onClick: () => changeTab('falsePositive'),
    },
  ];

  const initialTabIndex = tabs.findIndex((tab) => tab.id === selectedTab);

  return (
    <div className="back-office-page">
      <BasicTabs tabsData={tabs} initialTab={initialTabIndex} />
      {selectedTab === 'createInsight' && <CreateInsightPage />}
      {selectedTab === 'falsePositive' && <FalsePositivePage />}
    </div>
  );
}
