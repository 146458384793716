export const Operator = Object.freeze({
  greaterThanEquals: '≥',
  greaterThan: '>',
  lessThanEquals: '≤',
  lessThan: '<',
  is: '=',
  isNot: '≠',
  OR: 'OR',
  AND: 'AND',
});
