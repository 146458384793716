import { isEmpty, omitBy, partition, uniqBy } from 'lodash';
import { doGet } from '../../../services/HttpService';
import { checkIsFlow } from '../../../services/utils';
import { ENTITIES, USER_TYPES } from '../../../consts/Entities';

const MAX_ENTITIES_LENGTH = 100;

const limitEntities = (entities) => {
  const totalLength = Object.values(entities).reduce((sum, current) => sum + current.length, 0);
  if (totalLength > MAX_ENTITIES_LENGTH) {
    return true;
  }
  return false;
};

const removeEmptyArraysFromObj = (obj) => omitBy(obj, (value) => Array.isArray(value) && isEmpty(value));

const separateUsersAndGroups = (users) => ({
  users: users.filter(({ type }) => type !== USER_TYPES.GROUP),
  groups: users.filter(({ type }) => type === USER_TYPES.GROUP),
});

async function fetchApplicationsEntities(id) {
  const [{ data: flows }, { data: fetchedUsers }, { data: connections }] = await Promise.all([
    doGet(`application/${id}/flows`),
    doGet(`application/${id}/users`),
    doGet(`application/${id}/connections`),
  ]);

  const limited = limitEntities({ flows, users: fetchedUsers, connections });
  if (limited) {
    return [{}, true];
  }

  const { users, groups } = separateUsersAndGroups(fetchedUsers);

  return [
    {
      flows,
      users,
      groups,
      connections,
    },
  ];
}

async function fetchFlowsEntities(id) {
  const [{ data: connections }, { data: fetchedUsers }, { data: applications }] = await Promise.all([
    doGet(`application/${id}/connections`),
    doGet(`application/${id}/users`),
    doGet(`application/${id}/usedBy`),
  ]);

  const limited = limitEntities({ applications, users: fetchedUsers, connections });
  if (limited) {
    return [{}, true];
  }

  const { users, groups } = separateUsersAndGroups(fetchedUsers);

  return [
    {
      connections,
      users,
      groups,
      applications,
    },
  ];
}

async function fetchUsersEntities(id) {
  const [{ data: connections }, { data: apps }] = await Promise.all([
    doGet(`persons/${id}/connections`),
    doGet(`persons/${id}/applications`),
  ]);

  const limited = limitEntities({ applications: apps, connections });
  if (limited) {
    return [{}, true];
  }

  const flows = apps.data.filter((app) => checkIsFlow(app));
  const applications = apps.data.filter((app) => !checkIsFlow(app));

  return [
    {
      connections: connections.data,
      applications,
      flows,
    },
  ];
}

async function fetchConnectionsEntities(id) {
  const [{ data: fetchedUsers }, { data: apps }] = await Promise.all([
    doGet(`connections/${id}/users`),
    doGet(`connections/${id}/applications`),
  ]);

  const limited = limitEntities({ users: fetchedUsers, apps });
  if (limited) {
    return [{}, true];
  }

  const [flows, applications] = partition(apps, (app) => checkIsFlow(app));

  const uniqueUsers = uniqBy(fetchedUsers, 'id');
  const { users, groups } = separateUsersAndGroups(uniqueUsers);

  return [{ flows, users, groups, applications }];
}

export const fetchData = async (type, id) => {
  let data;
  switch (type) {
    case ENTITIES.APPLICATIONS:
      data = await fetchApplicationsEntities(id);
      break;
    case ENTITIES.FLOWS:
      data = await fetchFlowsEntities(id);
      break;
    case ENTITIES.USERS:
    case ENTITIES.GROUPS:
      data = await fetchUsersEntities(id);
      break;
    case ENTITIES.CONNECTIONS:
      data = await fetchConnectionsEntities(id);
      break;
    default:
      data = await fetchApplicationsEntities(id);
      break;
  }

  return [removeEmptyArraysFromObj(data[0]), data[1]];
};
