import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import { mapDate } from '../../../services/MapperUtils';

export default function ApplicationComponentsTable({ isLoading, type, components = [] }) {
  const { t } = useTranslation(['common']);

  const columns = useMemo(() => [
    {
      Header: t('general.name'),
      accessor: 'name',
      width: '35%',
    },
    {
      Header: t('general.createDate'),
      accessor: 'createdAt',
      width: '10%',
      Cell: ({ value }) => mapDate(value),
    },
    {
      Header: t('general.lastChange'),
      accessor: 'lastModifiedAt',
      width: '10%',
      Cell: ({ value }) => mapDate(value),
    },
  ]);

  const sortBy = [{ id: 'lastModifiedAt', desc: true }];

  return (
    <div className="card-view-table-container">
      <CollapseCard
        title={t(`${type}.title`)}
        totalElementsCount={components.length}
        isLoading={isLoading}
        initialOpen={false}
      >
        <Table
          columns={columns}
          data={components}
          totalCount={components.length}
          options={{ showFilter: false, showPagination: true, showSort: true, usePaginationSearchParam: false }}
          type={type}
          sortBy={sortBy}
          initialFilters={[]}
          customFilters={[]}
          isRowDisabled={() => false}
          getElementId={(component) => `${component.id}`}
          isLoading={isLoading}
          classNames="card-view-table"
          backendPagination={false}
          pageSize={5}
          emptyState={<TableEmptyContent img={emptyApplicationsImg} mainText={t(`${type}.noData`)} />}
        />
      </CollapseCard>
    </div>
  );
}
