import { createSelector } from '@reduxjs/toolkit';
import { POWER_PLATFORM } from '../../consts/platforms';

export const getAccounts = (state) => state.accounts.content;
export const getPowerPlatformAccounts = createSelector(getAccounts, (accounts) =>
  accounts.filter((a) => a.platformName === POWER_PLATFORM),
);
export const getAccountsLoading = (state) => state.accounts.loading;
const getAccountForRemediationInternal = (state) =>
  (state?.accounts?.content || []).filter((account) => account.data?.clientId);

export const getAccountForRemediation = createSelector(getAccountForRemediationInternal, (accounts) => accounts);

// hasNoAccounts is used by the router
// it must return true ONLY if the request was successful and there are no accounts.
export const hasNoAccounts = (state) =>
  !state.accounts.loading && !state.accounts.error && !state.accounts.content.length;
export const getAzureConfigClientId = (state) => state.accounts.azureConfigClientId;
export const getAccountByPlatformName = (platformName) => (state) =>
  state.accounts.content.find((a) => a.platformName === platformName && a.data);

export const getPowerPlatformIntegrationAccountId = (state) => state.accounts.powerPlatform.accountIdInProgress;

export const isPowerPlatformIntegrationLoading = (state) => state.accounts.powerPlatform.integrationCreate.loading;
export const getPowerPlatformIntegrationErrorMessage = (state) =>
  state.accounts.powerPlatform.integrationCreate.errorMessage;
export const getIsPowerPlatformIntegrationCompleted = (state) => state.accounts.powerPlatform.environments.success;

export const getPowerPlatformIntegrationEnvironments = (state) => state.accounts.powerPlatform.environments.content;

export const isPowerPlatformEnvironmentsLoading = (state) => state.accounts.powerPlatform.environments.loading;

export const getPowerPlatformEnvironmentsErrorMessage = (state) =>
  state.accounts.powerPlatform.environments.errorMessage;

export const getPowerPlatformCompletedEnvsCount = (state) =>
  state.accounts.powerPlatform.environments.content.completed || [];

export const getAccountRenameLoading = (accountId) => (state) =>
  state.accounts.accountOperations[accountId]?.accountRename?.loading;

export const getAccountRenameError = (accountId) => (state) =>
  state.accounts.accountOperations[accountId]?.accountRename?.error;

export const getAccountById = (accountId) =>
  createSelector(getAccounts, (accounts) => accounts.find((a) => a.id === accountId));

export const getAccountEditCredentialsLoading = (accountId) => (state) =>
  state.accounts.accountOperations[accountId]?.accountEditCredentials?.loading;

export const getAccountEditCredentialsError = (accountId) => (state) =>
  state.accounts.accountOperations[accountId]?.accountEditCredentials?.error;
