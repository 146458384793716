import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useTranslation } from 'react-i18next';
import EllipsisText from '../ellipsis-text/EllipsisText';
import './person-name.scss';

export default function PersonName({ name, icon = <AccountCircleIcon />, shouldEllipsis = false }) {
  const { t } = useTranslation(['common']);

  const personName = name ?? t('general.na');
  return (
    <div className="person-name">
      {name && icon}
      {shouldEllipsis ? <EllipsisText text={personName} /> : <div>{personName}</div>}
    </div>
  );
}
