import './widget-bar.scss';
import Card from '../card/Card';

export default function WidgetBar({ boxes }) {
  if (!boxes || boxes.length === 0) {
    return <div />;
  }

  return (
    <div className="widgets-bar row">
      {boxes.map((box) => {
        const flex = box.flex ?? 1;
        const title = box.title ?? '';
        const content = box.content ?? '';

        return (
          <Card key={title} title={title} style={{ flex }}>
            {content}
          </Card>
        );
      })}
    </div>
  );
}
