import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import { mapDate } from '../../../services/MapperUtils';
import { mapStatus } from '../../../services/InsightsMapper';
import CircledText from '../../../components/circled-text/CircledText';
import ColoredCircledBoolean from '../../../components/circled-text/ColoredCircledBoolean';
import InsightScope from '../../../components/insight-scope/InsightScope';
import InsightTypeBox from '../../../components/box/InsightTypeBox';
import PersonData from '../../../components/person-data/PersonData';

export default function InsightData({ rule, description, history, scope, closedAt, owner, linksBreadcrumbs }) {
  const { t } = useTranslation(['common', 'single-insight']);

  // history is sorted. the most updated one is the first element

  const { inProduction, status, statusMessage } = history.at(0);
  const { changeTime } = history.at(-1);
  const { name, type } = rule;

  const hasJustification = status === 'CLOSED_ACKNOWLEDGED' && !!statusMessage;
  const hasClosedTime = !!closedAt;

  const statusElement = (
    <KeyValueElement
      title={t('general.status')}
      value={
        <CircledText
          text={mapStatus(status)}
          className={classnames({ 'in-remediation': status === 'IN_REMEDIATION' })}
        />
      }
    />
  );

  return (
    <div className="insight-data">
      <div className="rule">
        <div>{name}</div>
        <div>{description}</div>
      </div>
      <div className="row">
        <KeyValueElement title={t('general.creationTime')} value={mapDate(changeTime)} />
        {statusElement}
        {owner && (
          <KeyValueElement
            title={t('insights.insightOwner')}
            value={<PersonData owner={owner} linksBreadcrumbs={linksBreadcrumbs} />}
            boxClassName="bordered"
          />
        )}
        <KeyValueElement
          title={t('general.inProduction')}
          value={<ColoredCircledBoolean isTrue={inProduction} />}
          boxClassName="bordered"
        />
        <KeyValueElement title={t('insights.scope')} value={<InsightScope insightScopeSubType={scope} />} />
        <KeyValueElement
          title={t('general.type')}
          value={<InsightTypeBox insightType={type} hasBorder={false} isFilled={false} hasPadding={false} />}
          boxClassName="bordered"
        />
        {hasClosedTime && (
          <KeyValueElement title={t('insights.closeTime')} value={mapDate(closedAt)} className="no-border" />
        )}
        {hasJustification && (
          <KeyValueElement
            title={t('justification', { ns: 'single-insight' })}
            // react by default escapes html so this is safe
            // https://legacy.reactjs.org/docs/introducing-jsx.html#jsx-prevents-injection-attacks
            value={statusMessage}
          />
        )}
      </div>
    </div>
  );
}
