import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { partition, snakeCase } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ButtonWithIcon from '../buttons/button-with-icon/ButtonWithIcon';
import AcknowledgeModal from './AcknowledgeModal';
import { isInsightAcknowledgeFailed } from '../../redux/selector/insightsSelector';
import { acknowledgeInsights } from '../../redux/slicers/insightSlicer';
import AckToast from '../toast/AckToast';
import { isSuperAdmin, isAdmin } from '../../redux/selector/authSelector';
import './acknowledge.scss';

export default function AcknowledgeButton({
  insights = [],
  onAcknowledge,
  text = 'Acknowledge',
  buttonColor = 'blue',
}) {
  const [message, setMessage] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [ackCompleted, setAckCompleted] = useState(false);
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const isAckFailed = useSelector(isInsightAcknowledgeFailed);
  const isSuperAdminRole = useSelector(isSuperAdmin);
  const isAdminRole = useSelector(isAdmin);
  const isAllowedToAck = isSuperAdminRole || isAdminRole;
  const activeInsights = insights.filter((i) => !i.status.toLowerCase().startsWith('closed'));
  const shouldDisableAck = activeInsights.length === 0 || !isAllowedToAck;

  const [messageRequiredInsights] = partition(insights, ({ status }) => snakeCase(status) === 'requires_resolution');

  async function doAck() {
    await dispatch(
      acknowledgeInsights({
        userEmail: user.email,
        userName: user.name,
        insightIds: insights.map((i) => i.id),
        message,
      }),
    );
    setAckCompleted(true);
    onAcknowledge();
  }

  async function onAckClicked() {
    if (shouldDisableAck) {
      return;
    }

    if (messageRequiredInsights.length > 0) {
      setModalOpen(true);
    } else {
      await doAck();
    }
  }

  return (
    <div className="acknowledge-button">
      <AckToast ackFailed={isAckFailed} ackCompleted={ackCompleted} setAckCompleted={setAckCompleted} />
      <AcknowledgeModal
        setModalOpen={setModalOpen}
        message={message}
        setMessage={setMessage}
        modalOpen={modalOpen}
        onAcknowledge={doAck}
        insights={insights}
      />
      <ButtonWithIcon
        enabled={!shouldDisableAck}
        onClick={onAckClicked}
        icon={<DoneAllIcon />}
        text={text}
        color={buttonColor}
      />
    </div>
  );
}
