import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { capitalize, startCase } from 'lodash';
import { formatDistanceToNow } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { sendFetchDataTask } from '../../../redux/slicers/tasksSlicer';
import { fetchHomepageTenantData } from '../../../redux/slicers/homepageSlicer';
import { isFetchDataTasksSuccessful } from '../../../redux/selector/tasksSelector';
import Card from '../../../components/card/Card';
import Toast from '../../../components/toast/Toast';
import Button from '../../../components/buttons/button/Button';
import CloseableModal from '../../../components/modal/ClosableModal';
import { isSuperAdmin } from '../../../redux/selector/authSelector';

function getLastScan(scanData) {
  if (scanData.length === 0) {
    return null;
  }

  return scanData[0].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())[0];
}

function getTimeAgo(lastScanTime) {
  if (!lastScanTime) {
    return 'Never';
  }

  const date = new Date(lastScanTime);

  return capitalize(formatDistanceToNow(date, { addSuffix: true }));
}

function ScanInfo({ mainText, secondaryText }) {
  return (
    <div className="scan-info">
      <div className="main-text">{mainText}</div>
      <div className="secondary-text">{secondaryText}</div>
    </div>
  );
}

function CreateScanModal({ open, setOpen }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['homePage', 'common']);

  const [toastOpen, setToastOpen] = useState(false);
  const fetchDataSuccessful = useSelector(isFetchDataTasksSuccessful);

  return (
    <>
      <Toast
        open={toastOpen}
        setOpen={setToastOpen}
        isSuccess={fetchDataSuccessful}
        title={fetchDataSuccessful ? t('systemWidget.systemScan.scanStarted') : t('systemWidget.systemScan.scanFailed')}
        message={
          fetchDataSuccessful
            ? t('systemWidget.systemScan.scanStartedMessage')
            : t('general.pleaseTryAgain', { ns: 'common' })
        }
      />

      <CloseableModal open={open} closeModal={() => setOpen(false)}>
        <div className="title">{t('systemWidget.systemScan.scanTitle')}</div>
        <div className="text-container">
          <div className="text bold">{t('systemWidget.systemScan.runScanConfirmation')}</div>
          <div className="text">{t('general.areYouSure', { ns: 'common' })}</div>
        </div>
        <div className="buttons-row">
          <Button isFilled={false} text={t('general.close', { ns: 'common' })} onClick={() => setOpen(false)} />
          <Button
            isFilled
            text={t('systemWidget.systemScan.runScan')}
            onClick={async () => {
              await dispatch(sendFetchDataTask());
              if (fetchDataSuccessful) {
                await dispatch(fetchHomepageTenantData());
              }
              setToastOpen(true);
              setOpen(false);
            }}
          />
        </div>
      </CloseableModal>
    </>
  );
}

export default function LastScanStatus({ scanData, loading }) {
  const [modalOpen, setModalOpen] = useState(false);
  const lastData = getLastScan(scanData);
  const isSuperAdminRole = useSelector(isSuperAdmin);
  const { t } = useTranslation(['homePage']);

  return (
    <Card
      title={t('systemWidget.systemScan.latestScanInformation')}
      className="short last-scan-data-container"
      loading={loading}
    >
      {isSuperAdminRole && (
        <div
          className="scan-button"
          role="button"
          tabIndex={0}
          onClick={async () => setModalOpen(true)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setModalOpen(true);
            }
          }}
        >
          {t('systemWidget.systemScan.scanNow')}
        </div>
      )}
      <CreateScanModal open={modalOpen} setOpen={setModalOpen} />
      <div className="last-scan-data">
        <ScanInfo mainText={getTimeAgo(lastData?.createdAt)} secondaryText={t('systemWidget.systemScan.lastScan')} />
        <ScanInfo mainText={startCase(lastData?.type)} secondaryText={t('systemWidget.systemScan.scanType')} />
        <ScanInfo mainText={startCase(lastData?.trigger)} secondaryText={t('systemWidget.systemScan.trigger')} />
      </div>
    </Card>
  );
}
