import { useSelector } from 'react-redux';
import * as _ from 'lodash';
import editIcon from './edit_icon.svg';
import { isSuperAdmin } from '../../redux/selector/authSelector';
import BooleanValue from '../../components/boolean-value/BooleanValue';

export default function RuleConfiguration({ configuration }) {
  const superAdmin = useSelector(isSuperAdmin);

  function displayConfigValue(value) {
    switch (typeof value) {
      case 'object':
        return value.map((v) => <div className="value">{_.startCase(v)}</div>);
      case 'boolean':
        return <BooleanValue isTrue={value} />;
      default:
        return <div className="value">{value}</div>;
    }
  }

  return (
    <div>
      {superAdmin && (
        <div className="edit-button-row">
          <div className="edit-button">
            <img src={editIcon} alt="edit icon" />
            <div>Edit Properties</div>
          </div>
        </div>
      )}
      <div className="text-container row">
        <div>
          {Object.entries(configuration).map(([key, value]) => (
            <div className="key-value-pair">
              <div className="key">{_.startCase(key)}:</div>
              {displayConfigValue(value)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
