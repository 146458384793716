const ignoredSearchParams = ['tab', 'sort', 'page'];

export function getInitialFilters(searchParams, defaultFilters) {
  const noFilters = searchParams.get('noFilters');
  // if explicitly set to no filters, return empty array
  if (noFilters) {
    return [];
  }
  // if no search params (excluding ignored), return default filters
  const numOfFilters = Array.from(searchParams.keys()).filter((key) => !ignoredSearchParams.includes(key)).length;
  if (numOfFilters === 0) {
    return defaultFilters;
  }

  // return search params filters
  const searchParamFilters = [];
  searchParams.forEach((value, key) => {
    const [column, type] = key.split(':');
    if (type) {
      searchParamFilters.push({ column, type, value });
    }
  });
  return searchParamFilters;
}

export function compareFilters(currentFilters, newFilters) {
  if (!currentFilters || !newFilters) {
    return false;
  }
  if (currentFilters.length !== newFilters.length) {
    return false;
  }
  return currentFilters.every((filter) => {
    const newFilter = newFilters.find((newFilterObj) => newFilterObj.column === filter.column);
    if (!newFilter) {
      return false;
    }

    return newFilter.value === filter.value && newFilter.operator === filter.operator;
  });
}
