import { useSelector } from 'react-redux';
import { sumBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import applicationImg from './applications_icon.svg';
import { getHomePagePlatformName, isHomePagePlatformNameLoading } from '../../../redux/selector/homepageSelector';
import HomePageCounter from '../HomePageCounter';

export default function TotalApplicationsCounter() {
  const applicationByPlatform = useSelector(getHomePagePlatformName);
  const applicationByPlatformLoading = useSelector(isHomePagePlatformNameLoading);
  const totalStaticApplicationCount = sumBy(applicationByPlatform, (platform) => Number(platform.value));
  const { t } = useTranslation(['homePage']);

  return (
    <HomePageCounter
      text={t('applicationsWidget.totalApplications')}
      src={applicationImg}
      count={totalStaticApplicationCount}
      loading={applicationByPlatformLoading}
    />
  );
}
