import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchReportsTrend } from '../../redux/slicers/biSlicer';
import { getReportsTrend, getReportsTrendLoading } from '../../redux/selector/biSelector';
import Loader from '../../components/loader/Loader';
import { blueColor } from '../../consts/colors';
import LineChart from '../../components/chart/line-chart/LineChart';

export default function ReportsTrend() {
  const dispatch = useDispatch();
  const loading = useSelector(getReportsTrendLoading);
  const reportsTrend = useSelector(getReportsTrend);

  const { t } = useTranslation(['bi']);

  useEffect(() => {
    dispatch(fetchReportsTrend());
  }, []);

  const labels = reportsTrend.map((developer) => developer.date);

  const data = {
    labels,
    datasets: [
      {
        label: t('newDevelopers'),
        data: reportsTrend.map((developer) => developer.count),
        fill: false,
        borderColor: blueColor,
        tension: 0.2,
      },
    ],
  };

  return loading ? <Loader /> : <LineChart data={data} showLegend={false} />;
}
