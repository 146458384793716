import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import EllipsisText from '../../../components/ellipsis-text/EllipsisText';
import { getApplicationHttpEndpoints } from '../../../redux/selector/applicationSelector';

export default function SingleFlowHttpEndpointsTable({ isLoading, appId }) {
  const { t } = useTranslation(['common', 'connections']);

  const httpEndpoints = useSelector(getApplicationHttpEndpoints(appId));

  const columns = useMemo(
    () => [
      {
        Header: t('tables.http-endpoints.columns.domain'),
        accessor: 'domain',
        width: '20%',
        Cell: ({ value }) => <EllipsisText text={value} />,
      },
      {
        Header: t('tables.http-endpoints.columns.path'),
        accessor: 'path',
        width: '30%',
        Cell: ({ value }) => <EllipsisText text={value} />,
      },
      {
        Header: t('tables.http-endpoints.columns.method'),
        accessor: 'method',
        width: '10%',
        Cell: ({ value }) => <EllipsisText text={value} />,
      },
      {
        Header: t('tables.http-endpoints.columns.stepName'),
        accessor: 'stepName',
        width: '20%',
        Cell: ({ value }) => <EllipsisText text={value} />,
      },
    ],
    [],
  );

  const sortBy = [{ id: 'domain', desc: true }];

  return (
    <div className="card-view-table-container">
      <CollapseCard
        title={t('httpEndpoints.name', { ns: 'connections' })}
        totalElementsCount={httpEndpoints.length}
        isLoading={isLoading}
        initialOpen={false}
      >
        <Table
          columns={columns}
          data={httpEndpoints}
          totalCount={httpEndpoints.length}
          options={{ showFilter: false, showPagination: true, showSort: true, usePaginationSearchParam: false }}
          type="http-endpoints"
          sortBy={sortBy}
          initialFilters={[]}
          customFilters={[]}
          isRowDisabled={() => false}
          getElementId={(httpEndpoint) => `${httpEndpoint.domain}-${httpEndpoint.path}-${httpEndpoint.stepName}`}
          isLoading={isLoading}
          classNames="card-view-table"
          backendPagination={false}
          pageSize={5}
          emptyState={
            <TableEmptyContent img={emptyApplicationsImg} mainText={t('httpEndpoints.noData', { ns: 'connections' })} />
          }
        />
      </CollapseCard>
    </div>
  );
}
