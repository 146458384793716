import { IS } from '../components/filter/filterConsts';
import { enrichInsightFilters } from '../services/MapperUtils';

export const initialInsightsFilters = enrichInsightFilters([
  {
    column: 'resolved',
    type: IS,
    value: 'false',
  },
  {
    column: 'resourceActive',
    type: IS,
    value: 'true',
  },
]);
