import Markdown from 'react-markdown';
import { Link, useSearchParams } from 'react-router-dom';
import { getTenantId, getTenants } from '../../services/AuthService';
import { systemErrors, SystemErrorType } from './errorConsts';

import './error-page.scss';

function getOrgName() {
  const tenants = getTenants();
  if (!tenants?.length) {
    return null;
  }

  const currentTenantId = getTenantId();
  const currentTenant = tenants.find((t) => t.name === currentTenantId);

  return currentTenant ? currentTenant.display_name : tenants[0].display_name;
}

export default function ErrorPage(error) {
  const [searchParams] = useSearchParams();
  const errorType = error?.error || searchParams.get('type') || SystemErrorType.NOT_FOUND;
  const { image, message, showReturnLink } = systemErrors[errorType];

  const orgName = getOrgName();

  return (
    <div className="error-page">
      <div className="message-container">
        <img src={image} alt="page not found" />
        <div className="error-page-message">
          <Markdown>{message}</Markdown>
        </div>
        {orgName && showReturnLink && <Link to={`/${orgName}/portal`}>Take me back to the main page</Link>}
      </div>
    </div>
  );
}
