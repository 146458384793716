import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HomePageTable } from './HomePageTable';
import BooleanValue from '../../../components/boolean-value/BooleanValue';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import {
  getHomepageBusinessContexts,
  isHomepageBusinessContextsLoading,
} from '../../../redux/selector/homepageSelector';

export default function BusinessContextTable({ inProductionOnly }) {
  const businessContexts = useSelector(getHomepageBusinessContexts);
  const loading = useSelector(isHomepageBusinessContextsLoading);
  const filteredContexts = businessContexts.filter((tag) => !inProductionOnly || tag.inProduction);
  const { t } = useTranslation(['common', 'homePage']);

  const columns = useMemo(
    () => [
      {
        backendKey: 'name',
        width: '30%',
        accessor: (cell) => cell.name,
        Cell: ({ cell }) => (
          <OrganizationAwareLink to={`/applications?businessContextName:is=${cell.value}`}>
            {cell.value}
          </OrganizationAwareLink>
        ),
      },
      {
        backendKey: 'inProduction',
        width: '10%',
        accessor: (cell) => cell.inProduction,
        Cell: ({ value }) => <BooleanValue isTrue={value} />,
        sortType: (rowA, rowB) => {
          const a = rowA.original.inProduction;
          const b = rowB.original.inProduction;
          return b - a; // reverse order so true is first
        },
      },
      {
        backendKey: 'applications',
        width: '10%',
        accessor: (cell) => cell.applications,
        Cell: ({ cell }) => <div className="number">{cell.value}</div>,
      },
    ],
    [],
  );

  return (
    <HomePageTable
      type="home.businessContexts"
      title={t('homePageTables.businessContext', { ns: 'homePage' })}
      tooltipText={t('homePageTables.businessContextTooltip', { ns: 'homePage' })}
      columns={columns}
      data={filteredContexts}
      isLoading={loading}
      numOfRows={20}
      emptyState={
        <TableEmptyContent
          img={emptyApplicationsImg}
          mainText={t('homePageTables.noBusinessContexts', { ns: 'homePage' })}
          secondaryText=""
        />
      }
    />
  );
}
