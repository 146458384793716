import { getDevelopersActivity, getDevelopersActivityLoading } from '../../redux/selector/userSelector';
import { fetchDevelopersActivity } from '../../redux/slicers/usersSlicer';
import LineChartEyeCandy from '../../components/line-chart-eye-candy/LineChartEyeCandy';

export default function ActiveDevelopers({ options = [] }) {
  return (
    <LineChartEyeCandy
      options={options}
      fetchDataAction={fetchDevelopersActivity}
      selector={getDevelopersActivity}
      isLoadingSelector={getDevelopersActivityLoading}
    />
  );
}
