import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { isNil } from 'lodash';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isSuperAdmin, isAdmin } from '../../redux/selector/authSelector';
import { fetchInsightConfidentialData } from '../../redux/slicers/insightSlicer';
import { getInsightConfidentialErrorMessage } from '../../redux/selector/insightsSelector';
import Toast from '../toast/Toast';

import './insight-attribute.scss';

function getDisplayValue(value, NA) {
  if (isNil(value) || value === '') {
    return NA;
  }

  let displayValue = value;
  if (typeof value === 'object') {
    displayValue = value?.join(', ');
  }

  return displayValue ?? NA;
}

function InsightAttribute({ title, value, insightId }) {
  const { t } = useTranslation(['common', 'single-insight']);
  const NA = t('general.na');

  const dispatch = useDispatch();
  const [toastOpen, setToastOpen] = useState(false);
  const errorMessage = useSelector(getInsightConfidentialErrorMessage);
  const displayValue = getDisplayValue(value, NA);
  const isConfidential = displayValue === '***';
  const tooltipId = `${title}-confidential`;
  const isSuperAdminRole = useSelector(isSuperAdmin);
  const isAdminRole = useSelector(isAdmin);
  const canRevealConfidential = isSuperAdminRole || isAdminRole;

  useEffect(() => {
    setToastOpen(!isNil(errorMessage));
  }, [errorMessage]);

  const showConfidential = () => {
    if (isConfidential && insightId && canRevealConfidential) {
      dispatch(fetchInsightConfidentialData(insightId));
    }
  };

  return (
    <>
      <div
        key={title}
        tabIndex={0}
        role="button"
        className={classNames('insight-attribute', {
          confidential: isConfidential && canRevealConfidential,
        })}
        data-tooltip-id={tooltipId}
        data-tooltip-content={t('showConfidentialValue', { ns: 'single-insight' })}
        onClick={showConfidential}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            showConfidential();
          }
        }}
      >
        <div>{title}:</div>
        <div className="value">{displayValue}</div>
      </div>
      <Toast
        isSuccess={isNil(errorMessage)}
        open={toastOpen}
        setOpen={setToastOpen}
        title={t('general.actionFailed')}
        message={errorMessage}
      />
      {isConfidential && canRevealConfidential && <Tooltip id={tooltipId} />}
    </>
  );
}

export default function InsightAttributes({ attributes = [], insightId }) {
  return (
    <div className="insight-attributes row">
      {attributes.map((attribute) => (
        <InsightAttribute key={attribute.title} {...attribute} insightId={insightId} />
      ))}
    </div>
  );
}
