import classNames from 'classnames';

export default function NotificationInfo({ label, value, img, className }) {
  return (
    <>
      <div>{label}</div>
      <div className={classNames('notification-value', label?.toLowerCase())}>
        <img src={img} alt="info-img" className={className} />
        <span className="notification-info-value">{value}</span>
      </div>
    </>
  );
}
