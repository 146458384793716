import classNames from 'classnames';

export default function AppInstanceStatus({ text, backgroundColor, circleColor, textColor }) {
  return (
    <div className={classNames('app-instance-status', backgroundColor)}>
      <span className={classNames('circle', circleColor)} />
      <span className={classNames('text', textColor)}>{text}</span>
    </div>
  );
}
