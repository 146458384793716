import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { criticalColor, highColor, infoColor, lowColor, mediumColor } from '../../consts/colors';
import DoughnutChart from '../../components/chart/doughnut/DoughnutChart';
import { insightSeverityToBackendValue } from '../../consts/insight-severity';
import Loader from '../../components/loader/Loader';
import { enrichLinkWithFilters } from '../home/utils';

function getValue(insightsBySeverity, key) {
  const res = insightsBySeverity.find((x) => x.key === key)?.value || 0;
  return Number(res);
}

export default function InsightSeverityWidget({
  size = 120,
  insightsBySeverity,
  loading,
  isProduction = false,
  startDate = null,
}) {
  const criticalCount = getValue(insightsBySeverity, insightSeverityToBackendValue.CRITICAL);
  const highCount = getValue(insightsBySeverity, insightSeverityToBackendValue.HIGH);
  const mediumCount = getValue(insightsBySeverity, insightSeverityToBackendValue.MEDIUM);
  const lowCount = getValue(insightsBySeverity, insightSeverityToBackendValue.LOW);
  const infoCount = getValue(insightsBySeverity, insightSeverityToBackendValue.INFO);
  const { t } = useTranslation(['common']);

  const navPath = 'tab=all&resolved:is=False';
  const tEnrichedNavPath = useMemo(
    () => `${enrichLinkWithFilters(startDate, 'creationTime', isProduction, navPath)}&severity:is`,
    [startDate, isProduction],
  );

  const data = {
    labels: [t('insights.critical'), t('insights.high'), t('insights.medium'), t('insights.low'), t('insights.info')],
    englishLabels: ['Critical', 'High', 'Medium', 'Low', 'Info'],
    datasets: [
      {
        data: [criticalCount, highCount, mediumCount, lowCount, infoCount],
        backgroundColor: [criticalColor, highColor, mediumColor, lowColor, infoColor],
        borderWidth: 3,
      },
    ],
  };

  return loading ? (
    <Loader />
  ) : (
    <DoughnutChart
      id="home-page-severity"
      width={size}
      height={size}
      data={data}
      loading={loading}
      navigatePath="/insights"
      navigateQueryParam={tEnrichedNavPath}
    />
  );
}
