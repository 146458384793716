import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getHistoryLength } from '../../redux/selector/historySelector';
import { getTenantNameFromUrl } from '../../services/OrgNameService';

import './breadcrumbs.scss';

const breadcrumbToPath = {
  applications: 'applications',
  insights: 'insights?tab=all',
  aggregations: 'insights',
  'data sources': 'data?tab=Connections',
  'http endpoints': 'data?tab=HttpEndpoints',
};

function navigateByPath(navigate, breadcrumb) {
  const orgName = getTenantNameFromUrl();
  const path = breadcrumbToPath[breadcrumb.toLowerCase()];
  navigate(`/${orgName}/portal/${path}`);
}

export default function Breadcrumbs({ current }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const historyLength = useSelector(getHistoryLength);

  const from = searchParams.get('from');

  if (!current) return null;

  const backSteps = from ? [...decodeURIComponent(from).split(','), current] : [current];
  // if backSteps is circular, like "A -> B -> C -> B" keep only "A -> B"
  const lastStep = backSteps[backSteps.length - 1];
  const lastStepIndex = backSteps.indexOf(lastStep);

  let slicedBackSteps = backSteps;
  if (lastStepIndex !== backSteps.length - 1) {
    slicedBackSteps = backSteps.slice(0, lastStepIndex + 1);
  }

  return (
    <div className="breadcrumbs">
      {slicedBackSteps.map((step, index) => (
        <div
          key={step}
          onClick={() => {
            if (index === backSteps.length - 1) return; // skip current one

            const goBackInHistory = backSteps.length - 1 - backSteps.findIndex((originalStep) => step === originalStep);
            if (historyLength > goBackInHistory) {
              navigate(goBackInHistory * -1);
            } else {
              navigateByPath(navigate, step);
            }
          }}
          className="breadcrumb-item"
        >
          {step}
        </div>
      ))}
    </div>
  );
}
