import InternalLink from '../components/internal-link/InternalLink';
import { AppType } from '../consts/application-type';

export const renderLinkOrZero = (count, url) => (count === 0 ? '0' : <InternalLink url={url} text={count} />);

export const checkIsFlow = (app) => app?.type === AppType.CLOUD_FLOW;

export const checkIsCopilot = (app) => app?.type === AppType.COPILOT_BOT;

export function validateEmails(emailInput) {
  if (!emailInput) {
    return 'Please enter at least one email';
  }
  const emails = emailInput.split(',').map((email) => email.trim());

  // eslint-disable-next-line no-restricted-syntax
  for (const email of emails) {
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      return 'Please enter valid email addresses';
    }
  }

  // check if emails are duplicated
  const uniqueEmails = [...new Set(emails)];
  if (emails.length !== uniqueEmails.length) {
    return 'Please enter unique email addresses';
  }

  return null;
}
