import { Link } from 'react-router-dom';
import './organization-aware-link.scss';
import classNames from 'classnames';
import { getTenantNameFromUrl } from '../../services/OrgNameService';

export default function OrganizationAwareLink({
  to,
  children,
  className,
  disabled = false,
  replace = false,
  onClick = () => {},
}) {
  const orgName = getTenantNameFromUrl();
  const isDisabled = disabled || !orgName;

  return (
    <Link
      replace={replace}
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      to={isDisabled ? '#' : `/${orgName}/portal${to}`}
      className={classNames('organization-aware-link', className, { disabled: isDisabled })}
    >
      {children}
    </Link>
  );
}
