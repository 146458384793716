import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { blues } from '../../../consts/colors';
import HorizontalBarChart from '../../../components/chart/horizontal-bar/HorizontalBarChart';
import Card from '../../../components/card/Card';
import {
  getHomePageInsightsByPlatform,
  isHomePageGroupedInsightsLoading,
} from '../../../redux/selector/homepageSelector';
import { enrichLinkWithFilters } from '../utils';

export default function OpenInsights({ inProduction, startDate }) {
  const insightsByPlatform = useSelector(getHomePageInsightsByPlatform);
  const loading = useSelector(isHomePageGroupedInsightsLoading);
  const { t } = useTranslation(['common']);

  const data = insightsByPlatform.map((status, index) => ({
    name: status.key,
    value: status.value,
    color: blues[index * 2],
  }));
  const enrichedNavPath = useMemo(
    () =>
      `${enrichLinkWithFilters(startDate, 'creationTime', inProduction, 'tab=all&resolved:is=False')}&platformName:is`,
    [startDate, inProduction],
  );

  return (
    <Card title={t('insights.openInsights')} className="short flex open-insights" loading={loading}>
      <HorizontalBarChart data={data} loading={loading} navigatePath="/insights" navigateQueryParam={enrichedNavPath} />
    </Card>
  );
}
