/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGet, doPost } from '../../services/HttpService';
import {
  enrichConnectorFilters,
  getConnectorBackendKeyForOrderBy,
  enrichDataSourceFilters,
  mapConnectorFilter,
  mapConnectorFilterValue,
  mapFiltersToParams,
  mapPropertiesToParams,
} from '../../services/MapperUtils';

export const fetchDataSourcesFiltersOptions = createAsyncThunk(
  'dataSources/fetchDataSourcesFiltersOptions',
  async () => {
    const res = await doGet(`dataSources/summary`);

    return res.data;
  },
);

export const fetchDataSummaries = createAsyncThunk('dataSources/fetchApplicationConnData', async (filters = []) => {
  const params = mapFiltersToParams(filters);

  const res = await doGet(`dataSources/summary?${params.toString()}`);

  return res.data;
});

export const updateDataFiltersAndRefetchSummaries = createAsyncThunk(
  'dataSources/updateFiltersAndRefetchSummaries',
  async (filters, { dispatch }) => {
    const enrichedFilters = enrichDataSourceFilters(filters);
    dispatch(fetchDataSummaries(enrichedFilters));
    return enrichedFilters;
  },
);

export const fetchConnectorsFiltersOptions = createAsyncThunk('dataSources/fetchConnectorsFiltersOptions', async () => {
  const res = await doGet(`connectors/summary/filters`);

  return res.data;
});

export const fetchConnectors = createAsyncThunk(
  'dataSources/fetchConnectors',
  async ({ filters, orderBy, order, page }) => {
    const params = mapPropertiesToParams(filters, orderBy, order, page, {
      mapFilterFunction: mapConnectorFilter,
      mapValueFunction: mapConnectorFilterValue,
    });

    const res = await doGet(`connectors/summary?${params.toString()}`, { invalidateCache: true });
    return res.data;
  },
);

export const vetConnector = createAsyncThunk('dataSources/vetConnector', async ({ connectorId, vettedBy, email }) => {
  const res = await doPost(`connector/${connectorId}/vet`, {
    vettedBy,
    email,
  });
  return res.data;
});

export const updateConnectorsFiltersAndRefetchSummaries = createAsyncThunk(
  'dataSources/updateConnectorsFiltersAndRefetchSummaries',
  async ({ filters, orderBy, order, page }, { dispatch }) => {
    const enrichedFilters = enrichConnectorFilters(filters);
    const enrichedOrderBy = getConnectorBackendKeyForOrderBy(orderBy);

    dispatch(fetchConnectors({ filters: enrichedFilters, orderBy: enrichedOrderBy, order, page }));
    return enrichedFilters;
  },
);

const initialState = {
  connections: {
    error: false,
    loading: false,
    content: [],
    filtersOptions: [],
    filters: [],
    total: 0,
  },
  connectors: {
    error: false,
    loading: false,
    content: [],
    filtersOptions: [],
    filters: [],
    total: 0,
    vetting: {
      error: false,
      loading: false,
    },
  },
};

export const dataSourceSlice = createSlice({
  name: 'data',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchConnectorsFiltersOptions.fulfilled, (state, { payload }) => {
      state.connectors.filtersOptions = payload;
    });
    builder.addCase(vetConnector.pending, (state) => {
      state.connectors.vetting.loading = true;
      state.connectors.vetting.error = false;
      state.connectors.vetting.successful = false;
    });
    builder.addCase(vetConnector.fulfilled, (state, { payload }) => {
      state.connectors.vetting.loading = false;
      state.connectors.vetting.error = false;
      state.connectors.vetting.successful = true;
      const updatedConnectorIndex = state.connectors.content.findIndex(({ id }) => id === payload.id);
      state.connectors.content[updatedConnectorIndex] = {
        ...state.connectors.content[updatedConnectorIndex],
        vetted: payload.vetted,
        vettedByName: payload.vettedByName,
        dateOfVetting: payload.dateOfVetting,
      };
    });
    builder.addCase(vetConnector.rejected, (state) => {
      state.connectors.vetting.loading = false;
      state.connectors.vetting.error = true;
      state.connectors.vetting.successful = false;
    });
    builder.addCase(fetchConnectors.pending, (state) => {
      state.connectors.loading = true;
      state.connectors.error = false;
    });
    builder.addCase(fetchConnectors.fulfilled, (state, { payload }) => {
      state.connectors.loading = false;
      state.connectors.error = false;
      state.connectors.content = payload.data;
      state.connectors.total = payload.totalCount;
    });
    builder.addCase(fetchConnectors.rejected, (state) => {
      state.connectors.loading = false;
      state.connectors.error = true;
    });
    builder.addCase(updateConnectorsFiltersAndRefetchSummaries.fulfilled, (state, { payload }) => {
      state.connectors.filters = payload;
    });
    builder.addCase(fetchDataSummaries.pending, (state) => {
      state.connections.loading = true;
      state.connections.error = false;
    });
    builder.addCase(fetchDataSummaries.fulfilled, (state, { payload }) => {
      state.connections.loading = false;
      state.connections.error = false;
      state.connections.content = payload.data;
    });
    builder.addCase(fetchDataSummaries.rejected, (state) => {
      state.connections.loading = false;
      state.connections.error = true;
    });
    builder.addCase(updateDataFiltersAndRefetchSummaries.fulfilled, (state, { payload }) => {
      state.connections.filters = payload;
    });
  },
});

// this is for configureStore
export default dataSourceSlice.reducer;
