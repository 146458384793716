import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useTranslation } from 'react-i18next';

export default function BaseScoreTooltip({ vector }) {
  const { t } = useTranslation(['common', 'single-insight']);

  const vectorLink = `https://www.first.org/cvss/calculator/3.1#CVSS:3.1/${vector}`;
  return (
    <div className="cvss-tooltip">
      <div className="main-text">{t('single-insight:priority.mainText')}</div>
      <div className="cvss-vector">
        <div>
          <div>{t('single-insight:priority.vector')}</div>
          <div>{vector}</div>
        </div>
        <div className="vector-link column">
          {t('single-insight:priority.learnMore')}
          <a
            href={vectorLink}
            className="open-new"
            target="_blank"
            rel="noreferrer"
            aria-label="Open CVSS specification document"
          >
            <OpenInNewOutlinedIcon className="icon" />
          </a>
        </div>
      </div>
    </div>
  );
}
