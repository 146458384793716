import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useTranslation } from 'react-i18next';

const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);

export default function PriorityScoreTooltip({ scoreWeightProperties, vector }) {
  const { t } = useTranslation(['common', 'single-insight']);
  const sortedScoreWeightProperties = [...scoreWeightProperties].sort((a) => (a.direction === 'POSITIVE' ? -1 : 1));
  const scoreWeightElements = sortedScoreWeightProperties.map((scoreWeightProperty) => {
    const icon =
      scoreWeightProperty.direction === 'POSITIVE' ? (
        <ArrowUpward className="arrow up" />
      ) : (
        <ArrowDownward className="arrow down" />
      );
    return (
      <div className="row score-weight-prop">
        {icon}
        <div>{scoreWeightProperty.name}</div>
      </div>
    );
  });
  let vectorLink = `https://www.first.org/cvss/calculator/3.1#CVSS:3.1/${vector}`;
  if (isChrome) {
    vectorLink += ':~:text=Environmental%20Score';
  }

  return (
    <div className="cvss-tooltip">
      {scoreWeightElements}
      {vector && (
        <div className="cvss-vector">
          <div>
            <div>{t('single-insight:priority.vector')}</div>
            <div>{vector}</div>
          </div>
          <div className="vector-link column">
            {t('single-insight:priority.first')}
            <a
              href={vectorLink}
              className="open-new"
              target="_blank"
              rel="noreferrer"
              aria-label="Open CVSS specification document"
            >
              <OpenInNewOutlinedIcon className="icon" />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
