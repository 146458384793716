import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { fetchDeveloper } from '../../../redux/slicers/usersSlicer';
import {
  getDeveloper,
  getDeveloperActivity,
  getDeveloperApplications,
  getDeveloperConnections,
  getDeveloperInsights,
  getDeveloperLoading,
  getDeveloperRoles,
} from '../../../redux/selector/userSelector';
import Segment from '../../../components/segment/Segment';
import UserDetails from './userDetails';
import './single-user-page.scss';
import UserApplicationsTable from './UserApplicationsTable';
import UserActivityTable from './UserActivityTable';
import UserConnectionsTable from './UserConnectionsTable';
import UserInsightsTable from './UserInsightsTable';
import UserRolesTable from './UserRolesTable';
import Breadcrumbs from '../../../components/breadcrumbs/Breadcrumbs';

export default function SingleUserPage() {
  const { userId } = useParams();
  const { t } = useTranslation(['single-user']);
  const dispatch = useDispatch();

  const developerData = useSelector(getDeveloper(userId));
  const loading = useSelector(getDeveloperLoading(userId));
  const applications = useSelector(getDeveloperApplications(userId));
  const connections = useSelector(getDeveloperConnections(userId));
  const insights = useSelector(getDeveloperInsights(userId));
  const roles = useSelector(getDeveloperRoles(userId));
  const activity = useSelector(getDeveloperActivity(userId));

  useEffect(() => {
    (async function fetchUser() {
      dispatch(fetchDeveloper(userId));
    })();
  }, []);

  return (
    <div className="user-page single-user-page">
      <Breadcrumbs current={developerData?.name} />
      <Segment title={t('userDetails.title')} isLoading={loading}>
        <UserDetails user={developerData} />
      </Segment>
      <UserApplicationsTable isLoading={loading} applications={applications} />
      <UserActivityTable isLoading={loading} activities={activity} />
      <UserConnectionsTable isLoading={loading} connections={connections} />
      <UserInsightsTable isLoading={loading} insights={insights} />
      <UserRolesTable isLoading={loading} roles={roles} />
    </div>
  );
}
