import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import './information-icon.scss';

export default function InformationIcon({ tooltipText, TooltipComponent = Tooltip }) {
  if (!tooltipText) {
    return null;
  }

  return (
    <TooltipComponent title={tooltipText} arrow>
      <InfoOutlinedIcon fontSize="small" className="information-icon" />
    </TooltipComponent>
  );
}
