/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGet, doPost } from '../../services/HttpService';

export const fetchTags = createAsyncThunk('tags', async () => {
  const res = await doGet('tags');

  return {
    data: res.data,
  };
});

export const createTag = createAsyncThunk('tags/create', async ({ name, sensitive }, { rejectWithValue }) => {
  try {
    await doPost('tags', {
      name,
      sensitive,
    });
    return { name, sensitive };
  } catch (e) {
    return rejectWithValue(e);
  }
});

const initialState = {
  tags: {
    loading: false,
    error: false,
    content: [],
  },
};

export const dataTagSlice = createSlice({
  name: 'dataTags',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchTags.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(fetchTags.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = false;
      state.content = payload.data;
    });
    builder.addCase(fetchTags.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(createTag.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(createTag.fulfilled, (state, { payload }) => {
      const { name, sensitive } = payload;
      state.loading = false;
      state.error = false;
      const updatedTags = [...state.content, { name, sensitive }];
      state.content = updatedTags;
    });
    builder.addCase(createTag.rejected, (state) => {
      state.tags.loading = false;
      state.tags.error = true;
    });
  },
});

// this is for configureStore
export default dataTagSlice.reducer;
