import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import Tags from '../../../components/tags/Tags';
import InternalLink from '../../../components/internal-link/InternalLink';
import ManageTagsDropdown, { TagResourceType } from '../../data-source/manage-tags-dropdown/ManageTagsDropdown';

export default function ConnectionDataTable({ connectionId, isLoading, data = [] }) {
  const { t } = useTranslation(['common', 'single-connection']);
  const columns = useMemo(
    () => [
      {
        Header: t('general.name', { ns: 'common' }),
        accessor: 'name',
        width: '30%',
      },
      {
        Header: t('connectionData.dataset', { ns: 'single-connection' }),
        accessor: 'metadata.dataSet',
        width: '25%',
        Cell: ({ value }) => {
          if (!value) {
            return t('general.na');
          }
          return Object.values(value).join(', ');
        },
      },
      {
        Header: t('tags.tags', { ns: 'common' }),
        accessor: 'tags',
        Cell: ({ value }) =>
          value?.length ? <Tags tags={value} tooltipAnchor={`connection-tags-${data.id}`} /> : t('tags.untagged'),
      },
      {
        Header: t('applications.apps', { ns: 'common' }),
        accessor: 'applicationCount',
        Cell: ({ cell, value }) => {
          const dataId = cell.row.original.id;
          return <InternalLink url={`/applications?dataSourceId:is=${dataId}`} text={value} />;
        },
      },
    ],
    [],
  );

  const sortBy = [{ id: 'name', desc: false }];

  return (
    <div className="card-view-table-container connection-data-table">
      <CollapseCard
        title={t('connectionData.title', { ns: 'single-connection' })}
        isLoading={isLoading}
        totalElementsCount={data.length}
        initialOpen
      >
        <Table
          columns={columns}
          data={data}
          options={{
            showFilter: false,
            showPagination: true,
            showSort: true,
            showRowSelection: true,
            usePaginationSearchParam: false,
          }}
          type="data"
          sortBy={sortBy}
          initialFilters={[]}
          customFilters={[]}
          isRowDisabled={() => false}
          getElementId={(dataSource) => dataSource.id}
          isLoading={isLoading}
          classNames="card-view-table"
          backendPagination={false}
          totalCount={data.length}
          pageSize={5}
          contextualActions={[
            (selectedData) => (
              <ManageTagsDropdown
                key="tags"
                data={data}
                selectedResources={selectedData}
                tagResourceType={TagResourceType.DataSource}
                connectionId={connectionId}
              />
            ),
          ]}
          emptyState={
            <TableEmptyContent
              img={emptyApplicationsImg}
              mainText={t('connectionData.noData', { ns: 'single-connection' })}
            />
          }
        />
      </CollapseCard>
    </div>
  );
}
