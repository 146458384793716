import { StrictMode, Suspense } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import { configureStore } from '@reduxjs/toolkit';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import rootReducer from './redux';
import reportWebVitals from './reportWebVitals';
import { Auth0ProviderWithNavigate } from './auth/auth0-provider-with-navigate';
import './FontAweomeLoader';
import './i18n';

import './App.scss';
import 'react-tooltip/dist/react-tooltip.css';

const deviceId = localStorage.getItem('deviceId');
if (!deviceId) {
  localStorage.setItem(
    'deviceId',
    Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StrictMode>
    <Provider
      store={configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
          getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
          }),
      })}
    >
      <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <Suspense>
            <App />
          </Suspense>
        </Auth0ProviderWithNavigate>
      </BrowserRouter>
    </Provider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
