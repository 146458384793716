import { createSelector } from '@reduxjs/toolkit';
import { EnvironmentType } from '../../pages/settings/platforms/power-platform/EnvironmentType';

export const getEnvironments = (state) => state.environments.environments;
export const hasNoEnvironments = (state) => state.environments.success && !state.environments.environments?.length;
export const isEnvironmentsLoading = (state) => state.environments.loading;

export const getDefaultEnvironmentsByAccountId = (accountId) =>
  createSelector(getEnvironments, (envs) => {
    if (!envs?.length) {
      return [];
    }

    return envs.filter((env) => env.accountId === accountId).filter((env) => env.type === EnvironmentType.Default);
  });
