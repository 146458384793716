/* eslint-disable no-param-reassign */

import { partition, sum } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGet, doPost } from '../../services/HttpService';
import {
  enrichAggregationsFilters,
  enrichInsightFilters,
  getAggregationsBackendKeyForOrderBy,
  getInsightBackendKeyForOrderBy,
  mapFiltersToParams,
  mapInsightFilter,
  mapPropertiesToParams,
} from '../../services/MapperUtils';
import { IS } from '../../components/filter/filterConsts';
import { INTERNAL_SERVER_ERROR } from '../../consts/erros';
import { initialInsightsFilters } from '../../consts/defaultFilters';

async function sendSummaryRequest(type, filters, orderBy, order, page, invalidateCache) {
  const params = mapPropertiesToParams(filters, orderBy, order, page, { mapFilterFunction: mapInsightFilter });
  const path = `${type}/summary?${params.toString()}`;
  const response = await doGet(path, { invalidateCache });

  return response.data;
}

export const fetchInsightFilterOptions = createAsyncThunk('insights/fetchFilterOptions', async () => {
  const response = await doGet(`insights/summary/filters`);
  return response.data;
});

export const fetchAggregationFilterOptions = createAsyncThunk('aggregations/fetchFilterOptions', async () => {
  const response = await doGet(`aggregations/summary/filters`);
  return response.data;
});

export const fetchAggregations = createAsyncThunk(
  'insights/fetchAggregations',
  async ({ filters, orderBy, order, page }) => {
    const params = mapPropertiesToParams(filters, orderBy, order, page);
    const path = `aggregations/summary?${params.toString()}`;
    const response = await doGet(path);
    return response.data;
  },
);

export const fetchTotalAggregationsNoFilters = createAsyncThunk('aggregations/fetchTotalNoFilters', async () =>
  sendSummaryRequest('aggregations', []),
);

export const fetchSingleAggregation = createAsyncThunk('insights/fetchSingleAggregation', async (aggId) => {
  const response = await doGet(`insight/aggregations/${aggId}`);

  const filters = [
    {
      column: 'rootCauseId',
      type: IS,
      value: aggId,
    },
  ];

  response.data.summaryInsights = await sendSummaryRequest('insights', filters);

  return response.data;
});

export const fetchAggregationFunnel = createAsyncThunk('insight/aggregations/funnel', async () => {
  const response = await doGet('insight/aggregations/funnel');
  return response.data;
});

export const markInsightAsFalsePositive = createAsyncThunk(
  'insights/markAsFalsePositive',
  async ({ insightId, email, name }) => {
    await doPost(`insight/${insightId}/falsePositive`, {
      auditUserName: name,
      auditUserEmail: email,
    });
    return insightId;
  },
);

export const fetchInsightSummaries = createAsyncThunk(
  'insights/fetchSummaries',
  async ({ filters = [], orderBy, order, page, invalidateCache }) => {
    const enrichedFilters = enrichInsightFilters(filters);
    return sendSummaryRequest('insights', enrichedFilters, orderBy, order, page, invalidateCache);
  },
);

export const fetchTotalActiveInsightsNoFilters = createAsyncThunk('insights/fetchTotalNoFilters', async () =>
  sendSummaryRequest('insights', initialInsightsFilters),
);

export const fetchInsightConfidentialData = createAsyncThunk('insights/fetchConfidentialData', async (insightId) => {
  const response = await doGet(`insight/${insightId}/confidential`);
  return response.data;
});

export const fetchSingleInsight = createAsyncThunk('insights/fetchSingleInsight', async (insightId) => {
  const response = await doGet(`insight/${insightId}`);

  return response.data;
});

export const fetchApplicationInsights = createAsyncThunk('insights/fetchApplicationInsights', async (application) => {
  const response = await doGet(`application/${application.id}/insights`);

  return response.data.map((insight) => {
    insight.resource = { id: application.id, type: 'application' };
    insight.platformName = application.account.platformName;
    return insight;
  });
});

export const updateAggregationFiltersAndRefetchSummaries = createAsyncThunk(
  'insights/updateAggregationFiltersAndRefetchSummaries',
  async ({ filters, orderBy, order, page }, { dispatch }) => {
    const enrichedFilters = enrichAggregationsFilters(filters);
    const enrichedOrderBy = getAggregationsBackendKeyForOrderBy(orderBy);

    dispatch(fetchAggregations({ filters: enrichedFilters, orderBy: enrichedOrderBy, order, page }));
    return enrichedFilters;
  },
);
export const updateInsightFiltersAndRefetchSummaries = createAsyncThunk(
  'insights/updateFiltersAndRefetchSummaries',
  async ({ filters, orderBy, order, page, invalidateCache }, { dispatch, getState }) => {
    const enrichedFilters = enrichInsightFilters(filters);
    const enrichedOrderBy = getInsightBackendKeyForOrderBy(orderBy);
    const shouldInvalidateCache = invalidateCache || getState().insights.shouldInvalidateCache;

    dispatch(
      fetchInsightSummaries({
        filters: enrichedFilters,
        orderBy: enrichedOrderBy,
        order,
        page,
        invalidateCache: shouldInvalidateCache,
      }),
    );
    // change the state back to false
    // eslint-disable-next-line no-use-before-define
    dispatch(changeInvalidateCacheState(false));
    return enrichedFilters;
  },
);

export const acknowledgeInsights = createAsyncThunk(
  'insights/acknowledgeInsights',
  async ({ userEmail, userName, insightIds, message }) => {
    const promises = insightIds.map((insightId) =>
      doPost(`insight/${insightId}/ack?email=${userEmail}&name=${userName}`, message ? { message } : {}),
    );
    await Promise.all(promises);
    // no optimistic update, summaries are just refetched

    return insightIds;
  },
);

export const remediateInsights = createAsyncThunk(
  'insights/remediateInsight',
  async ({ accessToken, insightIds, auditUserName, auditUserEmail, extraData }, { dispatch }) => {
    if (!insightIds.length) {
      return;
    }

    const promises = insightIds.map(async (insightId) => {
      try {
        const res = await doPost(`insight/${insightId}/remediate`, {
          token: accessToken,
          auditUserName,
          auditUserEmail,
          extraData,
        });
        // eslint-disable-next-line no-use-before-define
        dispatch(appendCompletedRemediation({ insightId, message: res.data }));
        dispatch(fetchSingleInsight(insightId));
        return res.data;
      } catch (e) {
        // eslint-disable-next-line no-use-before-define
        dispatch(appendFailuresRemediation({ insightId, error: e.message }));
        const errorWithStatusCode = {
          message: e.status === 500 ? INTERNAL_SERVER_ERROR : e.message,
        };
        throw errorWithStatusCode;
      }
    });

    const results = await Promise.allSettled(promises);
    const [saved, failures] = partition(results, ({ status }) => status === 'fulfilled');

    if (!saved.length) {
      let reason = null;
      if (typeof failures[0].reason?.message === 'string') {
        reason = failures[0].reason.message;
      }
      throw new Error(`Failed to remediate insights. Reason: ${reason || 'Unexpected error occurred'}`);
    }
  },
);

export const fetchInsightNotifications = createAsyncThunk(
  'insights/fetchInsightsNotifications',
  async ({ insightId }) => {
    const response = await doGet(`notifications/${insightId}`);
    return response.data;
  },
);

export const sendInsightNotifications = createAsyncThunk(
  'insights/sendInsightNotifications',
  async ({ insightId, targets, notificationChannelId, message, senderName }) => {
    const response = await doPost(`notifications/${insightId}`, {
      targets,
      notificationChannelId,
      message: message?.length ? message : null,
      senderName,
    });
    return response.data;
  },
);

export const fetchGroupedByType = createAsyncThunk('insights/fetchGroupedByType', async (filters) => {
  const enrichedFilters = enrichInsightFilters(filters);
  const params = mapFiltersToParams(enrichedFilters, { mapFilterFunction: mapInsightFilter });
  const response = await doGet(`insights/summary/grouped/type?${params.toString()}`);
  return response.data;
});

export const fetchGroupedBySeverity = createAsyncThunk('insights/fetchGroupedBySeverity', async (filters) => {
  const enrichedFilters = enrichInsightFilters(filters);
  const params = mapFiltersToParams(enrichedFilters, { mapFilterFunction: mapInsightFilter });
  const response = await doGet(`insights/summary/grouped/severity?${params.toString()}`);
  return response.data;
});

export const createInsight = createAsyncThunk('insights/createInsight', async (insight) => {
  const response = await doPost('insight', insight);
  return response.data;
});

const initialState = {
  summaries: {
    all: {
      loading: false,
      error: false,
      weak: 0,
      strong: 0,
      total: {
        count: 0,
        content: [],
      },
    },
    total: {
      count: 0,
      content: [],
    },
    content: [],
    filters: [],
    loading: false,
    error: false,
    extended: {
      loading: false,
      error: false,
      content: {},
    },
    filtersOptions: {},
  },
  acknowledge: {
    loading: false,
    error: false,
    message: null,
  },
  remediate: {
    loading: false,
    error: false,
    failures: [],
    successes: [],
  },
  falsePositive: {
    loading: false,
    error: false,
    success: false,
  },
  confidential: {
    loading: false,
    error: false,
    message: null,
  },
  createInsight: {
    loading: false,
    errorMessage: null,
    success: false,
  },
  aggregations: {
    counters: {
      loading: false,
      error: false,
      content: {},
    },
    all: {
      loading: false,
      error: false,
      weak: 0,
      strong: 0,
      total: {
        count: 0,
        content: [],
      },
    },
    filtered: {
      content: [],
      loading: false,
      error: false,
      total: {
        count: 0,
        content: [],
        weak: 0,
        strong: 0,
      },
    },
    filters: [],
    extended: {
      loading: false,
      error: false,
      content: {},
    },
    filtersOptions: {},
  },
  appInsights: {
    loading: false,
    error: false,
  },
  notifications: {
    loading: false,
    error: false,
  },
  insightsByType: {
    loading: false,
    error: false,
    content: [],
  },
  insightsBySeverity: {
    loading: false,
    error: false,
    content: [],
  },
  shouldInvalidateCache: false,
};

export const insightsSlice = createSlice({
  name: 'insights',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(createInsight.pending, (state) => {
      state.createInsight.loading = true;
      state.createInsight.errorMessage = null;
      state.createInsight.success = false;
    });
    builder.addCase(createInsight.rejected, (state, { error }) => {
      state.createInsight.loading = false;
      state.createInsight.errorMessage = error.message;
      state.createInsight.success = false;
    });
    builder.addCase(createInsight.fulfilled, (state) => {
      state.createInsight.loading = false;
      state.createInsight.errorMessage = null;
      state.createInsight.success = true;
    });
    builder.addCase(markInsightAsFalsePositive.pending, (state) => {
      state.falsePositive.loading = true;
      state.falsePositive.error = false;
      state.falsePositive.success = false;
    });
    builder.addCase(markInsightAsFalsePositive.rejected, (state) => {
      state.falsePositive.loading = false;
      state.falsePositive.error = true;
      state.falsePositive.success = false;
    });
    builder.addCase(markInsightAsFalsePositive.fulfilled, (state, { payload }) => {
      state.falsePositive.loading = false;
      state.falsePositive.error = false;
      state.falsePositive.success = true;
      state.summaries.content = state.summaries.content.filter((insight) => insight.id !== payload);
    });
    builder.addCase(fetchGroupedByType.pending, (state) => {
      state.insightsByType.loading = true;
      state.insightsByType.error = false;
    });
    builder.addCase(fetchGroupedByType.fulfilled, (state, { payload }) => {
      state.insightsByType.loading = false;
      state.insightsByType.error = false;
      state.insightsByType.content = payload;
    });
    builder.addCase(fetchGroupedByType.rejected, (state) => {
      state.insightsByType.loading = false;
      state.insightsByType.error = true;
    });
    builder.addCase(fetchGroupedBySeverity.pending, (state) => {
      state.insightsBySeverity.loading = true;
      state.insightsBySeverity.error = false;
    });
    builder.addCase(fetchGroupedBySeverity.fulfilled, (state, { payload }) => {
      state.insightsBySeverity.loading = false;
      state.insightsBySeverity.error = false;
      state.insightsBySeverity.content = payload;
    });
    builder.addCase(fetchGroupedBySeverity.rejected, (state) => {
      state.insightsBySeverity.loading = false;
      state.insightsBySeverity.error = true;
    });
    builder.addCase(fetchInsightNotifications.fulfilled, (state, { payload }) => {
      state.notifications.loading = false;
      state.notifications.error = false;

      state.notifications[payload.insightId] = {
        notifications: [...payload.notifications].sort((a, b) => a.sentTime - b.sentTime),
        nextNotificationTime: payload.nextNotificationTime,
      };
    });
    builder.addCase(fetchInsightNotifications.pending, (state) => {
      state.notifications.loading = true;
      state.notifications.error = false;
    });
    builder.addCase(fetchInsightNotifications.rejected, (state) => {
      state.notifications.loading = false;
      state.notifications.error = true;
    });
    builder.addCase(sendInsightNotifications.pending, (state) => {
      state.notifications.loading = true;
      state.notifications.error = false;
    });
    builder.addCase(sendInsightNotifications.rejected, (state) => {
      state.notifications.loading = false;
      state.notifications.error = true;
    });
    builder.addCase(sendInsightNotifications.fulfilled, (state, { payload }) => {
      state.notifications.loading = false;
      state.notifications.error = false;
      state.notifications[payload.insightId] = {
        ...state.notifications[payload.insightId],
        notifications: payload.notifications.sort((a, b) => a.sentTime - b.sentTime),
      };
    });
    builder.addCase(fetchInsightConfidentialData.fulfilled, (state, { payload }) => {
      state.confidential.loading = false;
      state.confidential.error = false;
      state.confidential.message = null;
      const { insightId } = payload[0];
      state.summaries.extended.content[insightId].insightData = payload;
    });

    builder.addCase(fetchInsightConfidentialData.pending, (state) => {
      state.confidential.loading = true;
      state.confidential.error = false;
      state.confidential.message = null;
    });
    builder.addCase(fetchInsightConfidentialData.rejected, (state, { error }) => {
      state.confidential.loading = false;
      state.confidential.error = true;
      state.confidential.message = error.message;
    });
    builder.addCase(fetchAggregationFilterOptions.fulfilled, (state, { payload }) => {
      state.aggregations.filtersOptions = payload;
    });
    builder.addCase(fetchAggregations.fulfilled, (state, { payload }) => {
      state.aggregations.filtered.content = payload.data;
      state.aggregations.filtered.total.count = payload.totalCount;

      state.aggregations.filtered.loading = false;
      state.aggregations.filtered.error = false;
    });
    builder.addCase(fetchAggregations.pending, (state) => {
      state.aggregations.filtered.loading = true;
      state.aggregations.filtered.error = false;
    });
    builder.addCase(fetchAggregations.rejected, (state) => {
      state.aggregations.filtered.loading = false;
      state.aggregations.filtered.error = true;
    });
    builder.addCase(fetchTotalAggregationsNoFilters.fulfilled, (state, { payload }) => {
      state.aggregations.all.loading = false;
      state.aggregations.all.error = false;
      state.aggregations.all.total.content = payload.data;
      state.aggregations.all.total.count = payload.totalCount;
      state.aggregations.all.strong = sum(
        payload.data.filter((agg) => agg.type.toLowerCase() === 'strong').map((agg) => agg.activeInsights),
      );
      state.aggregations.all.weak = sum(
        payload.data.filter((agg) => agg.type.toLowerCase() === 'weak').map((agg) => agg.activeInsights),
      );
    });
    builder.addCase(fetchAggregationFunnel.pending, (state) => {
      state.aggregations.counters.loading = true;
      state.aggregations.counters.error = false;
    });
    builder.addCase(fetchAggregationFunnel.rejected, (state) => {
      state.aggregations.counters.loading = false;
      state.aggregations.counters.error = true;
    });
    builder.addCase(fetchAggregationFunnel.fulfilled, (state, { payload }) => {
      state.aggregations.counters.loading = false;
      state.aggregations.counters.error = false;
      state.aggregations.counters.content = payload;
    });
    builder.addCase(fetchTotalAggregationsNoFilters.pending, (state) => {
      state.aggregations.all.loading = true;
      state.aggregations.all.error = false;
    });
    builder.addCase(fetchTotalAggregationsNoFilters.rejected, (state) => {
      state.aggregations.all.loading = false;
      state.aggregations.all.error = true;
    });
    builder.addCase(fetchTotalActiveInsightsNoFilters.fulfilled, (state, { payload }) => {
      state.summaries.all.loading = false;
      state.summaries.all.error = false;
      state.summaries.all.total.content = payload.data;
      state.summaries.all.total.count = payload.totalCount;
    });
    builder.addCase(fetchTotalActiveInsightsNoFilters.pending, (state) => {
      state.summaries.all.loading = true;
      state.summaries.all.error = false;
    });
    builder.addCase(fetchTotalActiveInsightsNoFilters.rejected, (state) => {
      state.summaries.all.loading = false;
      state.summaries.all.error = true;
    });
    builder.addCase(fetchSingleAggregation.fulfilled, (state, { payload }) => {
      state.aggregations.extended.loading = false;
      state.aggregations.extended.error = false;
      state.aggregations.extended.content[payload.id] = payload;
    });
    builder.addCase(fetchSingleAggregation.pending, (state) => {
      state.aggregations.extended.loading = true;
      state.aggregations.extended.error = false;
    });
    builder.addCase(fetchSingleAggregation.rejected, (state) => {
      state.aggregations.extended.loading = false;
      state.aggregations.extended.error = true;
    });
    builder.addCase(fetchInsightFilterOptions.fulfilled, (state, { payload }) => {
      state.summaries.filtersOptions = payload;
    });
    builder.addCase(fetchInsightSummaries.pending, (state) => {
      state.summaries.loading = true;
      state.summaries.error = false;
    });
    builder.addCase(fetchInsightSummaries.rejected, (state) => {
      state.summaries.loading = false;
      state.summaries.error = true;
    });
    builder.addCase(fetchInsightSummaries.fulfilled, (state, { payload }) => {
      state.summaries.loading = false;
      state.summaries.error = false;
      state.summaries.content = payload.data;
      state.summaries.total.count = payload.totalCount;
    });
    builder.addCase(acknowledgeInsights.pending, (state) => {
      state.acknowledge.loading = true;
      state.acknowledge.error = false;
      state.acknowledge.message = null;
    });
    builder.addCase(acknowledgeInsights.rejected, (state) => {
      state.acknowledge.loading = false;
      state.acknowledge.error = true;
      state.acknowledge.message = 'Failed to acknowledge insights';
    });
    builder.addCase(acknowledgeInsights.fulfilled, (state, { payload }) => {
      state.acknowledge.loading = false;
      state.acknowledge.error = false;
      state.acknowledge.message = 'Insights acknowledged successfully';
      state.content = state.summaries.content.filter((insight) => !payload.includes(insight.id));
      delete state.summaries.extended.content[payload.id];
      // removes acknowledged insights from content to be fetched from backend again
    });
    builder.addCase(remediateInsights.pending, (state) => {
      state.remediate.loading = true;
      state.remediate.error = null;
      state.remediate.failures = [];
      state.remediate.successes = [];
    });
    builder.addCase(remediateInsights.rejected, (state) => {
      state.remediate.loading = false;
      state.remediate.error = true;
    });
    builder.addCase(remediateInsights.fulfilled, (state) => {
      state.remediate.loading = false;
      state.remediate.error = false;
    });
    builder.addCase(fetchSingleInsight.pending, (state) => {
      state.summaries.extended.loading = true;
      state.summaries.extended.error = false;
    });
    builder.addCase(fetchSingleInsight.fulfilled, (state, { payload }) => {
      state.summaries.extended.loading = false;
      state.summaries.extended.error = false;
      state.summaries.extended.content[payload.id] = payload;
    });
    builder.addCase(fetchSingleInsight.rejected, (state) => {
      state.summaries.extended.loading = false;
      state.summaries.extended.error = true;
    });
    builder.addCase(updateAggregationFiltersAndRefetchSummaries.fulfilled, (state, { payload }) => {
      state.aggregations.filters = payload;
    });
    builder.addCase(updateInsightFiltersAndRefetchSummaries.fulfilled, (state, { payload }) => {
      state.summaries.filters = payload;
    });
    builder.addCase(fetchApplicationInsights.pending, (state) => {
      state.appInsights.loading = true;
      state.appInsights.error = false;
    });
    builder.addCase(fetchApplicationInsights.fulfilled, (state, { payload }) => {
      state.appInsights.loading = false;
      state.appInsights.error = false;
      payload.forEach((insight) => {
        state.summaries.extended.content[insight.id] = insight;
      });
    });
    builder.addCase(fetchApplicationInsights.rejected, (state) => {
      state.appInsights.loading = false;
      state.appInsights.error = true;
    });
  },
  reducers: {
    clearFilters: (state) => {
      state.summaries.filters = [];
      state.aggregations.filters = [];
    },
    clearRemediation: (state) => {
      state.remediate.loading = false;
      state.remediate.error = null;
      state.remediate.failures = [];
      state.remediate.successes = [];
    },
    removeAggregation: (state, { payload }) => {
      state.aggregations.filtered.content = state.aggregations.filtered.content.filter((agg) => agg.id !== payload);
      state.aggregations.filtered.total.count -= 1;
    },
    appendFailuresRemediation: (state, { payload }) => {
      state.remediate.failures.push(payload);
    },
    appendCompletedRemediation: (state, { payload }) => {
      state.remediate.successes.push(payload);
    },
    changeInvalidateCacheState: (state, { payload }) => {
      state.shouldInvalidateCache = payload;
    },
  },
});

export const {
  appendCompletedRemediation,
  appendFailuresRemediation,
  removeAggregation,
  clearRemediation,
  changeInvalidateCacheState,
} = insightsSlice.actions;
export default insightsSlice.reducer;
