import { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import './basic-tabs.scss';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default function BasicTabs({ tabsData = [], icon, initialTab = 0, showDivider = true }) {
  const [value, setValue] = useState(initialTab);

  const handleChange = (event, newValue) => {
    const indicator = document.getElementsByClassName('MuiTabs-indicator')[0];
    indicator.className = `${indicator.className.split(' ').slice(0, 2).join(' ')} ${
      tabsData[newValue].className
    }-background`;

    setValue(newValue);
  };

  useEffect(() => {
    handleChange(null, initialTab);
  }, [initialTab]);

  return (
    <div className="simple-tabs">
      <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="false">
        {tabsData.map((tab) => (
          <Tab label={tab.label} icon={icon} className={tab.className} onClick={tab.onClick} key={tab.label} />
        ))}
      </Tabs>
      {showDivider && <Divider />}
      {tabsData.map((tab, index) => (
        <TabPanel value={value} index={index} key={tab.label}>
          {tab.content}
        </TabPanel>
      ))}
    </div>
  );
}
