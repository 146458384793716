import { useTranslation } from 'react-i18next';
import './simple-error.scss';

export default function SimpleError({ error, dataTestId = 'simple-error' }) {
  const { t } = useTranslation(['common']);
  return (
    error && (
      <div className="simple-error" data-testid={dataTestId}>
        <div>{t('general.error')}:</div>
        <div>{error}</div>
      </div>
    )
  );
}
