import sharepoinrt from './sharepoint.svg';
import microsoft365Outlook from './microsoft_365_outlook.svg';
import microsoftSqlServer from './microsoft_sql_server.svg';
import office365Users from './office_365.svg';
import msnWeather from './msn_weather.svg';
import postgreSql from './postgre_sql.svg';
import defaultConnector from './default_connector.svg';
import forms from './forms.svg';
import logicFlows from './logic_flows.svg';
import outlook from './outlook.svg';
import powerapps from './powerapps.svg';
import slack from './slack.svg';
import sqlServer from './sql_server.svg';
import teams from './teams.svg';
import approvals from './approvals.svg';
import azureKeyVault from './azure_key_vault.svg';
import azureStorage from './azure_storage.svg';
import bingMaps from './bing_maps.svg';
import dataverse from './dataverse.svg';
import entraId from './entra_id.svg';
import microsoftContentConversion from './microsoft_content_conversion.svg';
import microsoftNotifications from './microsoft_notifications.svg';
import microsoftProjectOnline from './microsoft_office.svg';
import microsoftRss from './microsoft_rss.svg';
import oneCloud from './one_cloud.svg';
import powerBi from './power_bi.svg';
import googleDrive from './google_drive.svg';
import gmail from './gmail.svg';
import excel from './excel.svg';
import asana from './asana.svg';
import googleCalendar from './google_calendar.svg';
import gsuite from './gsuite.svg';
import linkedin from './linkedin.svg';
import mastercard from './mastercard.svg';
import oneNote from './one_note.svg';
import rabbitMQ from './rabbit_mq.svg';
import salesforce from './salesforce.svg';
import skype from './skype.svg';
import powerAutomate from './power_automate.svg';

import ssh from './ssh.svg';
import ftp from './ftp.svg';
import http from './http.svg';
import database from './database.svg';
import email from './email.svg';

export function getConnectorIcon(connectorName) {
  if (!connectorName) return defaultConnector;

  switch (connectorName.toString().toLowerCase()) {
    case 'ssh':
      return ssh;
    case 'ftp':
      return ftp;
    case 'http':
      return http;
    case 'database':
      return database;
    case 'mail':
      return email;
    case 'asana':
      return asana;
    case 'google calendar':
      return googleCalendar;
    case 'gsuite':
      return gsuite;
    case 'linkedin':
      return linkedin;
    case 'mastercard':
      return mastercard;
    case 'one note':
    case 'one note (business)':
      return oneNote;
    case 'rabbitmq':
    case 'rabbitmq - publisher':
    case 'rabbitmq - consumer':
      return rabbitMQ;
    case 'salesforce':
      return salesforce;
    case 'power automate':
    case 'desktop flows':
    case 'power query dataflows':
      return powerAutomate;
    case 'skype':
    case 'skype for business online':
      return skype;
    case 'share point online':
    case 'share point':
    case 'sharepoint':
    case 'sharepointonline':
      return sharepoinrt;
    case 'office365':
    case 'office365_1':
    case 'office 365':
      return microsoft365Outlook;
    case 'microsoft sql server':
      return microsoftSqlServer;
    case 'office 365 users':
    case 'office 365 groups':
      return office365Users;
    case 'msn weather':
      return msnWeather;
    case 'postgresql':
      return postgreSql;
    case 'microsoftforms':
    case 'microsoft forms':
      return forms;
    case 'logic flows':
      return logicFlows;
    case 'office 365 outlook':
    case 'outlook tasks':
    case 'outlook.com':
      return outlook;
    case 'power platform for admins':
    case 'power apps for admins':
    case 'power apps for makers':
    case 'power apps notification':
    case 'power apps notification v2':
      return powerapps;
    case 'slack':
    case 'slack_webhook':
    case 'slack_private':
      return slack;
    case 'sql':
    case 'sql server':
      return sqlServer;
    case 'microsoft teams':
    case 'teams':
      return teams;
    case 'approvals':
      return approvals;
    case 'azure key vault':
      return azureKeyVault;
    case 'azure blob storage':
      return azureStorage;
    case 'bing maps':
      return bingMaps;
    case 'commondataserviceforapps':
    case 'microsoft dataverse':
      return dataverse;
    case 'microsoft entra id':
      return entraId;
    case 'notifications':
      return microsoftNotifications;
    case 'onedrive for business':
    case 'onedrive':
      return oneCloud;
    case 'power bi':
      return powerBi;
    case 'content conversion':
      return microsoftContentConversion;
    case 'rss':
      return microsoftRss;
    case 'project online':
      return microsoftProjectOnline;
    case 'google drive':
      return googleDrive;
    case 'gmail':
    case 'email':
    case 'direct_email':
      return gmail;
    case 'excel':
    case 'excel online (business)':
    case 'excel online (onedrive)':
      return excel;
    default:
      return defaultConnector;
  }
}
