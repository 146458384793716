import { useSelector } from 'react-redux';
import { groupBy } from 'lodash';
import LastScanStatus from './LastScanStatus';
import DataInProcessing from './DataInProcessing';
import { getHomepageScanData, isHomePageTenantDataLoading } from '../../../redux/selector/homepageSelector';

export default function ScanDataContainer({ labels, timeRangeLabel }) {
  const tenantDataLoading = useSelector(isHomePageTenantDataLoading);
  const scanData = useSelector(getHomepageScanData);

  const byDate = groupBy(scanData, (scan) => new Date(scan.createdAt).toDateString());
  const sortedByDate = Object.entries(byDate)
    .sort((a, b) => new Date(b[0]) - new Date(a[0]))
    .map((i) => i[1]);

  return (
    <>
      <LastScanStatus scanData={sortedByDate} loading={tenantDataLoading} />
      <DataInProcessing
        labels={labels}
        scanData={sortedByDate}
        loading={tenantDataLoading}
        datesLabel={timeRangeLabel}
      />
    </>
  );
}
