import { startCase } from 'lodash';

export function extractDataFromAggregationIdentity(identity) {
  if (!identity) {
    return [];
  }
  const splitData = identity.split(':');
  splitData.shift(); // remove feature name
  const identityWithoutFeatureName = splitData.join(':');
  const dataPairs = identityWithoutFeatureName.split('#');

  const pairs = dataPairs.map((pair) => {
    const split = pair.split(':');
    const key = startCase(split[0]);
    const value = split.slice(1).join(':');

    return [key, value];
  });
  return pairs;
}
