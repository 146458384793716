import * as moment from 'moment/moment';
import Card from '../../../components/card/Card';
import LineChart from '../../../components/chart/line-chart/LineChart';
import { getColorWithOpacity } from '../../../consts/colors';

function numberToBytes(num) {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
  let numCopy = num;
  for (let i = 0; i < units.length; i++) {
    const unit = units[i];
    if (numCopy < 1024) {
      return `${numCopy.toFixed(1)} ${unit}`;
    }
    numCopy /= 1024;
  }
  return `${numCopy.toFixed(1)} ${units[units.length - 1]}`;
}

export default function CollectedDataWidget({ labels, text, img, values = [], color, counter, loading, datesLabel }) {
  while (values.length < labels.length) {
    values.unshift(0);
  }

  const data = {
    labels: labels.map((label) => moment(label).format('DD/MM')),
    datasets: [
      {
        label: 'Bytes',
        data: values,
        borderColor: color,
        backgroundColor: (context) => {
          const { ctx } = context.chart;
          const gradient = ctx.createLinearGradient(0, 0, 0, 200);
          gradient.addColorStop(0, getColorWithOpacity(color, 0.5));
          gradient.addColorStop(1, getColorWithOpacity(color, 0));
          return gradient;
        },
        fill: true,
        width: '2px',
        tension: 0.2,
      },
    ],
  };

  const tooltip = {
    callbacks: {
      label: (tooltipItem) => numberToBytes(tooltipItem.raw),
    },
  };

  return (
    <Card className="short" loading={loading}>
      <div className="collected-data-widget">
        <div className="collected-data-summary">
          <div className="collected-data-count">
            <img src={img} alt="data-consumed" />
            {numberToBytes(counter)}
          </div>
          <div className="collected-data-text">
            {text}
            <div>{datesLabel}</div>
          </div>
        </div>
        <LineChart id="collectedData" showLegend={false} showScales={false} data={data} tooltip={tooltip} />
      </div>
    </Card>
  );
}
