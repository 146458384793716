/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGet } from '../../services/HttpService';
import { enrichApplicationFilters, mapFiltersToParams, mapPropertiesToParams } from '../../services/MapperUtils';

export const fetchDynamicInsightSummaries = createAsyncThunk(
  'homepage/fetchDynamicInsightSummaries',
  async (filters = []) => {
    const params = mapFiltersToParams(filters);
    const path = `insights/summary?${params}`;
    const response = await doGet(path);

    return response.data;
  },
);

export const fetchDynamicInsightSummariesForMttr = createAsyncThunk(
  'homepage/fetchDynamicInsightSummariesForMttr',
  async (filters = []) => {
    const params = mapFiltersToParams(filters);
    const path = `insights/summary?${params}`;
    const response = await doGet(path);

    return response.data;
  },
);

export const fetchHomepageInsightSummaries = createAsyncThunk(
  'homepage/fetchInsightSummaries',
  async (filters = []) => {
    const params = mapPropertiesToParams(filters, 'score', 'desc', 1);
    const path = `insights/summary?${params}`;
    const response = await doGet(path);

    return response.data;
  },
);

export const fetchHomePageGroupedInsights = createAsyncThunk(
  'homepage/fetchHomePageGroupedInsights',
  async (filters = []) => {
    const params = mapFiltersToParams(filters);
    const groups = ['severity', 'platformName'];
    const promises = groups.map(async (group) => {
      const response = await doGet(`insights/summary/grouped/${group}?${params.toString()}`);
      return response.data;
    });

    const filtersWithoutStatus = filters.filter((filter) => filter.column !== 'active');
    const statusParams = mapFiltersToParams(filtersWithoutStatus);
    promises.push(doGet(`insights/summary/grouped/status?${statusParams.toString()}`));

    const [severityRes, platformNameRes, statusRes] = await Promise.all(promises);

    return {
      severity: severityRes,
      platformName: platformNameRes,
      status: statusRes.data,
    };
  },
);

export const fetchDevelopersWorkload = createAsyncThunk('homepage/fetchDevelopersWorkload', async () => {
  const response = await doGet('developers-workload');

  return response.data;
});

export const fetchUnattendedApplications = createAsyncThunk('homepage/fetchUnattendedApplications', async () => {
  const response = await doGet('unattended-applications');

  return response.data;
});

export const fetchApplicationByPlatform = createAsyncThunk('homepage/fetchApplicationByPlatform', async (filters) => {
  const enrichedFilters = enrichApplicationFilters(filters);
  const params = mapFiltersToParams(enrichedFilters);

  const response = await doGet(`application/summary/grouped/platform?${params.toString()}`);
  return response.data;
});

export const fetchBusinessContexts = createAsyncThunk('homepage/fetchBusinessTags', async () => {
  const response = await doGet('business-contexts');

  return response.data;
});

export const fetchAppChanges = createAsyncThunk('homepage/fetchAppChanges', async ({ since, inProductionOnly }) => {
  const response = await doGet(`app-changes?since=${since}&inProductionOnly=${inProductionOnly}`);

  return response.data;
});

export const fetchHomepageAggregations = createAsyncThunk(
  'homepage/fetchHomepageAggregations',
  async (filters = []) => {
    const params = mapFiltersToParams(filters);

    const path = `aggregations/summary?${params}`;
    const response = await doGet(path);

    return response.data;
  },
);
export const fetchHomepageTenantData = createAsyncThunk('homepage/fetchHomepageTenantData', async ({ startDate }) => {
  const scanDataPromise = doGet(`scan-data?since=${startDate}`);
  const postureDataPromise = doGet('posture');

  const [scanData, postureData] = await Promise.all([scanDataPromise, postureDataPromise]);

  return {
    scanData: scanData.data,
    postureData: postureData.data,
  };
});

const initialState = {
  static: {
    platformName: {
      content: [],
      loading: true,
      error: false,
    },
    insights: {
      content: [],
      loading: true,
      error: false,
    },
    developersWorkload: {
      content: [],
      loading: true,
      error: false,
    },
    unattendedApplications: {
      content: [],
      loading: true,
      error: false,
    },
    aggregations: {
      content: [],
      loading: true,
      error: false,
    },
    tenantData: {
      scanData: [],
      postureData: {},
      loading: true,
      error: false,
    },
    businessContexts: {
      content: [],
      loading: true,
      error: false,
    },
    appChanges: {
      content: {
        inProduction: {},
        all: {},
      },
      loading: true,
      error: false,
    },
  },
  dynamic: {
    grouped: {
      content: [],
      loading: true,
      error: false,
    },
    insights: {
      content: [],
      loading: true,
      error: false,
    },
    mttr: {
      content: [],
      loading: true,
      error: false,
    },
  },
};

export const homepageSlice = createSlice({
  name: 'homepage',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchHomePageGroupedInsights.pending, (state) => {
      state.dynamic.grouped.loading = true;
      state.dynamic.grouped.error = false;
    });
    builder.addCase(fetchHomePageGroupedInsights.rejected, (state) => {
      state.dynamic.grouped.loading = false;
      state.dynamic.grouped.error = true;
    });
    builder.addCase(fetchHomePageGroupedInsights.fulfilled, (state, { payload }) => {
      state.dynamic.grouped.loading = false;
      state.dynamic.grouped.error = false;
      state.dynamic.grouped.content = payload;
    });
    builder.addCase(fetchUnattendedApplications.pending, (state) => {
      state.static.unattendedApplications.loading = true;
      state.static.unattendedApplications.error = false;
    });
    builder.addCase(fetchUnattendedApplications.rejected, (state) => {
      state.static.unattendedApplications.loading = false;
      state.static.unattendedApplications.error = true;
    });
    builder.addCase(fetchUnattendedApplications.fulfilled, (state, { payload }) => {
      state.static.unattendedApplications.loading = false;
      state.static.unattendedApplications.error = false;
      state.static.unattendedApplications.content = payload;
    });
    builder.addCase(fetchDevelopersWorkload.pending, (state) => {
      state.static.developersWorkload.loading = true;
      state.static.developersWorkload.error = false;
    });
    builder.addCase(fetchDevelopersWorkload.rejected, (state) => {
      state.static.developersWorkload.loading = false;
      state.static.developersWorkload.error = true;
    });
    builder.addCase(fetchDevelopersWorkload.fulfilled, (state, { payload }) => {
      state.static.developersWorkload.loading = false;
      state.static.developersWorkload.error = false;
      state.static.developersWorkload.content = payload;
    });
    builder.addCase(fetchApplicationByPlatform.pending, (state) => {
      state.static.platformName.loading = true;
      state.static.platformName.error = false;
    });
    builder.addCase(fetchApplicationByPlatform.rejected, (state) => {
      state.static.platformName.loading = false;
      state.static.platformName.error = true;
    });
    builder.addCase(fetchApplicationByPlatform.fulfilled, (state, { payload }) => {
      state.static.platformName.loading = false;
      state.static.platformName.error = false;
      state.static.platformName.content = payload;
    });
    builder.addCase(fetchAppChanges.pending, (state) => {
      state.static.appChanges.loading = true;
      state.static.appChanges.error = false;
    });
    builder.addCase(fetchAppChanges.rejected, (state) => {
      state.static.appChanges.loading = false;
      state.static.appChanges.error = true;
    });
    builder.addCase(fetchAppChanges.fulfilled, (state, { payload }) => {
      const { applicationChanges = [], appInstanceChanges = [] } = payload;
      state.static.appChanges.loading = false;
      state.static.appChanges.error = false;
      state.static.appChanges.content = {
        applicationChanges,
        appInstanceChanges,
      };
    });
    builder.addCase(fetchBusinessContexts.pending, (state) => {
      state.static.businessContexts.loading = true;
      state.static.businessContexts.error = false;
    });
    builder.addCase(fetchBusinessContexts.rejected, (state) => {
      state.static.businessContexts.loading = false;
      state.static.businessContexts.error = true;
    });
    builder.addCase(fetchBusinessContexts.fulfilled, (state, { payload }) => {
      state.static.businessContexts.loading = false;
      state.static.businessContexts.error = false;
      state.static.businessContexts.content = payload;
    });
    builder.addCase(fetchHomepageAggregations.pending, (state) => {
      state.static.aggregations.loading = true;
      state.static.aggregations.error = false;
    });
    builder.addCase(fetchHomepageAggregations.rejected, (state) => {
      state.static.aggregations.loading = false;
      state.static.aggregations.error = true;
    });
    builder.addCase(fetchHomepageAggregations.fulfilled, (state, { payload }) => {
      state.static.aggregations.loading = false;
      state.static.aggregations.error = false;
      state.static.aggregations.content = payload.data;
    });
    builder.addCase(fetchHomepageTenantData.pending, (state) => {
      state.static.tenantData.loading = true;
      state.static.tenantData.error = false;
    });
    builder.addCase(fetchHomepageTenantData.rejected, (state) => {
      state.static.tenantData.loading = false;
      state.static.tenantData.error = true;
    });
    builder.addCase(fetchHomepageTenantData.fulfilled, (state, { payload }) => {
      state.static.tenantData.loading = false;
      state.static.tenantData.error = false;
      state.static.tenantData.scanData = payload.scanData;
      state.static.tenantData.postureData = payload.postureData;
    });
    builder.addCase(fetchDynamicInsightSummaries.pending, (state) => {
      state.dynamic.insights.loading = true;
      state.dynamic.insights.error = false;
    });
    builder.addCase(fetchDynamicInsightSummaries.rejected, (state) => {
      state.dynamic.insights.loading = false;
      state.dynamic.insights.error = true;
    });
    builder.addCase(fetchDynamicInsightSummaries.fulfilled, (state, { payload }) => {
      state.dynamic.insights.loading = false;
      state.dynamic.insights.error = false;
      state.dynamic.insights.content = payload.data;
    });

    builder.addCase(fetchHomepageInsightSummaries.pending, (state) => {
      state.static.insights.loading = true;
      state.static.insights.error = false;
    });
    builder.addCase(fetchHomepageInsightSummaries.rejected, (state) => {
      state.static.insights.loading = false;
      state.static.insights.error = true;
    });
    builder.addCase(fetchHomepageInsightSummaries.fulfilled, (state, { payload }) => {
      state.static.insights.loading = false;
      state.static.insights.error = false;
      state.static.insights.content = payload.data;
    });

    builder.addCase(fetchDynamicInsightSummariesForMttr.pending, (state) => {
      state.dynamic.mttr.loading = true;
      state.dynamic.mttr.error = false;
    });
    builder.addCase(fetchDynamicInsightSummariesForMttr.rejected, (state) => {
      state.dynamic.mttr.loading = false;
      state.dynamic.mttr.error = true;
    });
    builder.addCase(fetchDynamicInsightSummariesForMttr.fulfilled, (state, { payload }) => {
      state.dynamic.mttr.loading = false;
      state.dynamic.mttr.error = false;
      state.dynamic.mttr.content = payload.data;
    });
  },
  reducers: {
    // TODO: after we will have caching we could remove this and use the data we have
    resetState: (state) => {
      state.static.appChanges.content.all = {};
      state.static.appChanges.content.inProduction = {};
    },
  },
});
export const { resetState } = homepageSlice.actions;
export default homepageSlice.reducer;
