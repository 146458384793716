import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getPublicReports, getPublicReportsLoading, getPublicReportsTotal } from '../../redux/selector/biSelector';
import { fetchPublicReports } from '../../redux/slicers/biSlicer';
import Loader from '../../components/loader/Loader';

export default function PublicReports() {
  const loading = useSelector(getPublicReportsLoading);
  const publicReports = useSelector(getPublicReports);
  const totalReports = useSelector(getPublicReportsTotal);
  const privateReports = totalReports - publicReports;
  const dispatch = useDispatch();

  const { t } = useTranslation(['bi']);

  useEffect(() => {
    dispatch(fetchPublicReports());
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className="public-reports">
      <div className="public-reports-counter">{t('publicReportsCounter', { number: publicReports })}</div>
      <div className="light-text">{t('privateReportsCounter', { number: privateReports })}</div>
    </div>
  );
}
