import './arrow.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classnames from 'classnames';
import { useState } from 'react';
import PropTypes from 'prop-types';

export default function Arrow({ onClick, value }) {
  return (
    <div
      className={classnames('arrow-container', value)}
      onClick={(event) => {
        onClick && onClick(event);
      }}
    >
      <KeyboardArrowDownIcon />
    </div>
  );
}

Arrow.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.oneOf(['up', 'down', 'left', 'right']).isRequired,
};
