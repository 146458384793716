import activeRuleIcon from './icons/active_rule_icon.svg';
import disabledRuleIcon from './icons/disabled_rule_icon.svg';
import firedRuleIcon from './icons/fired_rule_icon.svg';

export default function TypedRulesList({ title, type, firedRules, activeRules, disabledRules }) {
  return (
    <div className="typed-rules-list column">
      <div className="typed-rules-list-title">{title}</div>
      <div className="typed-rules-list-items">
        {firedRules
          .filter((rule) => rule.type === type)
          .map((rule, index) => (
            <RuleRow key={rule.id} rule={rule} state="fired" index={index} />
          ))}
        {activeRules
          .filter((rule) => rule.type === type)
          .map((rule, index) => (
            <RuleRow key={rule.id} rule={rule} state="active" index={index} />
          ))}
        {disabledRules
          .filter((rule) => rule.type === type)
          .map((rule, index) => (
            <RuleRow key={rule.id} rule={rule} state="disabled" index={index} />
          ))}
      </div>
    </div>
  );
}

function getIcon(state) {
  if (state === 'fired') {
    return firedRuleIcon;
  }
  return state === 'active' ? activeRuleIcon : disabledRuleIcon;
}

function RuleRow({ rule, index, state }) {
  const icon = getIcon(state);

  return (
    <div key={index} className="typed-rules-list-item">
      <span className="typed-rules-list-icon">
        <img src={icon} alt="rule" />
      </span>
      <span className="typed-rules-list-text">{rule.name}</span>
    </div>
  );
}
