import { createSelector } from '@reduxjs/toolkit';
import { capitalize, chain } from 'lodash';
import { mapSeverityFromCode } from '../../services/MapperUtils';

export const getNotificationChannels = (state) => state.notificationChannels.channels.content;
export const getNotificationChannelById = (id) => (state) =>
  state.notificationChannels.channels.content.find((channel) => channel.id === id);

export const getNotificationPolicyById = (id) => (state) =>
  state.notificationChannels.policies.content.find((channel) => channel.id === id);

export const isNotificationChannelsLoading = (state) => state.notificationChannels.channels.loading || false;

export const getNotificationPolicies = (state) => state.notificationChannels.policies.content;
export const isNotificationPoliciesLoading = (state) => state.notificationChannels.policies.loading || false;

const selectErrors = (state) => state.notificationChannels.report.error || '';

export const getReportError = createSelector([selectErrors], (errors) => errors.split(','));
export const getReportSuccessful = (state) => state.notificationChannels.report.success;

export const deleteNotificationChannelLoading = (state) => state.notificationChannels.channels.delete.loading;
export const deleteNotificationChannelError = (state) => state.notificationChannels.channels.delete.error;

export const editNotificationChannelLoading = (state) => state.notificationChannels.channels.update.loading;
export const editNotificationChannelError = (state) => state.notificationChannels.channels.update.error;

export const createNotificationChannelLoading = (state) => state.notificationChannels.channels.create.loading;
export const createNotificationChannelError = (state) => state.notificationChannels.channels.create.error;

export const getNotificationCountByChannel = (state) =>
  chain(Object.values(state.notificationChannels.channels.countByChannel.content))
    .map((count) => Number(count))
    .sum()
    .value();

export const getNotificationsCountByChannelLoading = (state) =>
  state.notificationChannels.channels.countByChannel.loading;

export const deleteNotificationPolicyLoading = (state) => state.notificationChannels.policies.delete.loading;
export const deleteNotificationPolicyError = (state) => state.notificationChannels.policies.delete.error;

export const editNotificationPolicyLoading = (state) => state.notificationChannels.policies.update.loading;
export const editNotificationPolicyError = (state) => state.notificationChannels.policies.update.error;

export const createNotificationPolicyLoading = (state) => state.notificationChannels.policies.create.loading;
export const createNotificationPolicyError = (state) => state.notificationChannels.policies.create.error;

export const getNotificationPolicyFilters = (state) => state.notificationChannels.policies.editModeElement.filters;
export const getNotificationPolicyFiltersHumanReadable = (state) =>
  (state.notificationChannels.policies.editModeElement.filters || []).map((filter) => ({
    ...filter,
    value: filter.column === 'severity' ? mapSeverityFromCode(filter.value) : capitalize(filter.value),
  }));
