/* eslint-disable consistent-return */

import axios from 'axios';
import { getGuestToken, getAuthToken, getTenantId, isInitialized } from './AuthService';

const API_URL = process.env.REACT_APP_API_URL;
const SUPPORTED_METHODS = ['get', 'post', 'patch', 'delete'];

const defaultRequestConfig = { useTenant: true, stream: false, invalidateCache: false };

function handleError(e) {
  const statusCode = e.response ? e.response.status : null;
  if ([401].includes(statusCode)) {
    window.dispatchEvent(new Event('logout'));
  } else {
    throw e?.response?.data ? { message: e.response.data.message, status: e.response.status } : e;
  }
}

function canSendRequest(token, useTenant, tenantId, initialized) {
  if (!token) {
    return false;
  }

  if (!useTenant) {
    return true;
  }

  return tenantId && initialized;
}

async function sendRequest(path, method, body, requestConfig) {
  const { useTenant, stream, invalidateCache } = { ...defaultRequestConfig, ...requestConfig };
  if (!SUPPORTED_METHODS.includes(method)) {
    throw new Error(`Method ${method} is not supported`);
  }

  const guestToken = getGuestToken();
  const token = getAuthToken();
  const tokenToUse = guestToken ?? token;
  const initialized = isInitialized();
  const tenantId = getTenantId();
  if (!canSendRequest(tokenToUse, useTenant, tenantId, initialized)) {
    return;
  }
  try {
    const baseUrl = guestToken ? `${API_URL}guest/` : API_URL;
    const url = useTenant ? `${baseUrl}tenant/${tenantId}/${path}` : `${baseUrl}${path}`;
    const res = await axios.request({
      url,
      method,
      data: body,
      headers: {
        authorization: `Bearer ${tokenToUse}`,
        'Cache-Control': invalidateCache ? 'no-cache' : 'default',
      },
      responseType: stream ? 'stream' : 'json',
    });

    return res;
  } catch (e) {
    handleError(e);
  }
}

export async function doGet(path, requestConfig) {
  return sendRequest(path, 'get', undefined, requestConfig);
}

export async function doPost(path, body, requestConfig) {
  return sendRequest(path, 'post', body, requestConfig);
}

export async function doPatch(path, body, requestConfig) {
  return sendRequest(path, 'patch', body, requestConfig);
}

export async function doDelete(path, body, requestConfig) {
  return sendRequest(path, 'delete', body, requestConfig);
}
