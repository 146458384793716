import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCircleExclamation,
  faChevronLeft,
  faFilter,
  faChevronUp,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';

library.add(faCircleExclamation, faChevronLeft, faFilter, faChevronUp, faChevronDown);
