import classnames from 'classnames';
import { isNil } from 'lodash';
import { useState } from 'react';
import { Skeleton } from '@mui/material';
import Arrow from '../arrow/Arrow';
import './collapse-card.scss';
import './card-view-table.scss';

export default function CollapseCard({
  title,
  children,
  totalElementsCount,
  initialOpen = true,
  isLoading = false,
  collapseEnabled = true,
  headerComponents = [],
}) {
  const [open, setOpen] = useState(initialOpen);

  const titleWithCount = isLoading || isNil(totalElementsCount) ? title : `${title} (${totalElementsCount})`;

  return (
    <div className="collapse-card">
      <div
        className={classnames('collapse-card-title', {
          open,
          disabled: !collapseEnabled,
        })}
        onClick={() => collapseEnabled && setOpen(!open)}
      >
        <div className="collapse-card-title-text">
          {collapseEnabled && <Arrow value={open ? 'down' : 'right'} />}
          {titleWithCount}
        </div>
        <span className="collapse-header-components">{headerComponents}</span>
      </div>

      <div className={classnames('collapse-card-body', { open })}>
        <div className="body-content">{isLoading ? <Skeleton animation="wave" variant="rectangular" /> : children}</div>
      </div>
    </div>
  );
}
