import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { maxBy } from 'lodash';
import DropdownBox from '../dropdown-box/DropdownBox';
import EllipsisText from '../ellipsis-text/EllipsisText';
import './line-chart-eye-candy.scss';
import Loader from '../loader/Loader';
import { usersSinceOptions } from '../../pages/users/UsersConsts';

const BAR_WIDTH_FACTOR = 10;
export default function LineChartEyeCandy({ selector, options = [], fetchDataAction, isLoadingSelector }) {
  const { t } = useTranslation(['common']);
  const [since, setSince] = useState(usersSinceOptions.LAST_MONTH);
  const dispatch = useDispatch();
  const data = useSelector(selector);
  const loading = useSelector(isLoadingSelector);

  const dataToShow = [...data].sort((a, b) => b.counter - a.counter).slice(0, 4);
  const highestCounter = maxBy(dataToShow, (developer) => Number(developer.counter))?.counter || 1;

  useEffect(() => {
    (async function fetchData() {
      await dispatch(fetchDataAction(since));
    })();
  }, [since]);

  return loading ? (
    <Loader />
  ) : (
    <div className="line-chart-eye-candy">
      <DropdownBox
        width={240}
        text=""
        value={t(`times.${since}`)}
        selectedId={since}
        menuItems={options.map((option) => ({
          id: option,
          key: option,
          onClick: () => setSince(option),
          text: t(`times.${option}`),
        }))}
      />
      <div key="titles" className="developer-row light-text">
        <div>{t('general.name')}</div>
        <div>{t('applications.applications')}</div>
      </div>

      {dataToShow.map((developer) => {
        const width = (developer.counter / highestCounter) * BAR_WIDTH_FACTOR;
        return (
          <div key={developer.id} className="developer-row">
            <EllipsisText text={developer.name} className="half-width" />
            <div className="developer-counter">
              <div className="counter-bar" style={{ width: `${width}vw` }} />
              <div>{developer.counter}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
