import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getNotificationChannels,
  getNotificationCountByChannel,
  getNotificationPolicies,
  getNotificationsCountByChannelLoading,
  isNotificationChannelsLoading,
  isNotificationPoliciesLoading,
} from '../../../redux/selector/notificationSelector';
import {
  fetchNotificationCountByChannel,
  fetchNotificationPolicies,
  fetchNotificationsChannels,
} from '../../../redux/slicers/notificationsSlicer';
import NotificationsEyeCandy from './NotificationsEyeCandy';
import NotificationChannelTable from './NotificationChannelTable';
import NotificationPolicyTable from './NotificationPolicyTable';
import { fetchInsightFilterOptions } from '../../../redux/slicers/insightSlicer';

export default function NotificationSettingsPage() {
  const dispatch = useDispatch();
  const channels = useSelector(getNotificationChannels);
  const policies = useSelector(getNotificationPolicies);
  const totalNotifications = useSelector(getNotificationCountByChannel);

  const channelsLoading = useSelector(isNotificationChannelsLoading);
  const policiesLoading = useSelector(isNotificationPoliciesLoading);
  const notificationsCounterLoading = useSelector(getNotificationsCountByChannelLoading);

  const isLoading = channelsLoading || policiesLoading;

  useEffect(() => {
    if (!channelsLoading && !channels.length) {
      dispatch(fetchNotificationsChannels());
    }
    if (!policiesLoading && !policies.length) {
      dispatch(fetchNotificationPolicies());
    }
    if (!notificationsCounterLoading && !totalNotifications) {
      dispatch(fetchNotificationCountByChannel());
    }
    dispatch(fetchInsightFilterOptions());
  }, []);

  return (
    <div className="notifications-settings">
      <NotificationsEyeCandy
        loading={isLoading}
        polices={policies.length}
        channels={channels.length}
        notifications={totalNotifications}
      />
      <NotificationPolicyTable policies={policies} isLoading={policiesLoading} />
      <NotificationChannelTable channels={channels} isLoading={channelsLoading} />
    </div>
  );
}
