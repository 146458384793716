/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  history: [],
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    pushHistory: (state, { payload }) => {
      const last = state.history.at(-1);
      if (last !== payload) {
        state.history.push(payload);
      }
    },
  },
});

// this is for configureStore
export const { pushHistory } = historySlice.actions;
export default historySlice.reducer;
