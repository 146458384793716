import { sumBy } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import openInsightsImg from './open_insights_icon.svg';
import acknowledgedInsightsLogo from './acknowledged_insights_icon.svg';
import resolvedInsightsLogo from './resolved_insights_icon.svg';
import HomePageCounter from '../HomePageCounter';
import Card from '../../../components/card/Card';
import {
  getHomePageInsightsByStatus,
  isHomePageGroupedInsightsLoading,
} from '../../../redux/selector/homepageSelector';

export default function InsightDataCounters({ datesLabel, startDate, inProduction }) {
  const insightsByStatus = useSelector(getHomePageInsightsByStatus);
  const loading = useSelector(isHomePageGroupedInsightsLoading);
  const { t } = useTranslation(['common']);

  const resolved = Number(
    insightsByStatus.find((i) => i.key.toLowerCase().includes('resolved'))?.value || 0,
  ).toLocaleString();
  const acknowledged = Number(
    insightsByStatus.find((i) => i.key.toLowerCase().includes('acknowledged'))?.value || 0,
  ).toLocaleString();
  const openInsights = sumBy(
    insightsByStatus.filter((i) => !i.key.toLowerCase().includes('closed')),
    (status) => Number(status.value),
  ).toLocaleString();

  let dynamicFilters = `creationTime:after=${startDate}`;
  if (inProduction) {
    dynamicFilters += `&inProduction:is=True`;
  }

  return (
    <Card className="short flex data-counter-container" loading={loading}>
      <div className="dates-label">{datesLabel}</div>
      <div className="insight-data-counters">
        <HomePageCounter
          src={openInsightsImg}
          count={openInsights}
          text={t('insights.open')}
          navigateUrl={`/insights?tab=all&resolved:is=False&${dynamicFilters}`}
        />
        <HomePageCounter
          src={resolvedInsightsLogo}
          count={resolved}
          text={t('insights.resolved')}
          navigateUrl={`/insights?tab=all&status:is=Closed Resolved&${dynamicFilters}`}
        />
        <HomePageCounter
          src={acknowledgedInsightsLogo}
          count={acknowledged}
          text={t('insights.acknowledged')}
          navigateUrl={`/insights?tab=all&status:is=Closed Acknowledged&${dynamicFilters}`}
        />
      </div>
    </Card>
  );
}
