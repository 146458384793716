import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next';
import { enforceTranslations } from './filterUtils';
import { OR_OPERATOR } from './filterConsts';
import EllipsisText from '../ellipsis-text/EllipsisText';

export default function FilterBox({ tableName, columnName, type, removeFilter, closable, value }) {
  const { t } = useTranslation('common');
  const isId = columnName.endsWith('Id');

  function addTranslation() {
    return value
      .toString()
      .split(OR_OPERATOR)
      .map((v) => enforceTranslations(t, v))
      .join(', ');
  }

  return (
    <div className="filter-box" data-testid="filter-box">
      <div className="filter-title">
        <span className="column">
          {isId
            ? `${t('tables.filters.filteredBy')} ${t(`tables.filters.common.${columnName}`)}`
            : t(`tables.${tableName}.columns.${columnName}`)}
        </span>
        {!isId && (
          <>
            <span className="operator">{t(`tables.filters.operators.${type}`)}</span>
            <EllipsisText text={addTranslation()} />
          </>
        )}
      </div>
      {closable && (
        <span className="remove-filter-button" onClick={() => removeFilter(columnName, type, value)}>
          <CloseOutlinedIcon />
        </span>
      )}
    </div>
  );
}
