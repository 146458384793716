import { useTranslation } from 'react-i18next';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import CampaignIcon from '@mui/icons-material/Campaign';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Loader from '../../../components/loader/Loader';
import ColoredBox from '../../../components/box/ColoredBox';

function NotificationsEyeCandyCounter({ icon, iconColor, count, primaryText, secondaryText, loading }) {
  return (
    <div className="notifications-eye-candy-counter">
      <div className="notifications-eye-candy-counter-icon">
        <ColoredBox color={iconColor} icon={icon} isFilled hasBorder={false} />
      </div>
      {loading ? <Loader /> : <div className="notifications-eye-candy-counter-count">{count.toLocaleString()}</div>}
      <div className="notifications-eye-candy-counter-text">
        <div className="notifications-eye-candy-counter-text-primary">{primaryText}</div>
        {secondaryText && <div className="notifications-eye-candy-counter-text-secondary">{secondaryText}</div>}
      </div>
    </div>
  );
}

export default function NotificationsEyeCandy({ polices = 0, channels = 0, notifications = 0, loading }) {
  const { t } = useTranslation(['settings']);
  return (
    <div className="notifications-eye-candy">
      <NotificationsEyeCandyCounter
        icon={<HistoryEduIcon />}
        iconColor="blue"
        count={polices}
        primaryText={t('notifications.policies', { count: polices })}
        loading={loading}
      />
      <NotificationsEyeCandyCounter
        icon={<CampaignIcon />}
        iconColor="green"
        count={channels}
        primaryText={t('notifications.channels', { count: channels })}
        loading={loading}
      />
      <NotificationsEyeCandyCounter
        icon={<NotificationsActiveIcon />}
        iconColor="red"
        count={notifications}
        primaryText={t('notifications.notifications')}
        secondaryText={t('notifications.lastDays', { days: 30 })}
        loading={loading}
      />
    </div>
  );
}
