import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import Tags from '../../../components/tags/Tags';

export default function UserRolesTable({ isLoading, roles = [] }) {
  const { t } = useTranslation(['common', 'single-user']);
  const tableName = t('rolesTable.tableName', { ns: 'single-user' });
  const columns = useMemo(
    () => [
      {
        Header: t('rolesTable.roleName', { ns: 'single-user' }),
        accessor: 'roleName',
        width: '30%',
      },
      {
        Header: t('general.environments', { ns: 'common' }),
        accessor: 'environments',
        width: '70%',
        Cell: ({ value, cell }) => {
          if (!value?.length) {
            return <div className="gray">N/A</div>;
          }

          const tags = value.map((tag) => ({ name: tag }));
          return <Tags tags={tags} tooltipAnchor={`environment-${cell.row.id}`} />;
        },
      },
    ],
    [roles],
  );
  const sortBy = [{ id: 'roleName', desc: true }];

  return (
    <div className="card-view-table-container">
      <div className="collapse-card-container">
        <CollapseCard title={tableName} isLoading={isLoading} totalElementsCount={roles.length}>
          <Table
            columns={columns}
            data={roles}
            totalCount={roles.length}
            options={{ showFilter: false, showPagination: true, showSort: true, usePaginationSearchParam: false }}
            type="users"
            shouldScrollOnPagination={false}
            sortBy={sortBy}
            initialFilters={[]}
            customFilters={[]}
            isRowDisabled={() => false}
            getElementId={(role) => role.roleName}
            isLoading={isLoading}
            classNames="card-view-table"
            backendPagination={false}
            pageSize={5}
            emptyState={
              <TableEmptyContent img={emptyApplicationsImg} mainText={t('rolesTable.noData', { ns: 'single-user' })} />
            }
          />
        </CollapseCard>
      </div>
    </div>
  );
}
