import { createSelector } from '@reduxjs/toolkit';

export const getAllConnections = createSelector(
  (state) => state.connection.allConnections.content,
  (connections) => connections || [],
);

export const getTotalConnections = (state) => state.connection.allConnections.total;
export const isConnectionsLoading = (state) => state.connection.allConnections.loading;
export const getSingleConnection = (connId) => (state) =>
  state.connection.singleConnection[connId]?.content?.connection;
export const getSingleConnectionUsers = (connId) => (state) =>
  state.connection.singleConnection[connId]?.content?.users;
export const getSingleConnectionData = (connId) => (state) => state.connection.singleConnection[connId]?.content?.data;
export const getSingleConnectionEmbeddedFlows = (connId) => (state) =>
  state.connection.singleConnection[connId]?.content?.embeddedFlows;
export const getSingleConnectionFlowEditors = (connId) => (state) =>
  state.connection.singleConnection[connId]?.content?.flowEditors;
export const getSingleConnectionLoading = (connId) => (state) => state.connection.singleConnection[connId]?.loading;
export const getSingleConnectionError = (connId) => (state) => state.connection.singleConnection[connId]?.error;

export const getConnectionFilters = (state) => state.connection.allConnections.filters;
export const getHttpEndpointsFilters = (state) => state.connection.httpEndpoints.filters;
export const getConnectionsFiltersOptions = (state) => state.connection.allConnections.filtersOptions;

export const getHttpEndpoints = createSelector(
  (state) => state.connection.httpEndpoints.content,
  (httpEndpoints) => httpEndpoints || [],
);

export const getHttpEndpointsLoading = (state) => state.connection.httpEndpoints.loading;
export const getHttpEndpointsTotal = (state) => state.connection.httpEndpoints.total;
export const getHttpEndpointsFiltersOptions = (state) => state.connection.httpEndpoints.filtersOptions;
