import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { get, isArray, camelCase } from 'lodash';
import Popover from '@mui/material/Popover';
import Divider from '@mui/material/Divider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EllipsisText from '../../ellipsis-text/EllipsisText';
import { ENTITIES } from '../../../consts/Entities';
import { fieldsPerEntity } from './FieldsPerEntity';
import { formatDateToDDMMYYYY } from '../../../services/MapperUtils';
import { getTenantNameFromUrl } from '../../../services/OrgNameService';

import './entity-data.scss';

const entityTypeToUrlPathMap = {
  [ENTITIES.APPLICATIONS]: 'applications',
  [ENTITIES.FLOWS]: 'applications',
  [ENTITIES.USERS]: 'users',
  [ENTITIES.GROUPS]: 'users',
  [ENTITIES.CONNECTIONS]: 'data',
};

export default function EntityDataPopover({ entity }) {
  const { t } = useTranslation(['entity-map', 'common']);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const orgName = getTenantNameFromUrl();
  const { name, entityType, id } = entity || {};

  function handlePopoverOpen(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  }

  function handlePopoverClose() {
    setOpen(false);
  }

  function formatValue(value) {
    if (!Number.isNaN(Date.parse(value))) {
      return formatDateToDDMMYYYY(value);
    }
    if (isArray(value)) {
      return value.join(', ');
    }
    return value;
  }

  function onTitleClick() {
    const to = `/${entityTypeToUrlPathMap[entityType]}/${id}`;
    window.open(`/${orgName}/portal${to}`, '_blank');
  }

  return (
    <div className="entity-data" onClick={handlePopoverOpen}>
      <InfoOutlinedIcon />
      <Popover
        open={open}
        onClose={handlePopoverClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
      >
        <div className="popover-container" onMouseLeave={handlePopoverClose}>
          <div className="entity-link" onClick={onTitleClick}>
            <EllipsisText text={name || t('general.na', { ns: 'common' })} className="name" toolTipMaxWidth="90%" />
          </div>
          <Divider />
          {fieldsPerEntity?.[entityType]?.map(({ key, label }) => {
            const value = get(entity, key);
            return value ? (
              <div key={key} className="field">
                <div className="field-name">{t(`popover.${camelCase(label)}`)}:</div>
                <div className="field-value">
                  <EllipsisText text={formatValue(value)} containerMaxWidth="170px" toolTipMaxWidth="90%" />
                </div>
              </div>
            ) : null;
          })}
        </div>
      </Popover>
    </div>
  );
}
