export function enforceTranslations(t, option) {
  switch (option.toString().toLowerCase()) {
    case 'true':
      return t('general.true');
    case 'false':
      return t('general.false');
    case 'info':
      return t('insights.info');
    case 'low':
      return t('insights.low');
    case 'medium':
      return t('insights.medium');
    case 'high':
      return t('insights.high');
    case 'critical':
      return t('insights.critical');
    default:
      return option;
  }
}
