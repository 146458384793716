import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HomePageTable } from './HomePageTable';
import SeverityBox from '../../../components/box/SeverityBox';
import InsightTypeBox from '../../../components/box/InsightTypeBox';
import BooleanValue from '../../../components/boolean-value/BooleanValue';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import { compareSeverities, compareTypes } from '../../../services/comparisonUtils';
import emptyInsightsImg from '../../insights/empty_insights.png';
import { DAY } from '../../../consts/timesConsts';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import PlatformName from '../../../components/platform-name/PlatformName';
import { getHomepageStaticInsights, isHomepageStaticInsightsLoading } from '../../../redux/selector/homepageSelector';

const UNATTENDED_DAYS_THRESHOLDS = 14;

function getUnattendedDate() {
  const date = new Date();
  date.setDate(date.getDate() - UNATTENDED_DAYS_THRESHOLDS);
  const dayBeforeTwoWeeks = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
  return dayBeforeTwoWeeks;
}

export default function UnattendedInsightsTable({ inProduction }) {
  const insights = useSelector(getHomepageStaticInsights);
  const loading = useSelector(isHomepageStaticInsightsLoading);
  const dayBeforeTwoWeeks = getUnattendedDate();
  const { t } = useTranslation(['homePage', 'common']);

  const sortedInsights = insights
    .slice()
    .filter((insight) => insight.severity !== 'Info')
    .filter((insight) => new Date(insight.creationTime).getTime() < new Date(dayBeforeTwoWeeks).getTime())
    .sort((a, b) => new Date(a.creationTime).getTime() - new Date(b.creationTime).getTime());

  const columns = useMemo(
    () => [
      {
        backendKey: 'severity',
        width: '100px',
        accessor: (cell) => cell.severity,
        Cell: ({ cell }) => {
          const severity = cell.value;
          return <SeverityBox severity={severity} />;
        },
        sortType: (rowA, rowB) => {
          const a = rowA.original.severity;
          const b = rowB.original.severity;
          return compareSeverities(a, b);
        },
      },
      {
        backendKey: 'type',
        width: '140px',
        accessor: (cell) => cell.type,
        Cell: ({ cell }) => {
          const { value } = cell;
          return <InsightTypeBox insightType={value} hasBorder={false} isFilled={false} hasPadding={false} />;
        },
        sortType: (rowA, rowB) => {
          const a = rowA.original.type;
          const b = rowB.original.type;
          return compareTypes(a, b);
        },
      },
      {
        backendKey: 'name',
        width: '20%',
        accessor: (cell) => cell.name,
        Cell: ({ row, cell }) => (
          <OrganizationAwareLink to={`/insights/${row.original.id}?from=Overview`}>{cell.value}</OrganizationAwareLink>
        ),
      },
      {
        backendKey: 'platformName',
        width: '17%',
        accessor: ({ platformName }) => platformName,
        Cell: ({ value }) => <PlatformName platform={value} />,
      },
      {
        backendKey: 'creationTime',
        width: '10%',
        accessor: (cell) => cell.creationTime,
        Cell: ({ cell }) => {
          const time = cell.value;
          const daysPast = Math.floor((new Date() - new Date(time)) / DAY);
          return <span className="gray number">{daysPast}</span>;
        },
      },
      {
        backendKey: 'inProduction',
        width: '130px',
        accessor: (cell) => cell.inProduction,
        Cell: ({ cell }) => <BooleanValue isTrue={cell.value} />,
        sortType: (rowA, rowB) => {
          const a = rowA.original.inProduction;
          const b = rowB.original.inProduction;
          return b - a; // reverse order so true is first
        },
      },
    ],
    [],
  );

  return (
    <HomePageTable
      tooltipText={t('homePageTables.unattendedInsightsTooltip')}
      title={t('homePageTables.unattendedInsights')}
      columns={columns}
      data={sortedInsights}
      isLoading={loading}
      type="home.unattendedInsights"
      link={`/insights?tab=all&resolved:is=False&creationTime:before=${dayBeforeTwoWeeks}
      &severity:isNot=Info&sort=creationTime,asc${inProduction ? '&inProduction:is=true' : ''}`}
      emptyState={
        <TableEmptyContent
          img={emptyInsightsImg}
          mainText={t('homePageTables.noUnattendedInsights')}
          secondaryText=""
        />
      }
    />
  );
}
