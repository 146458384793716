/* eslint-disable no-restricted-syntax */
// This file was taken from react-flow auto-layout example
import dagre from '@dagrejs/dagre';

let dagreGraph;

export function resetDagreGraph() {
  dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
}

const dagreLayout = async (nodes, edges) => {
  dagreGraph.setGraph({
    rankdir: 'LR',
    nodesep: 50,
    ranksep: 50,
  });

  for (const node of nodes) {
    if (!node.hidden) {
      dagreGraph.setNode(node.id, {
        width: node.width ?? 0,
        height: node.height ?? 0,
      });
    } else {
      dagreGraph.removeNode(node.id);
    }
  }

  for (const edge of edges) {
    if (dagreGraph.hasNode(edge.target)) {
      dagreGraph.setEdge(edge.source, edge.target);
    } else {
      dagreGraph.removeEdge(edge);
    }
  }

  dagre.layout(dagreGraph);

  const nextNodes = nodes.map((node, i) => {
    // root is the first node
    if (node.hidden || i === 0) {
      return node;
    }

    const { x, y } = dagreGraph.node(node.id);
    const position = {
      x: x - (node.width ?? 0) / 2 + 100,
      y: y - (node.height ?? 0) / 2,
    };

    return { ...node, position };
  });

  return { nodes: nextNodes, edges };
};

export default dagreLayout;
