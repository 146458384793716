import classNames from 'classnames';

import './button-with-icon.scss';

export default function ButtonWithIcon({ icon, text, onClick, color = 'gray', enabled = true, dataTestId }) {
  const className = classNames('icon-button', {
    disabled: !enabled,
    [color]: enabled,
  });
  return (
    <div className={className} onClick={(e) => enabled && onClick(e)} data-testid={dataTestId}>
      <span className="icon-button-prop">{icon}</span>
      <span className="icon-button-prop">{text}</span>
    </div>
  );
}
