import classnames from 'classnames';
import Loader from '../loader/Loader';
import InformationIcon from '../information-icon/InformationIcon';

import './card.scss';

export default function Card({
  children,
  title,
  style = {},
  background = true,
  className = '',
  loading,
  tooltip,
  dataTestId,
}) {
  return (
    <div
      className={classnames('card', className, {
        'no-background': !background,
      })}
      style={style}
      data-testid={dataTestId}
    >
      <div className="title">
        {title}
        <InformationIcon tooltipText={tooltip} />
      </div>
      {loading ? <Loader /> : children}
    </div>
  );
}
