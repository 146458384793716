import { partition, snakeCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import CloseableModal from '../modal/ClosableModal';
import Input from '../input/Input';
import Button from '../buttons/button/Button';

export default function AcknowledgeModal({ modalOpen, setModalOpen, onAcknowledge, insights, message, setMessage }) {
  const { t } = useTranslation(['insights', 'common']);

  const [messageRequiredInsights] = partition(insights, (i) => snakeCase(i.status) === 'requires_resolution');

  return (
    <CloseableModal open={modalOpen} closeModal={() => setModalOpen(false)}>
      <div className="title">{t('insightsRequireMessage', { count: messageRequiredInsights.length })}</div>
      <div className="body">
        <Input
          id="message"
          value={message}
          setValue={setMessage}
          placeholder={t('acknowledgmentMessage')}
          type="text"
          required
          className="full-size-input"
        />
        <div className="buttons-row">
          <Button isFilled={false} text={t('general.close', { ns: 'common' })} onClick={() => setModalOpen(false)} />
          <Button
            isFilled
            text={t('insights.acknowledge', { ns: 'common' })}
            onClick={() => {
              onAcknowledge();
              setModalOpen(false);
            }}
            disabled={message.trim().length === 0 ?? true}
          />
        </div>
      </div>
    </CloseableModal>
  );
}
