import { ENTITIES } from '../../../consts/Entities';

export const fieldsPerEntity = {
  [ENTITIES.APPLICATIONS]: [
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'ownerName',
      label: 'Owner Name',
    },
    {
      key: 'createdAt',
      label: 'Creation Date',
    },
    {
      key: 'creationTime',
      label: 'Creation Date',
    },
    {
      key: 'environments',
      label: 'Environments',
    },
  ],
  [ENTITIES.FLOWS]: [
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'ownerName',
      label: 'Owner Name',
    },
    {
      key: 'creationTime',
      label: 'Creation Date',
    },
    {
      key: 'createdAt',
      label: 'Creation Date',
    },
    {
      key: 'environments',
      label: 'Environments',
    },
  ],
  [ENTITIES.USERS]: [
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'accessType',
      label: 'Roles',
    },
    {
      key: 'environmentName',
      label: 'Environments',
    },
    {
      key: 'creationTime',
      label: 'Creation Date',
    },
  ],
  [ENTITIES.GROUPS]: [
    {
      key: 'email',
      label: 'Email',
    },
    {
      key: 'accessType',
      label: 'Roles',
    },
    {
      key: 'environmentName',
      label: 'Environments',
    },
    {
      key: 'creationTime',
      label: 'Creation Date',
    },
  ],
  [ENTITIES.CONNECTIONS]: [
    {
      key: 'status',
      label: 'Status',
    },
    {
      key: 'connector.name',
      label: 'Connector Name',
    },
    {
      key: 'connectorName',
      label: 'Connector Name',
    },
    {
      key: 'connector.type',
      label: 'Connector Type',
    },
    {
      key: 'environments',
      label: 'Environments',
    },
  ],
};
