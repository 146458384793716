import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HomePageTable } from './HomePageTable';
import PersonName from '../../../components/person-name/PersonName';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import {
  getHomePageDeveloperWorkload,
  isHomePageDeveloperWorkloadLoading,
} from '../../../redux/selector/homepageSelector';

export default function DeveloperWorkloadTable({ inProduction, startDate }) {
  const developersWorkload = useSelector(getHomePageDeveloperWorkload);
  const loading = useSelector(isHomePageDeveloperWorkloadLoading);
  const { t } = useTranslation(['common', 'homePage']);

  const columns = useMemo(
    () => [
      {
        backendKey: 'owner',
        width: '15%',
        accessor: (cell) => cell.name,
        Cell: ({ value, cell }) => {
          const enrichedLink = `/users/${cell.row.original.id}`;
          return (
            <OrganizationAwareLink to={enrichedLink}>
              <PersonName name={value} />
            </OrganizationAwareLink>
          );
        },
        sortType: (rowA, rowB) => {
          const a = (rowA.original.owner ?? '').toLowerCase();
          const b = (rowB.original.owner ?? '').toLowerCase();
          return a.localeCompare(b);
        },
      },
      {
        backendKey: 'applications',
        width: '10%',
        accessor: (cell) => cell.applications,
        Cell: ({ cell }) => <div className="number">{cell.value}</div>,
      },
      {
        backendKey: 'insights',
        width: '10%',
        accessor: (cell) => cell.insights,
        Cell: ({ cell }) => <div className="number">{cell.value}</div>,
      },
    ],
    [inProduction, startDate],
  );

  return (
    <HomePageTable
      type="home.developersWorkload"
      tooltipText={t('homePageTables.developerWorkloadTooltip', { ns: 'homePage' })}
      title={t('homePageTables.developerWorkload', { ns: 'homePage' })}
      columns={columns}
      data={developersWorkload}
      isLoading={loading}
      link="/users?sort=Applications%2Cdesc"
      emptyState={
        <TableEmptyContent
          img={emptyApplicationsImg}
          mainText={t('homePageTables.noDeveloperWorkload', { ns: 'homePage' })}
          secondaryText=""
        />
      }
    />
  );
}
