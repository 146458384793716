/* eslint-disable no-param-reassign */
import { uniq, uniqBy } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doDelete, doGet, doPost } from '../../services/HttpService';
import {
  enrichConnectionFilters,
  enrichHttpEndpointsFilters,
  getConnectionBackendKeyForOrderBy,
  getHttpEndpointsBackendKeyForOrderBy,
  mapPropertiesToParams,
} from '../../services/MapperUtils';

const initialState = {
  allConnections: {
    loading: false,
    error: false,
    content: undefined,
    filters: [],
    total: 0,
    filtersOptions: {},
  },
  singleConnection: {},
  httpEndpoints: {
    loading: false,
    error: false,
    content: [],
    filters: [],
    total: 0,
    filtersOptions: {},
  },
};

function generateSingleConnectionState() {
  return {
    loading: false,
    error: false,
    content: undefined,
  };
}

export const fetchConnections = createAsyncThunk(
  'connection/fetchConnections',
  async ({ filters, orderBy, order, page }) => {
    const params = mapPropertiesToParams(filters, orderBy, order, page);

    const res = await doGet(`connections/summary?${params.toString()}`);

    return res.data;
  },
);

export const fetchHttpEndpoints = createAsyncThunk(
  'connection/fetchHttpEndpoints',
  async ({ filters, orderBy, order, page }) => {
    const params = mapPropertiesToParams(filters, orderBy, order, page);

    const res = await doGet(`http-endpoints/summary?${params.toString()}`);

    return res.data;
  },
);

export const fetchSingleConnections = createAsyncThunk('connection/fetchSingleConnections', async (connId) => {
  const [connection, connUsers, connData, embeddedFlows, flowEditors] = await Promise.all([
    doGet(`connections/${connId}`),
    doGet(`connections/${connId}/users`),
    doGet(`connections/${connId}/dataSources`),
    doGet(`connections/${connId}/embeddedFlows`),
    doGet(`connections/${connId}/flowEditors`),
  ]);

  return {
    connection: connection.data,
    users: connUsers.data,
    data: connData.data,
    embeddedFlows: embeddedFlows.data,
    flowEditors: flowEditors.data,
  };
});

export const updateConnectionsFiltersAndRefetchSummaries = createAsyncThunk(
  'connection/updateConnectionsFiltersAndRefetchSummaries',
  async ({ filters, orderBy, order, page }, { dispatch }) => {
    const enrichedFilters = enrichConnectionFilters(filters);
    const enrichedOrderBy = getConnectionBackendKeyForOrderBy(orderBy);

    dispatch(fetchConnections({ filters: enrichedFilters, orderBy: enrichedOrderBy, order, page }));
    return enrichedFilters;
  },
);

export const updateHttpEndpointsFiltersAndRefetchSummaries = createAsyncThunk(
  'connection/updateHttpEndpointsFiltersAndRefetchSummaries',
  async ({ filters, orderBy, order, page }, { dispatch }) => {
    const enrichedFilters = enrichHttpEndpointsFilters(filters);
    const enrichedOrderBy = getHttpEndpointsBackendKeyForOrderBy(orderBy);

    dispatch(fetchHttpEndpoints({ filters: enrichedFilters, orderBy: enrichedOrderBy, order, page }));
    return enrichedFilters;
  },
);

export const fetchConnectionsFiltersOptions = createAsyncThunk(
  'connection/fetchConnectionsFiltersOptions',
  async () => {
    const res = await doGet(`connections/summary/filters`);

    return res.data;
  },
);

export const fetchHttpEndpointsFiltersOptions = createAsyncThunk(
  'connection/fetchHttpEndpointsFiltersOptions',
  async () => {
    const res = await doGet(`http-endpoints/summary/filters`);

    return res.data;
  },
);

export const untagConnections = createAsyncThunk(
  'connection/untagConnection',
  async ({ connectionIds, tagNames, email }) => {
    await doDelete('connections/tag', {
      connectionIds,
      tagNames,
      email,
      untagNested: true,
    });
    return { connectionIds, tagNames };
  },
);

export const tagConnections = createAsyncThunk(
  'connection/tagConnection',
  async ({ connectionIds, tagNames, email }) => {
    await doPost('connections/tag', {
      connectionIds,
      tagNames,
      tagNested: true,
      email,
    });
    return { connectionIds, tagNames };
  },
);

export const untagDataSources = createAsyncThunk(
  'connection/untagData',
  async ({ connectionId, dataIds, tagNames, email }) => {
    await doDelete('data/tag', {
      dataIds,
      tagNames,
      email,
      untagNested: true,
    });
    return { dataIds, tagNames, connectionId };
  },
);

export const tagDataSources = createAsyncThunk(
  'connection/tagData',
  async ({ connectionId, dataIds, tagNames, email }) => {
    await doPost('data/tag', {
      dataIds,
      tagNames,
      email,
      tagNested: true,
      tagParents: true,
      tagSimilar: false,
    });
    return { dataIds, tagNames, connectionId };
  },
);

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchConnections.pending, (state) => {
      state.allConnections.loading = true;
      state.allConnections.error = false;
    });
    builder.addCase(fetchConnections.fulfilled, (state, { payload }) => {
      state.allConnections.loading = false;
      state.allConnections.error = false;

      // save tagsInfo also under tags so we can use the same key in the tags dropdown
      const content = payload.data.map((d) => ({ ...d, tags: d.tagsInfo }));
      state.allConnections.content = content;
      state.allConnections.total = payload.totalCount;
    });
    builder.addCase(fetchConnections.rejected, (state) => {
      state.allConnections.loading = false;
      state.allConnections.error = true;
    });
    builder.addCase(fetchConnectionsFiltersOptions.fulfilled, (state, { payload }) => {
      state.allConnections.filtersOptions = payload;
    });
    builder.addCase(fetchHttpEndpointsFiltersOptions.fulfilled, (state, { payload }) => {
      state.httpEndpoints.filtersOptions = payload;
    });

    builder.addCase(fetchSingleConnections.pending, (state, { meta }) => {
      if (!state.singleConnection[meta.arg]) {
        state.singleConnection[meta.arg] = generateSingleConnectionState();
      }
      state.singleConnection[meta.arg].loading = true;
      state.singleConnection[meta.arg].error = false;
    });
    builder.addCase(fetchSingleConnections.fulfilled, (state, { payload, meta }) => {
      if (!state.singleConnection[meta.arg]) {
        state.singleConnection[meta.arg] = generateSingleConnectionState();
      }
      state.singleConnection[meta.arg].loading = false;
      state.singleConnection[meta.arg].error = false;
      state.singleConnection[meta.arg].content = payload;
    });

    builder.addCase(fetchSingleConnections.rejected, (state, { meta }) => {
      if (!state.singleConnection[meta.arg]) {
        state.singleConnection[meta.arg] = generateSingleConnectionState();
      }
      state.singleConnection[meta.arg].loading = false;
      state.singleConnection[meta.arg].error = true;
    });

    builder.addCase(updateConnectionsFiltersAndRefetchSummaries.fulfilled, (state, { payload }) => {
      state.allConnections.filters = payload;
    });

    builder.addCase(fetchHttpEndpoints.pending, (state) => {
      state.httpEndpoints.loading = true;
      state.httpEndpoints.error = false;
    });
    builder.addCase(fetchHttpEndpoints.fulfilled, (state, { payload }) => {
      state.httpEndpoints.loading = false;
      state.httpEndpoints.error = false;
      state.httpEndpoints.content = payload.data;
      state.httpEndpoints.total = payload.totalCount;
    });
    builder.addCase(fetchHttpEndpoints.rejected, (state) => {
      state.httpEndpoints.loading = false;
      state.httpEndpoints.error = true;
    });
    builder.addCase(updateHttpEndpointsFiltersAndRefetchSummaries.fulfilled, (state, { payload }) => {
      state.httpEndpoints.filters = payload;
    });

    builder.addCase(tagConnections.fulfilled, (state, { payload }) => {
      const { connectionIds, tagNames } = payload;
      const tagsInfo = tagNames.map((name) => ({ name, explicit: true }));

      if (state.allConnections.content) {
        const updatedConnections = state.allConnections.content.map((connection) => {
          if (connectionIds.includes(connection.id)) {
            return {
              ...connection,
              tags: uniq([...tagNames.map((t) => ({ name: t })), ...connection.tags]),
              tagsInfo: uniqBy([...tagsInfo, ...connection.tagsInfo], (tag) => tag.name),
            };
          }
          return connection;
        });
        state.allConnections.content = updatedConnections;
      }

      if (state.singleConnection[connectionIds[0]]) {
        state.singleConnection[connectionIds[0]].content.connection.tags = uniq([
          ...tagNames.map((name) => ({ name, explicit: true })),
          ...state.singleConnection[connectionIds[0]].content.connection.tags,
        ]);
      }
    });
    builder.addCase(untagConnections.fulfilled, (state, { payload }) => {
      const { connectionIds, tagNames } = payload;

      if (state.allConnections.content) {
        const updatedDataSources = state.allConnections.content.map((connection) => {
          if (connectionIds.includes(connection.id)) {
            return {
              ...connection,
              tagsInfo: connection.tagsInfo.filter((tag) => !tagNames.includes(tag.name)),
              tags: connection.tags.filter((tag) => !tagNames.includes(tag.name)),
            };
          }
          return connection;
        });
        state.allConnections.content = updatedDataSources;
      }

      if (state.singleConnection[connectionIds[0]]) {
        state.singleConnection[connectionIds[0]].content.connection.tags = state.singleConnection[
          connectionIds[0]
        ].content.connection.tags.filter((tag) => !tagNames.includes(tag.name));
      }
    });

    builder.addCase(tagDataSources.fulfilled, (state, { payload }) => {
      const { dataIds, tagNames, connectionId } = payload;
      const tagsInfo = tagNames.map((name) => ({ name, explicit: true }));
      const updatedConnections = state.singleConnection[connectionId].content.data.map((data) => {
        if (dataIds.includes(data.id)) {
          return {
            ...data,
            tags: uniq([...tagsInfo, ...data.tags]),
          };
        }
        return data;
      });
      state.singleConnection[connectionId].content.data = updatedConnections;
    });
    builder.addCase(untagDataSources.fulfilled, (state, { payload }) => {
      const { dataIds, tagNames, connectionId } = payload;
      const updatedDataSources = state.singleConnection[connectionId].content.data.map((data) => {
        if (dataIds.includes(data.id)) {
          return {
            ...data,
            tags: data.tags.filter((tag) => !tagNames.includes(tag.name)),
          };
        }
        return data;
      });
      state.singleConnection[connectionId].content.data = updatedDataSources;
    });
  },

  reducers: {},
});

// this is for configureStore
export default connectionSlice.reducer;
