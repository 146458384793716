import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNotificationChannelError,
  createNotificationChannelLoading,
  editNotificationChannelError,
  editNotificationChannelLoading,
  getNotificationChannelById,
} from '../../../redux/selector/notificationSelector';
import { createNotificationChannel, updateNotificationChannel } from '../../../redux/slicers/notificationsSlicer';
import ActionModal from '../../../components/modal/ActionModal';
import { ChannelFields } from './NotificationConsts';
import NotificationInputField from './NotificationInputContainer';

export default function NotificationChannelEditModeModal({ modalOpen, closeModal, channelType, isNew, channelId }) {
  const { t } = useTranslation(['settings']);
  const { user } = useAuth0();
  const dispatch = useDispatch();

  const loading = useSelector(isNew ? createNotificationChannelLoading : editNotificationChannelLoading);
  const error = useSelector(isNew ? createNotificationChannelError : editNotificationChannelError);
  const channel = useSelector(getNotificationChannelById(channelId));

  const fields = ChannelFields[channelType || channel?.type]?.fields || [];

  const [fieldValues, setFieldValues] = useState({});
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    const newFieldValues = {};
    fields.forEach((field) => {
      newFieldValues[field.name] = channel?.[field.backendField] || '';
    });
    setFieldValues(newFieldValues);
  }, [channel]);

  const channelTypeText = t(`notifications.channelsTable.types.${ChannelFields[channelType || channel?.type]?.name}`);
  const dispatchAction = isNew ? createNotificationChannel : updateNotificationChannel;

  const toastTitle = t(`notifications.channelsTable.${isNew ? 'create' : 'edit'}${error ? 'Failed' : 'Success'}`, {
    name: channel?.name,
  });

  function validateFields() {
    let hasErrors = false;
    const newFieldErrors = {};
    fields.forEach((field) => {
      if (field.required && !fieldValues[field.name]?.trim()) {
        // check if required field is empty
        newFieldErrors[field.name] = t('notifications.channelsTable.create.errors.requiredField');
        hasErrors = true;
      } else if (field.type === 'email' && !fieldValues[field.name].match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        // check if email field is invalid
        newFieldErrors[field.name] = t('notifications.channelsTable.create.errors.invalidEmail');
        hasErrors = true;
      }
    });
    setFieldErrors(newFieldErrors);

    return hasErrors;
  }

  async function onApprove() {
    const hasErrors = validateFields();

    const channelFieldValues = fields.reduce(
      (acc, field) => ({
        ...acc,
        [field.backendField]: fieldValues[field.name],
      }),
      {},
    );

    if (!hasErrors) {
      await dispatch(
        dispatchAction({
          channelId,
          body: {
            auditUserName: user.name,
            auditUserEmail: user.email,
            type: channelType,
            ...channelFieldValues,
          },
        }),
      );
    }

    return !hasErrors;
  }

  return (
    <ActionModal
      id="create-notification-channel-modal"
      title={t(`notifications.channelsTable.${isNew ? 'create' : 'edit'}.title`, {
        type: channelTypeText,
        name: channel?.name,
      })}
      onApprove={onApprove}
      loading={loading}
      open={modalOpen}
      closeModal={() => {
        setFieldValues({});
        setFieldErrors({});
        closeModal();
      }}
      toastSuccessful={!error}
      toastTitle={toastTitle}
    >
      <div>
        {fields.map((field) => (
          <NotificationInputField
            name={t(`notifications.channelsTable.create.${field.name}`)}
            key={field.name}
            field={field}
            value={fieldValues[field.name]}
            error={fieldErrors[field.name]}
            setValue={(val) => setFieldValues({ ...fieldValues, [field.name]: val })}
          />
        ))}
      </div>
    </ActionModal>
  );
}
