import { getNotificationChannelIcon } from '../../../services/LogoService';
import { NotificationChannelType } from './NotificationConsts';

export function NotificationChannelName({ name, type }) {
  const displayName = type === NotificationChannelType.SLACK_CHANNEL ? `# ${name}` : name;

  const icon = getNotificationChannelIcon(type);
  return (
    <div className="notification-channel-name">
      <img src={icon} alt={type} />
      <div>{displayName}</div>
    </div>
  );
}
