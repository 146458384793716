import * as Color from 'color';

export const lightGreenColor = '#80D9C1';
export const lightBlueColor = '#90A9FA';
export const lightPurpleColor = '#AC9EFF';
export const lightOrangeColor = '#FDCE88';
export const lightRedColor = '#FF9A9A';

export function getColorWithOpacity(color, opacity) {
  return Color(color).alpha(opacity).rgb().string();
}

export const criticalColor = '#550A00';
export const highColor = '#F04438';
export const mediumColor = '#FB9E12';
export const lowColor = '#62708B';
export const infoColor = lightBlueColor;

export const maliciousColor = '#F04438';
export const vulnerabilityColor = '#62708B';
export const complianceColor = '#00B384';
export const governanceColor = '#FB9E12';

export const falseColor = '#F04438';
export const trueColor = '#00B384';

export const blueColor = '#2253F5';

export const blues = [
  'rgb(34, 83, 245, 1)',
  'rgba(34, 83, 245, 0.75)',
  'rgba(34, 83, 245, 0.6)',
  'rgba(34, 83, 245, 0.4)',
  '#D3DDFD',
  'rgba(34, 83, 245, 0.1)',
];
