import CircledText from './CircledText';
import { falseColor, trueColor } from '../../consts/colors';

export default function ColoredCircledBoolean({ isTrue }) {
  return (
    <CircledText
      text={isTrue ? 'True' : 'False'}
      style={{
        color: isTrue ? falseColor : trueColor,
        borderColor: isTrue ? falseColor : trueColor,
      }}
    />
  );
}
