import { useTranslation } from 'react-i18next';
import ExternalLink from '../../../components/external-link/ExternalLink';
import InformationIcon from '../../../components/information-icon/InformationIcon';

export default function ExternalResourceLink({ href }) {
  const { t } = useTranslation(['common']);

  return (
    <div className="external-resource-link">
      <ExternalLink href={href} text={t('general.appLink')} textFirst={false} />
      <InformationIcon tooltipText={t('general.externalResourceLinkTooltip')} />
    </div>
  );
}
