import OrganizationAwareLink from '../organization-aware-link/OrganizationAwareLink';
import internalLinkIcon from '../../icons/internal_link_icon.svg';
import './internal-link.scss';
import EllipsisText from '../ellipsis-text/EllipsisText';

export default function InternalLink({ url, text, onClick, replace = false, shouldEllipsis = false }) {
  return text ? (
    <div className="internal-link">
      {shouldEllipsis ? <EllipsisText text={text} /> : <span>{text}</span>}
      <OrganizationAwareLink to={url} onClick={onClick} replace={replace}>
        <img src={internalLinkIcon} alt="link" />
      </OrganizationAwareLink>
    </div>
  ) : (
    '-'
  );
}
