import { groupBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import { mapDate } from '../../../services/MapperUtils';
import { getEnvironmentName } from '../../../services/EnvironmentUtils';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import History from '../History';

export default function ApplicationHistory({ application, environments, isLoading }) {
  const { t } = useTranslation(['single-application', 'common']);

  const changesByTime = groupBy(application?.appInstances, 'lastPublishedTime');

  // The same version can be deployed simultaneously to multiple workspaces, and we want to show only one row.
  const sortedVersions = Object.entries(changesByTime)
    .sort((a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime())
    .map((entry) => entry[1].sort((a, b) => a.platformAppId.localeCompare(b.platformAppId)).at(-1));

  const historyCards = sortedVersions.map((version) => (
    <div key={version.id} className="history-card">
      <KeyValueElement title={t('activeVersion')} value={version.version} />
      <KeyValueElement title={t('general.changeTime', { ns: 'common' })} value={mapDate(version.lastPublishedTime)} />
      <KeyValueElement title={t('general.changedBy', { ns: 'common' })} value={version.lastModifiedUsername} />
      <KeyValueElement
        title={t('general.environment', { ns: 'common' })}
        value={getEnvironmentName(environments, version.environmentId)}
      />
    </div>
  ));

  return (
    <div className="application-history-container">
      <CollapseCard
        title={t('applicationHistory')}
        initialOpen={false}
        isLoading={isLoading}
        totalElementsCount={historyCards.length}
      >
        <History cards={historyCards} />
      </CollapseCard>
    </div>
  );
}
