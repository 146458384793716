import './page-header.scss';

export default function PageHeader({ title, counter }) {
  return (
    <div className="page-title" data-testid="page-title">
      <div>{title}</div>
      {!!counter && <div>{`(${counter.toLocaleString()})`}</div>}
    </div>
  );
}
