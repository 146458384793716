export const isRowSelected = (selectedRows, row) => selectedRows.includes(row.id);

export const getEnabledSelectedRows = (rows, selectedRows, isRowDisabled) =>
  rows.filter((row) => !isRowDisabled(row.original) && isRowSelected(selectedRows, row));

export const getDisabledSelectedRows = (rows, selectedRows, isRowDisabled) =>
  rows.filter((row) => isRowDisabled(row.original) && isRowSelected(selectedRows, row));

export const getPageSelectRows = (page, selectedRows) => page.filter((row) => isRowSelected(selectedRows, row));

export const removeSelectedRow = (selectedRows, row) => selectedRows.filter((id) => id !== row.id);

export const getEnabledRows = (rows, isRowDisabled) => rows.filter((row) => !isRowDisabled(row.original));
