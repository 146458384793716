import moment from 'moment/moment';
import { groupBy, chain } from 'lodash';
import { useTranslation } from 'react-i18next';
import { blueColor, trueColor } from '../../consts/colors';
import LineChart from '../../components/chart/line-chart/LineChart';
import { DAY, MONTH, WEEK } from '../../consts/timesConsts';

const DEFAULT_TIME_RANGE = WEEK;

function buildDays(createdAt, sortedInsights) {
  if (!sortedInsights.length) return [];

  const creationTime = new Date(createdAt);
  const firstDay = new Date(Math.max(creationTime.getTime(), Date.now() - MONTH));
  firstDay.setHours(0, 0, 0, 0);

  const lastClosedBy = chain(sortedInsights)
    .filter((insight) => insight.closedAt)
    .maxBy((insight) => new Date(insight.closedAt).getTime())
    .value();

  const isAggActive = sortedInsights.some((insight) => !insight.closedAt) || !lastClosedBy;
  const lastDay = isAggActive ? new Date() : new Date(lastClosedBy.closedAt);

  let nextDays;

  if (lastDay.getTime() - firstDay.getTime() < DEFAULT_TIME_RANGE) {
    nextDays = [...Array(6)].map((_, i) => new Date(firstDay.getTime() + (i + 1) * DAY));
  } else {
    const days = Math.round(Math.abs(firstDay - lastDay) / DAY);
    const gap = Math.floor(days / 6);
    nextDays = [...Array(6)].map((_, i) => new Date(firstDay.getTime() + (i + 1) * gap * DAY));
  }

  return [firstDay, ...nextDays];
}

function calculateValues(days, grouped, isProd) {
  return days
    .filter((day) => {
      const startDay = new Date(day);
      startDay.setHours(0, 0, 0, 0);
      return startDay.getTime() <= new Date().getTime();
    })
    .map((day) => {
      const numberOfActiveInsights = (grouped[isProd] || []).filter((insight) => {
        const isActive = !insight.closedAt;

        return isActive || new Date(insight.closedAt).getTime() > new Date(day).getTime() + DAY;
      }).length;

      return numberOfActiveInsights;
    });
}

export default function ResolutionWidget({ createdAt, insights = [] }) {
  const { t } = useTranslation(['common']);

  const sortedInsights = [...insights].sort(
    (a, b) => new Date(a.creationTime).getTime() - new Date(b.creationTime).getTime(),
  );
  const days = buildDays(createdAt, sortedInsights);

  const grouped = groupBy(insights, 'inProduction');
  const labels = days.map((date) => moment(date).format('DD/MM'));

  const prodValues = calculateValues(days, grouped, true);
  const devValues = calculateValues(days, grouped, false);

  const data = {
    labels,
    datasets: [
      {
        label: t('environment.production'),
        data: prodValues,
        fill: false,
        borderColor: blueColor,
        tension: 0.2,
      },
      {
        label: t('environment.development'),
        data: devValues,
        fill: false,
        borderColor: trueColor,
        tension: 0.2,
      },
    ],
  };

  return <LineChart data={data} showLegend />;
}
