import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import userIcon from '../icons/user_icon.svg';
import groupIcon from '../icons/group_icon.svg';
import IconWithText from '../../../components/icon-text/IconWithText';
import ConnectionStatus from '../ConnectionStatus';
import { mapDate } from '../../../services/MapperUtils';
import { ShareType } from '../ConnectionShareType';

export default function ConnectionUserTable({ shareType, isLoading, users = [] }) {
  const { t } = useTranslation(['common', 'single-connection']);
  const tableName = t(`connectionUsers.title.${(shareType || ShareType.Shared).toLowerCase()}`, {
    ns: 'single-connection',
  });
  const isImplicit = shareType === ShareType.Implicit;

  const columns = useMemo(() => {
    const nameColumn = {
      Header: t('general.name', { ns: 'common' }),
      accessor: isImplicit ? 'person.name' : 'name',
      width: '15%',
      Cell: ({ value, cell }) => {
        const userType = isImplicit ? cell.row.original.person.type : cell.row.original.type;
        return <IconWithText text={value} img={userType === 'User' ? userIcon : groupIcon} />;
      },
    };

    const emailColumn = {
      Header: t('users.emailAddress', { ns: 'common' }),
      accessor: shareType === ShareType.Implicit ? 'person.email' : 'email',
      width: '30%',
      Cell: ({ value }) => <div>{value || t('general.na', { ns: 'common' })}</div>,
    };

    const permissionColumn = {
      Header: t('connectionUsers.levelOfSharing', { ns: 'single-connection' }),
      accessor: 'permission',
      width: '15%',
    };

    const statusColumn = {
      Header: t('general.status', { ns: 'common' }),
      accessor: 'status',
      width: '15%',
      Cell: ({ value }) => <ConnectionStatus connectedStatus={value} />,
    };

    const environmentColumn = {
      Header: t('general.environment', { ns: 'common' }),
      accessor: 'environmentName',
      width: '15%',
    };

    const creationTimeColumn = {
      Header: t('general.creationTime', { ns: 'common' }),
      accessor: 'creationTime',
      width: '15%',
      Cell: ({ value }) => <div>{mapDate(value)}</div>,
    };

    const flowColumn = {
      Header: t('applications.flow', { ns: 'common' }),
      accessor: 'flowName',
      width: '30%',
    };

    const baseColumns = [nameColumn, emailColumn];

    switch (shareType) {
      case ShareType.Implicit:
        return [...baseColumns, flowColumn];
      case ShareType.None:
        return [...baseColumns, environmentColumn, creationTimeColumn, statusColumn];
      case ShareType.Shared:
      default:
        return [...baseColumns, permissionColumn];
    }
  }, [shareType]);

  const sortBy = [{ id: 'name', desc: false }];

  return (
    <div className="card-view-table-container">
      <CollapseCard title={tableName} isLoading={isLoading} totalElementsCount={users.length} initialOpen>
        <Table
          columns={columns}
          data={users}
          totalCount={users.length}
          options={{ showFilter: false, showPagination: true, showSort: true, usePaginationSearchParam: false }}
          type="users"
          sortBy={sortBy}
          initialFilters={[]}
          customFilters={[]}
          isRowDisabled={() => false}
          getElementId={(user) =>
            `${user.flowName}-${user.id}-${user.platformConnectionId}-${user.connectionInfoId}-${user.person?.id}`
          }
          isLoading={isLoading}
          classNames="card-view-table"
          backendPagination={false}
          pageSize={5}
          emptyState={
            <TableEmptyContent
              img={emptyApplicationsImg}
              mainText={t('connectionUsers.noData', { ns: 'single-connection' })}
            />
          }
        />
      </CollapseCard>
    </div>
  );
}
