import classnames from 'classnames';
import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function TablePagination({
  page,
  pageCount,
  gotoPage,
  showSelectedRows,
  selectedRowsCount,
  clearSelectedRows,
  isLoading,
}) {
  const { t } = useTranslation(['common']);

  const prevDisabled = isLoading || page === 0;
  const nextDisabled = isLoading || pageCount === 0 || page === pageCount - 1;

  return (
    <>
      {showSelectedRows ? (
        <div className="selected-rows">
          <div className="text">{t('tables.rowsSelected', { count: selectedRowsCount })}</div>
          <div className={`clear ${!selectedRowsCount ? 'hide' : ''}`} onClick={() => clearSelectedRows(true)}>
            {t('general.clear')}
          </div>
        </div>
      ) : (
        <div />
      )}
      <Pagination
        hidePrevButton
        disabled={isLoading}
        hideNextButton
        className="center"
        shape="rounded"
        count={pageCount || 1}
        page={page + 1}
        onChange={(e, pageNumber) => gotoPage(pageNumber - 1, e.target)}
      />
      <div className="pagination-button-container">
        <span
          className={classnames('pagination-button', {
            disabled: prevDisabled,
          })}
          onClick={() => {
            if (!prevDisabled) {
              gotoPage(page - 1);
            }
          }}
        >
          &#8592; {t('general.back')}
        </span>
        <span
          className={classnames('pagination-button', {
            disabled: nextDisabled,
          })}
          onClick={() => {
            if (!nextDisabled) {
              gotoPage(page + 1);
            }
          }}
        >
          {t('general.next')} &#8594;
        </span>
      </div>
    </>
  );
}
