import { max } from 'lodash';
import { Line } from 'react-chartjs-2';
import { getHtmlLegendPlugin } from '../chartLegends';

import './line-chart.scss';
import { mapChartTick } from '../../../services/MapperUtils';

const SHOW_ABOVE_FACTOR = 1.2;
export default function LineChart({ data, id = '', showLegend = true, showScales = true, tooltip = {} }) {
  const legendContainerId = `${id}-legend-container`;

  const plugins = [];
  if (showLegend) {
    const legendPlugin = getHtmlLegendPlugin({
      backgroundColorProp: 'strokeStyle',
    });
    plugins.push(legendPlugin);
  }

  return (
    <div className="line-chart">
      <div id={legendContainerId} data-testid={legendContainerId} className="legend-container" />
      <div className="chart-container">
        <Line
          id={`line-chart-${id}`}
          plugins={plugins}
          data={data}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            elements: {
              point: {
                radius: 1,
              },
            },
            scales: {
              y: {
                display: showScales,
                min: 0,
                max: Math.round(max(data.datasets.map((dataset) => dataset.data).flat(2)) * SHOW_ABOVE_FACTOR),
                ticks: {
                  callback: (value) => {
                    const decimalDigits = value > 999 || (value > 0 && value < 10 && value % 1 !== 0) ? 1 : 0;
                    let suffix = '';
                    let finalValue = Number(value);
                    if (value > 999) {
                      finalValue /= 1000;
                      suffix = 'K';
                    }
                    return `${finalValue.toFixed(decimalDigits)}${suffix}`;
                  },
                },
              },
              x: {
                display: showScales,
                ticks: {
                  display: true,
                  callback: (value, index) => mapChartTick(data.labels[index]),
                },
                grid: {
                  display: false,
                },
              },
            },
            plugins: {
              tooltip: {
                enabled: true,
                ...tooltip,
              },
              legend: {
                display: false,
              },
              htmlLegend: {
                legendContainerId,
              },
            },
          }}
        />
      </div>
    </div>
  );
}
