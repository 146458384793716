import { useTranslation } from 'react-i18next';
import ColoredBox from './ColoredBox';

function getColor(severity) {
  switch (severity) {
    case 'Critical':
      return 'brown';
    case 'High':
      return 'red';
    case 'Medium':
      return 'orange';
    case 'Low':
      return 'gray';
    case 'Info':
      return 'blue';
    default:
      return 'gray';
  }
}

export default function SeverityBox({ severity, isFilled = true, hasBorder = false, style }) {
  const { t } = useTranslation('common');
  return (
    <ColoredBox
      text={t(`insights.${severity.toLowerCase()}`)}
      isFilled={isFilled}
      hasBorder={hasBorder}
      color={getColor(severity)}
      className="severity-box"
      style={style}
    />
  );
}
