import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDevelopersInsights } from '../../redux/slicers/usersSlicer';
import { getDevelopersInsights, getDevelopersInsightsLoading } from '../../redux/selector/userSelector';
import { usersSinceOptions } from './UsersConsts';
import Loader from '../../components/loader/Loader';
import DropdownBox from '../../components/dropdown-box/DropdownBox';
import EllipsisText from '../../components/ellipsis-text/EllipsisText';

export default function DeveloperInsights({ options = [] }) {
  const { t } = useTranslation(['common']);
  const loading = useSelector(getDevelopersInsightsLoading);

  const [since, setSince] = useState(usersSinceOptions.LAST_MONTH);
  const dispatch = useDispatch();

  useEffect(() => {
    (async function fetchData() {
      await dispatch(fetchDevelopersInsights(since));
    })();
  }, [since]);

  const developerInsights = useSelector(getDevelopersInsights);
  const developersToShow = [...developerInsights].sort((a, b) => b.counter - a.counter).slice(0, 4);

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="developer-insights">
      <DropdownBox
        width={240}
        text=""
        value={t(`times.${since}`)}
        selectedId={since}
        menuItems={options.map((option) => ({
          id: option,
          onClick: () => setSince(option),
          text: t(`times.${option}`),
        }))}
      />
      <div key="titles" className="developer-row light-text">
        <div>{t('general.name')}</div>
        <div>{t('insights.insights')}</div>
      </div>
      {developersToShow.map((developer) => (
        <div key={developer.id} className="developer-row">
          <div className="developer-name">
            <div>{developer.name}</div>
            <EllipsisText text={developer.email} className="light-text" />
          </div>
          <div className="developer-counter">
            <div>{developer.counter}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
