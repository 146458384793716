import { Chip } from '@mui/material';
import classnames from 'classnames';
import { Tooltip } from 'react-tooltip';
import './circled-text.scss';

export default function CircledText({
  text,
  className,
  wrapperClassNames = '',
  isDark = false,
  tooltip = {},
  onDelete = undefined,
}) {
  const chipClassName = classnames('chip-content', className, { dark: isDark });
  const wrapperClassName = classnames('chip-wrapper', wrapperClassNames);

  return (
    <div className={wrapperClassName} id={tooltip.anchorId}>
      <Chip label={text} className={chipClassName} onDelete={onDelete} variant="outlined" />
      {tooltip.show && (
        <Tooltip anchorId={tooltip.anchorId}>
          {tooltip.content.split('\n').map((tag) => (
            <div key={tag}>{tag}</div>
          ))}
        </Tooltip>
      )}
    </div>
  );
}
