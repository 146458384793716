import './icon-text.scss';

export default function IconWithText({ text, img }) {
  return (
    <div className="icon-text">
      <img src={img} alt={text} />
      <span>{text}</span>
    </div>
  );
}
