import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getNewDevelopers, getNewDevelopersLoading } from '../../redux/selector/userSelector';
import LineChart from '../../components/chart/line-chart/LineChart';
import { blueColor } from '../../consts/colors';
import Loader from '../../components/loader/Loader';
import { fetchNewDevelopers } from '../../redux/slicers/usersSlicer';

export default function NewDevelopers() {
  const newDevelopers = useSelector(getNewDevelopers);
  const loading = useSelector(getNewDevelopersLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    (async function fetchData() {
      await dispatch(fetchNewDevelopers());
    })();
  }, []);

  const labels = newDevelopers.map((developer) => developer.date);

  const data = {
    labels,
    datasets: [
      {
        label: 'New Developers',
        data: newDevelopers.map((developer) => developer.count),
        fill: false,
        borderColor: blueColor,
        tension: 0.2,
      },
    ],
  };

  return loading ? <Loader /> : <LineChart data={data} showLegend={false} />;
}
