import { useSelector } from 'react-redux';
import * as moment from 'moment';
import { meanBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/card/Card';
import LineChart from '../../../components/chart/line-chart/LineChart';
import { lightGreenColor, lightOrangeColor } from '../../../consts/colors';
import { DAY } from '../../../consts/timesConsts';
import { getHomepageDynamicMttr, isHomepageDynamicInsightsLoading } from '../../../redux/selector/homepageSelector';

function groupInsights(labels, insights, slidingWindowSize) {
  const gap = slidingWindowSize * DAY;
  return labels.map((date) =>
    insights.filter((insight) => {
      const closedTime = new Date(insight.lastChangeTime).getTime();
      const lowerBoundary = new Date(date).getTime() - gap;
      const upperBoundary = new Date(date).getTime();
      return closedTime >= lowerBoundary && closedTime <= upperBoundary;
    }),
  );
}

function getValues(labels, insights, slidingWindowSize) {
  const resolvedGroupedByDate = groupInsights(labels, insights, slidingWindowSize);
  return resolvedGroupedByDate.map((insightsGroup) => (insightsGroup.length ? meanBy(insightsGroup, 'mttr') : 0));
}

function getInsights(insights, type) {
  return insights
    .filter((i) => i.status.toLowerCase().includes(type))
    .map((i) => ({
      ...i,
      mttr: (new Date(i.lastChangeTime).getTime() - new Date(i.creationTime).getTime()) / DAY,
    }));
}

export default function InsightMTTR({ labels = [], slidingWindowSize = 30 }) {
  const loading = useSelector(isHomepageDynamicInsightsLoading);
  const insights = useSelector(getHomepageDynamicMttr);
  const { t } = useTranslation(['homePage', 'common']);

  const resolvedInsight = getInsights(insights, 'resolved');
  const acknowledgedInsight = getInsights(insights, 'acknowledged');

  const resolvedValues = getValues(labels, resolvedInsight, slidingWindowSize);
  const acknowledgedValues = getValues(labels, acknowledgedInsight, slidingWindowSize);

  const data = {
    labels: labels.map((label) => moment(label).format('DD/MM')),
    datasets: [
      {
        label: t('insights.resolved', { ns: 'common' }),
        data: resolvedValues,
        borderColor: lightGreenColor,
        width: '2px',
        tension: 0.2,
      },
      {
        label: t('insights.acknowledged', { ns: 'common' }),
        data: acknowledgedValues,
        borderColor: lightOrangeColor,
        width: '2px',
        tension: 0.2,
      },
    ],
  };

  return (
    <Card
      title={t('securityWidget.insightMTTR')}
      loading={loading}
      tooltip={t('securityWidget.insightMTTRTooltip', { slidingWindowSize })}
    >
      <div className="chart-wrapper">
        <LineChart data={data} id="mttr" />
      </div>
    </Card>
  );
}
