import { useCallback, useEffect, useState } from 'react';
import { compact, debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Filters from '../filter/Filters';
import ScheduleReport from '../ScheduleReport';
import ExportButton from '../buttons/export-button/ExportButton';
import { CONTAINS } from '../filter/filterConsts';
import SearchInput from '../search-input/SearchInput';

export default function TableOptions({
  showFilter,
  customFilters,
  excludeFilters,
  columns,
  getFiltersSelector,
  handleFiltersChange,
  contextualActions,
  exportPath,
  exportFileName,
  type,
  selectedRows,
  clearSelectedRows,
  mapFilterValue,
  mapFilterFunction,
  rows,
  extraCsvFilters,
  defaultSearchColumn,
  defaultSearchColumnName,
  extraTableOptions,
  getColumnOptions,
  backendPagination,
  showSelectAll,
  selectAllRows,
  isAllSelected,
}) {
  function handleFilterChangeAndClearSelectedRows(...args) {
    handleFiltersChange(...args);
    clearSelectedRows(true);
  }

  const { t } = useTranslation(['common']);
  const filters = showFilter ? useSelector(getFiltersSelector) : [];
  const [searchValue, setSearchValue] = useState('');

  const debouncedUpdateFilters = useCallback(
    debounce((value) => {
      const newFilters = filters.filter(
        (filter) => !(filter.column === defaultSearchColumn && filter.type === CONTAINS),
      );

      const trimmedValue = value?.toString().trim();

      if (trimmedValue) {
        newFilters.push({
          column: defaultSearchColumn,
          type: CONTAINS,
          value: trimmedValue,
        });
      }
      handleFilterChangeAndClearSelectedRows(newFilters);
    }, 600),
    [filters],
  );

  function getRowById(id) {
    return rows.find((row) => row.id === id);
  }

  // Clear search value when search filter is removed
  useEffect(() => {
    const searchFilter = filters.find((filter) => filter.column === defaultSearchColumn && filter.type === CONTAINS);
    if (!searchFilter && !!searchValue && backendPagination) {
      setSearchValue('');
    }
  }, [filters]);

  const filtersColumns = [...(customFilters || []), ...columns].filter(
    (filter) => !(excludeFilters || []).includes(filter.backendColumn),
  );

  return (
    <div className="table-options">
      {showFilter && (
        <Filters
          tableName={type}
          columns={filtersColumns}
          getColumnOptions={getColumnOptions}
          getFiltersSelector={getFiltersSelector}
          handleFiltersChange={handleFilterChangeAndClearSelectedRows}
        />
      )}
      <div className="table-header-right">
        {extraTableOptions}
        {defaultSearchColumn && (
          <SearchInput
            placeholder={t('tables.searchByName', { name: defaultSearchColumnName })}
            value={searchValue}
            setValue={(val) => {
              setSearchValue(val);
              debouncedUpdateFilters(val);
            }}
          />
        )}
        {contextualActions(compact(selectedRows.map((rowId) => getRowById(rowId)?.original)))}
        {exportPath && (
          <div className="table-header-right">
            <ScheduleReport getFiltersSelector={getFiltersSelector} type={type} />
            <ExportButton
              extraCsvFilters={extraCsvFilters}
              mapFilterValue={mapFilterValue}
              mapFilterFunction={mapFilterFunction}
              exportPath={exportPath}
              getFiltersSelector={getFiltersSelector}
              filename={exportFileName}
            />
          </div>
        )}
      </div>
      {showSelectAll && (
        <div className="select-all">
          <span className="text" onClick={isAllSelected ? clearSelectedRows : selectAllRows}>
            {isAllSelected ? t('tables.clearSelection') : t('tables.selectAll')}
          </span>
        </div>
      )}
    </div>
  );
}
