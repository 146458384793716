import { useTranslation } from 'react-i18next';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import Tags from '../../../components/tags/Tags';
import BooleanValue from '../../../components/boolean-value/BooleanValue';
import { renderLinkOrZero } from '../../../services/utils';
import { mapDate } from '../../../services/MapperUtils';

export default function UserDetails({ user }) {
  const { t } = useTranslation(['common', 'single-user']);

  if (!user) return null;

  const lastActivity = user.lastActivity ? mapDate(user.lastActivity) : t('general.na', { ns: 'common' });
  const creationTime = user.creationTime ? mapDate(user.creationTime) : t('general.na', { ns: 'common' });
  const environmentNames = user.environmentNames?.map((tag) => ({ name: tag.trim(), isDark: false }));
  const envTags = environmentNames?.length ? (
    <Tags tags={environmentNames} tooltipAnchor="user-details" />
  ) : (
    t('general.na')
  );

  return (
    <div className="user-data">
      <KeyValueElement title={t('users.userName', { ns: 'common' })} value={user.name} />
      <KeyValueElement title={t('users.userEmail', { ns: 'common' })} value={user.email} />
      <KeyValueElement title={t('userDetails.lastActivity', { ns: 'single-user' })} value={lastActivity} />
      <KeyValueElement title={t('userDetails.dateOfUserCreation', { ns: 'single-user' })} value={creationTime} />
      <KeyValueElement title={t('general.environments', { ns: 'common' })} value={envTags} />
      <KeyValueElement
        title={t('applications.apps', { ns: 'common' })}
        value={renderLinkOrZero(user.applicationsCount, `/applications?ownerId:is=${user.id}`)}
      />
      <KeyValueElement
        title={t('connections.connections', { ns: 'common' })}
        value={renderLinkOrZero(user.connectionsCount, `/data?tab=Connections&ownerId:is=${user.id}`)}
      />
      <KeyValueElement
        title={t('insights.insights', { ns: 'common' })}
        value={renderLinkOrZero(user.insightsCount, `/insights?ownerId:is=${user.id}&resolved:is=False`)}
      />
      <KeyValueElement title={t('general.active', { ns: 'common' })} value={<BooleanValue isTrue={user.active} />} />
      <KeyValueElement title={t('general.guest', { ns: 'common' })} value={<BooleanValue isTrue={user.guest} />} />
    </div>
  );
}
