/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGet, doPost } from '../../services/HttpService';
import {
  enrichPowerBiFilters,
  getPowerBiBackendKeyForOrderBy,
  mapPropertiesToParams,
} from '../../services/MapperUtils';
import { INTERNAL_SERVER_ERROR } from '../../consts/erros';

const initialState = {
  resources: {
    loading: false,
    error: false,
    content: [],
    filters: [],
    total: 0,
    filtersOptions: {},
  },
  creationTrend: {
    content: [],
    loading: false,
    error: false,
  },
  activeDevelopers: {
    content: [],
    loading: false,
    error: false,
  },
  publicReports: {
    public: 0,
    total: 0,
    loading: false,
    error: false,
  },
  enrichment: {
    loading: false,
    error: false,
    success: false,
  },
  embedCodeEnrichment: {
    loading: false,
    error: false,
    success: false,
  },
  reportEnrichmentStatus: {
    content: {},
    publicReports: {},
    sharedReports: {},
    loading: false,
    error: false,
  },
};

export const enrichPowerBiEmbedCode = createAsyncThunk(
  'powerBi/enrichPowerBiEmbedCode',
  async ({ file, accountId, auditUserEmail, auditUserName }) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('accountId', accountId);
    formData.append('auditUserEmail', auditUserEmail);
    formData.append('auditUserName', auditUserName);

    try {
      const res = await doPost(`bi/enrich-embed-code-power-bi`, formData);

      return res.data;
    } catch (e) {
      const error = {
        message: e.status === 500 ? INTERNAL_SERVER_ERROR : e.message,
      };
      throw error;
    }
  },
);

export const enrichPowerBiShareWithEntireOrg = createAsyncThunk(
  'powerBi/enrichPowerBiShareWithEntireOrg',
  async ({ body }) => {
    try {
      const res = await doPost(`bi/enrich-shared-with-org-power-bi`, body);
      return res.data;
    } catch (e) {
      const error = {
        message: e.status === 500 ? INTERNAL_SERVER_ERROR : e.message,
      };
      throw error;
    }
  },
);

export const fetchPowerBiData = createAsyncThunk(
  'powerBi/fetchPowerBiData',
  async ({ filters, orderBy, order, page }) => {
    const params = mapPropertiesToParams(filters, orderBy, order, page);

    const res = await doGet(`bi/summary?${params.toString()}`);

    return res.data;
  },
);

export const updatePowerBiFiltersAndRefetchSummaries = createAsyncThunk(
  'powerBi/updatePowerBiFiltersAndRefetchSummaries',
  async ({ filters, orderBy, order, page }, { dispatch }) => {
    const enrichedFilters = enrichPowerBiFilters(filters);
    const enrichedOrderBy = getPowerBiBackendKeyForOrderBy(orderBy);

    dispatch(fetchPowerBiData({ filters: enrichedFilters, orderBy: enrichedOrderBy, order, page }));
    return enrichedFilters;
  },
);

export const fetchPowerBiFiltersOptions = createAsyncThunk('powerBi/fetchPowerBiFiltersOptions', async () => {
  const res = await doGet(`bi/summary/filters`);

  return res.data;
});

export const fetchPublicReports = createAsyncThunk('powerBi/fetchPublicReports', async () => {
  const res = await doGet(`bi/public-reports`);

  return res.data;
});

export const fetchReportsTrend = createAsyncThunk('powerBi/fetchReportsTrend', async () => {
  const res = await doGet(`bi/reports-trend`);

  return res.data;
});

export const fetchActiveDevelopers = createAsyncThunk('powerBi/fetchActiveDevelopers', async () => {
  const res = await doGet(`bi/reports-users`);

  return res.data;
});

export const fetchEnrichmentStatus = createAsyncThunk('powerBi/fetchEnrichmentStatus', async () => {
  const res = await doGet(`bi/enrichment-status`);

  return res.data;
});

export const powerBiSlice = createSlice({
  name: 'bi',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPowerBiData.pending, (state) => {
      state.resources.loading = true;
      state.resources.error = false;
    });
    builder.addCase(fetchPowerBiData.fulfilled, (state, { payload }) => {
      state.resources.loading = false;
      state.resources.error = false;
      state.resources.content = payload.data;
      state.resources.total = payload.totalCount;
    });
    builder.addCase(fetchPowerBiData.rejected, (state) => {
      state.resources.loading = false;
      state.resources.error = true;
    });
    builder.addCase(fetchPowerBiFiltersOptions.fulfilled, (state, { payload }) => {
      state.resources.filtersOptions = payload;
    });
    builder.addCase(updatePowerBiFiltersAndRefetchSummaries.fulfilled, (state, { payload }) => {
      state.resources.filters = payload;
    });
    builder.addCase(fetchPublicReports.pending, (state) => {
      state.publicReports.loading = true;
      state.publicReports.error = false;
    });
    builder.addCase(fetchPublicReports.fulfilled, (state, { payload }) => {
      state.publicReports.loading = false;
      state.publicReports.error = false;
      state.publicReports.public = payload.publicCount;
      state.publicReports.total = payload.totalCount;
    });
    builder.addCase(fetchPublicReports.rejected, (state) => {
      state.publicReports.loading = false;
      state.publicReports.error = true;
    });
    builder.addCase(fetchReportsTrend.pending, (state) => {
      state.creationTrend.loading = true;
      state.creationTrend.error = false;
    });
    builder.addCase(fetchReportsTrend.fulfilled, (state, { payload }) => {
      state.creationTrend.loading = false;
      state.creationTrend.error = false;
      state.creationTrend.content = payload;
    });
    builder.addCase(fetchReportsTrend.rejected, (state) => {
      state.creationTrend.loading = false;
      state.creationTrend.error = true;
    });
    builder.addCase(fetchActiveDevelopers.pending, (state) => {
      state.activeDevelopers.loading = true;
      state.activeDevelopers.error = false;
    });
    builder.addCase(fetchActiveDevelopers.fulfilled, (state, { payload }) => {
      state.activeDevelopers.loading = false;
      state.activeDevelopers.error = false;
      state.activeDevelopers.content = payload;
    });
    builder.addCase(fetchActiveDevelopers.rejected, (state) => {
      state.activeDevelopers.loading = false;
      state.activeDevelopers.error = true;
    });
    builder.addCase(enrichPowerBiShareWithEntireOrg.pending, (state) => {
      state.enrichment.loading = true;
      state.enrichment.error = false;
    });
    builder.addCase(enrichPowerBiShareWithEntireOrg.fulfilled, (state) => {
      state.enrichment.loading = false;
      state.enrichment.error = false;
      state.enrichment.success = true;
    });
    builder.addCase(enrichPowerBiShareWithEntireOrg.rejected, (state) => {
      state.enrichment.loading = false;
      state.enrichment.error = true;
      state.enrichment.success = false;
    });

    builder.addCase(enrichPowerBiEmbedCode.pending, (state) => {
      state.embedCodeEnrichment.loading = true;
      state.embedCodeEnrichment.error = false;
    });
    builder.addCase(enrichPowerBiEmbedCode.fulfilled, (state) => {
      state.embedCodeEnrichment.loading = false;
      state.embedCodeEnrichment.error = false;
      state.embedCodeEnrichment.success = true;
    });
    builder.addCase(enrichPowerBiEmbedCode.rejected, (state) => {
      state.embedCodeEnrichment.loading = false;
      state.embedCodeEnrichment.error = true;
      state.embedCodeEnrichment.success = false;
    });

    builder.addCase(fetchEnrichmentStatus.pending, (state) => {
      state.reportEnrichmentStatus.loading = true;
      state.reportEnrichmentStatus.error = false;
    });
    builder.addCase(fetchEnrichmentStatus.fulfilled, (state, { payload }) => {
      state.reportEnrichmentStatus.loading = false;
      state.reportEnrichmentStatus.error = false;
      state.reportEnrichmentStatus.publicReports = payload.publicReports;
      state.reportEnrichmentStatus.sharedReports = payload.sharedReports;
    });
    builder.addCase(fetchEnrichmentStatus.rejected, (state) => {
      state.reportEnrichmentStatus.loading = false;
      state.reportEnrichmentStatus.error = true;
    });
  },

  reducers: {
    resetEnrichmentStatus: (state) => {
      state.enrichment.loading = false;
      state.enrichment.error = false;
      state.enrichment.success = false;
    },
    resetEmbedCodeEnrichmentStatus: (state) => {
      state.embedCodeEnrichment.loading = false;
      state.embedCodeEnrichment.error = false;
      state.embedCodeEnrichment.success = false;
    },
  },
});

// this is for configureStore
export default powerBiSlice.reducer;

export const { resetEnrichmentStatus, resetEmbedCodeEnrichmentStatus } = powerBiSlice.actions;
