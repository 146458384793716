import { useTranslation } from 'react-i18next';
import Toast from './Toast';

export default function AckToast({ ackFailed, ackCompleted, setAckCompleted }) {
  const { t } = useTranslation(['insights', 'common']);

  return (
    <Toast
      isSuccess={!ackFailed}
      title={ackFailed ? t('acknowledgeFailed') : t('acknowledgeSuccess')}
      message={ackFailed ? t('general.pleaseTryAgain', { ns: 'common' }) : t('allInsightsAcknowledged')}
      open={ackCompleted}
      setOpen={setAckCompleted}
    />
  );
}
