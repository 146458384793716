import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { camelCase } from 'lodash';
import FilterValue from './FilterValue';
import Input from '../input/Input';

export function ContainsFilter({ onClose, addFilter, column, operator }) {
  const [value, setValue] = useState(null);
  const { t } = useTranslation(['common']);
  const title = t(`tables.filters.operators.${camelCase(operator)}`);

  return (
    <FilterValue title={title} onClose={onClose} onApply={() => addFilter(operator, column, value)}>
      <Input id="contains-filter" value={value} setValue={setValue} placeholder="..." type="text" />
    </FilterValue>
  );
}
