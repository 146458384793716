import classnames from 'classnames';
import Popover from '@mui/material/Popover';
import { useState } from 'react';
import { isNil } from 'lodash';
import TableCheckbox from './TableCheckbox';
import QuickFilter from './quick-filter/QuickFilter';
import { ColumnType } from './ColumnOptions';
import { isRowSelected, removeSelectedRow } from './tableUtils';

export default function TableRow({
  selectedRows,
  setSelectedRows,
  row,
  isRowDisabled,
  showRowSelection,
  getFiltersSelector,
  handleFiltersChange,
}) {
  const [openPopover, setOpenPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverCell, setPopoverCell] = useState(null);

  function isCellHasQuickFilter(cell) {
    if (![ColumnType.STRING, ColumnType.BOOLEAN].includes(cell.column.type)) {
      return false;
    }
    if (isNil(cell.value)) {
      return false;
    }

    return true;
  }

  function handlePopoverOpen(event, cell) {
    if (!isCellHasQuickFilter(cell)) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setPopoverCell(cell);
    setOpenPopover(true);
  }

  const isRowSelectedBool = isRowSelected(selectedRows, row);

  return (
    <tr
      {...row.getRowProps()}
      key={row.id}
      className={classnames('table-row', {
        selected: isRowSelectedBool,
      })}
    >
      {showRowSelection && (
        <td aria-label="checkbox" key={row.id}>
          <TableCheckbox
            checked={isRowSelectedBool}
            onChange={() => {
              if (isRowSelectedBool) {
                setSelectedRows(removeSelectedRow(selectedRows, row));
              } else {
                setSelectedRows([...selectedRows, row.id]);
              }
            }}
            disabled={isRowDisabled(row.original)}
          />
        </td>
      )}
      {row.cells.map((cell) => (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        <td
          key={cell.column.backendKey || cell.column.Header}
          className={classnames('table-cell', {
            'can-filter': isCellHasQuickFilter(cell),
          })}
          onClick={(e) => handlePopoverOpen(e, cell)}
        >
          {cell.render('Cell')}
        </td>
      ))}

      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={() => setOpenPopover(false)}
      >
        <QuickFilter
          cell={popoverCell}
          getFiltersSelector={getFiltersSelector}
          onClose={() => setOpenPopover(false)}
          handleFiltersChange={handleFiltersChange}
        />
      </Popover>
    </tr>
  );
}
