import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getHomePageInsightsBySeverity,
  isHomePageGroupedInsightsLoading,
} from '../../../redux/selector/homepageSelector';
import Card from '../../../components/card/Card';
import InsightSeverityWidget from '../../insights/InsightSeverityWidget';

export default function HomePageInsightsSeverityWidget({ isProduction, startDate }) {
  const insightsBySeverity = useSelector(getHomePageInsightsBySeverity);
  const loading = useSelector(isHomePageGroupedInsightsLoading);
  const { t } = useTranslation(['homePage']);

  return (
    <Card title={t('securityWidget.insightSeverity')}>
      <InsightSeverityWidget
        size={window.innerWidth > 1600 ? 160 : 120}
        loading={loading}
        insightsBySeverity={insightsBySeverity}
        isProduction={isProduction}
        startDate={startDate}
      />
    </Card>
  );
}
