import { POWER_PLATFORM } from '../../../../../consts/platforms';

export const AccountCredentialsFields = {
  [POWER_PLATFORM]: {
    name: 'powerPlatform',
    fields: [
      {
        name: 'Client ID',
        key: 'clientId',
        type: 'string',
        required: true,
        isUUID: true,
      },
      {
        name: 'Client Secret',
        key: 'clientSecret',
        type: 'password',
        required: true,
        isUUID: false,
      },
    ],
  },
};
