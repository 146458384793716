/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGet } from '../../services/HttpService';
import { enrichUsersFilters, getUsersBackendKeyForOrderBy, mapPropertiesToParams } from '../../services/MapperUtils';
import { usersSinceOptions } from '../../pages/users/UsersConsts';

const initialState = {
  developers: {
    loading: false,
    error: false,
    content: [],
    filters: [],
    total: 0,
    filtersOptions: {},
  },
  developer: {},
  developersActivity: {
    content: [],
    loading: false,
    error: false,
  },
  developersInsights: {
    content: [],
    loading: false,
    error: false,
  },
  newDevelopers: {
    content: [],
    loading: false,
    error: false,
  },
};

function generateSingleDeveloperState() {
  return {
    loading: false,
    error: false,
    content: undefined,
  };
}

function mapSince(since) {
  if (since === usersSinceOptions.ALL_TIME) {
    return new Date(0).toISOString();
  }
  if (since === usersSinceOptions.LAST_MONTH) {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date.toISOString();
  }
  // last year
  const date = new Date();
  date.setFullYear(date.getFullYear() - 1);
  return date.toISOString();
}

export const fetchDevelopers = createAsyncThunk('users/fetchDevelopers', async ({ filters, orderBy, order, page }) => {
  const params = mapPropertiesToParams(filters, orderBy, order, page);

  const res = await doGet(`users/summary?${params.toString()}`);

  return res.data;
});

export const fetchDeveloper = createAsyncThunk('users/fetchDeveloper', async (userId) => {
  const [devData, applications, connections, insights, roles, activity] = await Promise.all([
    doGet(`users/summary?id%5Bequals%5D=${userId}`),
    doGet(`persons/${userId}/applications`),
    doGet(`persons/${userId}/connections`),
    doGet(`persons/${userId}/insights`),
    doGet(`persons/${userId}/roles`),
    doGet(`persons/${userId}/activity`),
  ]);

  return {
    devData: devData.data.data[0],
    applications: applications.data.data,
    connections: connections.data.data,
    insights: insights.data.data,
    roles: roles.data,
    activity: activity.data,
  };
});

export const fetchDevelopersActivity = createAsyncThunk('users/fetchDevelopersActivity', async (since) => {
  const res = await doGet(`persons/developers-activity?since=${mapSince(since)}`);

  return res.data;
});

export const fetchDevelopersInsights = createAsyncThunk('users/fetchDevelopersInsights', async (since) => {
  const res = await doGet(`persons/developers-insights?since=${mapSince(since)}`);

  return res.data;
});

export const fetchNewDevelopers = createAsyncThunk('users/fetchNewDevelopers', async () => {
  const res = await doGet(`persons/new-developers`);
  return res.data;
});

export const updateDevelopersFiltersAndRefetchSummaries = createAsyncThunk(
  'users/updateDevelopersFiltersAndRefetchSummaries',
  async ({ filters, orderBy, order, page }, { dispatch }) => {
    const enrichedFilters = enrichUsersFilters(filters);
    const enrichedOrderBy = getUsersBackendKeyForOrderBy(orderBy);

    dispatch(fetchDevelopers({ filters: enrichedFilters, orderBy: enrichedOrderBy, order, page }));
    return enrichedFilters;
  },
);

export const fetchDevelopersFiltersOptions = createAsyncThunk('users/fetchDevelopersFiltersOptions', async () => {
  const res = await doGet(`users/summary/filters`);

  return res.data;
});

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchDevelopers.pending, (state) => {
      state.developers.loading = true;
      state.developers.error = false;
    });
    builder.addCase(fetchDevelopers.fulfilled, (state, { payload }) => {
      state.developers.loading = false;
      state.developers.error = false;
      state.developers.content = payload.data;
      state.developers.total = payload.totalCount;
    });
    builder.addCase(fetchDevelopers.rejected, (state) => {
      state.developers.loading = false;
      state.developers.error = true;
    });
    builder.addCase(fetchDeveloper.pending, (state, { meta }) => {
      if (!state.developer[meta.arg]) {
        state.developer[meta.arg] = generateSingleDeveloperState();
      }
      state.developer[meta.arg].loading = true;
      state.developer[meta.arg].error = false;
    });
    builder.addCase(fetchDeveloper.fulfilled, (state, { payload, meta }) => {
      if (!state.developer[meta.arg]) {
        state.developer[meta.arg] = generateSingleDeveloperState();
      }
      // const { userId, ...content } = payload;
      state.developer[meta.arg].content = payload;
      state.developer[meta.arg].loading = false;
      state.developer[meta.arg].error = false;
    });
    builder.addCase(fetchDeveloper.rejected, (state) => {
      state.developer.loading = false;
      state.developer.error = true;
    });
    builder.addCase(fetchDevelopersFiltersOptions.fulfilled, (state, { payload }) => {
      state.developers.filtersOptions = payload;
    });
    builder.addCase(updateDevelopersFiltersAndRefetchSummaries.fulfilled, (state, { payload }) => {
      state.developers.filters = payload;
    });
    builder.addCase(fetchDevelopersActivity.fulfilled, (state, { payload }) => {
      state.developersActivity.content = payload;
      state.developersActivity.loading = false;
      state.developersActivity.error = false;
    });
    builder.addCase(fetchDevelopersActivity.pending, (state) => {
      state.developersActivity.loading = true;
      state.developersActivity.error = false;
    });
    builder.addCase(fetchDevelopersActivity.rejected, (state) => {
      state.developersActivity.loading = false;
      state.developersActivity.error = true;
    });
    builder.addCase(fetchDevelopersInsights.fulfilled, (state, { payload }) => {
      state.developersInsights.content = payload;
      state.developersInsights.loading = false;
      state.developersInsights.error = false;
    });
    builder.addCase(fetchDevelopersInsights.pending, (state) => {
      state.developersInsights.loading = true;
      state.developersInsights.error = false;
    });
    builder.addCase(fetchDevelopersInsights.rejected, (state) => {
      state.developersInsights.loading = false;
      state.developersInsights.error = true;
    });
    builder.addCase(fetchNewDevelopers.fulfilled, (state, { payload }) => {
      state.newDevelopers.content = payload;
      state.newDevelopers.loading = false;
      state.newDevelopers.error = false;
    });
    builder.addCase(fetchNewDevelopers.pending, (state) => {
      state.newDevelopers.loading = true;
      state.newDevelopers.error = false;
    });
    builder.addCase(fetchNewDevelopers.rejected, (state) => {
      state.newDevelopers.loading = false;
      state.newDevelopers.error = true;
    });
  },

  reducers: {},
});

// this is for configureStore
export default usersSlice.reducer;
