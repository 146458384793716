import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import { mapDate } from '../../../services/MapperUtils';
import DeleteNotificationChannelModal from './DeleteNotificationChannelModal';
import NotificationChannelEditModeModal from './NotificationChannelEditModeModal';
import { NotificationChannelName } from './NotificationChannelName';
import { ChannelFields } from './NotificationConsts';
import EllipsisText from '../../../components/ellipsis-text/EllipsisText';
import { isPermitted } from '../../../redux/selector/authSelector';

const ModalType = Object.freeze({
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete',
});

export default function NotificationChannelTable({ channels, isLoading }) {
  const { t } = useTranslation(['settings']);

  const [newChannelMenuOpen, setNewChannelMenuOpen] = useState(false);
  const [newChannelAnchor, setNewChannelAnchor] = useState(null);
  const [newChannelType, setNewChannelType] = useState(null);
  const [modalType, setModalType] = useState(null);
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const canCreateChannel = useSelector(isPermitted('policies:create'));
  const canEditChannel = useSelector(isPermitted('policies:update'));
  const canDeleteChannel = useSelector(isPermitted('policies:delete'));

  function closeModal() {
    setModalType(null);
  }

  const columns = useMemo(
    () => [
      {
        Header: t('notifications.channelsTable.columns.name'),
        accessor: 'name',
        width: '30%',
        Cell: ({ value, cell }) => <NotificationChannelName name={value} type={cell.row.original.type} />,
        sortType(rowA, rowB) {
          const a = rowA.original.name;
          const b = rowB.original.name;
          return a.localeCompare(b);
        },
      },
      {
        Header: t('notifications.channelsTable.columns.creatorName'),
        accessor: 'creatorName',
        width: '20%',
        Cell: ({ value }) => <EllipsisText text={value} />,
      },
      {
        Header: t('notifications.channelsTable.columns.type'),
        accessor: 'type',
        width: '20%',
      },
      {
        Header: t('notifications.channelsTable.columns.creationTime'),
        accessor: 'createdAt',
        width: '20%',
        Cell: ({ value }) => (value ? mapDate(value) : '-'),
      },
      {
        Header: ' ',
        accessor: 'id',
        Cell: ({ value, cell }) => {
          if (cell.row.original.type === 'Dynamic Email Address') {
            return null;
          }
          return (
            <div className="notification-actions" data-testid={`channel-actions-${cell.row.original.name}`}>
              <EditIcon
                data-testid={`channel-edit-${cell.row.original.name}`}
                className={classnames('notification-actions-edit', { disabled: !canEditChannel })}
                onClick={() => {
                  if (!canEditChannel) {
                    return;
                  }
                  setSelectedChannelId(value);
                  setModalType(ModalType.EDIT);
                }}
              />
              <DeleteOutlineOutlinedIcon
                data-testid={`channel-delete-${cell.row.original.name}`}
                className={classnames('notification-actions-delete', { disabled: !canDeleteChannel })}
                onClick={() => {
                  if (!canDeleteChannel) {
                    return;
                  }
                  setSelectedChannelId(value);
                  setModalType(ModalType.DELETE);
                }}
              />
            </div>
          );
        },
      },
    ],
    [canEditChannel, canDeleteChannel],
  );

  const sortBy = [{ id: 'name', desc: false }];

  function handleAddChannelClick(e) {
    if (!canCreateChannel) {
      return;
    }
    setNewChannelAnchor(e.currentTarget);
    setNewChannelMenuOpen(true);
  }

  function handleNewChannelItemClick(type) {
    setNewChannelMenuOpen(false);
    setNewChannelType(type);
    setModalType(ModalType.CREATE);
  }

  return (
    <div className="notifications-settings-table" data-testid="notification-channels-table">
      <Menu
        id="new-notification-channel-menu"
        open={newChannelMenuOpen}
        anchorEl={newChannelAnchor}
        onClose={() => setNewChannelMenuOpen(false)}
      >
        {Object.keys(ChannelFields).map((type) => (
          <MenuItem onClick={() => handleNewChannelItemClick(type)} key={type} data-testid={type}>
            <NotificationChannelName name={type} type={type} />
          </MenuItem>
        ))}
      </Menu>
      <NotificationChannelEditModeModal
        modalOpen={modalType === ModalType.CREATE}
        closeModal={closeModal}
        channelType={newChannelType}
        isNew
      />
      <NotificationChannelEditModeModal
        modalOpen={modalType === ModalType.EDIT}
        closeModal={closeModal}
        channelId={selectedChannelId}
        isNew={false}
      />
      <DeleteNotificationChannelModal
        modalOpen={modalType === ModalType.DELETE}
        closeModal={closeModal}
        channelId={selectedChannelId}
      />
      <div className="add-new-item">
        <div className="add-new-item-title">{t('notifications.channels')}</div>
        <div
          className={classnames('add-new-item-button', { disabled: !canCreateChannel })}
          onClick={handleAddChannelClick}
          data-testid="add-new-channel"
        >
          + {t('notifications.channelsTable.addNew')}
        </div>
      </div>
      <Table
        columns={columns}
        data={channels}
        totalCount={channels.length}
        options={{ showFilter: false, showPagination: false, showSort: true, usePaginationSearchParam: false }}
        type="notification-channels"
        shouldScrollOnPagination={false}
        sortBy={sortBy}
        initialFilters={[]}
        customFilters={[]}
        isRowDisabled={() => false}
        getElementId={(app) => app.id}
        isLoading={isLoading}
        classNames="card-view-table"
        backendPagination={false}
        pageSize={100}
        emptyState={<TableEmptyContent img={emptyApplicationsImg} mainText={t('notifications.channelsTable.empty')} />}
      />
    </div>
  );
}
