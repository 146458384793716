import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { mapDate } from '../../services/MapperUtils';
import PersonName from '../../components/person-name/PersonName';
import BooleanValue from '../../components/boolean-value/BooleanValue';
import PageHeader from '../../components/page-header/PageHeader';
import WidgetBar from '../../components/widget-bar/WidgetBar';
import Table from '../../components/table/Table';
import TableEmptyContent from '../../components/table/TableEmptyContent';
import { fetchPowerBiFiltersOptions, updatePowerBiFiltersAndRefetchSummaries } from '../../redux/slicers/biSlicer';
import {
  getBiFilterOptions,
  getBiResources,
  getBiResourcesFilters,
  getBiResourcesLoading,
  getBiResourcesTotal,
} from '../../redux/selector/biSelector';
import PublicReports from './PublicReports';
import ReportsTrend from './ReportsTrend';
import emptyApplicationsImg from '../inventory/empty_applications.svg';

import './bi.scss';
import { ColumnType } from '../../components/table/ColumnOptions';
import EnrichDropdown from './enrich-reports/EnrichDropdown';
import { isAdmin, isSuperAdmin } from '../../redux/selector/authSelector';
import PlatformName from '../../components/platform-name/PlatformName';
import EllipsisText from '../../components/ellipsis-text/EllipsisText';

export default function PowerBiPage() {
  const dispatch = useDispatch();

  const resources = useSelector(getBiResources);
  const total = useSelector(getBiResourcesTotal);
  const loading = useSelector(getBiResourcesLoading);
  const biFilterOptions = useSelector(getBiFilterOptions);
  const admin = useSelector(isAdmin);
  const superAdmin = useSelector(isSuperAdmin);

  const { t } = useTranslation(['common', 'bi']);

  useEffect(() => {
    dispatch(fetchPowerBiFiltersOptions());
  }, []);

  const columns = useMemo(
    () => [
      {
        backendKey: 'name',
        width: '20%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.name,
      },
      {
        backendKey: 'owner',
        width: '10%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.owner,
        Cell: ({ value }) => <PersonName name={value} shouldEllipsis />,
      },
      {
        backendKey: 'accountName',
        width: '10%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.accountName,
        Cell: ({ value, cell }) => (
          <EllipsisText
            text={<PlatformName platform={cell.row.original.platformName} name={value} />}
            overrideTooltipText={value}
          />
        ),
      },
      {
        backendKey: 'creationTime',
        type: ColumnType.DATE,
        width: '10%',
        accessor: (cell) => cell.creationTime,
        Cell: ({ cell }) => <div className="gray">{cell.value ? mapDate(cell.value) : '-'}</div>,
      },
      {
        backendKey: 'lastModified',
        type: ColumnType.DATE,
        width: '10%',
        accessor: (cell) => cell.lastModified,
        Cell: ({ cell }) => <div className="gray">{cell.value ? mapDate(cell.value) : '-'}</div>,
      },
      {
        backendKey: 'workspace',
        width: '10%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.workspace,
      },
      {
        backendKey: 'public',
        type: ColumnType.BOOLEAN,
        width: '5%',
        accessor: (cell) => cell.public,
        Cell: ({ value }) => <BooleanValue isTrue={value} />,
      },
      {
        backendKey: 'sharedWithOrg',
        width: '7%',
        type: ColumnType.BOOLEAN,
        accessor: (cell) => cell.sharedWithOrg,
        Cell: ({ value }) => <BooleanValue isTrue={value} />,
      },
    ],
    [],
  );

  const sortBy = [
    {
      id: 'Last Change', // fixme: support translation
      desc: true,
    },
  ];

  return (
    <div className="bi-page">
      <PageHeader title={t('powerBIReports', { ns: 'bi' })} counter={total} />
      <WidgetBar
        boxes={[
          {
            title: t('reportsTrend', { ns: 'bi' }),
            flex: 2,
            content: <ReportsTrend />,
          },
          // {
          //   title: 'Most active developers ',
          //   flex: 2,
          //   content: <BiReportsByUser />,
          // },
          {
            title: t('numberOfReports', { ns: 'bi' }),
            flex: 1,
            content: <PublicReports />,
          },
        ]}
      />
      <Table
        columns={columns}
        data={resources}
        options={{ showFilter: true, showHeaders: true }}
        type="bi"
        sortBy={sortBy}
        exportPath="bi/summary/csv"
        exportFileName="Nokod Power BI"
        fetchDataWithFilters={(filters, orderBy, order, page) => {
          dispatch(updatePowerBiFiltersAndRefetchSummaries({ filters, orderBy, order, page }));
        }}
        getFiltersSelector={getBiResourcesFilters}
        customFilters={[]}
        contextualActions={[]}
        excludeFilters={['type']}
        onRowSelected={() => {}}
        isRowDisabled={() => false}
        getElementId={(resource) => resource.id}
        filtersOptions={biFilterOptions}
        isLoading={loading}
        totalCount={total}
        emptyState={
          <TableEmptyContent
            img={emptyApplicationsImg}
            mainText={t('noResults', { ns: 'bi' })}
            secondaryText={t('tables.makeDifferentFilterSelection')}
          />
        }
        defaultSearchColumn="name"
        defaultSearchColumnName={t('tables.bi.columns.name')}
        extraTableOptions={admin || superAdmin ? <EnrichDropdown /> : null}
      />
    </div>
  );
}
