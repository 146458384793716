import './input.scss';
import classNames from 'classnames';
import SimpleError from '../simple-error/SimpleError';

// TODO replace it with MUI Input element
export default function Input({ id, icon, value = '', setValue, placeholder, type = 'text', required = true, error }) {
  return (
    <div className="input-container">
      <div className={classNames('input-holder', { error })}>
        {icon && <div className="icon">{icon}</div>}
        <div className="text">
          <input
            data-testid={placeholder}
            autoComplete="new-password" // removes autocomplete. Somewhat autoComplete off doesn't work
            type={type}
            id={id}
            value={value}
            placeholder={placeholder}
            onChange={(event) => setValue(event.target.value)}
            onKeyDown={(event) => event.stopPropagation()}
            required={required}
          />
        </div>
      </div>
      <SimpleError error={error} dataTestId="input-error-message" />
    </div>
  );
}
