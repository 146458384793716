import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useTranslation } from 'react-i18next';
import { WhiteTooltip, WideWhiteTooltip } from '../../../../components/information-icon/WideWhiteTooltip';
import KeyValueElement from '../../../../components/key-value-element/KeyValueElement';
import InformationIcon from '../../../../components/information-icon/InformationIcon';
import cvss from './cvss.svg';
import BaseScoreTooltip from './BaseScoreTooltip';
import PriorityScoreTooltip from './PriorityScoreTooltip';
import SeverityBox from '../../../../components/box/SeverityBox';
import { mapSeverityFromCode } from '../../../../services/MapperUtils';

export default function RiskMetricsData({ score, baseScore, vector, severity, scoreWeightProperties, baseVector }) {
  const { t } = useTranslation(['common', 'single-insight']);
  const scoreComponent = (
    <div className="column info-icon">
      {score}
      {!!scoreWeightProperties?.length && (
        <InformationIcon
          tooltipText={<PriorityScoreTooltip scoreWeightProperties={scoreWeightProperties} vector={vector} />}
          TooltipComponent={WhiteTooltip}
        />
      )}
      <SeverityBox severity={mapSeverityFromCode(severity)} />
    </div>
  );

  const baseScoreTitle = (
    <div className="column">
      {t('insights.baseScore')}
      {baseVector && (
        <div className="column info-icon">
          <img src={cvss} alt="CVSS" />
          <InformationIcon tooltipText={<BaseScoreTooltip vector={baseVector} />} TooltipComponent={WideWhiteTooltip} />
        </div>
      )}
    </div>
  );
  const baseVectorLink = `https://www.first.org/cvss/calculator/3.1#CVSS:3.1/${baseVector}`;

  const baseScoreElement = (
    <div className="column">
      {baseScore || t('general.na')}
      {baseVector && (
        <a
          href={baseVectorLink}
          target="_blank"
          rel="noreferrer"
          aria-label="Open CVSS specification document"
          className="open-new"
        >
          <OpenInNewOutlinedIcon className="icon" />
        </a>
      )}
    </div>
  );

  return (
    <div className="row spaced">
      <KeyValueElement title={t('insights.priorityScore')} value={scoreComponent} />
      <KeyValueElement title={baseScoreTitle} value={baseScoreElement} />
    </div>
  );
}
