import './horizontal-bar-chart.scss';
import { Tooltip } from 'react-tooltip';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableLoading from '../../table/TableLoading';
import { navigateToSearch } from '../chartHelper';

export default function HorizontalBarChart({
  data,
  loading,
  loadingBars = 2,
  navigatePath = null,
  navigateQueryParam = null,
  legendFlat,
  legendValuePosition,
}) {
  const [enabled, setEnabled] = useState(data.map(() => true));
  const canNavigate = !!(navigatePath && navigateQueryParam);

  const navigate = useNavigate();

  useEffect(() => {
    setEnabled(data.map(() => true));
  }, [data.length]);

  if (loading) {
    return (
      <div className="horizontal-bar-chart-container">
        <div className="loading-bar">
          {Array.from({ length: loadingBars }, (v, i) => i).map((v) => (
            <TableLoading key={v} />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="horizontal-bar-chart-container">
      <div className="horizontal-bar-chart">
        {data
          .filter((_, index) => enabled[index])
          .filter(({ value }) => value > 0)
          .map(({ name, value, color }) => (
            <div
              key={name}
              style={{
                flex: value,
                backgroundColor: color,
                cursor: canNavigate ? 'pointer' : 'default',
              }}
              className="bar"
              data-tooltip-id="horizontal-bar-tooltip"
              data-tooltip-content={`${name}: ${value}`}
              onClick={(e) => {
                if (canNavigate) {
                  e.stopPropagation();
                  navigateToSearch(navigate, navigatePath, navigateQueryParam, name);
                }
              }}
            />
          ))}
        <Tooltip id="horizontal-bar-tooltip" />
      </div>
      <div className="legend">
        {data.map(({ value, color, name, icon }, index) => (
          <LegendItem
            key={name}
            legendValuePosition={legendValuePosition}
            legendFlat={legendFlat ?? !!icon}
            color={color}
            name={name}
            value={Number(value).toLocaleString()}
            icon={icon}
            enabled={enabled.length === 0 || enabled[index]}
            setEnabled={(currentValue) => {
              const newEnabled = [...enabled];
              newEnabled[index] = currentValue;
              setEnabled(newEnabled);
            }}
          />
        ))}
      </div>
    </div>
  );
}

function LegendItem({ color, name, value, icon, enabled, setEnabled, legendFlat, legendValuePosition = 'right' }) {
  const legendValue = legendFlat ? (
    <div className={classnames('legend-value flat', { disabled: !enabled })}>
      {icon}
      <div className="name">{legendValuePosition === 'right' ? `${name}: ${value}` : `${value} ${name}`}</div>
    </div>
  ) : (
    <div className={classnames('legend-value', { disabled: !enabled })}>
      <div className="name">{name}</div>
      <div className="value">{value}</div>
    </div>
  );

  return (
    <div className="legend-item" onClick={() => setEnabled(!enabled)}>
      <div className={classnames('color-box', { disabled: !!icon })} style={{ backgroundColor: color }} />
      {legendValue}
    </div>
  );
}
