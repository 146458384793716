import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getNotificationChannels } from '../../../../redux/selector/notificationSelector';
import Input from '../../../../components/input/Input';
import DropdownBox from '../../../../components/dropdown-box/DropdownBox';
import { sendInsightNotifications } from '../../../../redux/slicers/insightSlicer';
import { getNotificationError, getNotificationsLoading } from '../../../../redux/selector/insightsSelector';
import { validateEmails } from '../../../../services/utils';
import { isSuperAdmin } from '../../../../redux/selector/authSelector';
import { NotificationChannelName } from '../../../settings/notifications/NotificationChannelName';
import { NotificationChannelType } from '../../../settings/notifications/NotificationConsts';
import ActionModal from '../../../../components/modal/ActionModal';
import LargeTextBox from '../../../../components/large-text-box/LargeTextBox';

const SendOptions = Object.freeze({
  INSIGHT_OWNER: 'insightOwner',
  CHANNEL: 'channel',
  CUSTOM_EMAIL: 'customEmail',
});

const MAX_MESSAGE_SIZE = 500;

function ChannelOption({ notificationChannels, selectedChannel, setSelectedChannel }) {
  const { t } = useTranslation(['notifications']);

  const channelComponents = notificationChannels.map((channel) => ({
    id: channel.id,
    onClick: () => setSelectedChannel(channel),
    text: <NotificationChannelName name={channel.name} type={channel.type} />,
  }));

  return (
    <DropdownBox
      width={240}
      text={t('chooseChannel')}
      selectedId={selectedChannel.id}
      value={channelComponents.find((channel) => channel.id === selectedChannel.id).text}
      menuItems={channelComponents}
    />
  );
}

function NotificationOption({ value, label, isSelected, children }) {
  return (
    <div className="option">
      <FormControlLabel value={value} control={<Radio size="small" checked={isSelected} />} label={label} />
      {isSelected && children}
    </div>
  );
}

export default function SendNotificationModal({ open, setOpen, insightId, insightOwnerEmail }) {
  const { user } = useAuth0();
  const { t } = useTranslation(['notifications', 'common']);

  const isSuperAdminRole = useSelector(isSuperAdmin);
  const dispatch = useDispatch();
  const error = useSelector(getNotificationError);
  const loading = useSelector(getNotificationsLoading);
  const notificationChannels = useSelector(getNotificationChannels);
  const notificationChannelsToShow = (notificationChannels || [])
    .filter((channel) => channel.type !== NotificationChannelType.DYNAMIC_EMAIL)
    .filter((channel) => isSuperAdminRole || !channel.destination?.toLowerCase().includes('@nokodsecurity.com'))
    .sort((a, b) => a.name.localeCompare(b.name));

  const [defaultOption, setDefaultOption] = useState(SendOptions.CUSTOM_EMAIL);
  const [option, setOption] = useState(defaultOption);
  const [selectedChannel, setSelectedChannel] = useState(null);

  const [message, setMessage] = useState('');
  const [messageValid, setMessageValid] = useState(true);
  const [customEmail, setCustomEmail] = useState('');
  const [customEmailError, setCustomEmailError] = useState(null);

  useEffect(() => {
    let newDefaultOption = SendOptions.CUSTOM_EMAIL;
    if (insightOwnerEmail) {
      newDefaultOption = SendOptions.INSIGHT_OWNER;
    } else if (notificationChannelsToShow.length) {
      newDefaultOption = SendOptions.CHANNEL;
    }
    setDefaultOption(newDefaultOption);
    setOption(newDefaultOption);
  }, [insightOwnerEmail, notificationChannels]);

  useEffect(() => {
    if (!selectedChannel && notificationChannelsToShow.length) {
      setSelectedChannel(notificationChannelsToShow[0]);
    }
  }, [notificationChannels]);

  // nullify the error when the user types
  useEffect(() => {
    setCustomEmailError(null);
  }, [customEmail]);

  function closeModal() {
    setOption(defaultOption);
    setCustomEmail('');
    setCustomEmailError(null);
    setMessage('');
    setMessageValid(true);
    setOpen(false);
  }

  async function handleOnClick() {
    if (option === SendOptions.CUSTOM_EMAIL) {
      const emailError = validateEmails(customEmail);
      if (emailError) {
        setCustomEmailError(emailError);
        return false;
      }
    }

    let targets = [];
    switch (option) {
      case SendOptions.INSIGHT_OWNER:
        targets = [insightOwnerEmail];
        break;
      case SendOptions.CUSTOM_EMAIL:
        targets = customEmail.split(',').map((email) => email.trim());
        break;
      case SendOptions.CHANNEL:
      default:
        break;
    }

    await dispatch(
      sendInsightNotifications({
        insightId,
        notificationChannelId: option === SendOptions.CHANNEL ? selectedChannel.id : null,
        targets,
        senderName: user.name,
        message,
      }),
    );

    return true;
  }

  return (
    <ActionModal
      id="send-notification-modal"
      title={t('sendNotificationsManually')}
      onApprove={handleOnClick}
      loading={loading}
      open={open}
      closeModal={closeModal}
      toastSuccessful={!error}
      toastTitle={t(error ? 'sendFailedMessage' : 'sentSuccessfullyMessage')}
      approveDisabled={!messageValid}
    >
      <div className="notification-option-container">
        <div className="notification-option-form">
          <RadioGroup
            row
            id="option"
            defaultValue={SendOptions.INSIGHT_OWNER}
            label={t('general.option', { ns: 'common' })}
            onChange={(e) => setOption(e.target.value)}
          >
            {insightOwnerEmail && (
              <NotificationOption
                value={SendOptions.INSIGHT_OWNER}
                label={t('emailInsightOwner')}
                isSelected={option === SendOptions.INSIGHT_OWNER}
              >
                <div className="insight-owner-box">{insightOwnerEmail}</div>
              </NotificationOption>
            )}
            {!!notificationChannelsToShow?.length && selectedChannel && (
              <NotificationOption
                value={SendOptions.CHANNEL}
                label={t('sendToChannel')}
                isSelected={option === SendOptions.CHANNEL}
              >
                <ChannelOption
                  notificationChannels={notificationChannelsToShow}
                  selectedChannel={selectedChannel}
                  setSelectedChannel={setSelectedChannel}
                />
              </NotificationOption>
            )}
            <NotificationOption
              value={SendOptions.CUSTOM_EMAIL}
              label={t('sendToEmail')}
              isSelected={option === SendOptions.CUSTOM_EMAIL}
            >
              <Input
                value={customEmail}
                setValue={setCustomEmail}
                placeholder={t('useCommasToEnterMoreThanOneEmail')}
                error={customEmailError}
              />
            </NotificationOption>
          </RadioGroup>
        </div>
        <LargeTextBox
          title={t('addCustomMessage')}
          id="notification-message"
          placeholder={t('general.enterText', { ns: 'common' })}
          message={message}
          setMessage={setMessage}
          messageValid={messageValid}
          setMessageValid={setMessageValid}
          maxSize={MAX_MESSAGE_SIZE}
        />
      </div>
    </ActionModal>
  );
}
