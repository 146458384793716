import { useTranslation } from 'react-i18next';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import ConnectionShareType, { ShareType } from '../ConnectionShareType';
import Tags from '../../../components/tags/Tags';
import { mapDate } from '../../../services/MapperUtils';
import CircledText from '../../../components/circled-text/CircledText';
import InternalLink from '../../../components/internal-link/InternalLink';
import ConnectionStatus from '../ConnectionStatus';
import PersonData from '../../../components/person-data/PersonData';
import ConnectorName from '../../../components/connector-name/ConnectorName';
import ManageTagsDropdown, { TagResourceType } from '../../data-source/manage-tags-dropdown/ManageTagsDropdown';
import EllipsisText from '../../../components/ellipsis-text/EllipsisText';
import { renderLinkOrZero } from '../../../services/utils';

export default function ConnectionDetails({ connection, linksBreadcrumbs }) {
  const { t } = useTranslation(['common', 'single-connection']);
  if (!connection) return null;

  const environments = (connection.environmentNames || []).map((e) => ({ name: e }));
  const { errorMessage } = connection.metadata;
  const naText = t('general.na', { ns: 'common' });

  return (
    <>
      {connection.shareType !== ShareType.None && (
        <ManageTagsDropdown
          connectionId={connection.id}
          selectedResources={[
            {
              id: connection.id,
              tags: connection.tags,
            },
          ]}
          tagResourceType={TagResourceType.Connection}
          allowEdit
        />
      )}
      <div className="connections-details">
        <KeyValueElement
          title={t('general.name', { ns: 'common' })}
          value={<ConnectorName connectorName={connection.connectorName} name={connection.name} />}
        />
        <KeyValueElement
          title={t('general.type', { ns: 'common' })}
          value={
            <InternalLink
              url={`/data?tab=Connectors&name:is=${connection.connectorName}`}
              text={connection.connectorName}
            />
          }
        />
        <KeyValueElement
          title={t('connectionDetails.sharedConnection', { ns: 'single-connection' })}
          value={<ConnectionShareType type={connection.shareType} />}
        />
        <KeyValueElement
          title={t('tags.tags', { ns: 'common' })}
          value={
            connection.tags.length ? <Tags tags={connection.tags} tooltipAnchor="conn-tags" /> : t('tags.untagged')
          }
          className="block no-border"
        />
        <KeyValueElement
          title={t('general.creationTime', { ns: 'common' })}
          value={mapDate(connection.creationTime) || naText}
        />
        <KeyValueElement
          title={t('general.lastChange', { ns: 'common' })}
          value={mapDate(connection.updateTime) || naText}
        />
        <KeyValueElement
          title={t('general.environments', { ns: 'common' })}
          value={environments.length ? <Tags tags={environments} tooltipAnchor="conn-environments" /> : naText}
        />
        <KeyValueElement
          title={t('general.inProduction', { ns: 'common' })}
          value={<CircledText text={t(connection.isProduction ? 'general.true' : 'general.false', { ns: 'common' })} />}
          className="no-border"
        />
        <KeyValueElement
          title={t('applications.applications', { ns: 'common' })}
          value={renderLinkOrZero(connection.applicationCount, `/applications?connectionId:is=${connection.id}`)}
        />
        {!!connection.connectedStatus && (
          <KeyValueElement
            title={t('general.status', { ns: 'common' })}
            value={<ConnectionStatus connectedStatus={connection.connectedStatus} />}
          />
        )}
        {connection.connectedStatus && errorMessage && (
          <KeyValueElement
            title={t('general.errorMessage', { ns: 'common' })}
            value={<EllipsisText text={errorMessage} containerMaxWidth="70vh" toolTipMaxWidth="70vh" />}
          />
        )}
        {!!connection.owner && (
          <KeyValueElement
            title={t('general.owner', { ns: 'common' })}
            value={<PersonData owner={connection.owner} linksBreadcrumbs={linksBreadcrumbs} />}
            className="no-border"
          />
        )}
      </div>
    </>
  );
}
