import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import CircledText from '../circled-text/CircledText';
import './tags.scss';

const tagMargin = 35;
export default function Tags({ tags, extraTagsDark = false, tooltipAnchor = '', extraMargin = 0 }) {
  if (!tags?.length) {
    return null;
  }

  const [visibleTags, setVisibleTags] = useState([tags[0]]);
  const [hiddenTagsCount, setHiddenTagsCount] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    function calculateChipWidth(tag) {
      const tempChip = document.createElement('div');
      tempChip.innerText = tag;
      tempChip.style.visibility = 'hidden';
      tempChip.style.position = 'absolute';
      document.body.appendChild(tempChip);
      const width = tempChip.offsetWidth;
      document.body.removeChild(tempChip);
      return width + tagMargin + extraMargin;
    }

    function updateVisibleTags() {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const newVisibleTags = [];
        let hiddenCount = 0;
        let currentWidth = calculateChipWidth(tags[0].name);

        for (let i = 1; i < tags.length; i++) {
          const tag = tags[i];
          const chipWidth = calculateChipWidth(tag.name);
          if (currentWidth + chipWidth <= containerWidth) {
            newVisibleTags.push(tag);
            currentWidth += chipWidth;
          } else {
            hiddenCount = tags.length - newVisibleTags.length - 1;
            break;
          }
        }

        setVisibleTags([tags[0], ...newVisibleTags]);
        setHiddenTagsCount(hiddenCount);
      }
    }

    updateVisibleTags();
    window.addEventListener('resize', updateVisibleTags);
    return () => window.removeEventListener('resize', updateVisibleTags);
  }, [tags]);

  const invisibleTags = tags.slice(visibleTags.length);

  const tooltip = {
    show: hiddenTagsCount,
    content: invisibleTags.map((tag) => tag.name).join('\n'),
    anchorId: tooltipAnchor,
  };

  return (
    <div className="tags-container" ref={containerRef}>
      {visibleTags.map((tag, index) => {
        const tagTooltip = {
          show: true,
          content: tag.name,
          anchorId: `${tooltipAnchor}-tag-${index}`,
        };

        return (
          <CircledText
            key={tag.name}
            text={tag.name}
            className="tag"
            tooltip={tagTooltip}
            wrapperClassNames={classNames({ 'before-extra': index + 1 === visibleTags.length })}
            isDark={tag.isDark}
          />
        );
      })}
      {hiddenTagsCount > 0 && <CircledText text={`+${hiddenTagsCount}`} tooltip={tooltip} isDark={extraTagsDark} />}
    </div>
  );
}
