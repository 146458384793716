import { useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '../checkbox/Checkbox';
import EllipsisText from '../ellipsis-text/EllipsisText';

import './multi-select-dropdown.scss';

function sortBySelected(array) {
  return array.slice().sort((a, b) => {
    if (a.isSelected !== b.isSelected) {
      return a.isSelected ? -1 : 1;
    }

    if (a.isIndeterminate !== b.isIndeterminate) {
      return a.isIndeterminate ? -1 : 1;
    }

    return 0;
  });
}

export const CheckType = Object.freeze({
  CHECKBOX: 'checkbox',
  CHECKMARK: 'checkmark',
});

function MultiSelectDropdown({
  isOpen,
  anchorEl,
  handleClose,
  items = [],
  checkboxDisabled,
  className,
  handleSelect,
  footer,
  header,
  checkType = CheckType.CHECKBOX,
  isSortBySelected = true,
}) {
  const id = isOpen ? 'multiselect-dropdown' : undefined;
  const sortedItems = useMemo(() => sortBySelected(items), [items]);

  const renderList = () =>
    (isSortBySelected ? sortedItems : items).map((item) => (
      <MenuItem
        key={item.id}
        classes={{ root: 'list-item-button' }}
        onClick={() => {
          if (checkType === CheckType.CHECKMARK) {
            handleSelect(item.id, !item.isSelected);
          }
        }}
      >
        {checkType === CheckType.CHECKBOX && (
          <Checkbox
            checked={item.isSelected}
            indeterminate={item.isIndeterminate}
            onChange={({ target }) => handleSelect(target.value, target.checked)}
            isDisabled={checkboxDisabled}
            value={item.id}
            label={
              <EllipsisText
                className="primary-text"
                text={item.label}
                containerMaxWidth="150px"
                toolTipMaxWidth="95%"
              />
            }
          />
        )}
        {checkType === CheckType.CHECKMARK && (
          <div className="menu-item-checkmark">
            <span className="check-icon-container">{item.isSelected && <CheckIcon fontSize="small" />}</span>
            <EllipsisText className="primary-text" text={item.label} containerMaxWidth="220px" toolTipMaxWidth="95%" />
          </div>
        )}
      </MenuItem>
    ));

  return (
    <Menu
      id={id}
      open={isOpen}
      anchorEl={anchorEl}
      onClose={handleClose}
      className={`custom-select-menu multiselect-menu-popover ${className}`}
      placement="bottom-start"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {header && <div className="multiselect-dropdown-header">{header}</div>}
      <div className="options-list">{renderList()}</div>
      {footer && <div className="multiselect-dropdown-footer">{footer}</div>}
    </Menu>
  );
}

export default MultiSelectDropdown;
