import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { lowColor } from '../../consts/colors';

export default function History({ cards, historyTitle }) {
  return (
    <div>
      <div className="history-title">{historyTitle}</div>
      <VerticalTimeline layout="1-column-left" animate={false} lineColor="#E0E0E0">
        {cards.map((card) => (
          <VerticalTimelineElement
            key={card.key}
            className="vertical-timeline-element--work"
            contentStyle={{ boxShadow: 'none' }}
            iconStyle={{
              background: lowColor,
              width: '14px',
              height: '14px',
              left: '12px',
              boxShadow: 'none',
            }}
          >
            {card}
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>
    </div>
  );
}
