import { Modal } from '@mui/material';
import './modal.scss';
import ModalWrapper from './ModalWrapper';

export default function CloseableModal({ open, children, closeModal, id }) {
  return (
    <Modal open={open} id={id}>
      <ModalWrapper closeModal={closeModal}>
        <div onClick={(e) => e.stopPropagation()} style={{ width: 0 }}>
          <div className="contextual-action-modal">{children}</div>
        </div>
      </ModalWrapper>
    </Modal>
  );
}
