import { Outlet, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { useAuth0 } from '@auth0/auth0-react';
import classnames from 'classnames';
import Navbar from './navbar/Navbar';
import OrganizationPicker from './OrganizationPicker';
import LanguagePicker from './LanguagePicker';

export default function Layout() {
  const navigate = useNavigate();
  const { user } = useAuth0();

  return (
    <div>
      <CssBaseline />
      {user && <Navbar navigate={navigate} />}
      <Box
        className={classnames('body-wrapper', {
          'body-wrapper-with-navbar': user,
        })}
        component="main"
        sx={{
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <LanguagePicker />
        <OrganizationPicker />
        <Outlet />
        <div className="body-wrapper-footer" />
      </Box>
    </div>
  );
}
