import { capitalize } from 'lodash';
import ColoredBox from './ColoredBox';
import { getIcon } from '../../icons/iconUtils';

export default function InsightTypeBox({
  insightType = '',
  isFilled = false,
  hasBorder = true,
  showText = true,
  hasPadding = true,
  iconWidth = 15,
  style = {},
}) {
  return (
    <ColoredBox
      icon={getIcon(insightType, iconWidth)}
      text={showText ? capitalize(insightType) : ''}
      isFilled={isFilled}
      hasBorder={hasBorder}
      hasPadding={hasPadding}
      style={style}
      className="left"
      color="gray"
    />
  );
}
