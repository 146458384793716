import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { HomePageTable } from './HomePageTable';
import SeverityBox from '../../../components/box/SeverityBox';
import InsightTypeBox from '../../../components/box/InsightTypeBox';
import PlatformName from '../../../components/platform-name/PlatformName';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import { compareSeverities, compareTypes } from '../../../services/comparisonUtils';
import { mapSeverityFromCode } from '../../../services/MapperUtils';
import emptyInsightsImg from '../../insights/empty_insights.png';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import { getHomePageAggregations, isHomePageAggregationsLoading } from '../../../redux/selector/homepageSelector';

export default function TopAggregationsTable() {
  const aggregations = useSelector(getHomePageAggregations);
  const loading = useSelector(isHomePageAggregationsLoading);
  const { t } = useTranslation(['common', 'homePage']);

  const columns = useMemo(
    () => [
      {
        backendKey: 'severity',
        width: '100px',
        accessor: (cell) => cell.severity,
        Cell: ({ cell }) => {
          const severity = cell.value;
          return <SeverityBox severity={mapSeverityFromCode(severity)} />;
        },
        sortType: (rowA, rowB) => {
          const a = rowA.original.severity;
          const b = rowB.original.severity;
          return compareSeverities(a, b);
        },
      },
      {
        backendKey: 'insightType',
        width: '165px',
        accessor: (cell) => cell.insightType,
        Cell: ({ cell }) => {
          const { value } = cell;
          return <InsightTypeBox insightType={value} hasBorder={false} isFilled={false} hasPadding={false} />;
        },
        sortType: (rowA, rowB) => {
          const a = rowA.original.insightType;
          const b = rowB.original.insightType;
          return compareTypes(a, b);
        },
      },
      {
        backendKey: 'name',
        width: '30%',
        accessor: (cell) => cell.name,
        Cell: ({ cell, row }) => (
          <OrganizationAwareLink to={`/aggregations/${row.original.id}?from=Overview`}>
            {cell.value}
          </OrganizationAwareLink>
        ),
      },
      {
        backendKey: 'platformName',
        width: '15%',
        accessor: (cell) => cell.platformName,
        Cell: ({ value }) => <PlatformName platform={value} />,
      },
      {
        backendKey: 'activeInsights',
        width: '100px',
        accessor: (cell) => cell.activeInsights,
        Cell: ({ cell }) => <div className="number">{cell.value}</div>,
      },
    ],
    [],
  );

  // gotoTab should be weak if there are at least 3 aggregations with the same type of the top 5
  const sortedAggs = [...aggregations].sort((a, b) => b.activeInsights - a.activeInsights);

  return (
    <HomePageTable
      type="home.topAggregations"
      title={t('homePageTables.topAggregations', { ns: 'homePage' })}
      columns={columns}
      data={sortedAggs}
      isLoading={loading}
      link="/insights?tab=aggregations"
      emptyState={
        <TableEmptyContent
          img={emptyInsightsImg}
          mainText={t('homePageTables.noTopAggregations', { ns: 'homePage' })}
          secondaryText=""
        />
      }
    />
  );
}
