import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import MultiSelectDropdownButton, { CheckType } from '../multi-select-dropdown/MultiSelectDropdown';
import SearchInput, { SearchIconPosition } from '../search-input/SearchInput';
import { columnsMetadata, IS, OR_OPERATOR } from '../filter/filterConsts';
import { enforceTranslations } from '../filter/filterUtils';

export default function ColumnFilter({
  column,
  filter,
  columnOptions,
  filtersOptions,
  overrideSingleFilter,
  showFilter,
}) {
  const selectedOptions = filter?.value.toString().split(OR_OPERATOR) || [];
  const { t } = useTranslation(['common']);
  const [showFilterBox, setShowFilterBox] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [displayedOptions, setDisplayedOptions] = useState([]);
  const title = t('tables.filters.changeFilter');

  function mapOption(option) {
    return {
      id: option,
      label: enforceTranslations(t, option),
      isSelected: selectedOptions.includes(String(option)) ?? false,
    };
  }

  function getOptionsWithUpdatedSelection(value, selected) {
    return columnOptions.map((option) => {
      const optionObj = mapOption(option);
      return {
        ...optionObj,
        isSelected: option === value ? selected : optionObj.isSelected,
      };
    });
  }

  useEffect(() => {
    setDisplayedOptions(
      columnOptions
        .filter((option) => option && option.toString().toLowerCase().includes(searchValue.toLowerCase()))
        .map(mapOption),
    );
  }, [searchValue, filtersOptions]);
  // should update when searchValue is changed. Must depends on filtersOptions to trigger the getColumnOptions function

  useEffect(() => {
    const updatedColumnsToShow = displayedOptions.map((option) => ({
      ...option,
      isSelected: selectedOptions.includes(String(option.id)),
    }));
    setDisplayedOptions(updatedColumnsToShow);
  }, [filter]);

  function handleClick(e) {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setShowFilterBox(true);
  }

  function handleClose(e) {
    e.stopPropagation();
    setAnchorEl(null);
    setShowFilterBox(false);
    setSearchValue('');
  }

  function changeFilters(value, checked) {
    const newOptions = getOptionsWithUpdatedSelection(value, checked);
    const newSelectedOptions = newOptions.filter((option) => option.isSelected).map((option) => option.id);
    const newFilter = {
      column: column.backendKey,
      type: IS,
      value: newSelectedOptions.map((option) => option.toString()).join(OR_OPERATOR),
    };

    overrideSingleFilter(newFilter, !newSelectedOptions.length);
  }

  const showFilterIcon = !!(
    showFilter && // don't show on tables without filtering
    // don't show until options are loaded
    columnOptions?.length &&
    // don't show on columns that don't support IS
    (columnsMetadata[column.Header] || columnsMetadata[column.backendKey])?.operators.includes(IS)
  );

  return (
    showFilterIcon && (
      <span className="table-header-icon" onClick={handleClick} title={title}>
        {filter ? <FilterAltIcon className="clickable" /> : <FilterAltOutlinedIcon className="clickable" />}
        <MultiSelectDropdownButton
          isOpen={showFilterBox}
          anchorEl={anchorEl}
          handleClose={handleClose}
          items={displayedOptions}
          handleSelect={changeFilters}
          isSortBySelected={false}
          header={
            <SearchInput
              placeholder="Search"
              value={searchValue}
              setValue={setSearchValue}
              searchIconPosition={SearchIconPosition.END}
            />
          }
          checkType={CheckType.CHECKMARK}
        />
      </span>
    )
  );
}
