import { useTranslation } from 'react-i18next';
import detectionIcon from './detection_notification_icon.svg';
import reminderIcon from './reminder_notification_icon.svg';
import escalationIcon from './escalation_notification_icon.svg';
import notificationSuccessfulIcon from './notification_successful_icon.svg';
import notificationFailedIcon from './notification_failed_icon.svg';
import manualNotificationIcon from './manual_notification_icon.svg';
import personIcon from './person_icon.svg';
import { mapDate } from '../../../../services/MapperUtils';
import NotificationInfo from './NotificationInfo';
import { getNotificationChannelIcon } from '../../../../services/LogoService';

function getIconByType(type) {
  switch (type) {
    case 'Original':
      return detectionIcon;
    case 'Reminder':
      return reminderIcon;
    case 'Escalation':
      return escalationIcon;
    case 'Manual':
      return manualNotificationIcon;
    default:
      return detectionIcon;
  }
}

function getTitleByType(type) {
  const { t } = useTranslation(['notifications']);

  switch (type) {
    case 'Original':
      return t('detection');
    case 'Reminder':
      return t('reminder');
    case 'Escalation':
      return t('escalation');
    case 'Manual':
      return t('manual');
    default:
      return t('detection');
  }
}

function NotificationBoxHeader({
  trigger,
  time,
  // channelName
}) {
  // const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="notification-box-header">
      <div className="notification-box-header-item">
        <div className="notification-box-header-icon">
          <img src={getIconByType(trigger)} alt="notification-icon" />
        </div>
        <div className="notification-box-header-info">
          <div className="notification-box-header-type">{getTitleByType(trigger)}</div>
          <div className="notification-box-header-time">{mapDate(time)}</div>
        </div>
      </div>
      {/* <div className="notification-box-header-item notification-box-resend" onClick={() => setModalOpen(true)}> */}
      {/*  <img src={resendIcon} alt="resend" /> */}
      {/*  <span>Resend</span> */}
      {/* </div> */}
      {/* <NotificationResendModal open={modalOpen} setOpen={setModalOpen} channelName={channelName} /> */}
    </div>
  );
}

export default function NotificationBox({ trigger, sentTime, channelName, sentSuccessfully, channelType, senderName }) {
  const { t } = useTranslation(['notifications', 'common']);
  const channelIcon = getNotificationChannelIcon(channelType);

  return (
    <div className="notification-box">
      <NotificationBoxHeader trigger={trigger} time={sentTime} channelName={channelName} />

      <div className="notification-info">
        {senderName && <NotificationInfo label={t('sentBy')} value={senderName} img={personIcon} />}

        <NotificationInfo label={t('channel')} value={channelName} img={channelIcon} className="channel" />
        <NotificationInfo
          label={t('general.status', { ns: 'common' })}
          value={t(sentSuccessfully ? 'sentSuccessfully' : 'sendFailed')}
          img={sentSuccessfully ? notificationSuccessfulIcon : notificationFailedIcon}
        />
      </div>
    </div>
  );
}
