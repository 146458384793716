import { insightStatus } from '../consts/insight-status';
import { insightType } from '../consts/insight-type';
import { insightSeverity } from '../consts/insight-severity';

const types = [insightType.GOVERNANCE, insightType.COMPLIANCE, insightType.VULNERABILITY, insightType.MALICIOUS];

const severities = [insightSeverity.LOW, insightSeverity.MEDIUM, insightSeverity.HIGH, insightSeverity.CRITICAL];

const statuses = [
  insightStatus.OPEN,
  insightStatus.IN_REMEDIATION,
  insightStatus.REQUIRES_ACKNOWLEDGEMENT,
  insightStatus.REQUIRES_RESOLUTION,
  insightStatus.CLOSED_ACKNOWLEDGED,
  insightStatus.CLOSED_RESOLVED,
  insightStatus.CLOSED,
];

// these are all in ascending order

export function compareTypes(a, b) {
  return types.indexOf(a.toLowerCase()) - types.indexOf(b.toLowerCase());
}

export function compareSeverities(a, b) {
  return severities.indexOf(b.toLowerCase()) - severities.indexOf(a.toLowerCase());
}

export function compareStatuses(a, b) {
  return statuses.indexOf(a) - statuses.indexOf(b);
}
