import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { startCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import { fetchRules } from '../../../redux/slicers/policySlicer';
import { getRules, isRulesLoading } from '../../../redux/selector/policySelector';
import TypedRulesList from './TypedRulesList';
import CollapseCard from '../../../components/collapse-card/CollapseCard';

const insightTypes = ['malicious', 'vulnerability', 'compliance', 'governance'];

export default function ApplicationRules({ insights = [], isLoading }) {
  const dispatch = useDispatch();
  const rules = useSelector(getRules);
  const rulesLoading = useSelector(isRulesLoading);

  const [showFired, setShowFired] = useState(true);
  const [showActive, setShowActive] = useState(true);
  const [showDisabled, setShowDisabled] = useState(false);
  const { t } = useTranslation(['common']);

  useEffect(() => {
    if (!rules.length && !rulesLoading) {
      (async function fetchData() {
        dispatch(fetchRules());
      })();
    }
  }, []);

  const firedRules = [];
  const activeRules = [];
  const disabledRules = [];

  rules.forEach((rule) => {
    if (showFired && insights.find((insight) => insight.ruleId === rule.id && insight.active)) {
      firedRules.push(rule);
    } else if (showActive && rule.active) {
      activeRules.push(rule);
    } else if (showDisabled && !rule.active) {
      disabledRules.push(rule);
    }
  });

  return (
    <CollapseCard title={t('rules', { ns: 'single-application' })} initialOpen={false} isLoading={isLoading}>
      <div className="application-rules">
        <div className="application-rules-filters">
          <div className="filters-text">Show:</div>
          <RulesFilter title={t('fired', { ns: 'single-application' })} state={showFired} setState={setShowFired} />
          <RulesFilter title={t('general.active')} state={showActive} setState={setShowActive} />
          <RulesFilter title={t('general.disabled')} state={showDisabled} setState={setShowDisabled} />
        </div>
        <div className="rules-body">
          {insightTypes.map((type) =>
            rules.some((rule) => rule.type === type) ? (
              <TypedRulesList
                key={type}
                title={startCase(type) /* todo: support translation */}
                insights={insights}
                type={type}
                activeRules={activeRules}
                firedRules={firedRules}
                disabledRules={disabledRules}
              />
            ) : null,
          )}
        </div>
      </div>
    </CollapseCard>
  );
}

function RulesFilter({ title, state, setState }) {
  return (
    <div className="rules-filter">
      <input type="checkbox" onChange={() => setState(!state)} checked={state} />
      <div>{title}</div>
    </div>
  );
}
