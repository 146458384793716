import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import GaugeChart from 'react-gauge-chart';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/card/Card';
import ColoredBox from '../../../components/box/ColoredBox';
import { falseColor, trueColor } from '../../../consts/colors';
import { getHomepagePostureData, isHomePageTenantDataLoading } from '../../../redux/selector/homepageSelector';

export default function EnvironmentPosture() {
  const loading = useSelector(isHomePageTenantDataLoading);
  const postureData = useSelector(getHomepagePostureData);
  const { t } = useTranslation(['homePage']);

  const score = postureData?.score;
  // this is required otherwise the component will be re-rendered every time and the animation is annoying
  const gauge = useMemo(
    () =>
      score !== undefined ? (
        <GaugeChart
          id="gauge-chart"
          arcPadding={0}
          arcWidth={0.2}
          hideText
          cornerRadius={0}
          colors={[falseColor, trueColor]}
          nrOfLevels={200}
          percent={score / 100}
        />
      ) : null,
    [score],
  );

  return (
    <Card className="short flex environment-posture-container" loading={loading}>
      <div className="environment-posture">
        {gauge}
        <div>
          <ColoredBox isFilled text={Math.round(score)} hasBorder={false} color={score < 50 ? 'red' : 'green'} />
          <div>{t('securityWidget.environmentPosture')}</div>
        </div>
      </div>
    </Card>
  );
}
