import { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { CircularProgress } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { getIncomers, getOutgoers, Handle, Position, useReactFlow } from 'reactflow';
import Toast from '../toast/Toast';
import SubEntity from './SubEntity';
import { fetchData } from './utils/dataFechting';
import { buildGraph, getEntityIcon } from './utils/flowUtils';
import { mapSeverityFromCode } from '../../services/MapperUtils';
import EntityDataPopover from './entity-data-popover/EntityDataPopover';
import { setSubEntities } from '../../redux/slicers/entityFlowSlicer';
import { getEntitiesState } from '../../redux/selector/entityFlowSelector';

import './flow.scss';

function generateSubEntitiesObj(subEntitiesNames, defaultValue = false) {
  return subEntitiesNames.reduce((acc, entity) => {
    acc[entity] = defaultValue;
    return acc;
  }, {});
}

function EntityNode({ data, id, position }) {
  const { t } = useTranslation(['entity-map', 'common']);
  const [isLoading, setIsLoading] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [isTooBusy, setIsTooBusy] = useState(false);
  const dispatch = useDispatch();
  const subEntitiesStates = useSelector(getEntitiesState);
  const subEntitiesState = subEntitiesStates?.[id] || {};
  const { getNodes, getEdges, setNodes, setEdges } = useReactFlow();
  const { type, entityData, subEntities, insights, label } = data;
  const subEntitiesNames = Object.keys(subEntities);
  const nodes = getNodes();
  const edges = getEdges();
  const isGraphRoot = id === nodes[0].id;
  const [isMenuOpen, setIsMenuOpen] = useState(isGraphRoot);
  const outgoersNodes = getOutgoers({ id, position, data }, nodes, edges);
  const incomersNodes = getIncomers({ id, position, data }, nodes, edges);

  const maxSeverityName = mapSeverityFromCode(insights?.maxInsightSeverity.toString()).toLowerCase();
  const Icon = getEntityIcon(type);

  const handleClick = async () => {
    // if we already fetched data
    if (subEntitiesNames.length) {
      setIsMenuOpen(!isMenuOpen);
      return;
    }

    try {
      setIsLoading(true);
      const [nodeData, isDataTooBusy] = await fetchData(type, id);
      setIsLoading(false);
      setIsTooBusy(isDataTooBusy);
      setIsMenuOpen(true);
      if (isDataTooBusy) {
        return;
      }

      const graphData = {
        root: { ...data, id },
        ...nodeData,
      };
      const { nodes: newNodes, edges: newEdges } = buildGraph(nodes, edges, graphData);
      setNodes(newNodes);
      setEdges([...edges, ...newEdges]);
    } catch (e) {
      setIsLoading(false);
      setToastOpen(true);
    }
  };

  useEffect(() => {
    const subEntitiesObj = generateSubEntitiesObj(subEntitiesNames, isGraphRoot);
    dispatch(setSubEntities({ id, subEntitiesObj }));
  }, [subEntitiesNames.length]);

  function onSubEntityClick(name) {
    dispatch(setSubEntities({ id, subEntitiesObj: { ...subEntitiesState, [name]: !subEntitiesState[name] } }));
  }

  function renderSubEntities() {
    if (isTooBusy) {
      return <div>{t('messages.tooMuchData')}</div>;
    }
    if (!subEntitiesNames.length) {
      return <div>{t('messages.noSubEntities')}</div>;
    }
    return subEntitiesNames.map((name) => (
      <SubEntity key={name} name={name} isOpen={subEntitiesState[name]} onSubEntityClick={onSubEntityClick} />
    ));
  }

  return (
    <>
      <Toast open={toastOpen} setOpen={setToastOpen} isSuccess={false} title={t('toast.failureTitle')} />
      <div className={classnames('entity-node', type)}>
        <Handle type="target" position={Position.Left} style={{ opacity: incomersNodes.length }} />
        <div className="overview-container" onClick={handleClick}>
          <div className="icon-wrapper">
            <Icon className="icon" />
          </div>
          <div className="title-container">
            <div className="text">{label || t('general.na', { ns: 'common' })}</div>
            <EntityDataPopover entity={entityData} />
          </div>
          {!!insights?.insightsCount && (
            <div className={classnames('insights', maxSeverityName)}>
              {t('insights', { count: insights.insightsCount })}
            </div>
          )}
          {!!subEntitiesNames.length && !isLoading && (
            <div className={classnames('arrow', { open: isMenuOpen })}>
              {isMenuOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
            </div>
          )}
          {isLoading && <CircularProgress className="flow-loader" color="inherit" size="30px" />}
        </div>
        {isMenuOpen && <div className="sub-entities">{renderSubEntities()}</div>}
        <Handle type="source" position={Position.Right} style={{ opacity: outgoersNodes.length }} />
      </div>
    </>
  );
}

export default memo(EntityNode);
