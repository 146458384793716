import { useTranslation } from 'react-i18next';
import KeyValueElement from '../../../components/key-value-element/KeyValueElement';
import { mapDate } from '../../../services/MapperUtils';
import PersonName from '../../../components/person-name/PersonName';
import AppInstanceStatus from './AppInstanceStatus';
import { POWER_PLATFORM, UI_PATH } from '../../../consts/platforms';
import BooleanValue from '../../../components/boolean-value/BooleanValue';

function getBusinessContextName(appInstance) {
  if (!appInstance?.appInstanceBusinessContexts?.length) {
    return null;
  }

  return appInstance.appInstanceBusinessContexts
    .map((businessContext) => businessContext.businessContext.name)
    .join(', ');
}

function getStatusColor({ status, deployed }) {
  if (!deployed) {
    return 'gray';
  }

  return status === 'SUSPENDED' ? 'suspended' : 'green';
}

export default function AppInstance({ platform, appInstance, isCopilot }) {
  const { t } = useTranslation(['common', 'single-application']);

  const { version, creationTime, lastUsed, owner } = appInstance;

  const statusColor = getStatusColor(appInstance);
  const businessContextName = getBusinessContextName(appInstance) || t('general.na');

  return (
    <div className="app-instance" key={appInstance.id}>
      <div className="app-instance-content">
        <KeyValueElement title={t('general.version')} value={version} />
        <KeyValueElement title={t('general.owner')} value={<PersonName name={owner?.name} />} />
        {isCopilot && (
          <>
            <KeyValueElement
              title={t('applicationData.copilotData.authType', { ns: 'single-application' })}
              value={appInstance.extraData?.authType || t('general.unknown')}
            />
            <KeyValueElement
              title={t('applicationData.copilotData.genAI', { ns: 'single-application' })}
              value={<BooleanValue isTrue={appInstance.extraData?.genAI} />}
            />
            <KeyValueElement
              title={t('applicationData.copilotData.useGeneralKnowledge', { ns: 'single-application' })}
              value={<BooleanValue isTrue={appInstance.extraData?.useGeneralKnowledge} />}
            />
            <KeyValueElement
              title={t('applicationData.copilotData.publishedOn', { ns: 'single-application' })}
              value={appInstance.lastPublishedTime ? mapDate(appInstance.lastPublishedTime) : t('general.na')}
            />
          </>
        )}
        <KeyValueElement title={t('general.creationDate')} value={mapDate(creationTime)} />
        {platform === POWER_PLATFORM && <KeyValueElement title={t('general.solution')} value={businessContextName} />}
        {platform === UI_PATH && (
          <>
            <KeyValueElement
              title={t('feed', { ns: 'single-application' })}
              value={appInstance.extraData?.feedName || t('general.na')}
            />
            <KeyValueElement
              title={t('processName', { ns: 'single-application' })}
              value={(appInstance.deployed && appInstance.name) || t('general.na')}
            />
            <KeyValueElement title={t('folder', { ns: 'single-application' })} value={businessContextName} />
          </>
        )}

        <KeyValueElement
          title={t('lastUse', { ns: 'single-application' })}
          value={mapDate(lastUsed) || t('general.na')}
        />
        <AppInstanceStatus
          text={t(appInstance.deployed ? 'general.live' : 'general.draft')}
          backgroundColor={statusColor}
          circleColor={statusColor}
          textColor="black"
        />
      </div>
    </div>
  );
}
