import { useTranslation } from 'react-i18next';
import sharedIcon from './icons/shared_icon.svg';
import notSharedIcon from './icons/not_shared_icon.svg';
import implicitSharedIcon from './icons/implicit_shared_icon.svg';
import IconWithText from '../../components/icon-text/IconWithText';

export const ShareType = Object.freeze({
  Implicit: 'Implicit',
  None: 'None',
  Shared: 'Shared',
});

export default function ConnectionShareType({ type }) {
  const { t } = useTranslation('common');
  switch (type) {
    case ShareType.Shared:
      return <IconWithText text={t('connections.shared', { ns: 'common' })} img={sharedIcon} />;
    case ShareType.Implicit:
      return <IconWithText text={t('connections.implicit', { ns: 'common' })} img={implicitSharedIcon} />;
    case ShareType.None:
      return <IconWithText text={t('connections.none', { ns: 'common' })} img={notSharedIcon} />;
    default:
      return t('genral.na', { ns: 'common' });
  }
}
