import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import { mapDate } from '../../../services/MapperUtils';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import ConnectorName from '../../../components/connector-name/ConnectorName';
import Tags from '../../../components/tags/Tags';
import ConnectionShareType from '../../connection/ConnectionShareType';

export default function UserConnectionsTable({ isLoading, connections = [] }) {
  const { t } = useTranslation(['common', 'single-user']);

  const tableName = t('connectionsTable.tableName', { ns: 'single-user' });
  const columns = useMemo(
    () => [
      {
        Header: t('general.name', { ns: 'common' }),
        accessor: 'name',
        width: '15%',
        Cell: ({ value, cell }) => (
          <OrganizationAwareLink to={`/data/${cell.row.original.id}?from=Data`} className="black-link">
            <ConnectorName name={value} connectorName={cell.row.original.connectorName} shouldEllipsis />
          </OrganizationAwareLink>
        ),
      },
      {
        Header: t('general.environments', { ns: 'common' }),
        accessor: 'environments',
        width: '10%',
        Cell: ({ value, cell }) => {
          if (!value?.length) {
            return <div className="gray">N/A</div>;
          }

          const tags = [{ name: value }];
          return <Tags tags={tags} tooltipAnchor={`environment-${cell.row.original.id}`} />;
        },
        sortType(rowA, rowB) {
          return rowA.original.environments.localeCompare(rowB.original.environments);
        },
      },
      {
        Header: t('general.creationDate', { ns: 'common' }),
        accessor: 'creationTime',
        width: '10%',
        Cell: ({ value }) => mapDate(value) || t('general.na'),
      },
      {
        Header: t('connectionsTable.sharingType', { ns: 'single-user' }),
        accessor: 'shareType',
        width: '10%',
        Cell: ({ value }) => <ConnectionShareType type={value} />,
        sortType(rowA, rowB) {
          return rowA.original.shareType.localeCompare(rowB.original.shareType);
        },
      },
    ],
    [],
  );

  const sortBy = [{ id: 'grantDate', desc: true }];

  return (
    <div className="card-view-table-container">
      <div className="collapse-card-container">
        <CollapseCard title={tableName} isLoading={isLoading} totalElementsCount={connections.length}>
          <Table
            columns={columns}
            data={connections}
            totalCount={connections.length}
            options={{ showFilter: false, showPagination: true, showSort: true, usePaginationSearchParam: false }}
            type="users"
            shouldScrollOnPagination={false}
            sortBy={sortBy}
            initialFilters={[]}
            customFilters={[]}
            isRowDisabled={() => false}
            getElementId={(connection) => connection.id}
            isLoading={isLoading}
            classNames="card-view-table"
            backendPagination={false}
            pageSize={5}
            emptyState={
              <TableEmptyContent
                img={emptyApplicationsImg}
                mainText={t('connectionsTable.tableName', { ns: 'single-user' })}
              />
            }
          />
        </CollapseCard>
      </div>
    </div>
  );
}
