import { isEqual } from 'lodash';
import { getTenantId } from '../../services/AuthService';

export const getUser = (state) => state.auth.user.content;
export const getOrganizations = (state) => state.auth.organizations.content;
export const isOrganizationsOrRolesHasError = (state) => state.auth.organizations.error || state.auth.roles.error;
export const getRoles = (state) => state.auth.roles.content;
export const isSuperAdmin = (state) => state.auth.roles.isSuperAdmin;
export const isAdmin = (state) => state.auth.roles.isAdmin;
export const getIsGuest = (state) => state.auth.guest.isGuest;
export const isOrganizationLoading = (state) => state.auth.organizations.loading;

const memoize = (fn) => {
  let lastArgs = null;
  let lastResult = null;
  return (...args) => {
    if (lastArgs && isEqual(lastArgs, args)) {
      return lastResult;
    }
    lastArgs = args;
    lastResult = fn(...args);
    return lastResult;
  };
};

const getPermissionsInTenant = memoize((state) => {
  const permissions = state.auth.permissions.content;
  return permissions?.['*'] || permissions?.[getTenantId()] || [];
});

export const isPermitted = (permission) => (state) => {
  const permissions = getPermissionsInTenant(state);
  return permissions.includes(permission);
};

export const isPermittedToViewDashboardPage = (state) => {
  const permissions = getPermissionsInTenant(state);
  return permissions.includes('applications:read') && permissions.includes('insights:read');
};

export const isPermittedToViewApplicationsPage = (state) => {
  const permissions = getPermissionsInTenant(state);
  return permissions.includes('applications:read');
};

export const isPermittedToViewInsightsPage = (state) => {
  const permissions = getPermissionsInTenant(state);
  return permissions.includes('insights:read');
};

export const isPermittedToViewDataPage = (state) => {
  const permissions = getPermissionsInTenant(state);
  return permissions.includes('connections:read');
};

export const isPermittedToViewBiPage = (state) => {
  const permissions = getPermissionsInTenant(state);
  return permissions.includes('bi:read');
};

export const isPermittedToViewUsersPage = (state) => {
  const permissions = getPermissionsInTenant(state);
  return permissions.includes('users:read');
};

export const isPermittedToViewPolicyPage = (state) => {
  const permissions = getPermissionsInTenant(state);
  return permissions.includes('rules:read');
};
