import { TextField } from '@mui/material';
import './large-text-box.scss';

export default function LargeTextBox({
  id,
  message,
  setMessage,
  messageValid,
  setMessageValid,
  maxSize,
  placeholder,
  title,
}) {
  return (
    <div className="large-text-box">
      <div>
        <div>{title}</div>
        <TextField
          id={id}
          multiline
          error={!messageValid}
          rows={4}
          placeholder={placeholder}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
            setMessageValid(e.target.value.length <= maxSize);
          }}
        />
      </div>
      <div className="chars-left">
        {maxSize - message.length}/{maxSize}
      </div>
    </div>
  );
}
