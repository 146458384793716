import { useTranslation } from 'react-i18next';

export function mapStatus(status) {
  const { t } = useTranslation(['common']);

  switch (status) {
    case 'CLOSED':
      return t('insights.closed');
    case 'CLOSED_RESOLVED':
      return t('insights.resolved');
    case 'CLOSED_ACKNOWLEDGED':
      return t('insights.acknowledged');
    case 'REQUIRES_ACKNOWLEDGEMENT':
      return t('insights.requiresAck');
    case 'IN_REMEDIATION':
      return t('insights.inRemediation');
    case 'REQUIRES_RESOLUTION':
      return t('insights.requiresResolution');
    default:
      return t('insights.open');
  }
}
