import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import BooleanValue from '../../../components/boolean-value/BooleanValue';
import { HomePageTable } from './HomePageTable';
import SeverityBox from '../../../components/box/SeverityBox';
import { compareSeverities } from '../../../services/comparisonUtils';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyInsightsImg from '../../insights/empty_insights.png';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';
import { insightType } from '../../../consts/insight-type';
import PlatformName from '../../../components/platform-name/PlatformName';
import { getHomepageStaticInsights, isHomepageStaticInsightsLoading } from '../../../redux/selector/homepageSelector';
import { ColumnType } from '../../../components/table/ColumnOptions';

export default function VulnerableInsightsTable({ inProduction }) {
  const insights = useSelector(getHomepageStaticInsights);
  const loading = useSelector(isHomepageStaticInsightsLoading);
  const { t } = useTranslation(['common', 'homePage']);

  const sortedInsights = insights
    .slice()
    .filter(
      (insight) =>
        insight.type.toLowerCase() === insightType.VULNERABILITY ||
        insight.type.toLowerCase() === insightType.MALICIOUS,
    )
    .sort((a, b) => b.score - a.score);

  const columns = useMemo(
    () => [
      {
        backendKey: 'severity',
        width: '23%',
        accessor: (cell) => cell.severity,
        Cell: ({ cell }) => {
          const severity = cell.value;
          return <SeverityBox severity={severity} />;
        },
        sortType: (rowA, rowB) => {
          const a = rowA.original.severity;
          const b = rowB.original.severity;
          return compareSeverities(a, b);
        },
      },
      {
        backendKey: 'score',
        width: '15%',
        type: ColumnType.NUMBER,
        accessor: ({ score }) => score,
      },
      {
        backendKey: 'name',
        width: '40%',
        accessor: (cell) => cell.name,
        Cell: ({ cell, row }) => (
          <OrganizationAwareLink to={`/insights/${row.original.id}?from=Home page`}>{cell.value}</OrganizationAwareLink>
        ),
      },
      {
        backendKey: 'resourceName',
        width: '35%',
        accessor: ({ resourceName }) => resourceName,
        Cell: ({ cell }) => <div>{cell.value}</div>,
      },
      {
        backendKey: 'platformName',
        width: '28%',
        accessor: ({ platformName }) => platformName,
        Cell: ({ value }) => <PlatformName platform={value} />,
      },
      {
        backendKey: 'inProduction',
        width: '115px',
        accessor: (cell) => cell.inProduction,
        Cell: ({ cell }) => <BooleanValue isTrue={cell.value} />,
        sortType: (rowA, rowB) => {
          const a = rowA.original.inProduction;
          const b = rowB.original.inProduction;
          return b - a; // reverse order so true is first
        },
      },
    ],
    [],
  );

  return (
    <HomePageTable
      type="home.vulnerableInsights"
      title={t('homePageTables.vulnerabilities', { ns: 'homePage' })}
      columns={columns}
      data={sortedInsights}
      isLoading={loading}
      numOfRows={5}
      link={`/insights?tab=all&resolved:is=False&type:is=vulnerability&sort=Priority Score,desc${
        inProduction ? '&inProduction:is=true' : ''
      }`}
      emptyState={
        <TableEmptyContent
          img={emptyInsightsImg}
          mainText={t('homePageTables.noVulnerabilities', { ns: 'homePage' })}
          secondaryText=""
        />
      }
    />
  );
}
