import * as moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Card from '../../../components/card/Card';
import LineChart from '../../../components/chart/line-chart/LineChart';
import { lightBlueColor, lightGreenColor } from '../../../consts/colors';
import { getValues } from '../homePageChartsService';
import { getHomepageDynamicInsights, isHomepageDynamicInsightsLoading } from '../../../redux/selector/homepageSelector';

export default function InsightTrends({ labels = [] }) {
  const insights = useSelector(getHomepageDynamicInsights);
  const loading = useSelector(isHomepageDynamicInsightsLoading);
  const { t } = useTranslation(['homePage']);

  const newInsightsValues = getValues(labels, insights);
  const closedInsight = insights.filter((i) => i.status.toLowerCase().includes('closed'));
  const closedInsightsValues = getValues(labels, closedInsight, 'lastChangeTime');

  const data = {
    labels: labels.map((label) => moment(label).format('DD/MM')),
    datasets: [
      {
        label: t('securityWidget.newInsights'),
        data: newInsightsValues,
        borderColor: lightBlueColor,
        width: '2px',
        tension: 0.2,
      },
      {
        label: t('securityWidget.closedInsights'),
        data: closedInsightsValues,
        borderColor: lightGreenColor,
        width: '2px',
        tension: 0.2,
      },
    ],
  };

  return (
    <Card title={t('securityWidget.insightTrends')} loading={loading}>
      <div className="chart-wrapper">
        <LineChart data={data} id="insight-trends" />
      </div>
    </Card>
  );
}
