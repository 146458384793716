import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CollapseCard from '../../../components/collapse-card/CollapseCard';
import Table from '../../../components/table/Table';
import TableEmptyContent from '../../../components/table/TableEmptyContent';
import emptyApplicationsImg from '../../inventory/empty_applications.svg';
import IconWithText from '../../../components/icon-text/IconWithText';
import groupIcon from '../../connection/icons/group_icon.svg';
import userIcon from '../../connection/icons/user_icon.svg';
import BooleanValue from '../../../components/boolean-value/BooleanValue';
import { mapDate } from '../../../services/MapperUtils';

export default function ApplicationUsersTable({ isLoading, users = [] }) {
  const { t } = useTranslation(['common']);

  const columns = useMemo(() => {
    const typeColumn = {
      Header: t('general.type'),
      accessor: 'type',
      width: '10%',
      Cell: ({ value, cell }) => (
        <IconWithText text={value} img={cell.row.original.type === 'User' ? userIcon : groupIcon} />
      ),
    };

    const nameColumn = {
      Header: t('general.name'),
      accessor: 'name',
      width: '15%',
      Cell: ({ value }) => <div>{value}</div>,
    };

    const emailColumn = {
      Header: t('users.emailAddress'),
      accessor: 'email',
      width: '25%',
      Cell: ({ value }) => <div>{value || t('general.na')}</div>,
    };

    const accessTypeColumn = {
      Header: t('users.accessType'),
      accessor: 'accessType',
      width: '10%',
      Cell: ({ value }) => <div>{value.join(', ')}</div>,
    };

    const activeColumn = {
      Header: t('general.isActive'),
      accessor: 'active',
      width: '10%',
      Cell: ({ value }) => <BooleanValue isTrue={value} />,
    };

    const grantDateColumn = {
      Header: t('users.grantDate'),
      accessor: 'grantDate',
      width: '15%',
      Cell: ({ value }) => <div>{mapDate(value) || t('general.na')}</div>,
    };

    const baseColumns = [typeColumn, nameColumn, emailColumn, accessTypeColumn, activeColumn];
    const hasGrantDate = users.some((user) => !!user.grantDate);
    return hasGrantDate ? [...baseColumns, grantDateColumn] : baseColumns;
  });

  const sortBy = [{ id: 'grantDate', desc: true }];

  return (
    <div className="card-view-table-container">
      <CollapseCard
        title={t('users.sharedWith')}
        totalElementsCount={users.length}
        isLoading={isLoading}
        initialOpen={false}
      >
        <Table
          columns={columns}
          data={users}
          totalCount={users.length}
          options={{ showFilter: false, showPagination: true, showSort: true, usePaginationSearchParam: false }}
          type="users"
          sortBy={sortBy}
          initialFilters={[]}
          customFilters={[]}
          isRowDisabled={() => false}
          getElementId={(user) => `${user.id}-${user.accessType}`}
          isLoading={isLoading}
          classNames="card-view-table"
          backendPagination={false}
          pageSize={5}
          emptyState={<TableEmptyContent img={emptyApplicationsImg} mainText={t('users.noUsers')} />}
        />
      </CollapseCard>
    </div>
  );
}
