import { useState } from 'react';
import classnames from 'classnames';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Tooltip from '@mui/material/Tooltip';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Arrow from '../../../components/arrow/Arrow';
import OrganizationAwareLink from '../../../components/organization-aware-link/OrganizationAwareLink';

import './navbar.scss';
import { getAccounts, getAccountsLoading } from '../../../redux/selector/accountsSelector';
import { POWER_PLATFORM } from '../../../consts/platforms';
import {
  isPermittedToViewApplicationsPage,
  isPermittedToViewBiPage,
  isPermittedToViewDashboardPage,
  isPermittedToViewDataPage,
  isPermittedToViewInsightsPage,
  isPermittedToViewPolicyPage,
  isPermittedToViewUsersPage,
  isSuperAdmin,
} from '../../../redux/selector/authSelector';

export default function Navbar() {
  const { user } = useAuth0();
  const { t } = useTranslation(['navbar', 'common']);
  const accounts = useSelector(getAccounts);
  const accountLoading = useSelector(getAccountsLoading);
  const dataPagesDisabled = accounts.length === 0;
  const hasPowerPlatform = accounts.some((account) => account.platformName === POWER_PLATFORM);

  const currentPath = window.location.pathname;
  const pathes = [
    '/applications',
    '/insights',
    '/settings',
    '/policy',
    '/data',
    '/aggregations',
    '/users',
    '/bi',
    '/settings',
    '/back-office',
  ];
  const currentActiveOption = pathes.find((path) => currentPath.includes(path)) || '/';

  const [profileMenuOpen, setProfileMenuOpen] = useState(false);

  const superAdmin = useSelector(isSuperAdmin);

  const canViewDashboardPage = useSelector(isPermittedToViewDashboardPage);
  const canViewApplicationsPage = useSelector(isPermittedToViewApplicationsPage);
  const canViewInsightsPage = useSelector(isPermittedToViewInsightsPage);
  const canViewDataPage = useSelector(isPermittedToViewDataPage);
  const canViewBiPage = useSelector(isPermittedToViewBiPage);
  const canViewUsersPage = useSelector(isPermittedToViewUsersPage);
  const canViewPolicyPage = useSelector(isPermittedToViewPolicyPage);

  return (
    <div className="navbar">
      <div className="navbar-top">
        <div className="logo" />
      </div>
      <div className="navbar-items">
        <NavbarItem
          paths={['/']}
          title={t('overview')}
          iconId="dashboard_icon"
          activeOption={currentActiveOption}
          disabled={dataPagesDisabled || !canViewDashboardPage}
        />
        <NavbarItem
          paths={['/applications']}
          title={t('applications.applications', { ns: 'common' })}
          iconId="applications_icon"
          activeOption={currentActiveOption}
          disabled={dataPagesDisabled || !canViewApplicationsPage}
        />
        <NavbarItem
          paths={['/insights', '/aggregations']}
          title={t('insights.insights', { ns: 'common' })}
          iconId="insights_icon"
          activeOption={currentActiveOption}
          disabled={dataPagesDisabled || !canViewInsightsPage}
        />

        <NavbarItem
          paths={['/data']}
          title={t('general.dataSources', { ns: 'common' })}
          iconId="data_source_icon"
          activeOption={currentActiveOption}
          disabled={dataPagesDisabled || !canViewDataPage}
        />
        {hasPowerPlatform && (
          <NavbarItem
            paths={['/bi']}
            title={t('bi')}
            iconId="power_bi_icon"
            activeOption={currentActiveOption}
            disabled={dataPagesDisabled || !canViewBiPage}
          />
        )}
        <NavbarItem
          paths={['/users']}
          title={t('users.users', { ns: 'common' })}
          iconId="users_icon"
          activeOption={currentActiveOption}
          disabled={dataPagesDisabled || !canViewUsersPage}
        />
        <NavbarItem
          paths={['/policy']}
          title={t('policy')}
          iconId="policy_icon"
          activeOption={currentActiveOption}
          disabled={dataPagesDisabled || !canViewPolicyPage}
        />
        <NavbarItem
          paths={['/settings']}
          title={t('settings')}
          iconId="settings_icon"
          activeOption={currentActiveOption}
          disabled={accountLoading}
        />
        {superAdmin && (
          <NavbarItem
            paths={['/back-office']}
            title={t('backOffice')}
            iconId="back_office_icon"
            activeOption={currentActiveOption}
            disabled={accountLoading}
          />
        )}
      </div>

      <div className="navbar-bottom">
        <AccountCircleIcon className="account-photo" />
        <div className="profile-name-wrapper">
          <div className="profile-name">{user.name}</div>
          <Arrow value={profileMenuOpen ? 'up' : 'down'} onClick={() => setProfileMenuOpen(!profileMenuOpen)} />
          <div className={classnames('profile-menu', { open: profileMenuOpen })}>
            <ProfileMenuItem
              title={t('logout')}
              onClick={() => {
                window.dispatchEvent(new Event('logout'));
                setProfileMenuOpen(false);
              }}
            />
          </div>
        </div>
        <div className="profile-email">{user.email}</div>
      </div>
    </div>
  );

  function NavbarItem({ title, paths, iconId, activeOption, disabled }) {
    return (
      <OrganizationAwareLink to={`${paths[0]}`} className="gray-link" disabled={disabled}>
        <Tooltip title={disabled ? t('errors.disabled') : ''}>
          <div
            className={classnames('navbar-item', {
              'navbar-item-active': paths.includes(activeOption),
            })}
          >
            <div className="navbar-item-wrapper">
              <span className="navbar-item-icon" id={iconId} />
              <span className="navbar-item-title">{title}</span>
            </div>
          </div>
        </Tooltip>
      </OrganizationAwareLink>
    );
  }
}

function ProfileMenuItem({ title, onClick }) {
  return (
    <div className="profile-menu-item" onClick={onClick}>
      {title}
    </div>
  );
}
