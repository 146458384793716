import { useEffect, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import './copyable-link.scss';

export default function CopyableLink({ text, link }) {
  const { t } = useTranslation(['common']);
  const [hovered, setHovered] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  let timer;

  // there's a gap between mouseleave and tooltip hiding, so keep the tooltip visible for a short time after mouseleave
  useEffect(() => {
    if (hovered) {
      setTooltipVisible(true);
    } else {
      timer = setTimeout(() => setTooltipVisible(false), 100);
    }

    return () => clearTimeout(timer);
  }, [hovered]);

  async function copyLink() {
    await navigator.clipboard.writeText(link);
  }

  return (
    <div className="copyable-link" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
      <a href={link} target="_blank" rel="noreferrer">
        {text}
      </a>
      {tooltipVisible && (
        <div className="copy-link-tooltip-container">
          <Tooltip title={t('general.copyLink')}>
            <IconButton onClick={copyLink} size="small">
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
}
