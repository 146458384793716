import { BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow';
import { useTranslation } from 'react-i18next';
import ownerIcon from '../../icons/owner.svg';
import './owner-edge.scss';

export default function OwnerEdge({ sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, id }) {
  const { t } = useTranslation('entity-map');
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <div
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
          className="owner-edge"
        >
          <img src={ownerIcon} alt="owner" className="owner-icon" />
          {t('owner')}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
