/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { doGet, doPost } from '../../services/HttpService';

const sandboxFlowStatus = {
  EXISTS: 'Exists',
  MISSING: 'Missing',
  DELETED: 'Deleted',
  UPLOADING: 'Uploading',
  FAILED: 'Failed',
};

export const sendCopyFlow = createAsyncThunk(
  'flows/sendCopy',
  async ({ flowId, accountId, environmentId, platformAppId, email }) => {
    const res = await doPost(`application/${flowId}/copy`, {
      accountId,
      environmentId,
      platformAppId,
      copierEmail: email,
    });

    return res.data;
  },
);

export const fetchSandBoxFlowLink = createAsyncThunk(
  'flows/fetchSandBoxFlowLink',
  async ({ flowId, platformAppId }) => {
    const res = await doGet(`application/${flowId}/sandbox-flow-link?platformAppId=${platformAppId}`);

    return res.data;
  },
);

const initialState = {
  loading: {},
  content: {},
  error: {},
  showToast: false,
};

export const sandboxFlowSlice = createSlice({
  name: 'sandboxflow',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(sendCopyFlow.pending, (state, { meta }) => {
      const { platformAppId } = meta.arg;
      state.loading[platformAppId] = true;
      state.error[platformAppId] = false;
    });
    builder.addCase(sendCopyFlow.fulfilled, (state, { payload, meta }) => {
      const { platformAppId } = meta.arg;
      state.loading[platformAppId] = false;
      state.error[platformAppId] = false;
      state.content[platformAppId] = payload;
      state.showToast = true;
    });
    builder.addCase(sendCopyFlow.rejected, (state, { meta }) => {
      const { platformAppId } = meta.arg;
      state.loading[platformAppId] = false;
      state.error[platformAppId] = true;
      state.showToast = true;
    });
    builder.addCase(fetchSandBoxFlowLink.pending, (state, { meta }) => {
      const { platformAppId } = meta.arg;
      state.loading[platformAppId] = true;
      state.error[platformAppId] = false;
    });
    builder.addCase(fetchSandBoxFlowLink.fulfilled, (state, { payload, meta }) => {
      const { platformAppId } = meta.arg;
      const { link, status } = payload;

      switch (status) {
        case sandboxFlowStatus.EXISTS:
          state.content[platformAppId] = link;
          state.error[platformAppId] = false;
          state.loading[platformAppId] = false;
          break;
        case sandboxFlowStatus.MISSING:
          state.content[platformAppId] = undefined;
          state.error[platformAppId] = true;
          state.loading[platformAppId] = false;
          break;
        case sandboxFlowStatus.UPLOADING:
          state.error[platformAppId] = false;
          state.loading[platformAppId] = true;
          break;
        case sandboxFlowStatus.FAILED:
          state.error[platformAppId] = true;
          state.loading[platformAppId] = false;
          break;
        default:
          state.loading[platformAppId] = false;
          state.error[platformAppId] = false;
      }
    });
    builder.addCase(fetchSandBoxFlowLink.rejected, (state, { meta }) => {
      const { platformAppId } = meta.arg;
      state.loading[platformAppId] = false;
      state.error[platformAppId] = true;
    });
  },
});

// this is for configureStore
export default sandboxFlowSlice.reducer;
