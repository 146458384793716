import { createSelector } from '@reduxjs/toolkit';

export const isConnectionsLoading = (state) => state.dataSources.connections.loading;

export const getConnectionName = (dataId) => (state) =>
  state.dataSources.connections.content.find((d) => d.id === dataId)?.name || '';
export const isDataSourcesError = (state) => state.dataSources.connections.error;

const selectConnections = (state) => state.dataSources.connections.content;
export const getConnections = createSelector([selectConnections], (connections) => {
  const content = (connections || [])
    .filter((dataSource) => !dataSource.dependsOnId)
    .reduce((acc, curr) => {
      acc[curr.id] = { ...curr, children: [] };
      return acc;
    }, {});

  (connections || [])
    .filter((dataSource) => dataSource.dependsOnId)
    .forEach((dataSource) => {
      if (content[dataSource.dependsOnId]) {
        content[dataSource.dependsOnId].children.push(dataSource);
      }
    });

  return Object.values(content);
});

export const getFolderDataSources = (dataId) => (state) =>
  (state.dataSources.connections.content || []).filter((dataSource) => dataSource.dependsOnId === dataId);

export const getConnectionsSummariesFilters = (state) => state.dataSources.connections.filters;

export const getConnectionsFiltersOptions = (state) => state.dataSources.connections.filtersOptions;

export const getConnectors = (state) => state.dataSources.connectors.content;
export const getTotalConnectors = (state) => state.dataSources.connectors.total;
export const isConnectorsLoading = (state) => state.dataSources.connectors.loading;
export const getConnectorsFiltersOptions = (state) => state.dataSources.connectors.filtersOptions;
export const getConnectorsFilters = (state) => state.dataSources.connectors.filters;

export const isVettingSuccessful = (state) => state.dataSources.connectors.vetting.successful;
