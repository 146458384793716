import { unionBy } from 'lodash';
import { getOutgoers } from 'reactflow';
import GroupsIcon from '@mui/icons-material/Groups';
import TerminalIcon from '@mui/icons-material/Terminal';
import SourceRoundedIcon from '@mui/icons-material/SourceRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SchemaOutlinedIcon from '@mui/icons-material/SchemaOutlined';
import { ENTITIES } from '../../../consts/Entities';

export const getNode = (nodes, id) => nodes.find((n) => n.id === id);

export function buildGraph(currentNodes, currentEdges, graphData) {
  const nodes = [];
  const edges = [];
  const { id: rootId, name: rootName, entityType, insights: rootInsights, ownerId } = graphData.root;
  const defaultRoot = {
    id: rootId,
    data: { label: rootName, type: entityType, subEntities: {}, insights: rootInsights, entityData: graphData.root },
    position: { x: 0, y: 0 },
    type: 'entityNode',
  };

  const root = getNode(currentNodes, rootId) || defaultRoot;
  nodes.push(root);

  const addNodesAndEdges = (type, data) => {
    data.forEach((item) => {
      const { id, name, insights } = item;
      if (root?.parents?.includes(id)) {
        return;
      }

      const node = getNode(currentNodes, id);
      if (!node) {
        nodes.push({
          id,
          data: { label: name, type, subEntities: {}, insights, entityData: { ...item, entityType: type } },
          position: { x: 0, y: 0 },
          type: 'entityNode',
          hidden: true,
          parents: [rootId],
        });
      } else {
        node.parents?.push(rootId);
      }

      edges.push({
        id: `${rootId}->${id}`,
        source: rootId,
        target: id,
        type: id === ownerId ? 'ownerEdge' : 'default',
        data: { targetType: type },
      });
    });
  };

  // add sub entities
  Object.entries(graphData).forEach(([key, value]) => {
    if (key !== 'root' && value?.length) {
      const subEntitiesWithoutParent = value.filter((item) => !root.parents?.includes(item.id));
      if (subEntitiesWithoutParent.length) {
        addNodesAndEdges(key, value);
        root.data.subEntities = { ...root.data.subEntities, [key]: subEntitiesWithoutParent };
      }
    }
  });

  const merged = unionBy(currentNodes, nodes, 'id');
  return { nodes: merged, edges };
}

export function getEntityIcon(type) {
  switch (type) {
    case ENTITIES.APPLICATIONS:
      return TerminalIcon;
    case ENTITIES.FLOWS:
      return SchemaOutlinedIcon;
    case ENTITIES.USERS:
      return AccountCircleIcon;
    case ENTITIES.CONNECTIONS:
      return SourceRoundedIcon;
    case ENTITIES.GROUPS:
      return GroupsIcon;
    default:
      return TerminalIcon;
  }
}

export function getInsightsObj(insights) {
  const maxSeverity = insights.length ? Math.max(...insights.map((item) => Number(item.severity))) : 0;
  return {
    insightsCount: insights.length,
    maxInsightSeverity: maxSeverity,
  };
}

function isPathBetweenNodes(nodes, sourceId, targetId) {
  const visited = new Set();

  function dfs(currentId) {
    if (currentId === targetId) return true;
    if (visited.has(currentId)) return false;

    visited.add(currentId);

    const currentNode = nodes.find((node) => node.id === currentId);
    const isRoot = !currentNode.parents?.length;
    if (isRoot || currentNode.hidden) return false;

    return currentNode.parents.some((parentId) => dfs(parentId));
  }

  return dfs(sourceId);
}

export function checkIfNodeIsExpandedViaOtherParent(
  nodes,
  subEntitiesStates,
  currentNodeId,
  parentId,
  subEntity,
  parents,
) {
  const otherParents = parents.filter((id) => id !== parentId);
  // all parents are hidden
  if (otherParents.every((id) => getNode(nodes, id).hidden)) {
    return false;
  }
  if (otherParents.length) {
    // if one of the other parents is expanded, return true else false
    return otherParents.filter((id) => !getNode(nodes, id).hidden).some((id) => subEntitiesStates[id]?.[subEntity]);
  }
  return false;
}

export function hideShowChildrenNodes(nodes, edges, parent, subEntitiesStates) {
  const children = getOutgoers(parent, nodes, edges);

  return children.map((node) => {
    let shouldHide;
    const nodeId = node.id;
    const { id: rootId } = nodes[0];
    const { parents } = getNode(nodes, nodeId);

    // if it's the root node, it should not be hidden
    if (rootId === nodeId) {
      shouldHide = false;
    }
    // if the root is the folding node, it should be folded
    else if (parents.includes(rootId)) {
      shouldHide = !subEntitiesStates[rootId][node.data.type];
    }
    // if the parent fold the node or the parent is hidden
    else if (!subEntitiesStates[parent.id][node.data.type] || parent.hidden) {
      const hasOtherExpandedParent = checkIfNodeIsExpandedViaOtherParent(
        nodes,
        subEntitiesStates,
        node.id,
        parent.id,
        node.data.type,
        parents,
      );
      const noRouteToRoot = !isPathBetweenNodes(nodes, node.id, nodes[0].id);
      shouldHide = noRouteToRoot || !hasOtherExpandedParent;
    }

    return { ...node, hidden: shouldHide };
  });
}
