import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  fetchAggregationFilterOptions,
  fetchGroupedBySeverity,
  fetchGroupedByType,
  fetchInsightFilterOptions,
} from '../../redux/slicers/insightSlicer';
import {
  getFilteredInsightAggregations,
  getInsightGroupedBySeverity,
  getInsightGroupedBySeverityLoading,
  getInsightGroupedByType,
  getInsightGroupedByTypeLoading,
  getInsightSummaries,
  getInsightSummariesLoading,
  getInsightTotal,
  getTotalInsightsCount,
} from '../../redux/selector/insightsSelector';
import { getIcon } from '../../icons/iconUtils';
import WidgetBar from '../../components/widget-bar/WidgetBar';
import HorizontalBarChart from '../../components/chart/horizontal-bar/HorizontalBarChart';
import PageHeader from '../../components/page-header/PageHeader';
import { complianceColor, governanceColor, maliciousColor, vulnerabilityColor } from '../../consts/colors';
import BasicTabs from '../../components/basic-tabs/BasicTabs';
import SingleInsightsTable from './SingleInsightsTable';
import InsightAggregationTable from './InsightAggregationTable';
import InsightSeverityWidget from './InsightSeverityWidget';
import { insightsTabs, tabsNameToNumber } from './consts';
import { initialInsightsFilters } from '../../consts/defaultFilters';
import { SINGULAR_FORM } from '../../consts/translations';
import useChangeTab from '../../hooks/useChangeTab';

export default function InsightsPage() {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState('');
  const totalInsightsCount = useSelector(getInsightTotal);
  const insightSummaries = useSelector(getInsightSummaries);
  const insightAggregations = useSelector(getFilteredInsightAggregations);
  const insightsLoading = useSelector(getInsightSummariesLoading);
  const insightsByType = useSelector(getInsightGroupedByType);
  const insightsBySeverity = useSelector(getInsightGroupedBySeverity);
  const insightsByTypeLoading = useSelector(getInsightGroupedByTypeLoading);
  const insightsBySeverityLoading = useSelector(getInsightGroupedBySeverityLoading);
  const totalInsights = useSelector(getTotalInsightsCount);

  const { t } = useTranslation(['common', 'insights']);

  const changeTab = useChangeTab(selectedTab, setSelectedTab, insightsTabs.ALL);

  useEffect(() => {
    if (selectedTab === insightsTabs.AGGREGATIONS) {
      dispatch(fetchGroupedByType(initialInsightsFilters));
      dispatch(fetchGroupedBySeverity(initialInsightsFilters));
    }
  }, []);

  useEffect(() => {
    (async function fetchData() {
      await Promise.all([dispatch(fetchInsightFilterOptions()), dispatch(fetchAggregationFilterOptions())]);
    })();
  }, []);

  const [, setOpen] = useState(true);
  setTimeout(() => setOpen(false), 2000);

  return (
    <div className="insights-page">
      <PageHeader title={t('insights.insights', { ns: 'common' })} counter={totalInsights} />
      <WidgetBar
        boxes={[
          {
            title: t('general.type'),
            flex: 2,
            content: <TypeWidget insightsByType={insightsByType} loading={insightsByTypeLoading} />,
          },
          {
            title: t('insights.severity'),
            flex: 1,
            content: (
              <InsightSeverityWidget insightsBySeverity={insightsBySeverity} loading={insightsBySeverityLoading} />
            ),
          },
        ]}
      />

      <BasicTabs
        initialTab={tabsNameToNumber[selectedTab]}
        tabsData={[
          {
            label: t('allInsights', { ns: 'insights' }),
            onClick: () => changeTab(insightsTabs.ALL),
          },
          {
            label: t('aggregations', { ns: 'insights' }),
            onClick: () => changeTab(insightsTabs.AGGREGATIONS),
          },
        ]}
      />

      {selectedTab === insightsTabs.ALL ? (
        <SingleInsightsTable
          totalInsights={totalInsightsCount}
          data={insightSummaries}
          loading={insightsLoading}
          showFilter
        />
      ) : (
        <InsightAggregationTable data={insightAggregations} />
      )}
    </div>
  );
}

function getTypeValue(insightsByType, key) {
  const res = insightsByType.find((x) => x.key === key)?.value || 0;
  return Number(res);
}

function TypeWidget({ insightsByType, loading }) {
  const { t } = useTranslation(['insights', 'common']);
  const data = [];

  data.push({
    name: t('insights.malicious', { ns: 'common' }),
    value: getTypeValue(insightsByType, 'malicious'),
    color: maliciousColor,
    icon: getIcon('malicious'),
  });
  data.push({
    name: t('insights.vulnerability', { ns: 'common', count: SINGULAR_FORM }),
    value: getTypeValue(insightsByType, 'vulnerability'),
    color: vulnerabilityColor,
    icon: getIcon('vulnerability'),
  });
  data.push({
    name: t('insights.compliance', { ns: 'common' }),
    value: getTypeValue(insightsByType, 'compliance'),
    color: complianceColor,
    icon: getIcon('compliance'),
  });
  data.push({
    name: t('insights.governance', { ns: 'common' }),
    value: getTypeValue(insightsByType, 'governance'),
    color: governanceColor,
    icon: getIcon('governance'),
  });

  return (
    <HorizontalBarChart
      data={data}
      loading={loading}
      navigatePath="/insights"
      navigateQueryParam="resolved:is=False&type:is"
    />
  );
}
