import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import BasicTabs from '../../components/basic-tabs/BasicTabs';
import PlatformsSettingsPage from './platforms/PlatformsPage';
import NotificationSettingsPage from './notifications/NotificationSettingsPage';

import './settings.scss';

export default function SettingsPage() {
  const { t } = useTranslation(['settings']);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(searchParams.get('tab') || 'platforms');

  function changeTab(tab) {
    setSelectedTab(tab);
    setSearchParams({ tab });
  }

  const tabs = [
    {
      id: 'platforms',
      label: t('platforms.title'),
      onClick: () => changeTab('platforms'),
    },
    {
      id: 'notifications',
      label: t('notifications.title'),
      onClick: () => changeTab('notifications'),
    },
  ];

  const initialTabIndex = tabs.findIndex((tab) => tab.id === selectedTab);

  return (
    <div className="settings-page">
      <BasicTabs tabsData={tabs} initialTab={initialTabIndex} />
      {selectedTab === 'platforms' && <PlatformsSettingsPage />}
      {selectedTab === 'notifications' && <NotificationSettingsPage />}
    </div>
  );
}
