import TextField from '@mui/material/TextField';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classNames from 'classnames';

import './input-number.scss';

export default function InputNumber({
  id,
  label,
  value,
  setValue,
  disabled = false,
  minValue = Number.MIN_VALUE,
  maxValue = Number.MAX_VALUE,
}) {
  function handleClickUp() {
    if (!disabled && value < maxValue) {
      setValue(value + 1);
    }
  }

  function handleClickDown() {
    if (!disabled && value > minValue) {
      setValue(value - 1);
    }
  }

  function handleChange(e) {
    const newValue = e.target.value;
    if (newValue >= minValue && newValue <= maxValue) {
      setValue(newValue);
    }
  }

  return (
    <div className={classNames('input-number-container', { disabled })}>
      <TextField
        disabled={disabled}
        id={id}
        label={label}
        value={value}
        onChange={handleChange}
        type="number"
        slotProps={{
          inputLabel: {
            shrink: true,
          },
        }}
      />
      <KeyboardArrowUpIcon className="input-number-arrow arrow-up" onClick={handleClickUp} />
      <KeyboardArrowDownIcon className="input-number-arrow arrow-down" onClick={handleClickDown} />
    </div>
  );
}
