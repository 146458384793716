import { useEffect, useMemo, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  fetchGroupedBySeverity,
  fetchGroupedByType,
  updateInsightFiltersAndRefetchSummaries,
} from '../../redux/slicers/insightSlicer';
import { getInsightsFiltersOptions, getInsightSummariesFilters } from '../../redux/selector/insightsSelector';
import AcknowledgeButton from '../../components/aknowledge/AcknowledgeButton';
import TableEmptyContent from '../../components/table/TableEmptyContent';
import Table from '../../components/table/Table';
import SeverityBox from '../../components/box/SeverityBox';
import { compareSeverities, compareStatuses, compareTypes } from '../../services/comparisonUtils';
import InsightTypeBox from '../../components/box/InsightTypeBox';
import { mapDate, mapInsightFilter } from '../../services/MapperUtils';
import InsightScope from '../../components/insight-scope/InsightScope';
import BooleanValue from '../../components/boolean-value/BooleanValue';
import CircledText from '../../components/circled-text/CircledText';
import PlatformName from '../../components/platform-name/PlatformName';
import EllipsisText from '../../components/ellipsis-text/EllipsisText';
import OrganizationAwareLink from '../../components/organization-aware-link/OrganizationAwareLink';
import { insightStatus } from '../../consts/insight-status';
import { initialInsightsFilters } from '../../consts/defaultFilters';

import emptyInsightsImg from './empty_insights.png';
import './insights-page.scss';
import { ColumnType } from '../../components/table/ColumnOptions';

const CLOSED_STATUSES = [insightStatus.CLOSED, insightStatus.CLOSED_ACKNOWLEDGED, insightStatus.CLOSED_RESOLVED];
// TODO change to isActive

export default function SingleInsightsTable({
  data,
  totalInsights,
  backendPagination,
  showFilter = true,
  showAcknowledgeButton = true,
  loading,
  onRowSelected = () => {},
}) {
  const dispatch = useDispatch();
  const [currentFilters, setCurrentFilters] = useState();
  const filterOptions = useSelector(getInsightsFiltersOptions);

  const { t } = useTranslation(['common']);

  useEffect(() => {
    dispatch(fetchGroupedByType(currentFilters));
    dispatch(fetchGroupedBySeverity(currentFilters));
  }, [currentFilters]);

  const columns = useMemo(() => {
    const arr = [
      {
        backendKey: 'severity',
        minWidth: '100px',
        width: '12%',
        type: ColumnType.STRING,
        accessor: ({ severity }) => severity,
        Cell: ({ cell }) => {
          const severity = cell.value;
          return <SeverityBox severity={severity} />;
        },
        sortType: (rowA, rowB) => {
          const a = rowA.original.severity;
          const b = rowB.original.severity;
          return compareSeverities(a, b);
        },
      },
      {
        backendKey: 'score',
        width: '10%',
        type: ColumnType.NUMBER,
        accessor: ({ score }) => score,
      },
      {
        backendKey: 'scopeSubType',
        minWidth: '130px',
        width: '14%',
        type: ColumnType.STRING,
        accessor: ({ scopeSubType }) => scopeSubType,
        Cell: ({ cell }) => <InsightScope insightScopeSubType={cell.value} />,
      },
      {
        backendKey: 'name',
        width: '30%',
        type: ColumnType.STRING,
        accessor: ({ name }) => name,
        Cell: ({ cell, row }) => {
          const summary = row.original;
          return (
            <OrganizationAwareLink to={`/insights/${row.id}?from=Insights`}>
              <span id={summary.id} data-tooltip-content={summary.description}>
                {cell.value}
                <Tooltip anchorId={summary.id} className="insight-summery-tooltip" />
              </span>
            </OrganizationAwareLink>
          );
        },
      },
      {
        backendKey: 'type',
        minWidth: '145px',
        width: '15%',
        type: ColumnType.STRING,
        accessor: ({ type }) => type,
        Cell: ({ cell }) => {
          const { value } = cell;
          return <InsightTypeBox insightType={value} hasBorder={false} isFilled={false} hasPadding={false} />;
        },
        sortType: (rowA, rowB) => {
          const a = rowA.original.type;
          const b = rowB.original.type;
          return compareTypes(a, b);
        },
      },
      {
        backendKey: 'resourceName',
        width: '25%',
        type: ColumnType.STRING,
        accessor: ({ resourceName }) => resourceName,
        Cell: ({ cell }) => <EllipsisText text={cell.value} />,
      },
      {
        backendKey: 'creationTime',
        width: '13%',
        type: ColumnType.DATE,
        accessor: ({ creationTime }) => creationTime,
        Cell: ({ cell }) => {
          const time = cell.value;
          return <span className="gray">{mapDate(time)}</span>;
        },
      },
      {
        backendKey: 'closedAt',
        width: '11%',
        type: ColumnType.DATE,
        accessor: ({ closedAt }) => closedAt,
        Cell: ({ cell }) => {
          const time = cell.value;
          return <span className="gray">{mapDate(time)}</span>;
        },
      },
      {
        backendKey: 'inProduction',
        minWidth: '130px',
        width: '13%',
        type: ColumnType.BOOLEAN,
        accessor: ({ inProduction }) => inProduction,
        Cell: ({ cell }) => {
          const inProduction = cell.value;
          return <BooleanValue isTrue={inProduction} />;
        },
        sortType: (rowA, rowB) => {
          const a = rowA.original.inProduction;
          const b = rowB.original.inProduction;
          return b - a; // reverse order so true is first
        },
      },
      {
        backendKey: 'status',
        minWidth: '170px',
        width: '22%',
        type: ColumnType.STRING,
        accessor: ({ status }) => status,
        Cell: ({ cell }) => {
          const status = cell.value;
          return <CircledText text={status} />;
        },
        sortType: (rowA, rowB) => {
          const a = rowA.original.status;
          const b = rowB.original.status;
          return compareStatuses(a, b);
        },
      },
      {
        backendKey: 'accountName',
        width: '13%',
        type: ColumnType.STRING,
        accessor: (cell) => cell.accountName,
        Cell: ({ value, row }) => (
          <EllipsisText
            text={<PlatformName platform={row.original.platformName} name={value} />}
            overrideTooltipText={value}
          />
        ),
      },
      {
        backendKey: 'ownerName',
        minWidth: '150px',
        width: '15%',
        type: ColumnType.STRING,
        accessor: ({ ownerName }) => ownerName,
        Cell: ({ cell }) => {
          const ownerName = cell.value;
          return <EllipsisText text={ownerName} />;
        },
      },
    ];

    return arr;
  }, []);

  const sortBy = [
    {
      id: 'Priority Score',
      desc: true,
    },
  ];

  return (
    <Table
      classNames="scrollable-table"
      columns={columns}
      data={data}
      backendPagination={backendPagination}
      totalCount={totalInsights}
      options={{ showFilter, showHeaders: true }}
      type="insights"
      sortBy={sortBy}
      exportPath="insights/summary/csv"
      exportFileName="Nokod Insights"
      defaultFilters={initialInsightsFilters}
      fetchDataWithFilters={(filters, orderBy, order, page) => {
        dispatch(updateInsightFiltersAndRefetchSummaries({ filters, orderBy, order, page }));
        setCurrentFilters(filters);
      }}
      getFiltersSelector={getInsightSummariesFilters}
      customFilters={[
        {
          Header: t('insights.resolved'),
          options: ['true', 'false'],
          backendKey: 'resolved',
        },
        {
          Header: t('resourceActivity', { ns: 'insights' }),
          options: ['true', 'false'],
          backendKey: 'resourceActive',
        },
        {
          Header: t('general.lastChange'),
          backendKey: 'lastChange',
        },
      ]}
      contextualActions={[
        (selectedInsights, filters, orderBy, order, page) =>
          showAcknowledgeButton && (
            <AcknowledgeButton
              key="acknowledge"
              insights={selectedInsights}
              text={t('insights.acknowledge')}
              onAcknowledge={() =>
                dispatch(
                  updateInsightFiltersAndRefetchSummaries({ filters, orderBy, order, page, invalidateCache: true }),
                )
              }
            />
          ),
      ]}
      onRowSelected={onRowSelected}
      isRowDisabled={(insight) => CLOSED_STATUSES.includes(insight?.status)}
      getElementId={(insight) => insight.id}
      filtersOptions={filterOptions}
      mapFilterFunction={mapInsightFilter}
      isLoading={loading}
      emptyState={
        <TableEmptyContent
          img={emptyInsightsImg}
          mainText={t('insights.noInsights')}
          secondaryText={t('tables.makeDifferentFilterSelection')}
        />
      }
      defaultSearchColumn="resourceName"
      defaultSearchColumnName={t('tables.insights.columns.resourceName')}
    />
  );
}
