import { useNavigate } from 'react-router-dom';
import Loader from '../../components/loader/Loader';

export default function HomePageCounter({ count, loading, text, src, navigateUrl }) {
  const navigate = useNavigate();
  const orgName = window.location.pathname.split('/')[1];

  return loading ? (
    <Loader />
  ) : (
    <div
      className="home-page-counter"
      onClick={() => {
        if (navigateUrl) {
          navigate(`/${orgName}/portal${navigateUrl}`);
        }
      }}
    >
      <div className="total-applications-content">
        <img src={src} alt="applications" />
        <div className="value">{count.toLocaleString()}</div>
      </div>
      {text}
    </div>
  );
}
