import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import DropdownBox from '../../components/dropdown-box/DropdownBox';
import { getTenantId } from '../../services/AuthService';

const supportedLanguages = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'pt',
    name: 'Portugeeze',
  },
];

const AB_INBEV_TENANT_ID = '8f5498bd-a2ac-43b7-97d7-7c1a5d0a5463';
export default function LanguagePicker() {
  const { t, i18n } = useTranslation(['homePage']);
  const { user } = useAuth0();

  const [, setAnchorEl] = useState(null);
  const currentLanguage = supportedLanguages.find((language) => language.code === i18n.resolvedLanguage);
  const [selectedLanguage, setSelectedLanguage] = useState(currentLanguage);

  if (!selectedLanguage) {
    return null;
  }

  if (!(user?.email.includes('nokodsecurity.com') || getTenantId() === AB_INBEV_TENANT_ID)) {
    return null;
  }

  async function handleClose(language) {
    setAnchorEl(null);
    if (language.code) {
      await i18n.changeLanguage(language.code);
      setSelectedLanguage(language);
      window.location.reload(); // get rid of React.memo
    }
  }

  return (
    <div className="organization">
      <DropdownBox
        width={240}
        text={t('languages.languages')}
        value={t(`languages.${selectedLanguage.code}`)}
        selectedId={selectedLanguage.code}
        menuItems={supportedLanguages.map((language) => ({
          id: language.code,
          onClick: () => handleClose(language),
          text: t(`languages.${language.code}`),
        }))}
      />
    </div>
  );
}
